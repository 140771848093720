/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/run-scripts.directive";
import * as i3 from "../../pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./ad.component";
import * as i7 from "@angular/router";
import * as i8 from "../../services/station.service";
var styles_AdComponent = [i0.styles];
var RenderType_AdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdComponent, data: {} });
export { RenderType_AdComponent as RenderType_AdComponent };
function View_AdComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["runScripts", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.RunScriptsDirective, [i1.ElementRef], null, null), i1.ɵppd(3, 1)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.render())); _ck(_v, 1, 0, currVal_0); }); }
export function View_AdComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.renderAd; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AdComponent_0, RenderType_AdComponent)), i1.ɵdid(1, 4308992, null, 0, i6.AdComponent, [i7.Router, i8.StationService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdComponentNgFactory = i1.ɵccf("app-ad", i6.AdComponent, View_AdComponent_Host_0, { path: "path", sizes: "sizes", sizesDesktop: "sizesDesktop", sizesMobile: "sizesMobile", wallpaper: "wallpaper", metadata: "metadata", renderAd: "renderAd", margin: "margin", padding: "padding" }, {}, []);
export { AdComponentNgFactory as AdComponentNgFactory };
