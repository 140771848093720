/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secret-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../video/video.component.ngfactory";
import * as i5 from "../video/video.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./secret-code.service";
import * as i8 from "@angular/common/http";
import * as i9 from "./secret-code.component";
var styles_SecretCodeComponent = [i0.styles];
var RenderType_SecretCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecretCodeComponent, data: {} });
export { RenderType_SecretCodeComponent as RenderType_SecretCodeComponent };
function View_SecretCodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "section", [["class", "secret-code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "secret-code-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["C\u00F3digo Secreto"])), (_l()(), i1.ɵeld(3, 0, null, null, 21, "div", [["class", "secret-code-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "secret-code-panel-initial"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "hide": 0, "remove": 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["class", "secret-code-panel-title"], ["src", "/assets/images/codigo-secreto-texto-inicial.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "input", [["class", "secret-code-panel-input"], ["placeholder", "***********"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.code = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "button", [["class", "secret-code-panel-button"]], [[8, "innerText", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "secret-code-panel-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "p", [["class", "secret-code-panel-feedback"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "animate": 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["alt", ""], ["class", "secret-code-panel-chest"], ["src", "/assets/images/codigo-secreto-bau-fechado.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.animateChest() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "secret-code-panel-result"]], null, null, null, null, null)), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "open": 0, "add": 1 }), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["alt", ""], ["class", "secret-code-panel-title"], ["src", "/assets/images/codigo-secreto-texto-final.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "img", [["alt", ""], ["class", "secret-code-panel-chest-result"], ["src", "/assets/images/codigo-secreto-bau-aberto.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showSecret() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "secret-code-panel-initial"; var currVal_1 = _ck(_v, 6, 0, _co.hitTheCode, _co.removePanel); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_9 = _co.code; _ck(_v, 11, 0, currVal_9); var currVal_12 = "secret-code-panel-feedback"; var currVal_13 = _ck(_v, 18, 0, _co.animateFeedback); _ck(_v, 17, 0, currVal_12, currVal_13); var currVal_14 = "secret-code-panel-result"; var currVal_15 = _ck(_v, 22, 0, _co.showResult, _co.addResult); _ck(_v, 21, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = "Enviar"; _ck(_v, 14, 0, currVal_10); var currVal_11 = _co.feedback; _ck(_v, 16, 0, currVal_11); }); }
function View_SecretCodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-video", [], null, null, null, i4.View_VideoComponent_0, i4.RenderType_VideoComponent)), i1.ɵdid(2, 638976, null, 0, i5.VideoComponent, [i1.ElementRef, i6.DomSanitizer], { url: [0, "url"], fixable: [1, "fixable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secretUrl; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SecretCodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecretCodeComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecretCodeComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showVideo; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showVideo; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SecretCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-secret-code", [], null, null, null, View_SecretCodeComponent_0, RenderType_SecretCodeComponent)), i1.ɵprd(512, null, i7.SecretCodeService, i7.SecretCodeService, [i8.HttpClient]), i1.ɵdid(2, 49152, null, 0, i9.SecretCodeComponent, [i7.SecretCodeService], null, null)], null, null); }
var SecretCodeComponentNgFactory = i1.ɵccf("app-secret-code", i9.SecretCodeComponent, View_SecretCodeComponent_Host_0, { requestData: "data" }, {}, []);
export { SecretCodeComponentNgFactory as SecretCodeComponentNgFactory };
