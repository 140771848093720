<section class="secret-code" *ngIf="!showVideo">
  <h2 class="secret-code-title">Código Secreto</h2>

  <div class="secret-code-panel">
    <div class="secret-code-panel-initial" [ngClass]="{'hide': hitTheCode, 'remove': removePanel}">
      <img src="/assets/images/codigo-secreto-texto-inicial.png" class="secret-code-panel-title" alt="">

      <input type="text" class="secret-code-panel-input" [(ngModel)]="code" placeholder="***********">

      <button class="secret-code-panel-button" (click)="submitCode()" [innerText]="'Enviar'"></button>

      <div class="secret-code-panel-wrapper">
        <p class="secret-code-panel-feedback" [ngClass]="{'animate': animateFeedback}" [innerText]="feedback"></p>

        <img src="/assets/images/codigo-secreto-bau-fechado.png" (click)="animateChest()" class="secret-code-panel-chest"
          alt="">
      </div>
    </div>

    <div class="secret-code-panel-result" [ngClass]="{'open': showResult, 'add': addResult}">
      <img src="/assets/images/codigo-secreto-texto-final.png" class="secret-code-panel-title" alt="">

      <img (click)="showSecret()" src="/assets/images/codigo-secreto-bau-aberto.png" class="secret-code-panel-chest-result"
        alt="">
    </div>
  </div>
</section>

<section *ngIf="showVideo" class="wrapper">
  <app-video [url]="secretUrl" [fixable]="false"></app-video>
</section>
