var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from '@angular/common';
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ComscoreService } from 'src/app/services/comscore.service';
import { TitleService } from 'src/app/services/title.service';
import { getURL } from 'src/app/utils/util.getUrl';
import { normalizeURL } from '../../utils/util.normalize';
import { Medias, Playlists } from './faq.service';
export class FAQ {
    constructor(analytics, activatedRoute, comscore, location, medias, playlists, title, router) {
        this.analytics = analytics;
        this.activatedRoute = activatedRoute;
        this.comscore = comscore;
        this.location = location;
        this.medias = medias;
        this.playlists = playlists;
        this.title = title;
        this.router = router;
        this.activeDoubt = -1;
        this.paging = false;
        this.urlCategory = 0;
        this.urlDoubt = '';
        this.urlSearch = '';
        this.itemsBreadcrumb = [];
        this.loader = true;
        this.searchTerm = '';
        this.showActiveDoubt = false;
        this.showCategories = false;
        this.showDoubts = false;
        this.showErrorMessage = false;
        this.showMenu = false;
        this.showSeeMore = false;
        this.showSearch = false;
        this.breadcrumbPieceRoute = '';
    }
    handleActiveAccordionDoubt(item) {
        if (item === this.activeDoubt)
            return this.setActiveDoubt(-1);
        this.analytics.sendEvent('Open Accordion', 'click', this.activeCategory['title']);
        this.setActiveDoubt(item);
    }
    handleActiveDoubt(doubt, termSearched) {
        let category = {};
        this.data.map(el => el['doubts'].map((x) => {
            if (doubt !== x)
                return false;
            category = el;
        }));
        !doubt.keywords ? doubt.keywords = Array.from(doubt.opcional.split(',')) : doubt.keywords;
        const urlSearch = location.href.split('=')[1];
        if (termSearched) {
            this.breadcrumbPieceRoute = `/faq/search?q=${termSearched}`;
            category['title'] = termSearched;
        }
        else if (urlSearch) {
            category['title'] = urlSearch;
            this.breadcrumbPieceRoute = `/faq/search?q=${urlSearch}`;
        }
        else {
            this.breadcrumbPieceRoute = `/faq/${category['id']}`;
        }
        const breadcrumbTitle = doubt.title.length > 50 ? doubt.title.substring(0, 50).concat('...') : doubt.title;
        this.itemsBreadcrumb = [{ route: '/faq', title: 'Faq' }, { route: this.breadcrumbPieceRoute, title: category['title'] }, { route: '', title: breadcrumbTitle }];
        this.location.replaceState(`/faq/duvida/${doubt.id}-${normalizeURL(doubt.title)}`);
        this.analytics.sendPageView(getURL(), normalizeURL(doubt.title), '');
        this.comscore.sendPageView(getURL());
        this.analytics.sendEvent('Open doubt', 'click', `${doubt.id}-${normalizeURL(doubt.title)}`);
        this.setDoubt(doubt);
        this.setShowActiveDoubt(true);
        this.setShowDoubts(false);
        this.setShowMenu(true);
        this.setShowSeeMore(false);
        this.setLoader(false);
        this.resetForm();
    }
    handleActiveCategory(category) {
        this.resetForm();
        this.state = 'category';
        const [selectedCategory] = this.data.filter(({ id }) => id === category);
        this.itemsBreadcrumb = [{ route: '/faq', title: 'Faq' }, { route: '', title: selectedCategory['title'] }];
        this.location.replaceState(`/faq/${selectedCategory['id']}`);
        this.analytics.sendPageView(getURL(), normalizeURL(selectedCategory['title']), '');
        this.comscore.sendPageView(getURL());
        this.setActiveDoubt(-1);
        this.setLoader(true);
        this.setShowActiveDoubt(false);
        this.setShowDoubts(false);
        this.setShowCategories(false);
        this.setShowSearch(false);
        this.setShowSeeMore(false);
        this.categories = [selectedCategory, ...this.data.filter(({ id }) => id !== selectedCategory['id'])];
        this.data
            .filter(({ id }) => id === category)
            .map((el) => {
            el.doubts = el.doubts.map((doubt) => {
                doubt.keywords = doubt.opcional.split(',').map(el => el.trim());
                return doubt;
            });
            if (el.next.moreResults === 'MORE_RESULTS_AFTER_LIMIT')
                this.setShowSeeMore(true);
            this.setActiveCategory(el);
            this.setDoubts(el.doubts);
            this.setLoader(false);
            this.setShowDoubts(true);
            this.setShowMenu(true);
            this.scrollMenuCategories(-999);
        });
    }
    handleKeyword(keyword) {
        this.analytics.sendEvent('Keyword clicked', 'click', keyword);
        this.setActiveDoubt(-1);
        this.setLoader(true);
        this.setShowActiveDoubt(false);
        this.handleSearch(keyword);
        this.setSearchTerm(keyword);
    }
    handlePagination() {
        if (this.paging)
            return;
        this.paging = true;
        this.categories
            .filter((el) => el.id === this.activeCategory['id'])
            .map((el) => {
            this.medias
                .get(this.activeCategory['id'], el.next.endCursor)
                .then(({ next, results }) => {
                el.next = next;
                el.doubts = [...el.doubts, ...results];
                if (next.moreResults !== 'MORE_RESULTS_AFTER_LIMIT')
                    this.setShowSeeMore(false);
                this.setDoubts(el.doubts);
                this.paging = false;
            })
                .catch(err => {
                throw err;
            });
        });
    }
    handleFormSearch(form) {
        if (!form.controls.search.value) {
            this.router.navigate(['/faq']);
            this.setShowCategories(true);
            this.setShowActiveDoubt(false);
            this.setShowDoubts(false);
            this.setShowMenu(false);
            this.itemsBreadcrumb = [];
        }
        else {
            this.state = 'search';
            this.setShowCategories(false);
            this.setShowActiveDoubt(false);
            this.setShowMenu(false);
            this.setShowDoubts(false);
            this.setLoader(true);
            this.handleSearch(form.value.search);
            this.setSearchTerm(form.value.search);
            this.itemsBreadcrumb = [{ route: '', title: 'Faq' }];
        }
    }
    handleSearch(term) {
        this.itemsBreadcrumb = [{ route: '', title: 'faq' }];
        this.location.replaceState(`/faq/search?q=${normalizeURL(term)}`);
        this.setShowErrorMessage(false);
        this.medias.search(normalizeURL(term), '').then(({ results }) => {
            this.doubts = results.map(el => {
                el.id = el['tableid'];
                return el;
            });
            const [activeCategory] = this.data;
            this.setActiveCategory(activeCategory);
            this.setShowMenu(true);
            this.setLoader(false);
            this.setShowDoubts(true);
            this.setShowSearch(true);
        });
    }
    ngOnInit() {
        this.title.setTitle('FAQ - SBT');
        this.activatedRoute.params.subscribe((params) => {
            this.urlCategory = Number(params.urlCategory);
            this.urlDoubt = params.urlDoubt;
            this.urlSearch = params.urlSearch;
        });
        this.playlists.get('')
            .then(({ results }) => __awaiter(this, void 0, void 0, function* () {
            if (!results) {
                this.setLoader(false);
                this.setShowErrorMessage(true);
                return false;
            }
            const playlists = results
                .map(({ id, opcionalplaylist3: image, title }) => ({
                id,
                image: image.replace(/\/tn\//, '/') || '/assets/images/sbt-icon-image-broken-fallback.png',
                title
            }));
            const categories = yield Promise.all(playlists.map(({ id, image, title }) => this.medias.get(id, '').then(({ next, results: doubts }) => {
                doubts = doubts.map((el) => {
                    el.description = el.description.replace(/<p>/gim, '<p style="margin: 0 0 8px; padding: 0;">');
                    el.description = el.description.replace(/<img/gim, '<img style="width: 100%; height: auto;"');
                    el.description = el.description.replace(/<figure/gim, '<figure style="margin: 12px auto;"');
                    el.description = el.description.replace(/<figcaption/gim, '<figcaption style="font-size: 14px; margin: 4px 0 0; text-align: center;"');
                    return el;
                });
                return {
                    doubts,
                    id,
                    image,
                    next,
                    title
                };
            })));
            this.categories = categories;
            this.data = categories;
            if (!this.urlCategory && !this.urlDoubt && !this.urlSearch.length) {
                this.setLoader(false);
                this.setShowCategories(true);
            }
            if (this.urlCategory && !this.urlDoubt && !this.urlSearch.length) {
                return this.handleActiveCategory(this.urlCategory);
            }
            if (!this.urlCategory && this.urlDoubt && !this.urlSearch.length) {
                const id = Number(this.urlDoubt.substring(0, this.urlDoubt.indexOf('-')));
                this.data.map((category) => {
                    const filtered = category.doubts.filter((doubt) => {
                        if (doubt.id !== id)
                            return false;
                        doubt.keywords = doubt.opcional.split(',').map(el => el.trim());
                        return doubt;
                    });
                    if (!filtered.length) {
                        this.setLoader(false);
                        this.setShowErrorMessage(true);
                        return;
                    }
                    this.setActiveCategory(category);
                    return filtered.map((el) => {
                        this.handleActiveDoubt(el, ' ');
                    });
                });
            }
            if (!this.urlCategory && !this.urlDoubt && this.urlSearch !== '') {
                this.handleSearch(new URLSearchParams(new URL(window.location.href).search).get('q'));
                this.setSearchTerm(location.href.split('=')[1]);
            }
        }))
            .catch(err => {
            throw err;
        });
    }
    scrollMenuCategories(left) {
        if (!this.menuCategories)
            return;
        this.menuCategories.nativeElement.scrollBy({
            left,
            behavior: 'smooth'
        });
    }
    setActiveDoubt(item) {
        this.activeDoubt = item;
    }
    setActiveCategory(category) {
        this.activeCategory = category;
    }
    setDoubt(doubt) {
        this.doubt = doubt;
    }
    setDoubts(doubts) {
        this.doubts = doubts;
    }
    setLoader(bool) {
        this.loader = bool;
    }
    setSearchTerm(term) {
        this.searchTerm = term;
    }
    setShowCategories(bool) {
        this.showCategories = bool;
    }
    setShowActiveDoubt(bool) {
        this.showActiveDoubt = bool;
    }
    setShowDoubts(bool) {
        this.showDoubts = bool;
    }
    setShowErrorMessage(bool) {
        this.showErrorMessage = bool;
    }
    setShowMenu(bool) {
        this.showMenu = bool;
    }
    setShowSearch(bool) {
        this.showSearch = bool;
    }
    setShowSeeMore(bool) {
        this.showSeeMore = bool;
    }
    resetForm() {
        document.querySelector('form').reset();
    }
}
