import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class TitleService {
    constructor(titleService) {
        this.titleService = titleService;
        this.title = new BehaviorSubject(null);
        this.titleChange$ = this.title.asObservable();
        // this.title.next(this.getTitle())
    }
    getTitleChangeObservable() {
        return this.titleChange$;
    }
    getTitle() {
        return this.titleService.getTitle();
    }
    /**
     * Set the title of the current HTML document.
     * @param newTitle
     */
    setTitle(newTitle) {
        this.titleService.setTitle(newTitle);
        this.title.next(newTitle);
    }
}
TitleService.ɵprov = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Title)); }, token: TitleService, providedIn: "root" });
