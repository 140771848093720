import { decrypt, encryptFull } from '../utils/util.encrypt';
import { Log } from '../utils/util.console';
import * as i0 from "@angular/core";
export class AnalyticsService {
    constructor() { }
    setAnalyticsID(GAConfig) { }
    sendPageView(page, title, tracker) {
        const userLogged = decrypt(localStorage.getItem('user') || '');
        let userEncryptedToGA = '';
        if (userLogged)
            userEncryptedToGA = encryptFull(userLogged['email'], '541278abcd4564654abdAc', '23123908abcde67532acc6');
        this.sendDataLayerEvent({
            event: "pageview-sbt",
            user_id: userEncryptedToGA
        });
    }
    sendEvent(action, category, label = '', value = 1) { }
    sendDataLayerEvent(obj) {
        Log('GA', obj, true);
        dataLayer.push(obj);
    }
}
AnalyticsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(); }, token: AnalyticsService, providedIn: "root" });
