<ng-container *ngIf="isNoticeNews; else internalBlock">
  <section *ngIf="content" class="cards">
    <a class="card" [ngClass]="[size]" [href]="link" target="_blank">
      <div class="card-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="
            content.thumbnail || content.image || content.header || defaultImage
          "
          [alt]="content.altimage"
          loading="lazy"
        />
        <span
          *ngIf="hasTag(content) && showBadge"
          class="card-image-badge"
          [ngClass]="[color || '']"
          [ngStyle]="{ background: '#' + color || '' }"
          [innerText]="content.taghighlight || content.program || content.opcional"
        ></span>
      </div>
      <div class="card-body">
        <div class="card-title" [innerText]="content.title"></div>
        <div
          *ngIf="content.shortdescription"
          class="card-subtitle"
          [innerHtml]="content.shortdescription"
        ></div>
        <div
          class="card-publish-date"
          [innerText]="
            content.enddate ||
              content.startdate ||
              content.publishdate ||
              content.createdAt | date: 'dd/MM/yyyy \'às\' HH:mm'
          "
        ></div>
      </div>
    </a>
  </section>
</ng-container>

<ng-template #internalBlock>
  <section *ngIf="content" class="cards">
    <a class="card" [ngClass]="[size]" [routerLink]="'/' + link">
      <div class="card-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="
            content.thumbnail || content.image || content.header || defaultImage
          "
          [alt]="content.altimage"
          loading="lazy"
        />
        <span
          *ngIf="hasTag(content) && showBadge"
          class="card-image-badge"
          [ngClass]="[color || '']"
          [ngStyle]="{ background: '#' + color || '' }"
          [innerText]="content.taghighlight || content.program || content.opcional"
        ></span>
      </div>
      <div class="card-body">
        <div class="card-title" [innerText]="content.title"></div>
        <div
          *ngIf="content.shortdescription"
          class="card-subtitle"
          [innerHtml]="content.shortdescription"
        ></div>
        <div
          class="card-publish-date"
          [innerText]="
            content.enddate ||
              content.startdate ||
              content.publishdate ||
              content.createdAt | date: 'dd/MM/yyyy \'às\' HH:mm'
          "
        ></div>
      </div>
    </a>
  </section>
</ng-template>
