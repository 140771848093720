import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class InscriptionFormService {
    constructor(_http) {
        this._http = _http;
    }
    getFields(idInscripion) {
        return this._http
            .get(`${URL}/api/playlistsrelated?idmediarelated=${idInscripion}`)
            .toPromise()
            .then((response) => {
            const fields = response.results;
            fields.map(field => {
                if (field.author.toLowerCase() === 'text' ||
                    field.author.toLowerCase() === 'youtubeurl') {
                    field.author = 'input';
                }
            });
            return fields;
        })
            .catch(e => console.log(e));
    }
}
InscriptionFormService.ɵprov = i0.ɵɵdefineInjectable({ factory: function InscriptionFormService_Factory() { return new InscriptionFormService(i0.ɵɵinject(i1.HttpClient)); }, token: InscriptionFormService, providedIn: "root" });
