<section class="market-optin">
  <section class="market-optin-container">
    <section class="header">
      <img src="../../../assets/images/market-optin-header.jpg" alt="" />
    </section>
    <section class="forms">
      <aw-wizard [navBarLayout]="'large-filled-symbols'">
        <aw-wizard-step
          [stepId]="1"
          stepTitle="Dados Pessoais"
          [navigationSymbol]="{ symbol: '1' }"
          awSelectedStep
        >
          <h2 class="data-content-title">1. Dados Pessoais</h2>
          <p class="data-hint">Campo obrigatório</p>
          <form class="data-form" [formGroup]="personalData" #form>
            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="name"
              label="Nome"
              placeholder="Digite o seu nome"
              [inputPattern]="pureTextPattern"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'select'"
              [parent-form]="personalData"
              name="sex"
              label="Sexo"
              [options]="sexOptions"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="birthday"
              label="Data de Nascimento"
              placeholder="Digite sua data de nascimento"
              inputMask="00/00/0000"
              [required]="true"
              class="reduced"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              inputMask="000.000.000-00"
              [required]="true"
              class="reduced"
              [inputPattern]="cpfValidator"
            ></app-form-input>

            <app-error
              *ngIf="isCpfValid()"
              error="O CPF inserido é inválido."
            ></app-error>

            <div class="data-form-buttons">
              <button
                [disabled]="personalData.invalid"
                type="button"
                class="button-next-step"
                awNextStep
              >
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          stepTitle="Dados de Contato"
          [navigationSymbol]="{ symbol: '2' }"
        >
          <h2 class="data-content-title">2. Dados de Contato</h2>
          <p class="data-hint">Campo obrigatório</p>
          <form class="data-form" [formGroup]="contactData" #form>
            <app-form-input
              [type]="'email'"
              [parent-form]="contactData"
              name="email"
              label="E-mail"
              placeholder="Digite o seu email"
              [required]="true"
              class="full-width"
              maxLength="250"
              minLength="5"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="cep"
              label="CEP"
              placeholder="00000-000"
              inputMask="00000-000"
              [required]="true"
              class="reduced"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="cellphone"
              label="Telefone Celular"
              placeholder="(00) 00000-0000"
              inputMask="(00) 00000-0000"
              class="reduced"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="phone"
              label="Telefone Residencial"
              placeholder="(00) 0000-0000"
              inputMask="(00) 0000-0000"
              class="reduced"
            ></app-form-input>

            <app-form-input [type]="'checkbox'" [parent-form]="contactData" name="emailoptin" [required]="false">
              <span class="modal-footer dark">
                Aceito receber informações e promoções do SBT, das demais empresas do Grupo Silvio Santos e de seus parceiros comerciais
              </span>
            </app-form-input>

            <app-form-input [type]="'checkbox'" [parent-form]="contactData" name="acceptTerms" [required]="true">
              <span class="modal-footer dark">
                Ao criar minha conta, eu declaro que li e aceito os
                <a (click)="openTermsModal()">Termos de Uso</a> e as
                <a (click)="openPolicyModal()">Políticas de Privacidade</a>
                do Meu SBT.
              </span>
            </app-form-input>

            <div class="captcha">
              <re-captcha name="recaptcha" formControlName="recaptcha" [siteKey]="siteKey"></re-captcha>
            </div>

            <div class="data-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button [disabled]="contactData.invalid" class="button-send" type="button" [awGoToStep]="{ stepIndex: 0 }"
                (finalize)="openFinishdata()">
                Enviar
              </button>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </section>
  </section>
</section>

<ngx-smart-modal
  #modalCadastroOptin
  identifier="modalCadastroOptin"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Informações Enviadas')"
  (onAnyCloseEventFinished)="removeFromTitle('Informações Enviadas')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalCadastroOptin.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header confirm">    
    <i class="fa fa-check"></i>
    <h1>Cadastro concluído com sucesso!</h1>
  </div>
  <div class="modal-content">
    <p>
      Obrigada por preencher o formulário.
    </p>
    <button class="modal-button" type="button" (click)="modalCadastroOptin.close()">
      Ok, entendi.
    </button>
  </div>
</ngx-smart-modal>
