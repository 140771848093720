<section class="journalism">
  <div class="content-ad">
    <app-ad path="Jornalismo_Home/top" sizesDesktop="[970,250],[728,90]" sizesMobile="[320,100],[320, 50]"
      [metadata]="{ bloco: 'top' }"></app-ad>
  </div>
  <app-header url="../../../assets/images/jornalismo-header.png" [has-blurred-frame]="false"></app-header>

  <app-ad *ngIf="!isMobile" path="Jornalismo_home/especial" [metadata]="{ bloco: 'especial' }" [wallpaper]="true">
  </app-ad>

  <!-- Componente de correspondentes -->
  <!-- <div class="journalism-nav">
    <div class="journalism-nav-content">
      <div class="journalism-nav-list">
        <p
          *ngFor="let columnist of journalism?.columnists; let i = index"
          [ngClass]="{ active: i === journalism?.currentColumnistIndex }"
          [innerText]="columnist.title"
          (click)="changeColumnist(columnist, i)"
        ></p>
      </div>
    </div>
  </div> -->
  <div class="journalism-breadcrumb-container">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <section class="journalism-container">
    <div class="content-mediabox" *ngIf="journalism.mediabox && !journalism?.isColumnist">
      <app-mediabox *ngIf="journalism.mediabox && !journalism?.isColumnist" [medias]="journalism.mediabox"
        [type]="'jornalismo'"></app-mediabox>
    </div>

    <div class="wrapper">
      <app-videos id="ultimos-videos" title="Últimos Vídeos" [videos]="videos" [is-white]="true" [has-title]="false"
        *ngIf="!journalism?.isColumnist"></app-videos>
    </div>

    <div class="journalism-wrapper" id="journalism-area">
      <div class="journalism-content">
        <div *ngIf="journalism?.isColumnist && journalism?.currentColumnist" class="wrapper">
          <app-about [content]="journalism?.currentColumnist.description" [title]="journalism?.currentColumnist.title"
            [header]="journalism?.currentColumnist.image" [has-share]="true"></app-about>
        </div>

        <div class="journalism-notices-wrapper">
          <div class="journalism-content-title">
            <h2>Últimas Notícias</h2>
          </div>

          <app-tab *ngIf="journalism?.currentColumnistIndex === 0" [content-list]="journalism.categories"
            (receive-data)="receiveData($event)" [extra-classes]="['wider']"></app-tab>

          <div class="journalism-notices" *ngIf="hasNoticesMedias()">
            <app-lego-list id="noticias" [has-title]="false" [list]="journalism.notices" [show-more]="false"
              size="normal"></app-lego-list>

            <button *ngIf="journalism.pagination && journalism.pagination.hasMore" class="journalism-load-more"
              [innerText]="'Ver Mais'" (click)="loadMore()" data-analytics="ver-mais-jornalismo"></button>
          </div>
          <section *ngIf="!hasNoticesMedias() && !loadingContent" class="journalism-notices without-results">
            <h2>OPS!</h2>
            <span>Infelizmente ainda não temos nenhuma notícia cadastrada para essa categoria.
              =/</span>
          </section>
        </div>
      </div>

      <div class="journalism-high">
        <div class="wrapper">
          <section class="journalism-ads">
            <app-ad path="Jornalismo_Home/between" sizesDesktop="[300,250],[360,640],[300,600],[336,280]"
              sizesMobile="[300,250], [320,50]" [metadata]="{ bloco: 'bloco-1' }"></app-ad>
          </section>

          <div class="journalism-high-title">
            <h2>Em Alta</h2>
          </div>

          <app-lego-list *ngIf="journalism.high" [has-title]="false" [list]="journalism.high" [show-more]="false"
            [full-items]="true" size="small"></app-lego-list>
          <section class="journalism-ads">
            <app-ad path="Jornalismo_Home/between" sizesDesktop="[300,250],[360,640],[300,600],[336,280]"
              sizesMobile="[300,250], [320,50]" [metadata]="{ bloco: 'bloco-2' }"></app-ad>
          </section>
        </div>
      </div>
    </div>

    <section class="journalism-ads">
      <app-ad path="Jornalismo_Home/bottom" sizesDesktop="[970, 90]" sizesMobile="[320, 100]"
        [metadata]="{ bloco: 'bottom' }"></app-ad>
    </section>
  </section>
</section>