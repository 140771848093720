/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accept-cookie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./accept-cookie.component";
import * as i5 from "../../services/user.service";
import * as i6 from "../../services/cookie.service";
var styles_AcceptCookieComponent = [i0.styles];
var RenderType_AcceptCookieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcceptCookieComponent, data: {} });
export { RenderType_AcceptCookieComponent as RenderType_AcceptCookieComponent };
function View_AcceptCookieComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "accept-cookie"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "accept-cookie-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["id", "cookie-banner-lgpd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "cookie-banner-lgpd_text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00F3s utilizamos cookies e tecnologias semelhantes que armazenam dados no seu dispositivo para personalizar, mensurar e entregar conte\u00FAdo e an\u00FAncios, analisar o uso e melhorar a sua experi\u00EAncia, seguindo a nossa "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["routerLink", "/politica-de-privacidade"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.visiblePopup("none") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Pol\u00EDtica de Privacidade"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clique em \"Aceitar\" para permitir."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "cookie-banner-lgpd_button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "cookie-banner-lgpd_button_aceitar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Aceitar"]))], function (_ck, _v) { var currVal_0 = "/politica-de-privacidade"; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_AcceptCookieComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AcceptCookieComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShow; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AcceptCookieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accept-cookie", [], null, null, null, View_AcceptCookieComponent_0, RenderType_AcceptCookieComponent)), i1.ɵdid(1, 114688, null, 0, i4.AcceptCookieComponent, [i5.UserService, i6.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AcceptCookieComponentNgFactory = i1.ɵccf("app-accept-cookie", i4.AcceptCookieComponent, View_AcceptCookieComponent_Host_0, {}, {}, []);
export { AcceptCookieComponentNgFactory as AcceptCookieComponentNgFactory };
