<section class="institutional">
  <app-quick-access-menu
    [components]="quickAccessMenuComponents"
    (updateUrlState)="onUpdateUrlState($event)"
    [offset-scroll]="-100"
    [is-at-home]="true"
  ></app-quick-access-menu>

  <section>
    <app-header
      url="../../../assets/images/institucional-header.png"
      [has-blurred-frame]="false"
    ></app-header>

    <section class="institutional-container">
      <app-breadcrumb></app-breadcrumb>
    <section
        *ngIf="station?.identifier !== 'scc'"
        class="institutional-information"
        id="information"
        title="Quem Somos"
      >
        <h2 class="institutional-section-title" [innerText]="'Quem Somos'"></h2>

        <section *ngIf="isAffiliate">
          <section class="institutional-container">
            <div class="station">
              <div class="station-header">
                <div class="header-logo">
                  <img src="../../../assets/images/sbt-logo-big.png" alt="" />
                  <span [innerText]="station?.title"></span>
                </div>
                <div class="header-editions">
                  <div *ngFor="let edition of logoEditions" class="edition">
                    <img [src]="edition.image" alt="edition.title" />
                    <span [innerText]="edition.title"></span>
                  </div>
                </div>
              </div>
              <div class="station-content">
                <p>
                  Felicidade é nascer com a vocação de divertir.<br />
                  É ter mais energia a cada ano que passa.<br />
                  É ter a alegria de realizar sonhos.<br />
                  É estar próximo de quem você ama.<br />
                  Mesmo que você ame 200 milhões de pessoas.<br />
                  Felicidade é ser lembrado, ser querido, ser compartilhado.<br />
                  Não por algumas pessoas, mas por todas as famílias. <br />
                  É informar e emocionar.<br />
                  É multiplicar seus melhores sentimentos.<br />
                  É mudar, renovar e compartilhar.<br />
                  Felicidade é fazer tudo isso por vários anos<br />
                  E se sentir cada vez mais jovem
                </p>
              </div>
            </div>
          </section>
        </section>

        <app-tab
          [content-list]="categories"
          (receive-data)="receiveData($event)"
          [current-index]="0"
        ></app-tab>

        <section class="current-about" [ngSwitch]="currentCategory">
          <article *ngSwitchCase="0">
            <section class="half">
              <p>
                Nossa Missão e Visão, desenvolvidas a partir dos 3 pilares que
                regem a nossa marca
                <strong>(Família, Diversão e Informação)</strong>, apoiam nossas
                atitudes para continuarmos construindo uma organização cada vez
                mais respeitada.
              </p>

              <app-share></app-share>

              <img src="/assets/images/institucional/familia.png" />
            </section>

            <section class="half">
              <section>
                <h3 [innerText]="'Missão'" [ngClass]="['blue']"></h3>

                <p>
                  Produzir conteúdo capaz de reunir a família, divertir e
                  informar, contribuindo com o desenvolvimento da população
                  brasileira.
                </p>
              </section>

              <section>
                <h3 [innerText]="'Visão'" [ngClass]="['green']"></h3>

                <p>
                  Consolidar a vice-liderança da audiência com rentabilidade.
                </p>
              </section>
            </section>
          </article>

          <article *ngSwitchCase="1">
            <section class="half">
              <p class="">
                Definidos e orientados a partir de valores que
                <strong>fortalecem e evidenciam o nosso DNA</strong>, nossos
                pilares reforçam o nosso posicionamento frente a todos os nossos
                públicos e parceiros.
              </p>

              <app-share></app-share>

              <img src="/assets/images/institucional/familia-em-casa.png" />
            </section>

            <section class="half">
              <section>
                <h3 [innerText]="'Família'" [ngClass]="['blue']"></h3>

                <p>Ser o elo que reúne toda a família.</p>

                <ul>
                  <li>Valores:</li>
                  <li>União</li>
                  <li>Comprometimento</li>
                  <li>Respeito</li>
                </ul>
              </section>

              <section>
                <h3 [innerText]="'Diversão'" [ngClass]="['green']"></h3>

                <p>
                  Divertir para despertar paixões individuais de tal forma que o
                  SBT atinja a família como um todo.
                </p>

                <ul>
                  <li>Valores:</li>
                  <li>Paixão pelo trabalho</li>
                  <li>Cultivo ao bom humor e descontração</li>
                  <li>Celebração de conquistas</li>
                </ul>
              </section>

              <section>
                <h3 [innerText]="'Informação'" [ngClass]="['purple']"></h3>

                <p>
                  Informar bem para melhor formar o nosso povo e a nossa gente.
                </p>

                <ul>
                  <li>Valores:</li>
                  <li>Ética</li>
                  <li>Transparência</li>
                  <li>Excelência</li>
                </ul>
              </section>
            </section>
          </article>

          <article *ngSwitchCase="2">
            <section class="half">
              <app-share></app-share>

              <section class="half-content">
                <div class="image">
                  <img
                    src="/assets/images/institucional/spotlight.png"
                    alt=""
                  />
                </div>

                <p class="right">
                  O CDT/Anhanguera, sede do SBT, está localizado na cidade de
                  Osasco/SP e conta com <strong>8 ESTÚDIOS</strong>, somando uma
                  área total de mais de <strong>4.400m²</strong>.
                </p>
              </section>

              <section class="half-content">
                <p class="left">
                  A cidade cenográfica tem área aproximada de
                  <strong>6.500m²</strong>.
                </p>

                <div class="image">
                  <img
                    src="/assets/images/institucional/clapperboard.png"
                    alt=""
                  />
                </div>
              </section>

              <section class="half-content">
                <div class="image">
                  <img src="/assets/images/institucional/bus.png" alt="" />
                </div>

                <p class="right">
                  Entre caravanas com convidados e visitantes , o Complexo
                  Anhanguera recebe em média
                  <strong>18.000 pessoas por mês</strong>.
                </p>
              </section>

              <section class="half-content">
                <p class="left">
                  Com <strong>97%</strong> de cobertura nacional, que geram 2,2
                  bilhões de impactos na TV por mês, o SBT é a segunda maior
                  emissora do país, o maior canal de TV do mundo no YouTube e,
                  nas redes sociais, conta com incríveis 77 milhões de fãs*.
                </p>

                <div class="image">
                  <img src="/assets/images/institucional/devices.png" alt="" />
                </div>
              </section>
            </section>
            <section class="half">
              <section class="half-content">
                <div class="image">
                  <img src="/assets/images/institucional/network.png" alt="" />
                </div>

                <p class="right">
                  Contamos com 114 emissoras que juntas empregam cerca de
                  <strong>6.000</strong> colaboradores.
                </p>
              </section>

              <section>
                <p class="full-paragraph">
                  *Fonte: Kantar IBOPE Media - MW - Dados Individuais - Regiões
                  Metropolitanas extrapoladas para todo o Brasil conforme Atlas
                  de Cobertura do SBT - Audiência média multiplicada pelo número
                  de dias de um mês normal para cada programa.
                </p>
              </section>

              <section class="half-content">
                <p class="left">
                  O Site do SBT recebe mais de <strong>11 MILHÕES</strong> de
                  visitantes únicos/mês e tem mais de 99,5 MILHÕES de page
                  views/mês**. <br />
                </p>

                <div class="image">
                  <img src="/assets/images/institucional/notebook.png" alt="" />
                </div>
              </section>

              <section>
                <p class="full-paragraph">
                  **Fonte: ComScore - MMX - Key Measures Multi-plataform -
                  Portal SBT - Agosto 2017.
                </p>
              </section>

              <section class="half-content">
                <div class="image">
                  <img
                    src="/assets/images/institucional/microphone.png"
                    alt=""
                  />
                </div>

                <p class="right">
                  O Programa Silvio Santos entrou para o Guinness 93 por ser um
                  dos programas mais duradouros da TV, com mais de
                  <strong>30 ANOS</strong>. <br />
                  <strong>Curiosidade:</strong> Em 2017, o Programa completou 54
                  anos.
                </p>
              </section>
            </section>
          </article>
        </section>
      </section>

      <section
        *ngIf="station?.identifier === 'scc'"
        class="institutional-information"
        id="information"
        title="Quem Somos"
      >
        <h2 class="institutional-section-title" [innerText]="'Quem Somos'"></h2>

        <app-tab
          [content-list]="scCategories"
          (receive-data)="receiveData($event)"
          [current-index]="0"
        ></app-tab>

        <section class="current-about" [ngSwitch]="currentCategory">
          <article *ngSwitchCase="0" class="full">
            <section class="video">
              <app-video
                url="https://www.youtube.com/watch?v=hxRSmGS0XcQ"
              ></app-video>
            </section>
            <section class="content">
              <p>
                Fazer comunicação com paixão e por missão de vida. Esse é o
                propósito do SBT Santa Catarina, que ao longo de sua história
                vem contribuindo para a construção de valores e para a promoção
                da cidadania em todo o estado.
              </p>
              <p>
                Fundada em 10 de julho de 1980, a então TV Planalto tornou-se a
                primeira afiliada ao Sistema Brasileiro de Televisão, assinando
                o contrato no mesmo dia de criação do SBT, em 19 de agosto de
                1981. Assim nascia o SBT Santa Catarina, a TV da família
                catarinense.
              </p>
              <p>
                Com o passar do tempo, a emissora se moderniza e ampliando sua
                programação local. Rapidamente o SBT Santa Catarina começa a
                expandir seu sinal e a fortalecer sua marca.
              </p>
              <p>
                Com um crescimento contínuo, a emissora alcançou a
                vice-liderança, dispondo de credibilidade junto ao mercado e
                oferecendo o melhor custo/benefício para os anunciantes
                catarinenses.
              </p>
              <p>
                Atualmente, o SBT Santa Catarina leva a sua programação com o
                sinal digital para cerca de cinco milhões de catarinenses.
                Nossos apresentadores e as produções do SBT fazem parte do
                cotidiano das famílias catarinenses, que encontram na emissora
                informação e entretenimento de qualidade.
              </p>
            </section>
          </article>

          <article *ngSwitchCase="1">
            <section class="vision">
              <img
                src="../../../assets/images/institucional/sc_visao.jpg"
                alt="Visão SBT SC"
              />
            </section>
          </article>
        </section>
      </section>

      <section
        *ngIf="station?.identifier !== 'scc'"
        class="institutional-location"
        title="Onde Estamos"
        id="location"
      >
        <h2
          class="institutional-section-title"
          [innerText]="'Onde Estamos'"
        ></h2>
        <app-share></app-share>

        <div class="institutional-location-map">
          <iframe [src]="mapUrlSanitized"></iframe>
        </div>

        <ul class="institutional-location-list">
          <li
            *ngFor="let location of locations; let i = index"
            (click)="openSubMenu($event, i)"
            [ngClass]="{ active: currentHeader === i }"
          >
            <div>
              <span [innerText]="location.estado"></span>
              <i class="fa fa-chevron-down" title="Expandir"></i>
            </div>
            <ul class="location-sublist">
              <p *ngFor="let emissoras of location.dados">
                <span [innerText]="emissoras.emissora"></span>
                <span [innerText]="emissoras.municipio"></span>
                <span class="location-url" *ngIf="emissoras.url">
                  <a [href]="emissoras.url" target="_blank">Acesse o site</a>
                </span>
              </p>
            </ul>
          </li>
        </ul>
      </section>

      <section
        *ngIf="station?.identifier === 'scc'"
        class="institutional-location"
        title="Onde Estamos"
        id="location"
      >
        <h2
          class="institutional-section-title"
          [innerText]="'Onde Estamos'"
        ></h2>
        <app-share></app-share>

        <section class="map"></section>
        <section class="contact">
          <h2>Contato</h2>
          <ul class="contact-list">
            <li class="list-item">
              <p class="item-title">SBT SC - Florianópolis</p>
              <p>Rua Ernesto Stodieck, 322</p>
              <p>Morro da Cruz - Florianópolis - SC</p>
              <p>Tel.:(48) 3231-9700</p>
            </li>
            <li class="list-item">
              <p class="item-title">SBT SC - Joinville</p>
              <p>Rua Blumenau, 1149 sala 3</p>
              <p>América - Joinville - SC</p>
              <p>Tel.:(47) 3422-7749</p>
            </li>
            <li class="list-item">
              <p class="item-title">SBT SC - Criciúma</p>
              <p>Rua Desembargador Pedro Silva, 765, Ed. Dona Ignez, sala 2</p>
              <p>Bairro Comerciário - Criciúma - SC</p>
              <p>Tel.:(48) 3433-9606</p>
            </li>

            <li class="list-item">
              <p class="item-title">SBT SC - Lages</p>
              <p>Rua Carlos Joffre do Amaral, 67</p>
              <p>Centro - Lages - SC</p>
              <p>Tel.:(49) 3221-3000</p>
            </li>
            <li class="list-item">
              <p class="item-title">SBT SC - Blumenau</p>
              <p>Rua São Paulo, 3055 1º andar</p>
              <p>Itopava Seca - Blumenau - SC</p>
              <p>Tel.:(47) 3322-8777</p>
            </li>
            <li class="list-item">
              <p class="item-title">SBT SC - Chapecó</p>
              <p>Rua Marechal Deodoro, nº 1246 D</p>
              <p>Maria Goretti - Chapecó - SC</p>
              <p>Tel.:(49) 3316-1748</p>
            </li>
            <li class="list-item">
              <p class="item-title">SBT SC - Itajaí</p>
              <p>Rua Almirante Barroso, 99, Sala 03</p>
              <p>Centro - Itajaí - SC</p>
              <p>Tel.:(47) 3249-0971</p>
            </li>
          </ul>
        </section>
      </section>

      <section
        class="institutional-social-responsability"
        id="social-responsability"
        title="Responsabilidade Social"
      >
        <app-about
          *ngIf="station?.identifier !== 'scc'"
          title="Responsabilidade Social"
          [content]="socialResponsabilityText"
          [has-share]="true"
        ></app-about>

        <app-about
          *ngIf="station?.identifier === 'scc'"
          title="Responsabilidade Social"
          [content]="socialResponsabilityText"
          [has-share]="true"
          [video]="socialResponsabilityVideo"
        ></app-about>

        <div class="cards-list">
          <div class="card items-per-row-2" routerLink="/especiais/teleton">
            <div class="card-image">
              <img [src]="teletonImage" alt="" />
            </div>
            <div class="card-title">
              <span>Teleton</span>
              <a routerLink="/especiais/teleton">Veja o site</a>
            </div>
          </div>

          <div
            class="card items-per-row-2"
            routerLink="/institucional/sbt-do-bem"
          >
            <div class="card-image">
              <img [src]="sbtDoBemImage" alt="" />
            </div>
            <div class="card-title">
              <span>SBT do bem</span>
              <a routerLink="/institucional/sbt-do-bem">Veja o site</a>
            </div>
          </div>
        </div>
      </section>

      <section
        class="institutional-work-with-us"
        id="work-with-us"
        title="Trabalhe Conosco"
      >
        <h2
          class="institutional-work-with-us-title"
          [innerText]="'Trabalhe no SBT'"
        ></h2>

        <div class="cards-list">
          <a class="card" href="https://sejasbt.gupy.io/" target="_blank">
            <div class="card-image">
              <img [src]="trabalheConoscoImage" alt="" />
            </div>
            <div class="card-title">
              <span>Trabalhe Conosco</span>
              <a href="https://sejasbt.gupy.io/" target="_blank"
                >Saiba Como</a
              >
            </div>
          </a>
        </div>

        <div class="institutional-work-with-us-about">
          <p>
            O SBT (Sistema Brasileiro de Televisão), empresa do Grupo Silvio
            Santos, é uma das principais emissoras do país. Há mais de 35 anos,
            compartilha alegria, entretenimento e informação com as famílias
            brasileiras de diferentes classes sociais. São 114 emissoras e 6 mil
            colaboradores espalhados por todo o Brasil prontos para levar
            notícias de qualidade e diversão garantida aos telespectadores.
          </p>
          <p>
            Em nossa emissora contamos também com programas de estágio, menores
            aprendizes e PCD (pessoa com deficiência). Aqui a diversidade é
            bem-vinda! Acreditamos que as diferenças se complementam,
            estimulando um ambiente de aprendizado e desenvolvimento de todos os
            colaboradores da Família SBT.
          </p>
          <p>SBT. Mais moderno a cada ano. Venha fazer parte deste time.</p>
        </div>
      </section>
    </section>
  </section>
</section>
