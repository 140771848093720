var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MetatagsService } from '../../services/metatags.service';
import { TrophyPressService } from './trophy-press.service';
import { TitleService } from 'src/app/services/title.service';
import { isMobile } from '../../utils/util.browser';
export class TrophyPressComponent {
    constructor(_service, title, meta, metasService) {
        this._service = _service;
        this.title = title;
        this.meta = meta;
        this.metasService = metasService;
        this.loaded = false;
        this.isMobile = false;
        this.quickAccessMenuComponents = [
            { id: 'about', title: 'Sobre', showElement: true },
            { id: 'notices', title: 'Fique por Dentro', showElement: true },
            { id: 'judges', title: 'Jurados', showElement: true },
            { id: 'photos', title: 'Fotos', showElement: true },
            { id: 'videos', title: 'Vídeos', showElement: true },
            { id: 'winners', title: 'Ganhadores', showElement: true }
        ];
    }
    ngOnInit() {
        this.title.setTitle('Troféu Imprensa - SBT');
        this.getProgram('trofuinternet');
    }
    getProgram(slug) {
        this.isMobile = isMobile();
        this._service.getProgram(slug).then(response => {
            this.program = response;
            const lastPhotosEdition = this.program.photosEditionsAndPlaylists[0];
            this.changePhotosEdition({ title: lastPhotosEdition.year });
        });
    }
    changePhotosEdition({ title }) {
        return __awaiter(this, void 0, void 0, function* () {
            const idPlaylist = yield this.program.photosEditionsAndPlaylists.filter(edition => edition.year === title);
            if (idPlaylist) {
                this._service
                    .getProgramPhotos(this.program.idsite, this.program.photosSiteArea, idPlaylist[0].idplaylist)
                    .then((response) => {
                    this.program.photos = response;
                });
            }
        });
    }
    changeJudgeYear({ title }) {
        return __awaiter(this, void 0, void 0, function* () {
            const idPlaylist = yield this.program.judgesEditionYearsAndPlaylists.filter(edition => edition.year === title);
            if (idPlaylist) {
                this._service
                    .getProgramJudges(this.program.idsite, this.program.judgesSiteArea, idPlaylist[0].idplaylist)
                    .then((response) => {
                    this.program.judges = response;
                });
            }
        });
    }
    changeWinnersYear({ title }) {
        return __awaiter(this, void 0, void 0, function* () {
            const idPlaylist = yield this.program.winnersEditionYearsAndPlaylists.filter(edition => edition.year === title);
            if (idPlaylist) {
                this._service
                    .getProgramWinners(this.program.idsite, this.program.winnersSiteArea, idPlaylist[0].idplaylist)
                    .then((response) => {
                    const winners = response;
                    winners.forEach(winner => {
                        let html;
                        if (winner.author && winner.author.includes('[*]')) {
                            html = `
                <h2>${winner.author.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.description}</span>
                <br>
                <span>${winner.opcional}</span>`;
                        }
                        if (winner.description && winner.description.includes('[*]')) {
                            html = `
                <h2>${winner.description.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.author}</span>
                <br>
                <span>${winner.opcional}</span>`;
                        }
                        if (winner.opcional && winner.opcional.includes('[*]')) {
                            html = `
                <h2>${winner.opcional.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.author}</span>
                <br>
                <span>${winner.description}</span>`;
                        }
                        winner.description = html;
                    });
                    this.program.winners = winners;
                });
            }
        });
    }
}
