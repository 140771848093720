import { URL_USERS_API } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmailService {
    constructor(_http) {
        this._http = _http;
    }
    errorHandler({ error }) {
        const errorText = error.error;
        throw new Error(errorText);
    }
    sendEmail(data) {
        return this._http
            .post(`${URL_USERS_API}/api/send_email`, data)
            .toPromise()
            .then(response => {
            return response;
        })
            .catch(this.errorHandler);
    }
}
EmailService.ɵprov = i0.ɵɵdefineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailService, providedIn: "root" });
