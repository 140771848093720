<div class="sitemap-breadcrumb-container">
  <app-breadcrumb></app-breadcrumb>
</div>
<section class="sitemap">
  <h1 class="sitemap-title" [innerText]="'Mapa do Site do SBT'"></h1>

  <section class="category-container">
    <div class="content-wrapper" #categoriesList>
      <div *ngFor="let category of categories; let i = index">
        <p (click)="changeCategory(category, i)" [ngClass]="{
            active: currentCategoryIndex === i
          }" [innerText]="category"></p>
      </div>
    </div>
  </section>

  <section *ngIf="currentCategoryIndex === 0" class="sitemap-list">
    <ul class="sitemap-item" *ngFor="let letter of alphabetLetters; let i = index">
      <li [innerText]="letter.value | uppercase" class="letter" [ngClass]="{ unavailable: !letter.available }"></li>

      <li *ngFor="let site of getSitesOf(letter.value); let j = index">
        <a *ngIf="letter.available" [routerLink]="buildLink(site.description)" [innerText]="site.title"></a>

        <span *ngIf="!letter.available" [innerText]="site.title"></span>
      </li>
    </ul>
  </section>

  <section *ngIf="currentCategoryIndex === 1" class="sitemap-list double-list">
    <ul class="sitemap-item" *ngFor="let link of otherLinks.links; let i = index">
      <li [innerText]="link.title | uppercase" class="letter"></li>

      <li *ngFor="let childrenLink of link.subItems; let j = index">
        <a *ngIf="childrenLink.opcional3 === 'S'" [href]="[childrenLink.author]" target="_blank"
          [innerText]="childrenLink.title"></a>

        <a *ngIf="childrenLink.opcional3 === 'N' || !childrenLink.opcional3" [routerLink]="[childrenLink.author]"
          [innerText]="childrenLink.title"></a>
      </li>
    </ul>
  </section>
</section>