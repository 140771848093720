var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { terms } from 'src/app/app.metatags';
import { CanonicalService } from 'src/app/services/canonical.service';
import { MetatagsService } from 'src/app/services/metatags.service';
import { TitleService } from 'src/app/services/title.service';
import { AnalyticsService } from '../../services/analytics.service';
export class PwaIntegraComponent {
    constructor(title, meta, metasService, canonicalService, analyticsService) {
        this.title = title;
        this.meta = meta;
        this.metasService = metasService;
        this.canonicalService = canonicalService;
        this.analyticsService = analyticsService;
        this.updateTitle = true;
        this.metasService.updateTags(terms, this.meta);
        const time = Math.floor(Date.now() / 1000);
        this.deferredPrompt = null;
        this.pwaName = 'installPwa';
        this.epochTime = time;
        this.pwaStatusInstalled = 'installed';
        this.pwaStatusCancelled = 'cancelled';
        this.pwaStatusNotInstalled = 'not installed';
        this.expires = parseInt(this.epochTime + 2592000, 10);
    }
    beforeInstallPwa(e) {
        e.preventDefault();
        this.deferredPrompt = e;
    }
    installPwa(e) {
        e.preventDefault();
        this.deferredPrompt = null;
    }
    ngOnDestroy() {
        this.canonicalService.removeLink();
    }
    ngOnInit() {
        this.canonicalService.appendLink();
        if (this.updateTitle) {
            this.title.setTitle('PWA - SBT');
        }
    }
    instalePwa() {
        return __awaiter(this, void 0, void 0, function* () {
            this.deferredPrompt.prompt();
            const { outcome } = yield this.deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                this.deferredPrompt = null;
                this.analyticsService.sendEvent('installed', 'app');
            }
        });
    }
}
