<span [ngSwitch]="type">
  <span *ngSwitchCase="'editions'">
    <app-menu-editions *ngIf="program.editions && program.editions.length > 0" [editions]="program.editions"
      extra-classes="space"></app-menu-editions>
  </span>

  <span *ngSwitchCase="'mediabox'">
    <app-mediabox *ngIf="program.mediabox && program.mediabox.length > 0" 
      [extra-classes]="['space-top']"
      [medias]="program.mediabox" 
      [type]="program.slug">
    </app-mediabox>
  </span>

  <span *ngSwitchCase="'programs'">
    <div
      class="wrapper"
      *ngIf="program.programs">
        <app-card-list [show-genders]="false" desktop-view="row" id="programs" [title]="title" route="/sbt-videos/"
          items-per-row="3" [content]="program.programs"></app-card-list>
    </div>
  </span>
  
  <span *ngSwitchCase="'iframe'">
    <div id="iframe" class="wrapper iframeProgram">
      <app-iframe 
        [title]="title" 
        [content]="program.iframe?.description"
      >
      </app-iframe>      
    </div>
  </span>

  <span *ngSwitchCase="'about'">
    <div class="wrapper flex" *ngIf="program.about && program.about !== undefined">
      <section
        class="about"
        id="about">
          <app-about [title]="title" [content]="program.about.description" [title]="program.title"
          [header]="program.about.thumbnail" [social-networks]="program.socialNetworks" [program-title]="program.title"></app-about>
      </section>

      <section
        class="stay-inside"
        id="notices">
          <app-stay-inside *ngIf="program.stayInside && program.stayInside.length > 0" [title]="program.gender === 'Jornalismo' ? 'Notícias' : 'Fique Por Dentro'"
            [stay-inside]="program.stayInside" [program]="program"></app-stay-inside>
          <section class="program-ads">
            <app-ad
              [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/between'"
              sizesDesktop="[300, 250]"
              sizesMobile="[300, 250]"
              [metadata]="{bloco: 'bloco-' + aboutAdIndex, componente: 'about'}"
            ></app-ad>
          </section>
      </section>
    </div>
  </span>

  <span *ngSwitchCase="'presenter'">
    <div
      class="wrapper"
      *ngIf="program.presenter !== undefined"
      id="presenter">
        <app-about [title]="title" [content]="program.presenter.description"
          [header]="program.presenter.thumbnail"></app-about>
    </div>
  </span>

  <span *ngSwitchCase="'presenters'">
    <div
      class="wrapper"
      *ngIf="program.presenters && program.presenters.length > 0"
      id="presenters">
        <app-vertical-scroll-content [title]="title" [content]="program.presenters">
        </app-vertical-scroll-content>
    </div>
  </span>

  <span *ngSwitchCase="'chapters'">
    <div
      class="wrapper"
      *ngIf="program.chapters && program.chapters.length >= 0"
      id="chapters">
        <app-chapters [title]="title" [program]="program"></app-chapters>
    </div>
  </span>

  <span *ngSwitchCase="'videos'">
    <div
      class="wrapper no-padding"
      *ngIf="program.videos && program.videos.length > 0"
      id="videos">
        <app-videos [title]="title" [videos]="program.videos"
          [redirect-to-sbt-videos]="program.showVideosOnSbtVideos" [slug]="program.slug" [programs]="[program]"></app-videos>
          <!-- <app-sbt-videos-program></app-sbt-videos-program> -->
    </div>
  </span>

  <span *ngSwitchCase="'videosprograma'">
    <div
      class="wrapper no-padding"
      *ngIf="program.videosprograma && program.videosprograma.length > 0"
      id="videosprograma">
        <app-sbt-videos-program></app-sbt-videos-program>
    </div>
  </span>  

  <span *ngSwitchCase="'frames'">
    <div
      class="wrapper"
      *ngIf="program.frames && program.frames.length > 0"
      id="frames">
        <app-list [title]="title" [has-search]="true" [content]="program.frames" [is-accordion]="true"
          [accordion-with-card]="false" endpoint="medias"></app-list>
    </div>
  </span>

  <span *ngSwitchCase="'inscriptions'">
    <div
      class="wrapper"
      *ngIf="program.inscriptions && program.inscriptions.length > 0"
      id="inscriptions">
        <app-list [title]="title" [has-search]="true" [content]="program.inscriptions"
          [is-accordion]="false" [has-subtitle]="true" [has-action]="true" [accordion-with-card]="false"
          endpoint="inscriptions" [program-id]="program.id" [program-slug]="program.slug"></app-list>
    </div>
  </span>

  <span *ngSwitchCase="'winners'">
    <div
      class="wrapper"
      *ngIf="program.winners && program.winners.length > 0"
      id="winners">
        <h2 class="title" [innerText]="title"></h2>

        <app-tab
          *ngIf="program.winnersEditions"
          [content-list]="program.winnersEditions"
          (receive-data)="changeWinners($event)"
          [current-index]="0"
          [sort]="false"
        ></app-tab>

        <app-list [has-search]="false" [content]="program.winners" [is-accordion]="true"
          [has-subtitle]="false" [accordion-with-card]="true" endpoint="medias"></app-list>
    </div>
  </span>

  <span *ngSwitchCase="'characters'">
    <div
      class="wrapper no-padding"
      *ngIf="program.characters && program.characters.length > 0"
      id="characters">
        <h2 class="title" [innerText]="title"></h2>

        <app-tab
          *ngIf="program.charactersEditions"
          [content-list]="program.charactersEditions"
          (receive-data)="changeCharacters($event)"
          [current-index]="0"
          [sort]="false"
        ></app-tab>

        <app-characters [characters]="program.characters"></app-characters>
    </div>
  </span>

  <span *ngSwitchCase="'secretCode'">
    <div
      class="wrapper"
      *ngIf="program.title === 'As Aventuras de Poliana'"
      id="secretCode">
        <app-secret-code [title]="title" [data]="program.secretCodeData"></app-secret-code>
    </div>
  </span>

  <span *ngSwitchCase="'tips'">
    <div
      class="wrapper no-padding"
      *ngIf="program.tips && program.tips.length > 0"
      id="tips">
        <app-lego-list [show-badge]="false" [is-horizontal-list]="true" [list]="program.tips" [title]="title" route="/noticia/"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'singleNotices'">
    <div
      class="wrapper"
      *ngIf="program.notices && program.notices.length > 0"
      id="singleNotices">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.notices"
          [without-playlists]="true"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'tbt'">
    <div
      class="wrapper"
      *ngIf="program.tbt && program.tbt.length > 0"
      id="tbt">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.tbt" [without-playlists]="true">
        </app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'whereTheyAre'">
    <div
      class="wrapper"
      *ngIf="program.whereTheyAre && program.whereTheyAre.length > 0"
      id="whereTheyAre">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.whereTheyAre"
          [without-playlists]="true"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'quiz'">
    <div
      class="wrapper"
      *ngIf="program.quiz && program.quiz.length > 0"
      id="quiz">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.quiz" [without-playlists]="true">
        </app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'campaigns'">
    <div
      class="wrapper"
      *ngIf="program.campaigns && program.campaigns.length > 0"
      id="campaigns">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.campaigns"
          [without-playlists]="true"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'support'">
    <div
      class="wrapper"
      *ngIf="program.support && program.support.length > 0"
      id="support">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.support"
          [without-playlists]="true"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'looks'">
    <div
      class="wrapper"
      *ngIf="program.looks && program.looks.length > 0"
      id="looks">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.looks" [without-playlists]="true">
        </app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'music'">
    <div
      class="wrapper"
      *ngIf="program.music && program.music.length > 0"
      id="music">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.music" [without-playlists]="true">
        </app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'sbtistas'">
    <div
      class="wrapper"
      *ngIf="program.sbtistas && program.sbtistas.length > 0"
      id="sbtistas">
        <app-lego-list [show-badge]="false" [title]="title" [show-more]="true" [list]="program.sbtistas"
          [without-playlists]="true"></app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'recipies'">
    <div
      class="wrapper no-padding"
      *ngIf="program.recipies && program.recipies.length > 0"
      id="recipies">
        <app-lego-list [show-badge]="false" [is-horizontal-list]="true" [list]="program.recipies" [title]="title">
        </app-lego-list>
    </div>
  </span>

  <span *ngSwitchCase="'thanks'">
    <div
      class="wrapper"
      *ngIf="program.thanks && program.thanks.length > 0"
      id="thanks">
        <app-thanks [thanks]="program.thanks" [title]="title"></app-thanks>
    </div>
  </span>

  <span *ngSwitchCase="'timeline'">
    <div
      class="wrapper"
      *ngIf="program.timeline && program.timeline.length > 0"
      id="timeline">
        <app-vertical-scroll-content [title]="title" [content]="program.timeline">
        </app-vertical-scroll-content>
    </div>
  </span>

  <span *ngSwitchCase="'donation-forms'" id="donation-forms">
    <span>
        <app-tab-cards *ngIf="program.formasDeDoar" [title]="title"></app-tab-cards>
    </span>
  </span>

  <!-- <span *ngSwitchCase="'comments'">
    <div
      class="wrapper"
      *ngIf="program"
      id="comments">
        <app-comments [title]="title"></app-comments>
    </div>
  </span> -->

  <span *ngSwitchCase="'contactForm'">
    <div
      class="wrapper"
      *ngIf="program"
      id="contactForm">
      <app-contact-form></app-contact-form>
    </div>
  </span>

  <span *ngSwitchCase="'gallery'">
    <div
      class="wrapper"
      *ngIf="program"
      id="gallery">
        <h2 class="title" [innerText]="title"></h2>

        <app-tab
          *ngIf="program.galleryEditions"
          [content-list]="program.galleryEditions"
          (receive-data)="changeGallery($event)"
          [current-index]="0"
          [sort]="false"
        ></app-tab>

        <app-gallery [galleryImages]="program.gallery"></app-gallery>
    </div>
  </span>

  <span *ngSwitchCase="'voting'">
    <div class="wrapper" id="voting">
      <h2 class="title" [innerText]="'Votação'"></h2>

      <iframe [src]="hotsiteVoteUrlSanitized"></iframe>
    </div>
  </span>
</span>
