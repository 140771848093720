<section *ngIf="home">
  <app-quick-access-menu
    *ngIf="quickAccessMenuComponents?.length > 0"
    [components]="quickAccessMenuComponents"
    [order]="true"
    [offset-scroll]="-150"
    [is-at-home]="true"
    (updateUrlState)="onUpdateUrlState($event)"
  ></app-quick-access-menu>

  <div class="ads-top">
    <div class="wrapper">
      <app-ad
        [path]="'Home' + stationAdUnit + '/top'"
        sizesDesktop="[970,250],[728,90]"
        sizesMobile="[320,100],[320, 50]"
        [metadata]="{ bloco: 'top' }"
      ></app-ad>
    </div>
  </div>

  <app-mediabox
    *ngIf="home.mediabox"
    [medias]="home.mediabox"
    [extra-classes]="'black-bars'"
    [type]="'home' + stationName"
  ></app-mediabox>

  <div class="wrapper" *ngIf="renderOrder">
    <app-home-wrapper
      *ngFor="let type of renderOrder"
      [home]="home"
      [type]="type"
      [station-name]="'home' + stationName"
      [station-ad-unit]="stationAdUnit"
    ></app-home-wrapper>

    <section class="home-ads">
      <app-ad
        [path]="'Home' + stationAdUnit + '/bottom'"
        sizesDesktop="[970,90]"
        sizesMobile="[320,50]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </section>
  </div>
</section>
