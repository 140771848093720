<section class="ecad">
    <app-header url="/assets/images/ecad-header.png" [has-blurred-frame]="false"></app-header>
    <div class="ecad-breadcrumb-container">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <h2 class="ecad-title">
        Execução Pública
    </h2>
    <div class="ecad-description">
        Planilhas de Programação Musical reportadas ao Ecad (Escritório Central de Arrecadação e Distribuição).
    </div>
    <div class="ecad-content">
        <div class="yearPanel" *ngFor="let ecad of ecadResults; let i = index">
            <button class="accordion" (click)="setCurrentContent(i)"
                [ngClass]="{ activeIndicator: currentContent === i }">{{ecad.year}}</button>
            <div class="monthsPanel" [ngClass]="{ active: currentContent === i }">
                <p class="monthsPanel-title" *ngFor="let data of ecad.data">
                    {{data.month}} [<a href="{{data.file}}" target="_blank">Baixar</a>]
                </p>
            </div>
        </div>
    </div>
</section>