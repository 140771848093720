var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { InscriptionsService } from './inscriptions.service';
import { ProgramService } from '../program/program.service';
import { ActivatedRoute } from '@angular/router';
import { StationService } from 'src/app/services/station.service';
import { CookieService } from 'src/app/services/cookie.service';
import { TitleService } from 'src/app/services/title.service';
import { isMobile } from '../../utils/util.browser';
export class InscriptionsComponent {
    constructor(service, programService, activatedRoute, stationService, cookieService, title) {
        this.service = service;
        this.programService = programService;
        this.activatedRoute = activatedRoute;
        this.stationService = stationService;
        this.cookieService = cookieService;
        this.title = title;
        this.highlightInscriptions = [];
        this.isAffiliate = false;
        this.isMobile = false;
        this.hideSearch = true;
        this.filterTerm = '';
        this.fragment = '';
        this.subscriptions = [];
        this.bannerfixed = false;
    }
    onscroll() {
        if (window.scrollY > 1950) {
            this.bannerfixed = true;
        }
        else {
            this.bannerfixed = false;
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
    ngOnInit() {
        this.title.setTitle('Inscrições - SBT');
        this.isMobile = isMobile();
        const fragmentRoute = this.activatedRoute.fragment
            .subscribe(fragment => {
            this.fragment = fragment;
        });
        this.subscriptions.push(fragmentRoute);
        const activeRouteSubscriber = this.activatedRoute.url.subscribe(urlSegment => {
            if (urlSegment[0] && urlSegment[0].path.includes('afiliada')) {
                this.isAffiliate = true;
                const stationIdentifier = urlSegment[1].path;
                this.station = this.stationService.getStation(stationIdentifier)[0];
                this.stationService.setStationCookie(this.station.identifier);
            }
        });
        this.subscriptions.push(activeRouteSubscriber);
        this.service.getInscriptions().then((inscriptions) => __awaiter(this, void 0, void 0, function* () {
            let programs = yield inscriptions.map(inscription => inscription.program);
            programs = Array.from(new Set(programs));
            let result = [];
            let stayInsideNotices = [];
            yield programs.forEach((program) => __awaiter(this, void 0, void 0, function* () {
                const programsInscriptions = yield inscriptions.filter(inscription => inscription.program === program);
                result.push({
                    idprogram: program,
                    inscriptions: programsInscriptions
                });
            }));
            this.inscriptions = result;
            this.programService.getPrograms().then((programs) => __awaiter(this, void 0, void 0, function* () {
                let programsFiltered = programs.filter(program => this.inscriptions.some((inscription) => inscription.idprogram === program.id.toString()));
                programsFiltered.forEach(program => {
                    this.programService.getProgramNotices(program).then(notices => {
                        stayInsideNotices.push(...notices.results);
                    });
                    this.inscriptions.forEach((programInscriptions) => {
                        if (programInscriptions.idprogram === program.id.toString()) {
                            program.inscriptions = programInscriptions.inscriptions;
                        }
                    });
                });
                this.stayInside = yield stayInsideNotices;
                if (this.station) {
                    let indexes = [];
                    programsFiltered.forEach((program, index) => {
                        if (program.idregional === this.station.id) {
                            indexes.push(index);
                        }
                    });
                    indexes.forEach(index => {
                        programsFiltered = this.moveArrayPosition(programsFiltered, index, 0);
                    });
                }
                this.programs = programsFiltered;
            }));
        }));
        this.service
            .getHighlightedInscriptions()
            .then(response => (this.highlightInscriptions = response));
    }
    toggleSearch() {
        this.hideSearch = !this.hideSearch;
        if (this.hideSearch) {
            this.filterTerm = '';
        }
    }
    search(term) {
        this.filterTerm = term;
    }
    moveArrayPosition(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length;
            while (k-- + 1) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    }
}
