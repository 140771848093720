import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  BrowserAnimationsModule,
  NoopAnimationsModule
} from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { ShareButtonModule } from '@ngx-share/button'
import { InViewportModule } from 'ng-in-viewport'
import { NgxSmartModalModule } from 'ngx-smart-modal'
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to'
import { ArchwizardModule } from 'angular-archwizard'
import { NgxMasonryModule } from 'ngx-masonry'
import { NgxMaskModule } from 'ngx-mask'
import { YoutubePlayerModule } from 'ngx-youtube-player'
import { DfpModule } from 'ngx-dfp'
import { RecaptchaModule } from 'ng-recaptcha'
import { RecaptchaFormsModule } from 'ng-recaptcha/forms'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'

import { AppComponent } from './app.component'
import { AppRoutes } from './app.routing'
import { DISQUS_SHORTNAME } from './app.settings'

import { MenuComponent } from './shared/menu/menu.component'
import { HomeComponent } from './pages/home/home.component'
import { FooterComponent } from './shared/footer/footer.component'
import { AcceptCookieComponent } from './shared/accpet-cookie/accept-cookie.component'
import { ManagerPreferencesCookieComponent } from './shared/manager-cookie-preferences/manager-preferences-cookie.component'
import { HeaderComponent } from './shared/header/header.component'
import { NavComponent } from './shared/nav/nav.component'
import { NoticeComponent } from './pages/notice/notice.component'
import { VideoComponent } from './shared/video/video.component'
import { ShareComponent } from './shared/share/share.component'
import { SeeMoreComponent } from './shared/see-more/see-more.component'
import { RelatedComponent } from './shared/related/related.component'
import { PillComponent } from './shared/pill/pill.component'
import { GalleryComponent } from './shared/gallery/gallery.component'
import { SearchComponent } from './shared/search/search.component'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { AccountActionsComponent } from './shared/account-actions/account-actions.component'
import { ProgramComponent } from './pages/program/program.component'
import { StayInsideComponent } from './shared/stay-inside/stay-inside.component'
import { ChaptersComponent } from './shared/chapters/chapters.component'
import { VideosComponent } from './shared/videos/videos.component'
import { CharactersComponent } from './shared/characters/characters.component'
import { SecretCodeComponent } from './shared/secret-code/secret-code.component'
import { ThanksComponent } from './shared/thanks/thanks.component'
import { VerticalScrollContentComponent } from './shared/vertical-scroll-content/vertical-scroll-content.component'
import { QuickAccessMenuComponent } from './shared/quick-access-menu/quick-access-menu.component'
import { AboutComponent } from './shared/about/about.component'
import { ListComponent } from './shared/list/list.component'
import { ListSearchComponent } from './shared/list/list-search/list-search.component'
import { ListHeaderComponent } from './shared/list/list-header/list-header.component'
import { MediaboxComponent } from './shared/mediabox/mediabox.component'
import { MenuFooterComponent } from './shared/menu/menu-footer/menu-footer.component'
import { MenuHeaderComponent } from './shared/menu/menu-header/menu-header.component'
import { VideoSectionComponent } from './shared/videos/video-section/video-section.component'
import { MenuEditionsComponent } from './shared/menu-editions/menu-editions.component'
import { LegoListComponent } from './shared/lego-list/lego-list.component'
import { TabCardsComponent } from './shared/tab-cards/tab-cards.component'
import { CardListComponent } from './shared/card-list/card-list.component'
import { ProgramsComponent } from './pages/programs/programs.component'
import { ProgrammingGridComponent } from './shared/programming-grid/programming-grid.component'
import { ListContentComponent } from './shared/list-content/list-content.component'
import { HighlightedListComponent } from './shared/highlighted-list/highlighted-list.component'
import { ListCardsComponent } from './shared/list-cards/list-cards.component'
import { DynamicCardListComponent } from './shared/dynamic-card-list/dynamic-card-list.component'
import { ListArticleComponent } from './shared/list-article/list-article.component'
import { LegoTrioComponent } from './shared/lego-list/lego-trio/lego-trio.component'
import { ItemComponent } from './shared/lego-list/item/item.component'
import { LegoTrioInvertedComponent } from './shared/lego-list/lego-trio-inverted/lego-trio-inverted.component'
import { LegoHibridoComponent } from './shared/lego-list/lego-hibrido/lego-hibrido.component'
import { LegoTextComponent } from './shared/lego-list/lego-text/lego-text.component'
import { HomeWrapperComponent } from './wrappers/home-wrapper/home-wrapper.component'
import { ProgramWrapperComponent } from './wrappers/program-wrapper/program-wrapper.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { JournalismComponent } from './pages/journalism/journalism.component'
import { LiveComponent } from './pages/live/live.component'
import { SearchResultsComponent } from './pages/search-results/search-results.component'
import { InstitutionalComponent } from './pages/institutional/institutional.component'
import { ServicesComponent } from './pages/services/services.component'
import { PromotionsComponent } from './pages/promotions/promotions.component'
import { LegoSingleItemComponent } from './shared/lego-list/lego-single-item/lego-single-item.component'
import { LegoSingleItemBigComponent } from './shared/lego-list/lego-single-item-big/lego-single-item-big.component'
import { BlockquoteComponent } from './shared/blockquote/blockquote.component'
import { BookmarksComponent } from './shared/bookmarks/bookmarks.component'
import { CardInscriptionsComponent } from './shared/card-inscriptions/card-inscriptions.component'
import { StationComponent } from './shared/station/station.component'
import { PlaylistComponent } from './shared/playlist/playlist.component'
import { TabComponent } from './shared/tab/tab.component'
import { ContactFormComponent } from './shared/contact-form/contact-form.component'

import { InscriptionFormComponent } from './pages/inscription-form/inscription-form.component'
import { FormInputComponent } from './shared/form-input/form-input.component'
import { CardComponent } from './shared/card/card.component'
import { TabSearchResultsComponent } from './shared/tab-search-results/tab-search-results.component'
import { SchedulesComponent } from './pages/schedules/schedules.component'
import { SitemapComponent } from './pages/sitemap/sitemap.component'
import { SbtVideosComponent } from './pages/sbt-videos/sbt-videos.component'
import { SbtVideosProgramComponent } from './pages/sbt-videos-program/sbt-videos-program.component'
import { PlaylistVideosComponent } from './pages/sbt-videos-program/playlist/playlist-videos.component'
import { TermsComponent } from './pages/terms/terms.component'
import { TermsPartnerComponent } from './pages/terms-partner/terms-partner.component'
import { MySbtComponent } from './pages/my-sbt/my-sbt.component'
import { InscriptionsComponent } from './pages/inscriptions/inscriptions.component'
import { DownloadAppComponent } from './pages/download-app/download-app.component'
import { SignalSearchComponent } from './pages/signal-search/signal-search.component'
import { MarketOptinComponent } from './pages/market-optin/market-optin.component'
import { ValidatedComponent } from './pages/validate-email/validate.component'

import { CookieService } from './services/cookie.service'
import { StationService } from './services/station.service'
import { SilviosantosComponent } from './pages/silviosantos/silviosantos.component'
import { AdComponent } from './shared/ad/ad.component'
import { ErrorComponent } from './shared/error/error.component'
import { TrophyPressComponent } from './pages/trophy-press/trophy-press.component'
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component'
import { RunScriptsDirective } from './directives/run-scripts.directive'
import { HttpInterceptorService } from './interceptors/http-interceptor.service'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'
import { NoAccentsPipe } from './pipes/no-accent.pipe'
import { PlaybuzzComponent } from './shared/playbuzz/playbuzz.component';
import { TwitterComponent } from './shared/twitter/twitter.component';
import { InstagramComponent } from './shared/instagram/instagram.component';
import { ChatbotComponent } from './shared/chatbot/chatbot.component'
import { AudimaBannerComponent } from './shared/audima-banner/audima-banner.component';
import { IframeComponent } from './shared/iframe/iframe.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ButtonYoutubeComponent } from './shared/button-youtube/button-youtube.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { PortalPrivacyComponent } from './pages/portal-privacy/portal-privacy.component'
import { EcadComponent } from './pages/ecad/ecad.component'
import { FAQ } from './pages/faq/faq.component'
import { MediastreamComponent } from './shared/mediastream/mediastream.component'
import { PodcastsComponent } from './pages/podcasts/podcasts.component'
import { PwaIntegraComponent } from './pages/pwa-integra/pwa-integra.component'
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component'

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    FooterComponent,
    AcceptCookieComponent,
    ManagerPreferencesCookieComponent,
    HeaderComponent,
    NavComponent,
    NoticeComponent,
    VideoComponent,
    ShareComponent,
    SeeMoreComponent,
    RelatedComponent,
    PillComponent,
    GalleryComponent,
    SearchComponent,
    SafeHtmlPipe,
    AccountActionsComponent,
    ProgramComponent,
    StayInsideComponent,
    ChaptersComponent,
    VideosComponent,
    CharactersComponent,
    SecretCodeComponent,
    ThanksComponent,
    MediaboxComponent,
    MenuFooterComponent,
    MenuHeaderComponent,
    VideoSectionComponent,
    VerticalScrollContentComponent,
    QuickAccessMenuComponent,
    AboutComponent,
    ListComponent,
    ListSearchComponent,
    ListHeaderComponent,
    MenuEditionsComponent,
    LegoListComponent,
    TabCardsComponent,
    CardListComponent,
    ProgramsComponent,
    ProgrammingGridComponent,
    ListContentComponent,
    HighlightedListComponent,
    ListCardsComponent,
    DynamicCardListComponent,
    ListArticleComponent,
    LegoTrioComponent,
    ItemComponent,
    LegoTrioInvertedComponent,
    LegoHibridoComponent,
    LegoTextComponent,
    HomeWrapperComponent,
    ProgramWrapperComponent,
    NotFoundComponent,
    JournalismComponent,
    LiveComponent,
    SearchResultsComponent,
    InstitutionalComponent,
    ServicesComponent,
    PromotionsComponent,
    LegoSingleItemComponent,
    LegoSingleItemBigComponent,
    InscriptionFormComponent,
    FormInputComponent,
    CardComponent,
    TabSearchResultsComponent,
    SchedulesComponent,
    SitemapComponent,
    TermsComponent,
    TermsPartnerComponent,
    SbtVideosComponent,
    MySbtComponent,
    BlockquoteComponent,
    BookmarksComponent,
    CardInscriptionsComponent,
    InscriptionsComponent,
    DownloadAppComponent,
    StationComponent,
    TabComponent,
    SbtVideosProgramComponent,
    PlaylistVideosComponent,
    PlaylistComponent,
    SignalSearchComponent,
    MarketOptinComponent,
    ValidatedComponent,
    ContactFormComponent,
    SilviosantosComponent,
    AdComponent,
    ErrorComponent,
    TrophyPressComponent,
    ResetPasswordComponent,
    RunScriptsDirective,
    PrivacyPolicyComponent,
    NoAccentsPipe,
    PlaybuzzComponent,
    TwitterComponent,
    InstagramComponent,
    ChatbotComponent,
    AudimaBannerComponent,
    IframeComponent,
    ButtonYoutubeComponent,
    PortalPrivacyComponent,
    EcadComponent,
    FAQ,
    MediastreamComponent,
    PodcastsComponent,
    PwaIntegraComponent,
    BreadcrumbComponent
  ],
  imports: [
    NoopAnimationsModule,
    NgxMasonryModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    }),
    HttpClientModule,
    InfiniteScrollModule,
    ShareButtonModule.forRoot(),
    FormsModule,
    InViewportModule,
    NgxSmartModalModule.forRoot(),
    ScrollToModule.forRoot(),
    ReactiveFormsModule,
    ArchwizardModule,
    NgxMaskModule.forRoot(),
    YoutubePlayerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgProgressModule,
    NgProgressRouterModule.withConfig({
      delay: 2000
    }),
    DfpModule.forRoot({
      idleLoad: true,
      enableVideoAds: false,
      personalizedAds: true, // Request personalized ads by default
      singleRequestMode: true, // Only applies to initial refresh
      onSameNavigation: 'refresh',
      centering: true,
      collapseIfEmpty: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    CookieService,
    StationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
