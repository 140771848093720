/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./highlighted-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./highlighted-list.component";
import * as i5 from "../../pages/program/program.service";
var styles_HighlightedListComponent = [i0.styles];
var RenderType_HighlightedListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HighlightedListComponent, data: {} });
export { RenderType_HighlightedListComponent as RenderType_HighlightedListComponent };
function View_HighlightedListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "list-item list-item-full"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "list-item-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "list-item-image-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#1"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "list-item-image-badge"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "list-item-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "list-item-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "list-item-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(10, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buildLink(_co.firstContent.programRoute, _co.firstContent.type, _co.firstContent.slug); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.firstContent.thumbnail; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.firstContent.program; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.firstContent.title; _ck(_v, 8, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _co.firstContent.publishdatestring, "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 9, 0, currVal_6); }); }
function View_HighlightedListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "list-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "list-item-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "list-item-image-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["#", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "list-item-image-badge"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "list-item-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "list-item-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "list-item-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(10, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buildLink(_v.context.$implicit.programRoute, _v.context.$implicit.type, _v.context.$implicit.slug); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.thumbnail; _ck(_v, 3, 0, currVal_3); var currVal_4 = (_v.context.index + 2); _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.program; _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.publishdatestring, "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 9, 0, currVal_7); }); }
function View_HighlightedListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "list-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "list-item-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "list-item-image-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["#", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "list-item-image-badge"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "list-item-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "list-item-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "list-item-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(10, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buildLink(_v.context.$implicit.programRoute, _v.context.$implicit.type, _v.context.$implicit.slug); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.thumbnail; _ck(_v, 3, 0, currVal_3); var currVal_4 = (_v.context.index + 4); _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.program; _ck(_v, 6, 0, currVal_5); var currVal_6 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.publishdatestring, "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 9, 0, currVal_7); }); }
export function View_HighlightedListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "section", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "h2", [["class", "list-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "section", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "list-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "topContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "topContent-big"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HighlightedListComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "topContent-small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HighlightedListComponent_2)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "bottomContent"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HighlightedListComponent_3)), i1.ɵdid(14, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.firstContent; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.topContent; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.bottomContent; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_HighlightedListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-highlighted-list", [], null, null, null, View_HighlightedListComponent_0, RenderType_HighlightedListComponent)), i1.ɵdid(1, 114688, null, 0, i4.HighlightedListComponent, [i2.Router, i5.ProgramService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HighlightedListComponentNgFactory = i1.ɵccf("app-highlighted-list", i4.HighlightedListComponent, View_HighlightedListComponent_Host_0, { title: "title", content: "content" }, {}, []);
export { HighlightedListComponentNgFactory as HighlightedListComponentNgFactory };
