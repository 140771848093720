import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class BrandingService {
    constructor() {
        this.branding = new BehaviorSubject(null);
        this.branding$ = this.branding.asObservable();
    }
    getBranding() {
        return this.branding$;
    }
    setBranding(brand) {
        this.branding.next(brand);
    }
    resetBranding() {
        this.branding.next(null);
    }
}
BrandingService.ɵprov = i0.ɵɵdefineInjectable({ factory: function BrandingService_Factory() { return new BrandingService(); }, token: BrandingService, providedIn: "root" });
