<div class="content-ad">
  <app-ad path="Programas/top" sizesDesktop="[970,250],[728,90]" sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"></app-ad>
</div>

<app-header url="/assets/images/programas-header.png" [has-blurred-frame]="false"></app-header>

<app-ad *ngIf="!isMobile" path="Programas/especial" [metadata]="{ bloco: 'especial' }" [wallpaper]="true"></app-ad>

<div class="programs-breadcrumb-container">
  <app-breadcrumb></app-breadcrumb>
</div>
<section class="programs">
  <div class="programs-wrapper">
    <div class="programs-list" #adsElement>
      <app-card-list *ngIf="programs" [return-genders]="true" [show-genders]="true" [content]="programs"
        [items-per-row]="itemsPerRow"></app-card-list>
    </div>
  </div>

  <app-ad path="Programas/bottom" sizesDesktop="[970,90]" sizesMobile=" [320,50], [320,100]"
    [metadata]="{ bloco: 'bottom' }"></app-ad>
</section>