<section class="contact-form">
  <aw-wizard [navBarLayout]="'large-filled-symbols'">
    <aw-wizard-step
      [stepId]="1"
      stepTitle="Dados Pessoais"
      [navigationSymbol]="{ symbol: '1' }"
      awSelectedStep
    >
      <h2 class="data-content-title">1. Dados Pessoais</h2>
      <p class="data-hint">Campo obrigatório</p>
      <form class="data-form" [formGroup]="personalData" #form>
        <app-form-input
          [type]="'input'"
          [parent-form]="personalData"
          name="nome"
          label="Nome"
          placeholder="Digite o seu nome"
          [inputPattern]="pureTextPattern"
          [required]="true"
          class="full-width"
        ></app-form-input>

        <div class="data-form-buttons">
          <button
            [disabled]="personalData.invalid"
            type="button"
            class="button-next-step"
            awNextStep
          >
            Continuar
          </button>
        </div>
      </form>
    </aw-wizard-step>
    <aw-wizard-step
      stepTitle="Dados de Contato"
      [navigationSymbol]="{ symbol: '2' }"
    >
      <h2 class="data-content-title">2. Dados de Contato</h2>
      <p class="data-hint">Campo obrigatório</p>
      <form class="data-form" [formGroup]="contactData" #form>
        <app-form-input
          [type]="'email'"
          [parent-form]="contactData"
          name="email"
          label="E-mail"
          placeholder="Digite o seu email"
          [required]="true"
          class="full-width"
        ></app-form-input>

        <app-form-input
          [type]="'input'"
          [parent-form]="contactData"
          name="telefone"
          label="Telefone Celular"
          placeholder="(00) 00000-000"
          inputMask="(00) 00000-000"
          [required]="true"
          class="reduced"
        ></app-form-input>
        <div class="data-form-buttons">
          <button class="button-prev-step" type="button" awPreviousStep>
            Voltar
          </button>
          <button
            [disabled]="contactData.invalid"
            class="button-next-step"
            type="button"
            awNextStep
          >

            Continuar
          </button>
        </div>
      </form>
    </aw-wizard-step>
    <aw-wizard-step
      stepTitle="Escrever Mensagem"
      [navigationSymbol]="{ symbol: '3' }"
    >
      <h2 class="data-content-title">3. Escrever Mensagem</h2>
      <p class="data-hint">Campo obrigatório</p>
      <form class="data-form" [formGroup]="writeMessage" #form>
        <app-form-input
          [type]="'textarea'"
          [parent-form]="writeMessage"
          name="mensagem"
          label="Mensagem"
          [required]="true"
          [maxLength]="500"
          [hint]="true"
          placeholder="..."
        ></app-form-input>

        <div class="data-form-buttons">
          <button class="button-prev-step" type="button" awPreviousStep>
            Voltar
          </button>

          <button [disabled]="writeMessage.invalid" class="button-send" type="button" [awGoToStep]="{ stepIndex: 0 }" (finalize)="openFinishdata()">
            Enviar Mensagem
          </button>
        </div>
      </form>
    </aw-wizard-step>
  </aw-wizard>
</section>

<ngx-smart-modal 
  #modalFinish 
  identifier="modalFinish" 
  class="modal" 
  [closable]="false"
  (onOpen)="changeTitle('Mensagem Enviada')"
  (onAnyCloseEventFinished)="removeFromTitle('Mensagem Enviada')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalFinish.close()" title="Fechar">    
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Mensagem enviada com sucesso!</h1>
  </div>
  <div class="modal-content">
    <button class="modal-button" type="button" (click)="modalFinish.close()">
      Ok, obrigado.
    </button>
  </div>
</ngx-smart-modal>

