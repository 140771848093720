<div
  *ngIf="isHorizontalList"
  class="lego-list"
  [ngClass]="{ 'horizontal-list': isHorizontalList }"
>
  <h1 class="lego-list-title" [innerText]="title"></h1>

  <div class="lego-list-articles {{ componentVariation }}">
    <div class="lego-list-control left" (click)="previousArticles()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="lego-list-articles-list" #articles>
      <a
        class="lego-list-article {{ componentVariation }}"
        *ngFor="let article of (list | slice: 1)"
        [routerLink]="'/' + getRouterLink(article)"
      >
        <div class="lego-list-article-image {{ componentVariation }}">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="article.thumbnail"
            loading="lazy"
            alt=""
          />
        </div>
        <div class="lego-list-article-content {{ componentVariation }}">
          <p>
            <a
              class="lego-list-article-content-title"
              [innerHtml]="article.title"
            ></a>
          </p>
          <span
            class="lego-list-article-content-date"
            [innerHtml]="article.publishedAt | date: 'dd/MM/yyyy \'às\' HH:mm'"
          ></span>
        </div>
      </a>
    </div>

    <div class="lego-list-control right" (click)="nextArticles()">      
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </div>

  <button
    *ngIf="showMore"
    class="lego-list-load-more"
    [attr.data-analytics]="stationName + '-ver-mais-' + title.toString().toLowerCase()"
  >
    Ver Mais
  </button>
</div>

<section
  *ngIf="!isHorizontalList"
  class="lego-list"
  [ngClass]="{ home: isHome }"
>
  <div *ngIf="hasTitle" class="lego-list-title-wrapper">
    <h2 *ngIf="hasLink"
      class="lego-list-title"

    ><a
        [innerText]="title"
        [title]="'Abrir em ' + title"
        [ngClass]="[color || '']"
        [ngStyle]="{ color: '#' + hexaColor }"
        [routerLink]="link !== '' ? link : '#' "
        class="lego-list-title"
        [attr.data-analytics]="stationName + '-ver-em-' + title.toString().toLowerCase()"
        [attr.data-analytics-label]="link"
      ></a>
  </h2>

  <h2 *ngIf="!hasLink"
      class="lego-list-title"
        [innerText]="title"
        [title]="title"
        [ngClass]="[color || '']"
        [ngStyle]="{ color: '#' + hexaColor }"
        class="lego-list-title"
       >

  </h2>

  </div>

  <section *ngIf="withoutPlaylists" class="lego-list-container">
    <div class="lego-list-content">
      <app-lego-single-item
        *ngFor="let item of list"
        [content]="item"
        [size]="size"
        [show-badge]="showBadge"
      ></app-lego-single-item>
    </div>
  </section>

  <section *ngIf="!withoutPlaylists && !scrollNotice" class="lego-list-container">
    <div class="lego-list-content">
      <span *ngFor="let item of list; let i = index">
        <span *ngIf="fullItems">
          <app-lego-single-item
            *ngFor="let media of item.medias"
            [content]="media"
            [size]="size"
            [show-badge]="showBadge"
          ></app-lego-single-item>
        </span>

        <span *ngIf="!fullItems" [ngSwitch]="item.opcionalplaylist2">

          <span *ngSwitchCase="'modulo-lista-dupla'">
            <app-lego-single-item-big
              *ngFor="let media of item.medias"
              [content]="media"
              [size]="size"
              [color]="color || hexaColor"
              [show-badge]="showBadge"
            ></app-lego-single-item-big>
          </span>

          <span *ngSwitchCase="'modulo-texto'">
            <app-lego-text
              [content]="item.medias"
              *ngIf="item.medias && item.medias.length"
              [color]="[color || hexaColor]"
            ></app-lego-text>
          </span>

          <span *ngSwitchCase="'modulo-trio'">
            <app-lego-trio
              *ngIf="item.medias && item.medias.length"
              [content]="item.medias"
              [color]="color || hexaColor"
              [show-badge]="showBadge"
            ></app-lego-trio>
          </span>

          <span *ngSwitchCase="'modulo-trio-invertido'">
            <app-lego-trio-inverted
              [content]="item.medias"
              *ngIf="item.medias && item.medias.length"
              [color]="color || hexaColor"
              [show-badge]="showBadge"
            ></app-lego-trio-inverted>
          </span>

          <span *ngSwitchCase="'modulo-hibrido'">
            <app-lego-hibrido
              [content]="item.medias"
              *ngIf="item.medias && item.medias.length"
              [size]="size"
              [color]="color || hexaColor"
              [show-badge]="showBadge"
            ></app-lego-hibrido>
          </span>

          <span *ngSwitchDefault>
            <app-lego-single-item
              *ngFor="let media of item.medias"
              [content]="media"
              [size]="size"
              [show-badge]="showBadge"
            ></app-lego-single-item>
          </span>
        </span>
      </span>
    </div>
  </section>

  <section *ngIf="!withoutPlaylists && scrollNotice" class="lego-list-container">
    <div class="lego-list-content content-scroll">
      <span *ngFor="let item of list; let i = index">


        <span *ngIf="item.medias.length > 4"
          class="notice-control up"
          (click)=" previousArticlesTop()"
          >      
            <i class="fa fa-chevron-up" title="Rolar para cima"></i>
          </span>
        <span *ngIf="fullItems">
          <div class="limit-notice" #limiteScroll >
            <app-lego-single-item
            *ngFor="let media of item.medias"
            [content]="media"
            [size]="size"
            [show-badge]="showBadge"
          ></app-lego-single-item>
          </div>

        </span>
        <span *ngIf="item.medias.length > 4"
          class="notice-control down"
          (click)="nextArticlesTop()"
          >      
          <i class="fa fa-chevron-down" title="Rolar para baixo"></i>
        </span>
        <button
        *ngIf="isMobile"
        class="lego-list-load-more-visible"
        [ngClass]="[color || '']"
        (click)="nextArticlesTop()"
        [attr.data-analytics]="'integra-da-noticia-' + title.toString().toLowerCase()"
        [ngStyle]="{ background: '#' + hexaColor }"
      >
        Ver Mais
      </button>
      </span>
    </div>
  </section>

  <button
    *ngIf="showMore && canPaginate"
    class="lego-list-load-more-visible"
    [ngClass]="[color || '']"
    (click)="loadMoreNotices()"
    [attr.data-analytics]="stationName + '-ver-mais-' + title.toString().toLowerCase()"
    [ngStyle]="{ background: '#' + hexaColor }"
  >
    Ver Mais
  </button>

  <button
    *ngIf="isHome && canPaginate"
    class="lego-list-load-more-visible"
    [ngClass]="[color || '']"
    (click)="loadMore()"
    [attr.data-analytics]="stationName + '-ver-mais-' + title.toString().toLowerCase()"
    [ngStyle]="{ background: '#' + hexaColor }"
  >
    Ver Mais
  </button>
</section>
