<div class="about">
  <h2 class="about-title" [innerText]="title"></h2>

  <img *ngIf="header"  onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="header" alt="" class="about-image" />

  <section *ngIf="socialNetworks && socialNetworks.length > 0" class="social-networks">
    <div class="networks">
      <span *ngFor="let socialNetwork of socialNetworks" [ngClass]="socialNetwork.type">
        <a [href]="socialNetwork.link" target="_blank">
          <i class="fa fa-{{socialNetwork.type}}"></i>
        </a>
      </span>
    </div>
  </section>

  <app-share *ngIf="hasShare"></app-share>

  <app-video *ngIf="video" [url]="video"></app-video>

  <p class="about-content" [innerHtml]="about | safeHtml"></p>

  <button *ngIf="!open" class="about-continue-reading" (click)="readMore()">
    Continue Lendo
  </button>

  <button *ngIf="hasDonation" class="about-donate" (click)="donate()">    
    Doe Aqui <i class="fa fa-external-link-square"></i>
  </button>
</div>
