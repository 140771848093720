<section class="bookmarks">
  <div *ngIf="showGenders" class="bookmarks-genders">
    <div class="bookmarks-genders-container">
      <div
        *ngFor="let gender of genders; let i = index"
        (click)="changeIndex(i, gender)"
      >
        <p [ngClass]="{ active: currentIndex === i }" [innerText]="gender"></p>
      </div>
    </div>
  </div>

  <div class="bookmarks-list">
    <div
      *ngFor="let content of contentList"
      class="bookmark"
      [ngClass]="{
        bookmarked: isBookmarked(content)
      }"
    >
      <div class="bookmark-image">
        <div class="bookmark-sparks">
          <img src="../../../assets/images/sparks.png" alt="" />
        </div>
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="content.header"
          alt=""
        />
      </div>
      <div class="bookmark-title">
        <span [innerText]="content.title"></span>
        <div class="bookmark-actions">
          <a [routerLink]="'/' + getRouterLink(content)">Veja o site</a>
          <i *ngIf="!isBookmarked(content)" class="fa fa-star" (click)="bookmarkProgram(content)"></i>
          <i *ngIf="isBookmarked(content)" class="fa fa-close" (click)="removeBookmark()"></i>
        </div>
      </div>
    </div>
  </div>
</section>
