import { URL, IDSITE_TERMS_POLICY, IDSITEAREA_TERMS_POLICY, PLAYLIST_ID_POLICY } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PolicyService {
    constructor(_http) {
        this._http = _http;
    }
    getPolicy() {
        let queryString = `idsite=${IDSITE_TERMS_POLICY}&idsitearea=${IDSITEAREA_TERMS_POLICY}&idplaylist=${PLAYLIST_ID_POLICY}`;
        return this._http
            .get(`${URL}/api/medias?limit=1&${queryString}`);
    }
}
PolicyService.ɵprov = i0.ɵɵdefineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.ɵɵinject(i1.HttpClient)); }, token: PolicyService, providedIn: "root" });
