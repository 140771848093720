import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SchedulesService {
    constructor(_http) {
        this._http = _http;
    }
    getHighlightedList() {
        return this._http
            .get(`${URL}/api/highlighted?limit=5`)
            .toPromise()
            .then((response) => {
            const notices = response.results;
            return [
                {
                    medias: notices
                }
            ];
        });
    }
}
SchedulesService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SchedulesService_Factory() { return new SchedulesService(i0.ɵɵinject(i1.HttpClient)); }, token: SchedulesService, providedIn: "root" });
