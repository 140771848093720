<div class="faq__header">
  <app-header url="/assets/images/faq-header.png" [has-blurred-frame]="false"></app-header>
</div>
<div class="faq__wrapper">
  <div class="faq-breadcrumb">
    <app-breadcrumb [items]="itemsBreadcrumb"></app-breadcrumb>
  </div>
  <div class="faq-title">
    <span>QUAL É A SUA DÚVIDA?</span>
  </div>
  <div class="faq-search">
    <form #form="ngForm" class="faq-search__form" (ngSubmit)="handleFormSearch(form)" novalidate>
      <input autofocus class="faq-search__input" name="search" ngModel placeholder="Buscar na FAQ do SBT" required title="Buscar na FAQ do SBT" type="text"  />
      <button class="faq-search__submit" type="submit" title="Buscar">BUSCAR</button>
    </form>
  </div>
  <div *ngIf="showMenu" class="faq-menu">
    <button (click)="scrollMenuCategories(-100)" class="faq-menu__controller faq-menu__controller--left" title="Próximo slide" type="button">
      <i class="fa fa-chevron-left"></i>
    </button>
    <nav class="faq-menu__nav">
      <ul class="faq-menu__list" #menuCategories>
        <li *ngFor="let category of categories; let i = index" [ngClass]="{'faq-menu__item--active': category.id === activeCategory.id }" class="faq-menu__item">
          <button (click)="handleActiveCategory(category.id)" class="faq-menu__button" href="#" title="{{category.title}}">
            <div class="faq-menu__content">
              <span [innerHtml]="category.title | safeHtml"></span>
            </div>
          </button>
        </li>
      </ul>
    </nav>
    <button (click)="scrollMenuCategories(100)" class="faq-menu__controller faq-menu__controller--right" title="Slide anterior" type="button">
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
  <div *ngIf="showCategories" class="faq__categories">
    <div class="faq-categories__title">
      <span>Categorias</span>
    </div>
    <nav class="faq-categories__nav">
      <ul class="faq-categories__list">
        <li *ngFor="let category of data; let i = index" class="faq-categories__item">
          <button class="faq-categories__button" (click)="handleActiveCategory(category.id)" title="{{category.title}}" type="button">
            <div [ngStyle]="{backgroundImage:'url(' + category.image + ')'}" class="faq-categories__picture"></div>
            <span class="faq-categories__label" [innerHtml]="category.title | safeHtml"></span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <div *ngIf="showDoubts" class="faq-doubts">
    <div *ngIf="!showSearch" class="faq-doubts__headline">
      <span>Categoria: <b>{{ activeCategory.title }}</b></span>
    </div>
    <div *ngIf="showSearch" class="faq-doubts__headline">
      <span *ngIf="doubts.length > 0">Resultados de busca para: <b [innerHtml]="searchTerm | safeHtml"></b></span>

      <span *ngIf="doubts.length <= 0">Não encontramos nenhum resultado para o termo "<b [innerHtml]="searchTerm | safeHtml"></b>", tente novamente.</span>
    </div>
    <!-- doubts.length -->
    <ul class="faq-doubts__list">
      <li *ngFor="let doubt of doubts; let i = index" class="faq-doubts__item" [ngClass]="{'faq-doubts__item--active': activeDoubt === i}">
        <button (click)="handleActiveAccordionDoubt(i)" [ngClass]="{'faq-doubts__button--active': activeDoubt === i}" class="faq-doubts__button" title="Lorem ipsum dolor sit, amet consectetur adipisicing elit." type="button">
          <div class="faq-doubts__title">
            <span [innerHtml]="doubt.title | safeHtml"></span>
          </div>
        </button>
        <div class="faq-doubts__content" [ngClass]="{'faq-doubts__content--active': activeDoubt === i}">
          <div class="faq-doubts__description" [ngClass]="{'faq-doubts__description--active': activeDoubt === i}" [innerHtml]="doubt.description | safeHtml"></div>
          <div class="faq-doubts__footer">
            <button (click)="handleActiveDoubt(doubt, form.value.search)" class="faq-doubts__seeMore" title="Veja Mais" type="button">
              <span>Veja Mais</span>
            </button>
            <div class="faq-doubts__icon"></div>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="showSeeMore" class="faq-pagination">
      <button (click)="handlePagination()" [ngClass]="{'faq-pagination__button--inactive': paging}" class="faq-pagination__button" title="Veja Mais" type="button">
        <span *ngIf="paging">CARREGANDO...</span>
        <span *ngIf="!paging">VEJA MAIS</span>
      </button>
    </div>
  </div>
  <div *ngIf="showActiveDoubt" class="faq-doubt">
    <div class="faq-doubt__header">
      <div class="faq-doubt__title">
        <span [innerHtml]="doubt.title | safeHtml"></span>
      </div>
    </div>
    <div class="faq-doubt__body">
      <div class="faq-doubt__content">
        <span [innerHtml]="doubt.description | safeHtml"></span>
      </div>
    </div>
    <div class="faq-doubt__footer">
      <div class="faq-doubt__subtitle">
        <span>Palavras-chaves relacionadas:</span>
      </div>
      <ul class="faq-doubt__keywords">
        <li class="faq-doubt__item" *ngFor="let keyword of doubt.keywords; let i = index">
          <button (click)="handleKeyword(keyword)" class="faq-doubt__button" title="{{keyword}}" type="button">
            <span [innerHtml]="keyword | safeHtml"></span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="showErrorMessage" class="faq-error">
    <span>Houve um erro ao processar a solicitação. Aguarde um instante e tente novamente...</span>
  </div>
  <div *ngIf="loader" class="loader">
    <div class="loader__spin"></div>
    <span class="loader__span loader__span--blink">Carregando...</span>
  </div>
</div>
