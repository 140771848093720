import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ServicesService {
    constructor(_http) {
        this._http = _http;
    }
    getAboutServices() {
        return this._http
            .get(`${URL}/api/medias?idsite=302&idsitearea=2412&idplaylist=10679&limit=1`)
            .toPromise()
            .then((response) => {
            return response.results;
        });
    }
    getResults() {
        return this._http
            .get(`${URL}/api/medias?idsitearea=1694`)
            .toPromise()
            .then((response) => {
            const services = response;
            services.results = services.results.filter(promotion => promotion.opcional2 === 'S');
            services.results.forEach(service => {
                service.title = service.title
                    .toLowerCase()
                    .split(' ')
                    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
            });
            return services.results;
        });
    }
}
ServicesService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ServicesService_Factory() { return new ServicesService(i0.ɵɵinject(i1.HttpClient)); }, token: ServicesService, providedIn: "root" });
