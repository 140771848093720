/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playlist.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../video/video.component.ngfactory";
import * as i4 from "../video/video.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./playlist.component";
import * as i7 from "@angular/router";
import * as i8 from "../../pages/program/program.service";
import * as i9 from "../../services/analytics.service";
import * as i10 from "../../services/station.service";
import * as i11 from "../../services/comscore.service";
var styles_PlaylistComponent = [i0.styles];
var RenderType_PlaylistComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaylistComponent, data: {} });
export { RenderType_PlaylistComponent as RenderType_PlaylistComponent };
function View_PlaylistComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "video-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeVideo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { actived: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "content-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "video-thumbnail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "video-title"]], [[8, "innerText", 0], [1, "data-title", 0], [1, "data-pageview", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-play-circle"], ["title", "Assistir"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "video-container"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.id === _co.currentVideo)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.thumbnail; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); var currVal_5 = (_co.urlAnalytics + ((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }); }
function View_PlaylistComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "video-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "content-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["data-analytics", "ver-mais-playlists"]], [[8, "innerText", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = "Ver Mais"; _ck(_v, 3, 0, currVal_0); }); }
export function View_PlaylistComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "playlist"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-video", [], null, null, null, i3.View_VideoComponent_0, i3.RenderType_VideoComponent)), i1.ɵdid(2, 638976, null, 0, i4.VideoComponent, [i1.ElementRef, i5.DomSanitizer], { url: [0, "url"], adunit: [1, "adunit"], fixable: [2, "fixable"], fullWidth: [3, "fullWidth"], programTitle: [4, "programTitle"], programId: [5, "programId"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["V\u00EDdeos da playlist atual"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "section", [["class", "playlist-videos"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaylistComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoUrl; var currVal_1 = _co.adunit; var currVal_2 = false; var currVal_3 = true; var currVal_4 = _co.programTitle; var currVal_5 = _co.programId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.videos; _ck(_v, 7, 0, currVal_6); var currVal_7 = (((((_co.videos == null) ? null : _co.videos.length) > 0) && _co.pagination) && _co.pagination.hasMore); _ck(_v, 9, 0, currVal_7); }, null); }
export function View_PlaylistComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playlist", [], null, null, null, View_PlaylistComponent_0, RenderType_PlaylistComponent)), i1.ɵdid(1, 114688, null, 0, i6.PlaylistComponent, [i7.Router, i2.Location, i8.ProgramService, i9.AnalyticsService, i10.StationService, i11.ComscoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaylistComponentNgFactory = i1.ɵccf("app-playlist", i6.PlaylistComponent, View_PlaylistComponent_Host_0, { videos: "videos", pagination: "pagination", currentVideo: "current-video", route: "route", programTitle: "program-title", programId: "program-id", adunit: "program-unit" }, { loadMore: "load-more" }, []);
export { PlaylistComponentNgFactory as PlaylistComponentNgFactory };
