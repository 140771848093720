import { URL } from '../../app.settings';
import Program from '../../models/program.class';
import { StationService } from '../../services/station.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/station.service";
export class ProgramsService {
    constructor(_http, _stationService) {
        this._http = _http;
        this._stationService = _stationService;
    }
    getPrograms() {
        return this._http
            .get(`${URL}/api/programs?idregional=0&noar=S`)
            .toPromise()
            .then((response) => {
            const programs = response;
            const hasStationCookie = this._stationService.checkStationCookie();
            if (hasStationCookie) {
                const stationIdentifier = this._stationService.getStationCookie();
                const station = this._stationService.getStation(stationIdentifier);
                if (station && station[0]) {
                    return this._http
                        .get(`${URL}/api/programs?noar=S&idregional=${station[0].id}`)
                        .toPromise()
                        .then((regionalResponse) => {
                        const regionalPrograms = regionalResponse.results.map(program => new Program(program));
                        const nationalPrograms = programs.results.map(program => new Program(program));
                        return [...regionalPrograms, ...nationalPrograms];
                    });
                }
            }
            return programs.results.map(program => new Program(program));
        })
            .catch(e => console.log(e));
    }
}
ProgramsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProgramsService_Factory() { return new ProgramsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StationService)); }, token: ProgramsService, providedIn: "root" });
