import { Component, OnInit } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Meta } from '@angular/platform-browser'
import { terms } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'

import { RECAPTCHA } from 'src/app/app.settings'
import { MarketOptinService } from './market-optin.service'
import { MySbtService } from '../my-sbt/my-sbt.service'
import { debounceTime } from 'rxjs/operators'
import { StatesService } from 'src/app/services/states.service';
import { TitleService } from 'src/app/services/title.service';
import { CanonicalService } from 'src/app/services/canonical.service'
import { checkCpf } from 'src/app/validators/cpf.validator';

@Component({
  selector: 'app-market-optin',
  templateUrl: './market-optin.component.html',
  styleUrls: ['./market-optin.component.scss'],
  providers: [MarketOptinService, CanonicalService]
})
export class MarketOptinComponent implements OnInit {
  public siteKey: string = RECAPTCHA

  public sexOptions: any = [
    {
      value: 'Masculino',
      text: 'Masculino'
    },
    {
      value: 'Feminino',
      text: 'Feminino'
    }
  ]

  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,4}$'
  public cpfValidator: string = '^[0-9]{11}$'

  public personalData = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(this.pureTextPattern)]],
    sex: [null, [Validators.required]],
    birthday: ['', [Validators.required]],
    //cpf: ['', [Validators.required]],
    cpf: ['', [Validators.required, Validators.pattern(this.cpfValidator), checkCpf()]]
  })

  public contactData = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    cep: ['', [Validators.required]],
    cellphone: [''],
    phone: [''],
    emailoptin: false,
    acceptTerms: [false, Validators.requiredTrue],
    recaptcha: [null, Validators.required]
  })

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private MarketOptinService: MarketOptinService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnDestroy () {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    this.title.setTitle('Optin - SBT')
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  openTermsModal() {
    this.ngxSmartModalService
      .getModal('modalTerms')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalTerms').open()
  }

  openPolicyModal() {
    this.ngxSmartModalService
      .getModal('modalPolicy')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalPolicy').open()
  }

  openFinishdata (): void {
    const data = {
      name: this.personalData.get('name').value,
      sex: this.personalData.get('sex').value,
      birthday: this.personalData.get('birthday').value,
      cpf: this.personalData.get('cpf').value,
      email: this.contactData.get('email').value.toLowerCase(),
      cep: this.contactData.get('cep').value,
      cellphone: this.contactData.get('cellphone').value,
      phone: this.contactData.get('phone').value,
      acceptTerms: this.contactData.get('acceptTerms').value,
      emailoptin: this.contactData.get('emailoptin').value,
    }

    this.MarketOptinService
      .sendInfo(data)
      .then(() => {
        this.ngxSmartModalService.getModal('modalCadastroOptin').open()
        this.resetForms()
      })
      .catch(error => console.log(error))
  }

  resetForms (): void {
    this.personalData.reset()
    this.contactData.reset()
  }

  isCpfValid () {
    return (
      this.personalData.get('cpf').value != null &&
      this.personalData.get('cpf').value.length === 11 &&
      this.personalData.get('cpf').errors &&
      this.personalData.get('cpf').errors.invalidCpf &&
      (this.personalData.get('cpf').touched ||
        this.personalData.get('cpf').dirty)
    )
  }
}
