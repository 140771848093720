<div class="nav">
  <div class="nav-content">
    <div class="nav-list">
      <p
        *ngFor="let item of navContent; let i = index"
        [ngClass]="{ active: i === currentItemIndex }"
        [innerText]="item.title"
        (click)="changeCurrentIndex(i, item.link)"
      ></p>
    </div>
  </div>
</div>
