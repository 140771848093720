<section class="cards">
  <div class="card" *ngFor="let service of services">
    <div class="card-header">
      <img
        onerror="this.src='../../../assets/images/default-image.png'"
        [src]="service.image"
        alt=""
      />
    </div>

    <div class="card-content">
      <div>
        <div class="card-title" [innerText]="service.title"></div>
        <div class="card-title-buttons">
          <div *ngIf="service.opcional" class="card-regulation">
            <a (click)="sendInfo(service, 'modalRegulation')">Regulamento</a>
          </div>
          <div *ngIf="service.opcional1" class="card-winners">
            <a (click)="sendInfo(service, 'modalWinners')">Ganhadores</a>
          </div>
        </div>
      </div>
      <div class="card-description" [innerHtml]="service.description"></div>
      <div class="card-buttons">
        <a *ngIf="service.author" [href]="service.author" target="_blank">
          <button class="button">Mais Informações</button>
        </a>
        <button class="white-button" (click)="sendInfo(service, 'modalService')">
          Mande suas dúvidas
        </button>
      </div>
    </div>
  </div>
</section>
