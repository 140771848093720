var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramService } from 'src/app/pages/program/program.service';
import { generateTitleSlug } from 'src/app/utils/utils.generators';
export class ListCardsComponent {
    constructor(router, programService) {
        this.router = router;
        this.programService = programService;
        this.hasCategories = false;
        this.isInscription = false;
        this.hasButtonToInternalPage = false;
        this.stationName = '';
        this.currentCategoryIndex = 0;
        this.categories = ['Todos'];
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.hasCategories) {
                yield this.contents.forEach(content => {
                    if (content.gender) {
                        this.categories.push(content.gender);
                    }
                });
            }
            (yield Array.from(new Set(this.categories)).length) > 2
                ? (this.hasCategories = true)
                : (this.hasCategories = false);
            this.contentList = this.contents;
            if (this.router.routerState.snapshot.root.children.length > 0) {
                this.currentPlaylist = this.router.routerState.snapshot.root.children[0].params.playlist;
            }
            setTimeout(() => {
                this.isOverflown = this.contents.length > 4;
                if (this.programs) {
                    this.contents.forEach(content => {
                        const program = this.programs.find(program => +content.program === program.id);
                        this.getRouterLink(content, program);
                    });
                }
            }, 2000);
        });
    }
    getRouterLink(content, program) {
        if (this.isInscription) {
            if (program) {
                const title = generateTitleSlug(content.title);
                content.routerLink = `/${this.route}/${program.slug}/${content.id}/${title}`;
                return;
            }
            content.routerLink = '/404';
            return;
        }
        if (content.link) {
            if (this.route) {
                content.routerLink = `/${this.route}/${content.link}`;
                return;
            }
            content.routerLink = content.link;
        }
        if (this.route) {
            content.routerLink = `/${this.route}`;
        }
    }
    scroll(offset) {
        this.contentSlider.nativeElement.scrollBy({
            left: offset,
            behavior: 'smooth'
        });
    }
    prev() {
        return this.scroll(-200);
    }
    next() {
        return this.scroll(200);
    }
    setCategory(event, index) {
        if (this.currentCategoryIndex !== index) {
            this.currentCategoryIndex = index;
        }
    }
    filter(gender) {
        if (gender === 'Todos') {
            this.contentList = this.contents;
            return;
        }
        this.contentList = this.contents.filter(content => content.gender === gender);
    }
    receiveData(content) {
        this.filter(content.gender);
        this.currentCategoryIndex = content.index;
    }
}
