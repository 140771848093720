/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./silviosantos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./silviosantos.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/title.service";
import * as i5 from "../../services/metatags.service";
var styles_SilviosantosComponent = [i0.styles];
var RenderType_SilviosantosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SilviosantosComponent, data: {} });
export { RenderType_SilviosantosComponent as RenderType_SilviosantosComponent };
export function View_SilviosantosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_SilviosantosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-silviosantos", [], null, null, null, View_SilviosantosComponent_0, RenderType_SilviosantosComponent)), i1.ɵdid(1, 114688, null, 0, i2.SilviosantosComponent, [i3.DomSanitizer, i4.TitleService, i3.Meta, i5.MetatagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SilviosantosComponentNgFactory = i1.ɵccf("app-silviosantos", i2.SilviosantosComponent, View_SilviosantosComponent_Host_0, {}, {}, []);
export { SilviosantosComponentNgFactory as SilviosantosComponentNgFactory };
