import * as i0 from "@angular/core";
export class MetatagsService {
    removeTags(meta) {
        meta.removeTag('property="keywords"');
        meta.removeTag('property="new_keywords"');
        meta.removeTag('property="description"');
        meta.removeTag('property="revisit-after"');
        meta.removeTag('property="googlebot"');
        meta.removeTag('property="robots"');
        meta.removeTag('property="og:url"');
        meta.removeTag('property="og:type"');
        meta.removeTag('property="og:locale"');
        meta.removeTag('property="og:title"');
        meta.removeTag('property="og:image"');
        meta.removeTag('property="og:description"');
        meta.removeTag('property="og:site_name"');
        meta.removeTag('property="alexaVerifyID"');
    }
    updateTags(tags, meta) {
        tags.forEach(tag => {
            meta.updateTag(tag);
        });
    }
}
MetatagsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function MetatagsService_Factory() { return new MetatagsService(); }, token: MetatagsService, providedIn: "root" });
