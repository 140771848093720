<section *ngIf="inscription && !notContent">
  <section class="inscription-form">
    <div class="content-ad">
      <app-ad
      path="Inscricoes/top"
      sizesDesktop="[970,250],[728,90]"
      sizesMobile="[320,100],[320, 50]"
      [metadata]="{ bloco: 'top' }"
      ></app-ad>
    </div>

    <app-header
      url="../../../assets/images/inscricoes-header.png"
      [has-blurred-frame]="false"
      route="/inscricoes"
    ></app-header>
    <app-ad
      *ngIf="!isMobile"
      path="Inscricoes/especial"
      [metadata]="{ bloco: 'especial' }"
      [wallpaper]="true"
    ></app-ad>

    <div class="inscription-container">
      <div class="inscriptions-context">
        <div class="content">
        <div class="wrapper">
          <div class="inscription-header">
            <img
              onerror="this.src='../../../assets/images/default-image.png'"
              [src]="inscription.thumbnail"
              alt=""
            />
          </div>

          <div *ngIf="inscription?.title" class="inscription-title">
            <h1 [innerText]="inscription.title"></h1>
            <h2>Formulário de inscrição</h2>
          </div>

          <app-share></app-share>

          <div class="inscription-quote">
            <app-blockquote
              content="<b>Aviso</b>: Para as promoções comerciais ou de cunho cultural realizadas pelo SBT ou por terceiros  em parceria com o SBT, não serão aceitas inscrições e/ou participação de funcionários, estagiários e/ou prestadores de serviços do SBT."
              [is-big]="true"
            ></app-blockquote>
          </div>

          <p
            class="inscription-description"
            [innerHtml]="inscription?.description | safeHtml"
          ></p>

          <div class="btn-container">
            <p *ngIf="inscription?.opcional1">
              <button
                class="btn-regulamento"
                (click)="openModal()"
              >
              ver regulamento</button>
            </p>

            <p *ngIf="inscription?.opcional4">
              <button
                class="btn-regulamento"
                (click)="openModalTC()"
              >
              ver termo de consentimento</button>
            </p>
          </div>

          <app-playbuzz></app-playbuzz>
        </div>

        <section class="form">
          <section class="form-unavailable" *ngIf="!userCookie">
            <div class="unavailable-header">
              <i class="fa fa-lock"></i>

              <h1>Crie sua conta para preencher o formulário</h1>
            </div>

            <p>
              Você precisa ter feito login no <strong>MEU SBT</strong> para então poder fazer as inscrições no site do SBT!
            </p>

            <app-blockquote
              content="Após ter criado sua conta no <strong>MEU SBT</strong>, os seus dados cadastrais <strong>ficarão salvos</strong> para que você não precise informá-los toda vez que for fazer uma nova inscrição."
              [is-big]="true"
            ></app-blockquote>

            <div class="modal-form-buttons">
              <div class="buttons-wrapper">
                <button
                  class="button-prev-step"
                  type="button"
                  (click)="openLoginModal()"
                >
                  Fazer Login
                </button>
                <button
                  type="button"
                  class="button-next-step"
                  (click)="openCreateAccountModal()"
                >
                  Criar Conta
                </button>
              </div>
            </div>
          </section>

          <section class="form-unavailable" *ngIf="userCookie && subscribed">
            <div class="unavailable-header">
              <i class="fa fa-check-square-o"></i>
              <h1>Você já está inscrito!</h1>
            </div>

            <p>
              Acesse sua conta no MEU SBT para gerenciar esta inscrição ou para
              alterar seus dados cadastrais.
            </p>

            <div class="modal-form-buttons">
              <div class="buttons-wrapper">
                <a routerLink="/meu-sbt/minhas-inscricoes">
                  <button
                    type="button"
                    class="button-next-step"
                  >
                    Gerenciar inscrição no meu SBT
                  </button>
                </a>
              </div>
            </div>
          </section>

          <section *ngIf="userCookie && !subscribed">
            <h2 class="modal-content-title">1. Dados de inscrição</h2>
            <p class="modal-hint">Campo obrigatório</p>
            <form class="modal-form" [formGroup]="inscriptionData" #form>
              <app-form-input
                *ngFor="let field of fields"
                [type]="field.author"
                [parent-form]="inscriptionData"
                [name]="field.description"
                [required]="field.opcional === 'S'"
                (upload-field)="receiveData($event)"
                [options]="field.opcional1"
                [label]="field.title"
                [id]="field.id"
                [limit-caracteres]="field.opcional2 === 'S'"
                [placeholder]="
                  field.author === 'input'
                    ? ''
                    : 'Escreva aqui informações sobre: ' +
                      field.title.toLowerCase()
                "
              ></app-form-input>

              <div class="captcha">
                <re-captcha
                  name="recaptcha"
                  formControlName="recaptcha"
                  [siteKey]="siteKey"
                ></re-captcha>
              </div>

              <app-error
                *ngIf="inscriptionError || uploadError"
                [error]="inscriptionError || uploadError"
              ></app-error>

              <div class="modal-form-buttons">
                <button
                  [disabled]="inscriptionData.invalid || isInvalidUpload()"
                  class="button-next-step finish"
                  type="button"
                  (click)="openFinishModal()"
                >
                  Finalizar Inscrição
                </button>
              </div>
              <div class="modal-footer">
                Não consegue finalizar a inscrição? <a href="http://static.sbt.com.br/emissoras/nacional/inscricoes/tutorial/index.html" target="_blank">Clique aqui</a> para realizar o passo a passo.
              </div>
            </form>
          </section>
        </section>
        </div>
        <div class="inscriptions-high">
        <section class="inscriptions-ads" *ngIf="!isMobile" (scroll)="onscroll()" [ngClass]="bannerfixed?'fixed':'nofixed'">
          <app-ad
            path="Inscricoes/between"
            sizesDesktop="[300,250], [360,640], [300,600], [336,280]"
            [metadata]="{ bloco: 'bloco-1' }"
          ></app-ad>
        </section>
        </div>
      </div>
      <section class="inscriptions-ads">
        <app-ad
          path="Inscricoes/bottom"
          sizesDesktop="[970,90]"
          sizesMobile="[320,100]"
          [metadata]="{ bloco: 'bottom' }"
        ></app-ad>
      </section>
    </div>
  </section>
</section>

<section *ngIf="notContent">
  <app-not-found></app-not-found>
</section>

<ngx-smart-modal
  #modalRegulamento
  identifier="modalRegulamento"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Lista de Inscrições')"
  (onAnyCloseEventFinished)="removeFromTitle('Lista de Inscrições')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalRegulamento.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <h1 class="modal-inscriptions-title">Regulamento</h1>
  <div class="modal-container">
      <p
      class="inscription-description"
      [innerHtml]="inscription?.opcional1 | safeHtml"
    ></p>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalTermoDeConsentimento
  identifier="modalTermoDeConsentimento"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Lista de Inscrições')"
  (onAnyCloseEventFinished)="removeFromTitle('Lista de Inscrições')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalTermoDeConsentimento.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <h1 class="modal-inscriptions-title">Termo de Consentimento</h1>
  <div class="modal-container">
      <p
      class="inscription-description"
      [innerHtml]="inscription?.opcional4 | safeHtml"
    ></p>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #inscriptionSuccess
  identifier="inscriptionSuccess"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Inscrição Realizada')"
  (onAnyCloseEventFinished)="removeFromTitle('Inscrição Realizada')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="inscriptionSuccess.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header-confirm">
    <i class="fa fa-check"></i>
    <h1>Inscrição feita com sucesso!</h1>
  </div>
  <div class="modal-content confirm">
    <p>
      Obrigado por se inscrever. Caso você seja selecionado(a), o SBT entrará em
      contato com você por e-mail ou telefone num período de até 30 dias.
    </p>
    <p>
      Você pode acessar suas inscrições acessando a página de "<strong
        >Meu SBT</strong
      >" (no ícone de usuaŕio na barra superior) ou clicando
      <a routerLink="/meu-sbt/minhas-inscricoes">neste link</a>
    </p>
  </div>
</ngx-smart-modal>
