<section class="cards" #cardsContainer>
  <div class="item-big">
    <app-lego-item *ngIf="content[0]" [content]="content[0]" imageBig="image-big"></app-lego-item>
  </div>
  <section class="group">
    <div class="item-small">
      <app-lego-item *ngIf="content[1]" [content]="content[1]"></app-lego-item>
    </div>
    <div class="item-small">
      <app-lego-item *ngIf="content[2]" [content]="content[2]"></app-lego-item>
    </div>
  </section>
</section>
