var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { MenuService } from './menu.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { StationService } from '../../services/station.service';
import { UserService } from '../../services/user.service';
import { AnalyticsService } from '../../services/analytics.service';
export class MenuComponent {
    constructor(_service, router, renderer, stationService, userService, analyticsService) {
        this._service = _service;
        this.router = router;
        this.renderer = renderer;
        this.stationService = stationService;
        this.userService = userService;
        this.analyticsService = analyticsService;
        this.open = false;
        this.search = false;
        this.headers = [];
        this.emissoras = {};
        this.footers = {};
        this.iconOpenWithoutContent = false;
        this.hasStationBefore = false;
        this.defaultApps = [{
                'network': 'google',
                'url': 'https://play.google.com/store/apps/details?id=br.com.sbt.app&pli=1',
                'icon': 'fa-android'
            }, {
                'network': 'apple',
                'url': 'https://apps.apple.com/br/app/tv-sbt/id965947410?l=pt&ls=1',
                'icon': 'fa-apple store'
            }];
        this.defaultSocialNetworks = [{
                'network': 'youtube',
                'url': 'https://www.youtube.com/user/SBTonline',
                'icon': 'fa-youtube'
            }, {
                'network': 'instagram',
                'url': 'https://www.instagram.com/sbtonline',
                'icon': 'fa-instagram'
            }, {
                'network': 'facebook',
                'url': 'https://pt-br.facebook.com/SBTonline/',
                'icon': 'fa-facebook'
            }, {
                'network': 'twitter',
                'url': 'https://twitter.com/sbtonline',
                'icon': 'fa-twitter'
            }];
        this.subscriptions = [];
        this.isShowButton = true;
        const time = Math.floor(Date.now() / 1000);
        this.deferredPrompt = null;
        this.pwaName = 'installPwa';
        this.epochTime = time;
        this.pwaStatusInstalled = 'installed';
        this.pwaStatusCancelled = 'cancelled';
        this.pwaStatusNotInstalled = 'not installed';
        this.expires = parseInt(this.epochTime + 2592000, 10);
    }
    beforeInstallPwa(e) {
        e.preventDefault();
        this.deferredPrompt = e;
        this.showInstallPromotion();
    }
    installPwa(e) {
        e.preventDefault();
        this.deferredPrompt = null;
        this.setCacheLocal(this.pwaName, this.pwaStatusInstalled, -1);
        this.isShowButton = false;
    }
    onKeydownHandler(event) {
        this.open === true ? this.open = false : this.open;
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const routeSubscription = this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.open = false;
                    this.setStation();
                }
                if (event instanceof NavigationEnd) {
                    const user = this.userService.getUser();
                    this.userCookie = user && user.name ? user.name : '';
                    if (event.urlAfterRedirects.includes('/resultados')) {
                        this.iconOpenWithoutContent = true;
                        return;
                    }
                    this.iconOpenWithoutContent = false;
                    this.setStation();
                    if (this.stationService.checkStationCookie()) {
                        const stationIdentifier = this.stationService.getStationCookie();
                    }
                }
            });
            this.subscriptions.push(routeSubscription);
            const resMenu = yield this._service.getMenu();
            if (resMenu && resMenu['headers']) {
                this.headers = resMenu["headers"];
                this.emissoras = resMenu["emissoras"];
                this.footers = resMenu["footers"];
                console.log('[MENU] Call GetMenu', this.headers, this.emissoras, this.footers);
                this.setStation();
            }
            else {
                console.log('[MENU] Call GetMenu - Empty');
            }
            let userAgent = navigator.userAgent || navigator.vendor;
            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/Macintosh/i) || userAgent.includes("Mac")) {
                this.isShowButton = false;
                return;
            }
            this.cache = this.getCacheLocal(this.pwaName);
            if (this.cache.status === this.pwaStatusInstalled || this.cache.status === this.pwaStatusCancelled) {
                this.isShowButton = false;
            }
            if (!this.cache.expires) {
                this.setCacheLocal(this.pwaName, this.pwaStatusNotInstalled, this.expires);
            }
        });
    }
    setStation() {
        const hasStation = this.stationService.checkStationCookie();
        if (!hasStation && this.hasStationBefore) {
            this.hasStationBefore = false;
            this.station = '';
        }
        if (hasStation) {
            const stationIdentifier = this.stationService.getStationCookie();
            const station = this.stationService.getStation(stationIdentifier)[0];
            if (station) {
                station.affiliate
                    ? (this.station = `afiliada/${station.identifier}`)
                    : (this.station = `${station.identifier}`);
                this.hasStationBefore = true;
                this.defaultSocialNetworks = [{
                        'network': 'youtube',
                        'url': station.socialNetworks.youtube,
                        'icon': 'fa-youtube'
                    }, {
                        'network': 'instagram',
                        'url': station.socialNetworks.instagram,
                        'icon': 'fa-instagram'
                    }, {
                        'network': 'facebook',
                        'url': station.socialNetworks.facebook,
                        'icon': 'fa-facebook'
                    }, {
                        'network': 'twitter',
                        'url': station.socialNetworks.twitter,
                        'icon': 'fa-twitter'
                    }];
            }
        }
    }
    openMenu() {
        this.menuItems.nativeElement.scrollBy({
            top: -999,
            behavior: 'smooth'
        });
        this.open = !this.open;
        this.search = false;
        this.currentHeader = -1;
        this.currentSubmenu = -1;
    }
    hideSearch(event) {
        const { hide } = event;
        if (hide) {
            this.search = false;
        }
    }
    openSubMenuChildren(element, index) {
        if (this.currentSubmenu === index) {
            this.currentSubmenu = -1;
            return;
        }
        this.currentSubmenu = index;
    }
    openSubMenu(element, index) {
        if (this.currentHeader === index) {
            this.currentHeader = -1;
            return;
        }
        this.currentHeader = index;
    }
    openSearch() {
        if (location.pathname.includes('resultados')) {
            return;
        }
        this.search = !this.search;
    }
    clickedInside($event) {
        if ($event.target) {
            if ($event.target.nodeName) {
                const tagName = $event.target.nodeName.toLowerCase();
                if (tagName !== 'a' && tagName !== 'i') {
                    $event.preventDefault();
                    $event.stopPropagation();
                    return;
                }
                if (tagName === 'i') {
                    const { parentNode } = $event.target;
                    if (parentNode) {
                        const { classList } = parentNode;
                        if (classList && !classList.contains('external-link')) {
                            $event.preventDefault();
                            $event.stopPropagation();
                            return;
                        }
                    }
                }
                if (tagName === 'a') {
                    const { classList } = $event.target;
                    if (classList && !classList.contains('external-link')) {
                        $event.preventDefault();
                        $event.stopPropagation();
                        return;
                    }
                }
            }
        }
    }
    clickedOutside($event) {
        if (!this.menuContainer.nativeElement.contains($event.target)) {
            this.open = false;
        }
    }
    logout() {
        this.userService.logout();
        this.router.navigate([`/`]);
    }
    onWindowScroll(e) {
        if (window.pageYOffset > 50) {
            this.renderer.addClass(this.menu.nativeElement, 'sticky');
        }
        else {
            this.renderer.removeClass(this.menu.nativeElement, 'sticky');
        }
    }
    changeStation(stationIdentifier) {
        const currentStation = this.stationService.getStation(stationIdentifier);
        if (stationIdentifier === 'default') {
            this.stationService.deleteStationCookie();
            window.location.href = '/';
            return;
            // return this.router.navigate([`/`])
        }
        if (currentStation) {
            this.stationService.setStationCookie(stationIdentifier);
            if (currentStation[0].affiliate) {
                window.location.href = `/afiliada/${stationIdentifier}`;
                return;
                // this.router.navigate([`/afiliada/${stationIdentifier}`])
            }
            window.location.href = `/${stationIdentifier}/home`;
            return;
            // this.router.navigate([`/${stationIdentifier}/home`])
        }
    }
    setCacheLocal(key, status, expires) {
        localStorage.setItem(key, JSON.stringify({ status, expires }));
    }
    getCacheLocal(key) {
        const LC = localStorage.getItem(key);
        if (LC) {
            try {
                return JSON.parse(LC);
            }
            catch (e) {
                return {};
            }
        }
        else {
            return {};
        }
    }
    insertPwa() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.deferredPrompt !== null)
                this.isShowButton = false;
            this.deferredPrompt.prompt();
            const { outcome } = yield this.deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                this.deferredPrompt = null;
                this.analyticsService.sendEvent('installed', 'app');
            }
            else if (outcome === 'dismissed') {
                this.setCacheLocal(this.pwaName, this.pwaStatusCancelled, this.expires);
                this.isShowButton = false;
            }
        });
    }
    showInstallPromotion() {
        const cacheLS = this.getCacheLocal(this.pwaName);
        const expiresInstallPWA = cacheLS.expires;
        const statusInstallPWA = cacheLS.status;
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.setCacheLocal(this.pwaName, this.pwaStatusInstalled, 0);
        }
        else {
            switch (statusInstallPWA) {
                case this.pwaStatusCancelled:
                    if (expiresInstallPWA < this.epochTime) {
                        this.isShowButton = true;
                    }
                    break;
                default:
                    this.isShowButton = true;
                    break;
            }
        }
    }
    isInternalLink(url) {
        const domain = (new URL(url));
        if (url.indexOf(domain.host) > -1) {
            return true;
        }
        else {
            return false;
        }
    }
    getInternalUrl(url) {
        const domain = (new URL(url));
        return domain.pathname;
    }
}
