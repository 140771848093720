/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./station.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../services/station.service";
import * as i5 from "../../services/cookie.service";
import * as i6 from "./station.component";
import * as i7 from "@angular/router";
var styles_StationComponent = [i0.styles];
var RenderType_StationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StationComponent, data: {} });
export { RenderType_StationComponent as RenderType_StationComponent };
function View_StationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "option", [], [[8, "innerHTML", 1], [8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_x, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.identifier; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.context.$implicit.identifier; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = (_v.context.$implicit.identifier === null); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_StationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "station-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stationLogo; _ck(_v, 1, 0, currVal_0); }); }
export function View_StationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { station: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["station", 1]], null, 17, "section", [["class", "station"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "station-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "station-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "label", [["class", "station-label"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "select-mask-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [["select", 1]], null, 7, "select", [["data-analytics", "mudar-regional/afiliada"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.changeStation($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(10, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_p]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StationComponent_1)), i1.ɵdid(14, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StationComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "station-icon"], ["data-analytics", "emissora"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openStation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/icone-antena.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.stationField; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.stationsList; _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.stationLogo; _ck(_v, 16, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topLabel; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_StationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-station", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StationComponent_0, RenderType_StationComponent)), i1.ɵprd(512, null, i4.StationService, i4.StationService, [i5.CookieService]), i1.ɵdid(2, 245760, null, 0, i6.StationComponent, [i1.Renderer2, i4.StationService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StationComponentNgFactory = i1.ɵccf("app-station", i6.StationComponent, View_StationComponent_Host_0, {}, {}, []);
export { StationComponentNgFactory as StationComponentNgFactory };
