import { URL, ID_ECAD, IDSITEAREA_ECAD } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EcadService {
    constructor(_http) {
        this._http = _http;
    }
    get() {
        let queryString = `idsite=${ID_ECAD}&idsitearea=${IDSITEAREA_ECAD}&orderby=ordem&sort=desc`;
        return this._http.get(`${URL}/api/medias?limit=200&${queryString}`);
    }
}
EcadService.ɵprov = i0.ɵɵdefineInjectable({ factory: function EcadService_Factory() { return new EcadService(i0.ɵɵinject(i1.HttpClient)); }, token: EcadService, providedIn: "root" });
