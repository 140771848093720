/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ad/ad.component.ngfactory";
import * as i3 from "../../shared/ad/ad.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/station.service";
import * as i6 from "../../shared/video/video.component.ngfactory";
import * as i7 from "../../shared/video/video.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "../../shared/list-article/list-article.component.ngfactory";
import * as i11 from "../../shared/list-article/list-article.component";
import * as i12 from "../program/program.service";
import * as i13 from "../../shared/header/header.component.ngfactory";
import * as i14 from "../../shared/header/header.component";
import * as i15 from "../../services/cookie.service";
import * as i16 from "./live.service";
import * as i17 from "@angular/common/http";
import * as i18 from "../../services/canonical.service";
import * as i19 from "./live.component";
import * as i20 from "../../services/title.service";
import * as i21 from "../../services/metatags.service";
import * as i22 from "../../services/branding.service";
var styles_LiveComponent = [i0.styles];
var RenderType_LiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LiveComponent, data: {} });
export { RenderType_LiveComponent as RenderType_LiveComponent };
function View_LiveComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ad", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], wallpaper: [1, "wallpaper"], metadata: [2, "metadata"] }, null), i1.ɵpod(2, { bloco: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (("Ao_Vivo" + _co.stationAdUnit) + "/especial"); var currVal_1 = true; var currVal_2 = _ck(_v, 2, 0, "especial"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LiveComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "live-iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-video", [], null, null, null, i6.View_VideoComponent_0, i6.RenderType_VideoComponent)), i1.ɵdid(2, 638976, null, 0, i7.VideoComponent, [i1.ElementRef, i8.DomSanitizer], { url: [0, "url"], fixable: [1, "fixable"], fullWidth: [2, "fullWidth"], programTitle: [3, "programTitle"], isLive: [4, "isLive"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoUrl; var currVal_1 = false; var currVal_2 = true; var currVal_3 = _co.programTitle; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_LiveComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "embed-container"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.playerHTML; _ck(_v, 0, 0, currVal_0); }); }
function View_LiveComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "item-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "item-button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "yellow-button": 0 }), i1.ɵdid(4, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Visite o Site "]))], function (_ck, _v) { var currVal_2 = "item-button"; var currVal_3 = _ck(_v, 3, 0, (_v.parent.context.index == 0)); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.link; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_LiveComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "live-grade-item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "current-program": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "item-status"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "item-informations"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "item-schedules"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "item-name"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "item-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveComponent_5)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "live-grade-item"; var currVal_1 = _ck(_v, 2, 0, (_v.context.index === 0)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = (!_v.context.$implicit.exibe || (_v.context.$implicit.exibe !== "N")); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = ((_v.context.index === 0) ? "AO VIVO" : "A SEGUIR"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.startdate, "HH:mm")); _ck(_v, 8, 0, currVal_4); }); }
function View_LiveComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-article", [["id", "ultimos-videos"], ["url", "/sbt-videos"]], null, null, null, i10.View_ListArticleComponent_0, i10.RenderType_ListArticleComponent)), i1.ɵdid(1, 114688, null, 0, i11.ListArticleComponent, [i12.ProgramService], { title: [0, "title"], contents: [1, "contents"], url: [2, "url"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videosTitle; var currVal_1 = _co.videos; var currVal_2 = "/sbt-videos"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LiveComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 27, "section", [["class", "live"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "content-ad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-ad", [["sizesDesktop", "[970,250],[728,90]"], ["sizesMobile", "[320,100],[320, 50]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(4, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(5, { bloco: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-header", [["url", "../../../assets/images/aovivo-header.png"]], null, null, null, i13.View_HeaderComponent_0, i13.RenderType_HeaderComponent)), i1.ɵdid(7, 114688, null, 0, i14.HeaderComponent, [i4.Router], { url: [0, "url"], hasBlurredFrame: [1, "hasBlurredFrame"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveComponent_1)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 18, "div", [["class", "live-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "live-video"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveComponent_2)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["otherIframe", 2]], null, 0, null, View_LiveComponent_3)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "section", [["class", "live-grade"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveComponent_4)), i1.ɵdid(17, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "section", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "a", [["routerLink", "/programacao"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "button-show-all-schedules"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ver Hor\u00E1rios de Todos os Programas "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveComponent_6)), i1.ɵdid(24, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 3, "section", [["class", "live-ads"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "app-ad", [["sizesDesktop", "[970,90]"], ["sizesMobile", "[320,50], [320,100]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(27, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(28, { bloco: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (("Ao_Vivo" + _co.stationAdUnit) + "/top"); var currVal_1 = "[970,250],[728,90]"; var currVal_2 = "[320,100],[320, 50]"; var currVal_3 = _ck(_v, 5, 0, "top"); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "../../../assets/images/aovivo-header.png"; var currVal_5 = false; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = !_co.isMobile; _ck(_v, 9, 0, currVal_6); var currVal_7 = (_co.typeVideo === "youtube"); var currVal_8 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_9 = _co.schedule; _ck(_v, 17, 0, currVal_9); var currVal_12 = "/programacao"; _ck(_v, 20, 0, currVal_12); var currVal_13 = (_co.videos && _co.currentProgram); _ck(_v, 24, 0, currVal_13); var currVal_14 = (("Ao_Vivo" + _co.stationAdUnit) + "/bottom"); var currVal_15 = "[970,90]"; var currVal_16 = "[320,50], [320,100]"; var currVal_17 = _ck(_v, 28, 0, "bottom"); _ck(_v, 27, 0, currVal_14, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_10 = i1.ɵnov(_v, 20).target; var currVal_11 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); }); }
export function View_LiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-live", [], null, null, null, View_LiveComponent_0, RenderType_LiveComponent)), i1.ɵprd(512, null, i5.StationService, i5.StationService, [i15.CookieService]), i1.ɵprd(512, null, i16.LiveService, i16.LiveService, [i17.HttpClient, i5.StationService]), i1.ɵprd(512, null, i18.CanonicalService, i18.CanonicalService, [i9.DOCUMENT]), i1.ɵdid(4, 245760, null, 0, i19.LiveComponent, [i16.LiveService, i4.Router, i20.TitleService, i5.StationService, i21.MetatagsService, i8.Meta, i18.CanonicalService, i22.BrandingService, i8.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var LiveComponentNgFactory = i1.ɵccf("app-live", i19.LiveComponent, View_LiveComponent_Host_0, {}, {}, []);
export { LiveComponentNgFactory as LiveComponentNgFactory };
