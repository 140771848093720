/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./share.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-share/core";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/common";
import * as i5 from "./share.component";
import * as i6 from "@angular/router";
var styles_ShareComponent = [i0.styles];
var RenderType_ShareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShareComponent, data: {} });
export { RenderType_ShareComponent as RenderType_ShareComponent };
function View_ShareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "share-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showButtons() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-h"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mais "]))], null, null); }
function View_ShareComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "share-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideButtons() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-h"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Menos "]))], null, null); }
function View_ShareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [["class", "share-show-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "share-pinterest"], ["shareButton", "pinterest"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 540672, null, 0, i2.ShareButtonDirective, [i2.ShareButtons, i3.HttpClient, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i1.PLATFORM_ID], { shareButton: [0, "shareButton"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-pinterest"], ["title", "Pinterest"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compartilhar "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "share-google"], ["shareButton", "google"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 540672, null, 0, i2.ShareButtonDirective, [i2.ShareButtons, i3.HttpClient, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i1.PLATFORM_ID], { shareButton: [0, "shareButton"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-google-plus"], ["title", "Google+"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compartilhar "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pinterest"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "google"; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showMore; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_ShareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "share-facebook"], ["shareButton", "facebook"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 540672, null, 0, i2.ShareButtonDirective, [i2.ShareButtons, i3.HttpClient, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i1.PLATFORM_ID], { shareButton: [0, "shareButton"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-facebook-square"], ["title", "Facebook"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compartilhar "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "share-whatsapp"], ["shareButton", "whatsapp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 540672, null, 0, i2.ShareButtonDirective, [i2.ShareButtons, i3.HttpClient, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i1.PLATFORM_ID], { shareButton: [0, "shareButton"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-whatsapp"], ["title", "Whatsapp"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compartilhar "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "share-twitter"], ["shareButton", "twitter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 540672, null, 0, i2.ShareButtonDirective, [i2.ShareButtons, i3.HttpClient, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i1.PLATFORM_ID], { shareButton: [0, "shareButton"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-twitter"], ["title", "Twitter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Compartilhar "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_1)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareComponent_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "facebook"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "whatsapp"; _ck(_v, 6, 0, currVal_1); var currVal_2 = "twitter"; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.showMore; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.showMore; _ck(_v, 16, 0, currVal_4); }, null); }
export function View_ShareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-share", [], null, null, null, View_ShareComponent_0, RenderType_ShareComponent)), i1.ɵdid(1, 114688, null, 0, i5.ShareComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShareComponentNgFactory = i1.ɵccf("app-share", i5.ShareComponent, View_ShareComponent_Host_0, {}, {}, []);
export { ShareComponentNgFactory as ShareComponentNgFactory };
