<section class="list">
  <section class="list-title-wrapper">
    <h2 class="list-title" [innerText]="title"></h2>

    <a
      *ngIf="hasLink"
      [routerLink]="link"
      class="list-title-link"
      [innerText]="linkTitle"
    ></a>
  </section>

  <section class="list-container">
    <div class="list-control left" (click)="prev()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="list-content-list" #contentSlider>
      <a
        *ngFor="let content of contents; let i = index"
        class="list-item"
        [routerLink]="content.routerLink"
        [queryParams]="{video:content.id}"
        fragment="videos"
      >
        <div class="list-item-image">
          <img
             onerror="this.src='../../../assets/images/default-image.png'" [src]="
              content.thumbnail"
            alt=""
            [attr.data-analytics]="content.routerLink + '/video/' + content.id"
            [attr.data-title]="content?.title"
            loading="lazy"
          />
        </div>
        <div class="list-item-title-wrapper">
          <span class="list-item-title" [innerText]="content.title"></span>
        </div>
      </a>
    </div>

    <div class="list-control right" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </section>
</section>
