<section class="list">

  <div class="list-title-button">
  <h2 class="list-title"
    [innerText]="title"
    [title]="'Abrir em ' + title"
    [routerLink]="[showMoreLink]"
    [attr.data-analytics]="stationName + '-ver-incricoes'"
    [attr.data-analytics-label]="[showMoreLink]"
    ></h2>

    <section class="list-button-container">
      <a
        *ngIf="hasButtonToInternalPage"
        class="list-button"
        [routerLink]="[showMoreLink]"
        [attr.data-analytics]="stationName + '-botao-ver-todas-incricoes'"
        [attr.data-analytics-label]="[showMoreLink]"
      >
        Ver Todas
      </a>
    </section>
  </div>

  <section *ngIf="hasCategories" class="tab">
    <app-tab
      [content-list]="categories"
      (receive-data)="receiveData($event)"
    ></app-tab>
  </section>

  <section class="list-container" [ngClass]="{ 'is-overflown': isOverflown }">
    <div *ngIf="isOverflown" class="list-control left" (click)="prev()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="list-content-list" [ngClass]="extraClasses" #contentSlider>
      <a
        *ngFor="let content of contents; let i = index"
        class="list-item"
        [title]="content.title"
        [routerLink]="content.routerLink"
        [ngClass]="{
          actived: (currentPlaylist && content.link === currentPlaylist) || (!currentPlaylist && content.link === '')
        }"
      >
        <div class="list-item-image">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="content.thumbnail"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="list-item-title">
          <span [innerText]="content.title"></span>
        </div>
      </a>
    </div>

    <div *ngIf="isOverflown" class="list-control right" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </section>
</section>
