export const DOMAIN: string = 'content.tvsbt.com.br'
export const SITE_LINK: string = 'https://www-dev.tvsbt.com.br'
export const URL: string = `https://${DOMAIN}`
export const URL_SEARCH_API: string = 'https://search.tvsbt.com.br'
export const URL_USERS_API: string = 'https://user.tvsbt.com.br'
export const URL_INSCRIPTIONS: string = 'https://inscriptions.tvsbt.com.br'
export const URL_TOKEN_MEDIASTREAM: string = 'https://utils.tvsbt.com.br/player/mediastream/token/'
export const BUCKET: string = 'https://static-dev.tvsbt.com.br'
export const INSTITUTIONAL_MAP: string = 'https://sitemap-dev.tvsbt.com.br'
export const HOTSITE_SILVIOSANTOS: string = 'https://silviosantos-dev.tvsbt.com.br'
export const HOTSITE_TROFEUINTERNET: string = 'https://trofeuinternet-dev.tvsbt.com.br'
export const SOCIAL_CONTENT: string = 'https://social-content.tvsbt.com.br'
export const DISQUS_SHORTNAME: string = 'sbt-com-br'
export const URL_GEOLOCATION: string = 'https://us-east1-sbt-site-dev.cloudfunctions.net/getLocation'
export const RECAPTCHA: string = '6LcajeESAAAAADG8_ykpOWga9hdH1pyarGCfpYjp'
export const API_GATEWAY_TOKEN: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImlsZWdyYS1kZXYiLCJlbmRwb2ludHMiOlsiKiJdLCJob3N0cyI6WyIqIl0sInNlcnZpY2VzIjpbIioiXSwiaWF0IjoxNTUyNTkyNjg1fQ.jpdeX3GgVY2lteBro6YZU2vK4uxfUzCrOlVZtvPKqpA'
export const COMSCORE_PUBLISHER_ID: string = '17692091'
export const ANALYTICS_ID: string = 'UA-5650410-30'
export const ANALYTICS_ID_ALLAMANDA: string = 'UA-131746191-1'
export const ANALYTICS_ID_VTV: string = 'UA-27740738-1'
export const IDSITE_TERMS_POLICY: number = 10
export const IDSITEAREA_TERMS_POLICY: number = 2629
export const IDSITEAREA_ECAD: number = 24795
export const IDSITEAREA_FAQ: number = 25795
export const IDSITEAREA_PODCASTS: number = 29795
export const PLAYLIST_ID_TERMS: number = 11295
export const PLAYLIST_ID_POLICY: number = 11294
export const PLAYLIST_ID_PORTAL_POLICY: number = 26067
export const PLAYLIST_ID_TERMS_PARTNER: number = 14696
export const TYPE_ENVIRONMENT_CHAT_BOT: string = 'staging'
export const URL_SITE_SBTNEWS: string  = 'http://sbtnews.tvsbt.com.br'
export const ID_SITE: number = 10
export const ID_SITE_SBTNEWS: number = 680
export const ID_SITE_SBT_BRASIL: number = 26
export const ID_SITE_PRIMEIRO_IMPACTO: number = 490
export const ID_SITE_SBTNOTICIAS: number = 517
export const ID_SBTNOTICIAS: number = 327
export const DEBUG: boolean = true
export const ID_ECAD: number = 251
export const ID_FAQ: number = 10
export const ID_PODCASTS: number = 2466
export const DEFAULT_TITLE: string = 'Sistema Brasileiro de Televisão - SBT'
export const SECRET_KEY: string = '!@#$%¨&*()_sitesbt142536_)(*&¨%$#@!'
export const MENU_IDS: any = {
    site: {
        id: 10
    },
    areas: {
        header: {
            id: 33814
        },
        emissoras: {
            id: 32813,
            playlists: {
                sul: {
                    id: 78085
                },
                sudeste: {
                    id: 78086
                },
                centroOeste: {
                    id: 78084
                },
                norte: {
                    id: 78082
                },
                nordeste: {
                    id: 78083
                }
            }
        },
        footer: {
            id: 32812,
            playlists: {
                about: {
                    id: 78079
                },
                gss: {
                    id: 78080
                }
            }
        }
    }
}
export const WEBPUSH: any = {
    ENABLED: true,
    SERVICE: 'https://webpush-subscription.sbt-digital-hlg.com.br',
    SERVICE_ACCESS_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IndlYnB1c2gtc3Vic2NyaXB0aW9uLXNidG5ld3MiLCJlbmRwb2ludHMiOlsiKiJdLCJob3N0cyI6WyIqIl0sInNlcnZpY2VzIjpbIndlYnB1c2gtc3Vic2NyaXB0aW9uIl0sImlhdCI6MTYyODYwMDQ2Nn0.SBk4HYG6O-tEqQ-3Or6RtT4nU8jxC9bEMDT7s4y_GAc',
    METHODS: {
        SUBSCRIBE: 'subscribe',
        UNSUBSCRIBE: 'unsubscribe',
        REFRESH: 'refreshsubscription'
    },
    TOPIC_NAME: 'site-sbt-homolog',
    VAPIKEY: 'BBiVDkgAHvdgJI2n7FxU6ULQJl2wz5K8t93o21EwhFZLbu7rVLSlmPIsLf0bHZcQxgknxTZbUatIsBHj0gKRBtw'
}
