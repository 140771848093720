<section [ngSwitch]="type">
  <span *ngSwitchCase="'playlists'">
    <section
      *ngFor="let playlist of home.playlists"
      class="with-ad"
      [id]="playlist.id">
        <app-lego-list
          *ngIf="playlist.medias && playlist.medias?.length > 0"
          [id]="playlist.title?.toLowerCase().split(' ').join('-')"
          [title]="playlist.title"
          [list]="playlist.medias"
          [hexa-color]="playlist.color"
          [is-home]="true"
          [has-link]="playlist.link !== ''"
          [link]="playlist.link"
          [link-title]="'Ver em ' + playlist.title"          
          [station-name]="stationName"          

        ></app-lego-list>

        <app-ad
          [path]="'Home' + stationAdUnit + '/between'"
          sizesDesktop="[300,250], [360,640], [336,280], [300,600]"
          sizesMobile="[300,250], [336,280]"
          [metadata]="{ bloco: playlist.adUnitBlock }"
        ></app-ad>
    </section>
  </span>

  <span *ngSwitchCase="'programming-grid'">
    <section id="programming-grid">
        <app-programming-grid
          title="Programação"
          [is-home]="true"
          [station-name]="stationName"
        ></app-programming-grid>
    </section>
  </span>

  <span *ngSwitchCase="'programFav'">
    <section id="programFav">
        <app-lego-list
          *ngIf="home.programFav"
          [hexa-color]="'464646'"
          [title]="home.programFavTitle"
          [list]="home.programFav"
          [is-home]="true"
          [has-link]="true"
          link="{{ '/' + home.programFavRoute | noAccents }}"
          [link-title]="'Ver na página'"
          [station-name]="stationName + '-favorito'"
        ></app-lego-list>
    </section>
  </span>

  <span *ngSwitchCase="'station'">
    <section id="station">
        <app-lego-list
          *ngIf="home.station && home.station?.notices?.length > 0"
          [hexa-color]="'464646'"
          title="Destaques"
          [list]="home.station.notices"
          [is-home]="true"
          [has-link]="false"
          [station-name]="stationName"
        ></app-lego-list>
    </section>
  </span>

  <span *ngSwitchCase="'high'">
    <section id="high">
        <app-highlighted-list
          *ngIf="home.inHigh"
          title="Em Alta!"
          [content]="home.inHigh"
        ></app-highlighted-list>
    </section>

     <app-ad
      [path]="'Home' + stationAdUnit + '/between'"
      sizesDesktop="[728,90]"
      sizesMobile="[320,50], [320,100], [300,250]"
      [metadata]="{ bloco: 'bloco-4' }"
    ></app-ad>
  </span>

  <span *ngSwitchCase="'videos'">
    <section id="videos">
      <app-list-article
        *ngIf="home.videos && home.programs"
        url="/sbt-videos"
        title="Últimos Vídeos"
        [content]="home.videos"
        [has-link]="false"
        [link]="'/sbt-videos'"
        [link-title]="'Ver em Vídeos'"
      ></app-list-article>
    </section>
  </span>

  <span *ngSwitchCase="'inscriptions'">
    <section id="inscriptions">
        <app-list-cards
          *ngIf="home.inscriptions && home.programs"
          title="Inscrições"
          [has-categories]="true"
          [has-button-to-internal-page]="true"
          [content]="home.inscriptions"
          [programs]="home.programs"
          show-more-link="/inscricoes"
          route="/inscricoes"
          [is-inscription]="true"
          [station-name]="stationName"
        ></app-list-cards>
    </section>
  </span>

  <!-- <span *ngSwitchCase="'programs'">
    <section id="programs">
        <app-card-list
          *ngIf="home.programs"
          title="Programas"
          items-per-row="3"
          title="Programas"
          [content]="home.programs"
        ></app-card-list>
    </section>
  </span> -->
</section>
