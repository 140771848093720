<a
  class="header"
  [ngClass]="{ 'has-route': route?.length > 0 }"
  [routerLink]="route"
>
  <img
    *ngIf="hasBlurredFrame"
    onerror="this.src='../../../assets/images/default-image.png'"
    [src]="url"
    alt=""
    class="img-background"
  />
  <img
    [ngClass]="{ blurred: hasBlurredFrame, 'only-image': isOnlyImage }"
    onerror="this.src='../../../assets/images/default-image.png'"
    [src]="url"
    [alt]="'logo do programa ' + alt"
    loading="lazy"
    class="img-header"
  />
  <div *ngIf="hasDarkOverlay" class="dark-overlay"></div>
  <div *ngIf="hasBlueOverlay" class="blue-overlay"></div>

  <h1 *ngIf="title" [innerText]="title"></h1>
  <img
    *ngIf="logo"
    onerror="this.src='../../../assets/images/default-image.png'"
    [src]="logo"
    alt=""
    class="img-logo"
  />
</a>
