/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lego-trio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../item/item.component.ngfactory";
import * as i3 from "../item/item.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./lego-trio.component";
var styles_LegoTrioComponent = [i0.styles];
var RenderType_LegoTrioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegoTrioComponent, data: {} });
export { RenderType_LegoTrioComponent as RenderType_LegoTrioComponent };
function View_LegoTrioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-item", [["imageBig", "image-big"]], null, null, null, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemComponent, [i4.Router], { content: [0, "content"], imageBig: [1, "imageBig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content[0]; var currVal_1 = "image-big"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LegoTrioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-item", [], null, null, null, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemComponent, [i4.Router], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content[1]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LegoTrioComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-item", [], null, null, null, i2.View_ItemComponent_0, i2.RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.ItemComponent, [i4.Router], { content: [0, "content"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content[2]; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LegoTrioComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cardsContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["cardsContainer", 1]], null, 10, "section", [["class", "cards"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "item-big"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoTrioComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "section", [["class", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "item-small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoTrioComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "item-small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoTrioComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content[0]; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.content[1]; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.content[2]; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_LegoTrioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-trio", [], null, null, null, View_LegoTrioComponent_0, RenderType_LegoTrioComponent)), i1.ɵdid(1, 114688, null, 0, i6.LegoTrioComponent, [i1.Renderer2, i4.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegoTrioComponentNgFactory = i1.ɵccf("app-lego-trio", i6.LegoTrioComponent, View_LegoTrioComponent_Host_0, { content: "content", color: "color", showBadge: "show-badge" }, {}, []);
export { LegoTrioComponentNgFactory as LegoTrioComponentNgFactory };
