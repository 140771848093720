<section
  class="tab"
  [ngClass]="{ 'is-overflown': isOverflown }"
>
  <div *ngIf="isOverflown" class="content-previous" (click)="prev()">
    <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>    
  </div>

  <div class="content-wrapper" #tabContent [ngClass]="extraClasses">
    <div
      *ngFor="let content of contentList; let i = index"
      (click)="changeIndex(i, content)"
    >
      <p
        [ngClass]="{
          active: currentIndex === i
        }"
        [innerText]="content?.title || content"
      ></p>
    </div>
  </div>

  <div *ngIf="isOverflown" class="content-next" (click)="next()">    
    <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
  </div>
</section>
