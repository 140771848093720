var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InscriptionsService } from '../inscriptions/inscriptions.service';
import { ProgramService } from '../program/program.service';
import { RECAPTCHA } from 'src/app/app.settings';
import { InscriptionFormService } from './inscription-form.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/services/title.service';
import { MetatagsService } from 'src/app/services/metatags.service';
import { Meta } from '@angular/platform-browser';
import { isMobile } from '../../utils/util.browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Log } from 'src/app/utils/util.console';
export class InscriptionFormComponent {
    constructor(ngxSmartModalService, fb, activatedRout, inscriptionsService, service, programService, userService, router, title, metaService, meta, analytics) {
        this.ngxSmartModalService = ngxSmartModalService;
        this.fb = fb;
        this.activatedRout = activatedRout;
        this.inscriptionsService = inscriptionsService;
        this.service = service;
        this.programService = programService;
        this.userService = userService;
        this.router = router;
        this.title = title;
        this.metaService = metaService;
        this.meta = meta;
        this.analytics = analytics;
        this.siteKey = `${RECAPTCHA}`;
        this.uploadPathObject = {};
        this.subscriptions = [];
        this.isMobile = false;
        this.notContent = false;
        this.inscriptionData = this.fb.group({
            recaptcha: [null, Validators.required]
        });
        this.bannerfixed = false;
    }
    onscroll() {
        if (window.scrollY > 550) {
            this.bannerfixed = true;
        }
        else {
            this.bannerfixed = false;
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isMobile = isMobile();
            const activeRouteSubscriber = this.activatedRout.params.subscribe(params => {
                this.currentInscription = params['inscription'];
                this.currentProgramSlug = params['slug'];
            });
            if (isNaN(parseInt(this.currentInscription, 10))) {
                this.router.navigate(['/inscricoes']);
            }
            this.subscriptionUserLogged = this.userService
                .getUserLogin()
                .subscribe((userObservable) => __awaiter(this, void 0, void 0, function* () {
                if (userObservable.userIsLogged) {
                    yield this.userService.getUserFromDatabase(userObservable.user.email)
                        .then((response) => {
                        if (response && response.email) {
                            this.userCookie = userObservable.user.email;
                            this.userService
                                .getInscriptionsByUser(this.userCookie)
                                .then(inscription => {
                                if (inscription && inscription.length > 0) {
                                    const hasInscription = inscription[0].inscriptions
                                        .find(inscription => inscription.id.toString() === this.currentInscription);
                                    hasInscription ? this.subscribed = true : this.subscribed = false;
                                }
                                else {
                                    this.title.setTitle(`Sistema Brasileiro de Televisão - SBT`);
                                }
                            });
                        }
                        else {
                            this.userService.logout();
                            this.router.navigate(['/']);
                        }
                    })
                        .catch((error) => {
                        this.userService.logout();
                        this.router.navigate(['/']);
                    });
                }
            }));
            this.subscriptions.push(this.subscriptionUserLogged);
            this.subscriptions.push(activeRouteSubscriber);
            yield this.inscriptionsService
                .getInscriptionsById(this.currentInscription)
                .then(inscription => {
                this.inscription = inscription[0];
                if (inscription && inscription.length > 0) {
                    this.title.setTitle(`${this.inscription.title} - SBT`);
                    this.updateMetaTags();
                }
                else {
                    this.notContent = true;
                }
            });
            yield this.programService
                .getProgramBySlug(this.currentProgramSlug)
                .then(program => {
                this.program = program.program;
            });
            this.service.getFields(this.currentInscription).then((response) => {
                this.fields = response;
                this.fields.forEach((field) => {
                    if (field.opcional1.length > 0) {
                        field.opcional1 = this.buildSelectOptions(field.opcional1);
                    }
                    field.opcional === 'S'
                        ? this.inscriptionData.addControl(field.description, new FormControl(null, Validators.required))
                        : this.inscriptionData.addControl(field.description, new FormControl(null));
                    if (field.author.includes('video')) {
                        const videoFieldInscription = this.inscriptionData
                            .get(field.description)
                            .valueChanges.subscribe(value => {
                            this.uploadPathObject[field.description] = value;
                        });
                        this.subscriptions.push(videoFieldInscription);
                    }
                    if (field.author.includes('image')) {
                        const imageFieldInscription = this.inscriptionData
                            .get(field.description)
                            .valueChanges.subscribe(value => {
                            this.uploadPathObject[field.description] = value;
                        });
                        this.subscriptions.push(imageFieldInscription);
                    }
                });
            });
        });
    }
    updateMetaTags() {
        this.metaService.removeTags(this.meta);
        const metatags = [
            { name: 'description', content: this.inscription.title },
            { name: 'keywords', content: `SBT, novelas, carrossel, chiquititas, chaves, Silvio Santos, eliana, celso portiolli, ratinho, Programa Silvio Santos, esquadrão da moda, câmeras escondidas, a praça é nossa, a praca e nossa, raul gil, programa raul gil, filmes, programa do ratinho, novelas mexicanas, bastidores da tv, programação SBT, casos de família, programa eliana, The Noite, Eliana, programa da maisa, silvio santos, inscricao, inscrição, INSCRIÇÃO, inscrições, sbt, as aventuras de poliana,poliana` },
            { property: 'og:type', content: 'website' },
            { property: 'og:locale', content: 'pt_BR' },
            { property: 'og:site_name', content: 'SBT - Sistema Brasileiro de Televis&atilde;o' },
            { property: 'og:title', content: this.inscription.title },
            { property: 'og:description', content: this.inscription.title },
            { property: 'og:image', content: this.inscription.thumbnail },
            { property: 'og:url', content: window.location.href },
            { name: 'twitter:card', content: 'summary' }
        ];
        this.metaService.updateTags(metatags, this.meta);
    }
    removeFromTitle(title) {
        const currentTitle = this.title.getTitle().replace(`${title} - `, '');
        this.title.setTitle(`${currentTitle}`);
    }
    changeTitle(title) {
        const currentTitle = this.title.getTitle();
        this.title.setTitle(`${title} - ${currentTitle}`);
    }
    buildSelectOptions(optionsString) {
        let options = optionsString.split(';');
        let optionsObject = options.filter(option => option !== '').map(option => {
            if (option.length > 0) {
                const value = option.split('_')[0];
                const text = option.split('_')[1];
                return {
                    value,
                    text
                };
            }
        });
        return optionsObject;
    }
    isInvalidUpload() {
        return (this.uploadError ||
            Object.keys(this.uploadPathObject).some(key => {
                return this.uploadPathObject[key].includes('fakepath');
            }));
    }
    openModal() {
        this.ngxSmartModalService.getModal('modalRegulamento').open();
    }
    openModalTC() {
        this.ngxSmartModalService.getModal('modalTermoDeConsentimento').open();
    }
    openFinishModal() {
        location.hash = "finalizouinscricao";
        let data = Object.assign({}, this.inscriptionData.value, { enable: true });
        data = Object.assign({}, data, this.uploadPathObject);
        const inscriptionValues = {
            inscription: this.currentInscription,
            user: this.userCookie,
            inscriptionName: this.inscription.title,
            inscriptionProgram: this.program.title,
            data
        };
        this.inscriptionsService
            .createUserInscription(inscriptionValues)
            .then(response => {
            this.ngxSmartModalService.getModal('inscriptionSuccess').open();
            Log('INSCRICAO', { idinscription: this.currentInscription, inscription: this.inscription, program: this.program });
            this.analytics.sendDataLayerEvent({
                event: "inscricao-programa",
                programa: this.program.title,
                quadro: this.inscription.title,
                status: 'sucesso'
            });
            setTimeout(() => this.router.navigate(['/inscricoes']), 4000);
        })
            .catch(error => {
            this.inscriptionError = error.message;
            this.analytics.sendDataLayerEvent({
                event: "inscricao-programa",
                programa: this.program.title,
                quadro: this.inscription.title,
                status: 'falha'
            });
        });
    }
    openCreateAccountModal() {
        if (this.ngxSmartModalService.getModal('modalLogin').isVisible()) {
            this.ngxSmartModalService.getModal('modalLogin').close();
        }
        this.ngxSmartModalService.getModal('modalCreateAccount').open();
    }
    openLoginModal() {
        if (this.ngxSmartModalService.getModal('modalForms').isVisible()) {
            this.ngxSmartModalService.getModal('modalForms').close();
        }
        if (this.ngxSmartModalService.getModal('modalFinish').isVisible()) {
            this.ngxSmartModalService.getModal('modalFinish').close();
        }
        if (this.ngxSmartModalService.getModal('modalCreateAccount').isVisible()) {
            this.ngxSmartModalService.getModal('modalCreateAccount').close();
        }
        this.ngxSmartModalService.getModal('modalLogin').open();
    }
    receiveData(event) {
        if (event.uploadError) {
            this.uploadError = event.uploadError;
            return;
        }
        this.uploadError = undefined;
        if (event.fieldName) {
            this.uploadPathObject[event.fieldName] = event.fieldValue;
        }
    }
}
