/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-inscriptions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-smart-modal/ngx-smart-modal.ngfactory";
import * as i4 from "ngx-smart-modal";
import * as i5 from "./card-inscriptions.component";
import * as i6 from "../../services/title.service";
var styles_CardInscriptionsComponent = [i0.styles];
var RenderType_CardInscriptionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardInscriptionsComponent, data: {} });
export { RenderType_CardInscriptionsComponent as RenderType_CardInscriptionsComponent };
function View_CardInscriptionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "inscription"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "inscription-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "inscription-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "inscription-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "inscription-description"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "inscription-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "button button-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelInscriptionModal(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancelar Inscri\u00E7\u00E3o "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.thumbnail; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.description.slice(0, 250) + "..."); _ck(_v, 5, 0, currVal_2); }); }
export function View_CardInscriptionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "card-inscriptions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "inscriptions-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardInscriptionsComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 17, "ngx-smart-modal", [["class", "modal"], ["identifier", "modalCancel"]], null, [[null, "onOpen"], [null, "onAnyCloseEventFinished"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).targetPlacement() !== false);
        ad = (pd_0 && ad);
    } if (("onOpen" === en)) {
        var pd_1 = (_co.changeTitle("Cancelar Inscri\u00E7\u00E3o") !== false);
        ad = (pd_1 && ad);
    } if (("onAnyCloseEventFinished" === en)) {
        var pd_2 = (_co.removeFromTitle("Cancelar Inscri\u00E7\u00E3o") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NgxSmartModalComponent_0, i3.RenderType_NgxSmartModalComponent)), i1.ɵdid(5, 4440064, [["modalCancel", 4]], 0, i4.NgxSmartModalComponent, [i1.Renderer2, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i2.DOCUMENT, i1.PLATFORM_ID], { closable: [0, "closable"], identifier: [1, "identifier"] }, { onAnyCloseEventFinished: "onAnyCloseEventFinished", onOpen: "onOpen" }), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "button", [["aria-label", "Close"], ["class", "modal-button-close nsm-dialog-btn-close"], ["title", "Fechar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "div", [["class", "modal-header cancel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tem certeza que deseja cancelar sua inscri\u00E7\u00E3o?"])), (_l()(), i1.ɵeld(12, 0, null, 0, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" N\u00F3s sentimos muito por voc\u00EA cancelar sua inscri\u00E7\u00E3o. Mas se voc\u00EA deseja realizar esta a\u00E7\u00E3o, clique em "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar Inscri\u00E7\u00E3o."])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "modal-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Manter Inscri\u00E7\u00E3o "])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "modal-button delete"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDeletion(_co.inscription) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancelar Inscri\u00E7\u00E3o "])), (_l()(), i1.ɵeld(22, 0, null, null, 10, "ngx-smart-modal", [["class", "modal"], ["identifier", "modalFinish"]], null, [[null, "onOpen"], [null, "onAnyCloseEventFinished"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).targetPlacement() !== false);
        ad = (pd_0 && ad);
    } if (("onOpen" === en)) {
        var pd_1 = (_co.changeTitle("Inscri\u00E7\u00E3o Cancelada") !== false);
        ad = (pd_1 && ad);
    } if (("onAnyCloseEventFinished" === en)) {
        var pd_2 = (_co.removeFromTitle("Inscri\u00E7\u00E3o Cancelada") !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NgxSmartModalComponent_0, i3.RenderType_NgxSmartModalComponent)), i1.ɵdid(23, 4440064, [["modalFinish", 4]], 0, i4.NgxSmartModalComponent, [i1.Renderer2, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i2.DOCUMENT, i1.PLATFORM_ID], { closable: [0, "closable"], identifier: [1, "identifier"] }, { onAnyCloseEventFinished: "onAnyCloseEventFinished", onOpen: "onOpen" }), (_l()(), i1.ɵeld(24, 0, null, 0, 1, "button", [["aria-label", "Close"], ["class", "modal-button-close nsm-dialog-btn-close"], ["title", "Fechar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, 0, 3, "div", [["class", "modal-header-confirm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inscri\u00E7\u00E3o cancelada com sucesso!"])), (_l()(), i1.ɵeld(30, 0, null, 0, 2, "div", [["class", "modal-content confirm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "modal-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Ok, obrigado. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentList; _ck(_v, 3, 0, currVal_0); var currVal_1 = false; var currVal_2 = "modalCancel"; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = false; var currVal_4 = "modalFinish"; _ck(_v, 23, 0, currVal_3, currVal_4); }, null); }
export function View_CardInscriptionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-inscriptions", [], null, null, null, View_CardInscriptionsComponent_0, RenderType_CardInscriptionsComponent)), i1.ɵdid(1, 638976, null, 0, i5.CardInscriptionsComponent, [i4.NgxSmartModalService, i6.TitleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardInscriptionsComponentNgFactory = i1.ɵccf("app-card-inscriptions", i5.CardInscriptionsComponent, View_CardInscriptionsComponent_Host_0, { contents: "content", route: "route" }, { cancelInscription: "cancel-inscription" }, []);
export { CardInscriptionsComponentNgFactory as CardInscriptionsComponentNgFactory };
