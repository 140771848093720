<div [ngClass]="{ 'video-full': isFull,'video': isFixed ,'video-fixed': isFixed && fixable, 'full-width': fullWidth  }">
  <youtube-player
    [videoId]="videoId"
    (ready)="savePlayer($event)"
    (change)="onStateChange($event)"
    [playerVars]="params"
  ></youtube-player>

  <!-- <div *ngIf="!isYoutube" [innerHTML]="iframe"></div> -->
  <div class="btn-mobile" [ngClass]="{ 'DB': isFixed }">
    <div
      *ngIf="fixable"
      class="video-mask"
      [ngClass]="{ 'video-mask-fixed': isFixed, 'video': isFixed ,'video-fixed': isFixed}"
      (click)="onFixVideo($event)"
      title="Fixar vídeo"
    >
      {{ isFixed ? "Desfixar
      Video do topo" : "Fixar Video No Topo"
      }}
      <i class="fa fa-external-link {{ isFixed  ? 'fa-times-circle' : 'fa-thumb-tack' }}"></i>
    </div>
  </div>
</div>
<div class="btn-desktop"  [ngClass]="{ 'DN': isFixed }">
  <div
    *ngIf="fixable"
    class="video-mask"
    [ngClass]="{ 'video-mask-fixed': isFixed, 'video': isFixed ,'video-fixed': isFixed}"
    (click)="onFixVideo($event)"
    title="Fixar vídeo"
  >
    {{ isFixed ? "Desfixar
    Video do topo" : "Fixar Video No Topo"
    }}    
    <i class="fa fa-external-link {{ isFixed  ? 'fa-times-circle' : 'fa-thumb-tack' }}"></i>
  </div>
</div>
