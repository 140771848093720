<div class="form-control" [ngSwitch]="type" [formGroup]="parentForm">
  <div *ngSwitchCase="'input'">
    <label class="form-label" [innerHtml]="label"></label>
    <input
      type="text"
      class="form-input"
      name="model"
      [formControlName]="name"
      autocomplete="off"
      [required]="required"
      #field
      [ngClass]="{ 'is-invalid': field.invalid }"
      [mask]="inputMask"
      [pattern]="inputPattern"
      [placeholder]="placeholder ? placeholder : ''"
      (keyup)="checkForErrors()"
      [maxLength]="maxLength || textInputMaxLength"
    />
   <p class="form-textarea-hint" *ngIf="limitcaracteres">
      Você digitou:
      <span class="hint-chars green">{{ field.value.length }}</span>
      caracteres. Limite:
      <span class="hint-chars">{{ maxLength || textInputMaxLength }}</span> caracteres.
    </p>
  </div>
  
  <div *ngSwitchCase="'email'">
    <label class="form-label" [innerHtml]="label"></label>
    <input
      type="email"
      class="form-input"
      name="model"
      [formControlName]="name"
      autocomplete="off"
      [required]="required"
      #field
      [ngClass]="{ 'is-invalid': field.invalid }"
      [pattern]="inputPattern"
      [placeholder]="placeholder ? placeholder : ''"
      (keyup)="checkForErrors()"
      [maxLength]="maxLength || ''"
      [minLength]="minLength || ''"
    />
  </div>

  <div *ngSwitchCase="'date'">
    <label class="form-label" [innerHtml]="label"></label>
    <input
      type="date"
      class="form-input"
      name="model"
      [formControlName]="name"
      autocomplete="off"
      [required]="required"
      #field
      [ngClass]="{ 'is-invalid': field.invalid }"
      [mask]="inputMask"
      [pattern]="inputPattern"
      [placeholder]="placeholder ? placeholder : ''"
      (keyup)="checkForErrors()"
    />
  </div>

  <div *ngSwitchCase="'password'">
    <label class="form-label" [innerHtml]="label"></label>
    <div class="form-password">
      <input
        [type]="show ? 'text' : 'password'"
        class="form-input"
        name="model"
        [formControlName]="name"
        autocomplete="off"
        [required]="required"
        #field
        [ngClass]="{ 'is-invalid': field.invalid }"
        [mask]="inputMask"
        [pattern]="inputPattern"
        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
        [maxLength]="maxLength || ''"
        [minLength]="minLength || ''"
      />
      <i class="fa fa-eye-slash" (click)="toggleVisible()" title="Exibe texto"></i>
    </div>
  </div>

  <div
    *ngSwitchCase="'select'"
    class="form-select"
    [ngClass]="{ active: active }"
  >
    <label class="form-label" [innerHtml]="label"></label>
    <div class="select-mask-wrapper">
      <select
        #select
        [ngClass]="{ 'option-disabled': select.value === 'null' }"
        [formControlName]="name"
        (select)="setActive()"
      >
        <option
          [value]="null"
          selected
          disabled
          [innerText]="'Selecione uma opção'"
        ></option>
        <option
          *ngFor="let option of options"
          [value]="option.value"
          [innerHtml]="option.text"
        ></option>
      </select>

      <!-- <div class="select-mask" (click)="setActive()"></div> -->
    </div>
  </div>

  <div *ngSwitchCase="'textarea'">
    <label class="form-label" [innerHtml]="label"></label>
    <textarea
      [placeholder]="placeholder ? placeholder : ''"
      class="form-textarea"
      [formControlName]="name"
      (ngModelChange)="updateChanges()"
      [maxLength]="maxLength || textAreaMaxLength"
      #textarea
    ></textarea>
    <p class="form-textarea-hint">
      Você digitou:
      <span class="hint-chars green">{{ textarea.value.length }}</span>
      caracteres. Limite:
      <span class="hint-chars">{{ maxLength || textAreaMaxLength }}</span> caracteres.
    </p>
  </div>

  <div *ngSwitchCase="'video'">
    <label class="form-label" [innerHtml]="label"></label>
    <div class="form-upload">
      <span
        [innerText]="fileName ? fileName : 'Envie seu arquivo por aqui'"
        [ngClass]="{ uploaded: fileName?.length > 0 }"
      ></span>
      <label class="upload-button">
        Escolher Arquivo
        <input
          type="file"
          [name]="name"
          [formControlName]="name"
          [required]="required"
          #upload
          [ngClass]="{ 'is-invalid': upload.invalid }"
          (change)="uploadVideo($event)"
        />
      </label>
    </div>
  </div>

  <div *ngSwitchCase="'image'">
    <label class="form-label" [innerHtml]="label"></label>
    <div class="form-upload">
      <span
        [innerText]="fileName ? fileName : 'Envie seu arquivo por aqui'"
        [ngClass]="{ uploaded: fileName?.length > 0 }"
      ></span>
      <label class="upload-button">
        Escolher Arquivo
        <input
          type="file"
          [name]="name"
          [formControlName]="name"
          [required]="required"
          #upload
          [ngClass]="{ 'is-invalid': upload.invalid }"
          (change)="uploadImage($event)"
        />
      </label>
    </div>
  </div>

  <div *ngSwitchCase="'checkbox'">
    <input
      type="checkbox"
      class="form-checkbox"
      [name]="name"
      [value]="S"
      [formControlName]="name"
      [required]="required"
      #field
      [ngClass]="{ 'is-invalid': field.invalid }"
      (keyup)="checkForErrors()"
    />
    <label class="form-label">
        <ng-content></ng-content>
      <span *ngIf="required" class="required">*</span>
    </label>
  </div>

  <div *ngSwitchCase="'checkbox-inscriptions'">
      <input
        type="checkbox"
        class="form-checkbox"
        [name]="name"
        [value]="S"
        [formControlName]="name"
        [required]="required"
        #field
        [ngClass]="{ 'is-invalid': field.invalid }"
        (keyup)="checkForErrors()"
        [id]=id
      />
      <label [for]="id" class="form-label" [innerHtml]="label">
        <span *ngIf="required" class="required">*</span>
      </label>
    </div>


  <div *ngSwitchCase="'label'">
    <label class="form-label" [innerHtml]="label"></label>
  </div>
</div>
