/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-policy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i5 from "../../shared/breadcrumb/breadcrumb.component";
import * as i6 from "@angular/common";
import * as i7 from "../../services/canonical.service";
import * as i8 from "./privacy-policy.component";
import * as i9 from "../../services/title.service";
import * as i10 from "../../services/metatags.service";
import * as i11 from "./policy.service";
var styles_PrivacyPolicyComponent = [i0.styles];
var RenderType_PrivacyPolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyPolicyComponent, data: {} });
export { RenderType_PrivacyPolicyComponent as RenderType_PrivacyPolicyComponent };
function View_PrivacyPolicyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "privacy-policy"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _v.context.ngIf.results[0].opcional1)); _ck(_v, 0, 0, currVal_0); }); }
export function View_PrivacyPolicyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "privacy-policy-breadcrumb-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i4.View_BreadcrumbComponent_0, i4.RenderType_BreadcrumbComponent)), i1.ɵdid(3, 114688, null, 0, i5.BreadcrumbComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PrivacyPolicyComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.textPolicy$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PrivacyPolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-privacy-policy", [], null, null, null, View_PrivacyPolicyComponent_0, RenderType_PrivacyPolicyComponent)), i1.ɵprd(512, null, i7.CanonicalService, i7.CanonicalService, [i6.DOCUMENT]), i1.ɵdid(2, 245760, null, 0, i8.PrivacyPolicyComponent, [i9.TitleService, i3.Meta, i10.MetatagsService, i11.PolicyService, i7.CanonicalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PrivacyPolicyComponentNgFactory = i1.ɵccf("app-privacy-policy", i8.PrivacyPolicyComponent, View_PrivacyPolicyComponent_Host_0, { updateTitile: "update-title" }, {}, []);
export { PrivacyPolicyComponentNgFactory as PrivacyPolicyComponentNgFactory };
