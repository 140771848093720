import { SITE_LINK } from 'src/app/app.settings';
import * as i0 from "@angular/core";
export class InstitutionalService {
    getLocations() {
        return {
            locations: [
                {
                    estado: 'ACRE',
                    dados: [
                        {
                            emissora: 'TV JURUÁ',
                            municipio: 'CRUZEIRO DO SUL',
                            url: 'http://www.juruaonline.net/'
                        },
                        {
                            emissora: 'TV RIO BRANCO',
                            municipio: 'RIO BRANCO',
                            url: 'http://www.oriobranco.net/'
                        }
                    ]
                },
                {
                    estado: 'ALAGOAS',
                    dados: [
                        {
                            emissora: 'TV PONTA VERDE',
                            municipio: 'MACEIÓ',
                            url: 'http://www.tvpontaverde.tv.br/'
                        }
                    ]
                },
                {
                    estado: 'AMAZÔNAS',
                    dados: [
                        {
                            emissora: 'TV EM TEMPO',
                            municipio: 'TEFÉ',
                            url: ''
                        },
                        {
                            emissora: 'TV EM TEMPO',
                            municipio: 'COARI',
                            url: ''
                        },
                        {
                            emissora: 'TV EM TEMPO',
                            municipio: 'MANAUS',
                            url: 'http://www.emtempo.com.br'
                        },
                        {
                            emissora: 'TV EM TEMPO',
                            municipio: 'PARINTINS',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'AMAPÁ',
                    dados: [
                        {
                            emissora: 'TV IDEAL',
                            municipio: 'VIGIA',
                            url: ''
                        },
                        {
                            emissora: 'TV AMAZÔNIA',
                            municipio: 'MACAPÁ',
                            url: ''
                        },
                        {
                            emissora: 'TV FERREIRA GOMES',
                            municipio: 'FERREIRA GOMES',
                            url: ''
                        },
                        {
                            emissora: 'TV JARÍ',
                            municipio: 'LARANJAL DO JARI',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'BAHIA',
                    dados: [
                        {
                            emissora: 'TV ARATÚ',
                            municipio: 'SALVADOR',
                            url: 'http://www.aratuonline.com.br/'
                        }
                    ]
                },
                {
                    estado: 'BRASÍLIA',
                    dados: [
                        {
                            emissora: 'SBT BRASÍLIA',
                            municipio: 'BRASÍLIA',
                            url: `${SITE_LINK}/brasilia/home`
                        }
                    ]
                },
                {
                    estado: 'CEARÁ',
                    dados: [
                        {
                            emissora: 'TV JANGADEIRO',
                            municipio: 'FORTALEZA',
                            url: 'http://tribunadoceara.uol.com.br/tv-jangadeiro/'
                        }
                    ]
                },
                {
                    estado: 'ESPÍRITO SANTO',
                    dados: [
                        {
                            emissora: 'TV TRIBUNA',
                            municipio: 'VITÓRIA',
                            url: 'http://www.tribunaonline.com.br/'
                        }
                    ]
                },
                {
                    estado: 'GOIÁS',
                    dados: [
                        {
                            emissora: 'TV SERRA DOURADA',
                            municipio: 'GOIÂNIA',
                            url: 'http://www.tvsd.com.br'
                        }
                    ]
                },
                {
                    estado: 'MARANHÃO',
                    dados: [
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'BALSAS',
                            url: 'http://www.difusorasul.com'
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'IMPERATRIZ',
                            url: 'http://www.ma10.com.br/'
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'PEDREIRAS',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'BARRA DO CORDA',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'AÇAILÂNDIA',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'BACABAL',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'CAXIAS',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'TIMBIRAS',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'CODÓ',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'SANTA INÉS',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'SÃO LUÍS',
                            url: 'http://www.ma10.com.br/'
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'CHAPADINHA',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'PINHEIRO',
                            url: ''
                        },
                        {
                            emissora: 'TV DIFUSORA',
                            municipio: 'TUTÓIA',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'MATO GROSSO',
                    dados: [
                        {
                            emissora: 'TV NORTÃO',
                            municipio: 'ALTA FLORESTA',
                            url: ''
                        },
                        {
                            emissora: 'TV OURO MINAS',
                            municipio: 'MATUPÁ',
                            url: ''
                        },
                        {
                            emissora: 'TV TROPICAL',
                            municipio: 'COLÍDER',
                            url: ''
                        },
                        {
                            emissora: 'TV CIDADE',
                            municipio: 'SINOP',
                            url: 'http://www.sbtmt.com.br/'
                        },
                        {
                            emissora: 'TV CIDADE',
                            municipio: 'SORRISO',
                            url: ''
                        },
                        {
                            emissora: 'TV RIO VERDE',
                            municipio: 'LUCAS DO RIO VERDE',
                            url: ''
                        },
                        {
                            emissora: 'BEM TV',
                            municipio: 'TANGARÁ DA SERRA',
                            url: 'http://www.bemnoticias.com.br/'
                        },
                        {
                            emissora: 'TV MUTUM',
                            municipio: 'NOVA MUTUM',
                            url: ''
                        },
                        {
                            emissora: 'TV CENTRO OESTE',
                            municipio: 'PONTES E LACERDA',
                            url: 'http://www.tvcentrooeste.com.br/'
                        },
                        {
                            emissora: 'TV RONDON',
                            municipio: 'CUIABÁ',
                            url: 'http://www.sbtmt.com.br/'
                        },
                        {
                            emissora: 'TV DESCALVADOS',
                            municipio: 'CÁCERES',
                            url: ''
                        },
                        {
                            emissora: 'TV REAL',
                            municipio: 'CAMPO VERDE',
                            url: ''
                        },
                        {
                            emissora: 'TV NOVA XAVANTINA',
                            municipio: 'NOVA XAVANTINA',
                            url: ''
                        },
                        {
                            emissora: 'TV CENTRO OESTE',
                            municipio: 'BARRA DO GARÇAS',
                            url: ''
                        },
                        {
                            emissora: 'TV RONDON',
                            municipio: 'CUIABÁ',
                            url: ''
                        },
                        {
                            emissora: 'TV RONDON',
                            municipio: 'CUIABÁ',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'MATO GROSSO DO SUL',
                    dados: [
                        {
                            emissora: 'TV CAMPO GRANDE',
                            municipio: 'CAMPO GRANDE',
                            url: 'http://www.sbtms.com.br'
                        }
                    ]
                },
                {
                    estado: 'MINAS GERAIS',
                    dados: [
                        {
                            emissora: 'TV VITORIOSA',
                            municipio: 'ITUIUTABA',
                            url: 'http://www.redevitoriosa.com.br'
                        },
                        {
                            emissora: 'TV VITORIOSA',
                            municipio: 'UBERLÂNDIA',
                            url: 'http://www.redevitoriosa.com.br'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'DIVINÓPOLIS',
                            url: 'http://www.alterosa.com.br/'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'VARGINHA',
                            url: 'http://www.alterosa.com.br/'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'JUIZ DE FORA',
                            url: 'http://www.alterosa.com.br/'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'BELO HORIZONTE',
                            url: 'http://www.alterosa.com.br/'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'MANHUAÇÚ',
                            url: 'http://www.alterosa.com.br/'
                        },
                        {
                            emissora: 'TV ALTEROSA',
                            municipio: 'INTERIOR',
                            url: 'http://www.alterosa.com.br/'
                        }
                    ]
                },
                {
                    estado: 'PARANÁ',
                    dados: [
                        {
                            emissora: 'TV NAIPI',
                            municipio: 'FOZ DO IGUAÇU',
                            url: 'http://www.redemassa.com.br'
                        },
                        {
                            emissora: 'TV TIBAGI',
                            municipio: 'APUCARANA',
                            url: 'http://www.redemassa.com.br'
                        },
                        {
                            emissora: 'TV CIDADE LONDRINA',
                            municipio: 'LONDRINA',
                            url: 'http://www.redemassa.com.br'
                        },
                        {
                            emissora: 'TV GUARÁ',
                            municipio: 'FRANCISCO BELTRÃO',
                            url: 'http://www.redemassa.com.br'
                        },
                        {
                            emissora: 'TV IGUAÇU',
                            municipio: 'CURITIBA',
                            url: 'http://www.redemassa.com.br'
                        }
                    ]
                },
                {
                    estado: 'PARAÍBA',
                    dados: [
                        {
                            emissora: 'TV TAMBAÚ',
                            municipio: 'JOÃO PESSOA',
                            url: 'http://www.portalt5.com.br/'
                        },
                        {
                            emissora: 'TV BORBOREMA',
                            municipio: 'CAMPINA GRANDE',
                            url: 'http://www.tvborborema.tv.br/'
                        }
                    ]
                },
                {
                    estado: 'PARÁ',
                    dados: [
                        {
                            emissora: 'TV SBT PARÁ',
                            municipio: 'BELÉM',
                            url: ''
                        },
                        {
                            emissora: 'TV SUL DO PARÁ',
                            municipio: 'REDENÇÃO',
                            url: ''
                        },
                        {
                            emissora: 'TV XINGÚ',
                            municipio: 'SÃO FELIZ DO XINGÚ',
                            url: ''
                        },
                        {
                            emissora: 'TV TUCUMÃ',
                            municipio: 'TUCUMÃ',
                            url: ''
                        },
                        {
                            emissora: 'TV XINGUARA',
                            municipio: 'Xinguara',
                            url: 'http://www.portal9denoticia.com.br/'
                        },
                        {
                            emissora: 'TV CORREIO',
                            municipio: 'MARABÁ',
                            url: ''
                        },
                        {
                            emissora: 'TV RONDON',
                            municipio: 'RONDON DO PARÁ',
                            url: ''
                        },
                        {
                            emissora: 'TV FLORESTA',
                            municipio: 'TUCURUÍ',
                            url: 'http://www.sistemafloresta.com.br/'
                        },
                        {
                            emissora: 'TV VALE DO XINGÚ',
                            municipio: 'ALTAMIRA',
                            url: 'http://www.valedoxingu.com.br/'
                        },
                        {
                            emissora: 'TV OURO VERDE',
                            municipio: 'PARAGOMINAS',
                            url: ''
                        },
                        {
                            emissora: 'RTP SÃO MIGUEL',
                            municipio: 'SÃO MIGUEL',
                            url: ''
                        },
                        {
                            emissora: 'TV ABAETETUBA',
                            municipio: 'ABAETETUBA',
                            url: ''
                        },
                        {
                            emissora: 'RTP CAPANEMA',
                            municipio: 'CAPANEMA',
                            url: ''
                        },
                        {
                            emissora: 'RTP BRAGANÇA',
                            municipio: 'BRAGANÇA',
                            url: ''
                        },
                        {
                            emissora: 'RTP SALINÓPOLIS',
                            municipio: 'SALINÓPOLIS',
                            url: ''
                        },
                        {
                            emissora: 'RTP CASTANHAL',
                            municipio: 'CASTANHAL',
                            url: ''
                        },
                        {
                            emissora: 'TV PONTA NEGRA',
                            municipio: 'MOJUÍ DOS CAMPOS',
                            url: ''
                        },
                        {
                            emissora: 'TV TAPAJOARA',
                            municipio: 'ITAITUBA',
                            url: 'http://www.portaltvtapajoara.com.br/'
                        },
                        {
                            emissora: 'RTP BRAGANÇA',
                            municipio: 'BRAGANÇA',
                            url: ''
                        },
                        {
                            emissora: 'TV PONTA NEGRA',
                            municipio: 'SANTARÉM',
                            url: ''
                        },
                        {
                            emissora: 'TV MONTES CLAROS',
                            municipio: 'ALENQUER',
                            url: ''
                        },
                        {
                            emissora: 'TV PONTA NEGRA',
                            municipio: 'JURUTÍ',
                            url: ''
                        },
                        {
                            emissora: 'TV PONTA NEGRA',
                            municipio: 'ÓBIDOS',
                            url: ''
                        },
                        {
                            emissora: 'TV PONTA NEGRA',
                            municipio: 'ORIXIMINÁ',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'PERNAMBUCO',
                    dados: [
                        {
                            emissora: 'TV JORNAL',
                            municipio: 'CARUARÚ',
                            url: 'http://www.tvjornal.com.br'
                        },
                        {
                            emissora: 'TV JORNAL',
                            municipio: 'RECIFE',
                            url: 'http://www.tvjornal.com.br'
                        }
                    ]
                },
                {
                    estado: 'PIAUÍ',
                    dados: [
                        {
                            emissora: 'TV CIDADE VERDE',
                            municipio: 'TERESINA',
                            url: 'http://www.cidadeverde.com'
                        }
                    ]
                },
                {
                    estado: 'RIO DE JANEIRO',
                    dados: [
                        {
                            emissora: 'SBT RIO',
                            municipio: 'RIO DE JANEIRO',
                            url: ''
                        },
                        {
                            emissora: 'SBT INTERIOR RJ',
                            municipio: 'NOVA FRIBURGO',
                            url: `${SITE_LINK}/riodejaneiro/home`
                        }
                    ]
                },
                {
                    estado: 'RIO GRANDE DO NORTE',
                    dados: [
                        {
                            emissora: 'PONTA NEGRA',
                            municipio: 'MOSSORÓ',
                            url: 'http://www.tvpontanegra.com.br/'
                        },
                        {
                            emissora: 'PONTA NEGRA',
                            municipio: 'NATAL',
                            url: 'http://www.tvpontanegra.com.br/'
                        }
                    ]
                },
                {
                    estado: 'RIO GRANDE DO SUL',
                    dados: [
                        {
                            emissora: 'SBT RS',
                            municipio: 'PORTO ALEGRE',
                            url: `${SITE_LINK}/riograndedosul/home`
                        }
                    ]
                },
                {
                    estado: 'RONDÔNIA',
                    dados: [
                        {
                            emissora: 'TV ALLAMANDA',
                            municipio: 'PORTO VELHO',
                            url: `${SITE_LINK}/afiliada/tvallamanda`
                        },
                        {
                            emissora: 'TV ALLAMANDA',
                            municipio: 'ARIQUEMES',
                            url: `${SITE_LINK}/afiliada/tvallamanda`
                        },
                        {
                            emissora: 'TV ALLAMANDA',
                            municipio: 'JÍ-PARANÁ',
                            url: `${SITE_LINK}/afiliada/tvallamanda`
                        },
                        {
                            emissora: 'TV ALLAMANDA',
                            municipio: 'CACOAL',
                            url: `${SITE_LINK}/afiliada/tvallamanda`
                        },
                        {
                            emissora: 'TV ALLAMANDA',
                            municipio: 'VILHENA',
                            url: `${SITE_LINK}/afiliada/tvallamanda`
                        }
                    ]
                },
                {
                    estado: 'RORAIMA',
                    dados: [
                        {
                            emissora: 'TV TROPICAL',
                            municipio: 'BOA VISTA',
                            url: ''
                        }
                    ]
                },
                {
                    estado: 'SANTA CATARINA',
                    dados: [
                        {
                            emissora: 'SBT SC',
                            municipio: 'LAGES',
                            url: 'http://www.sbtsc.com.br/sbthd/'
                        }
                    ]
                },
                {
                    estado: 'SÃO PAULO',
                    dados: [
                        {
                            emissora: 'TV SBT SÃO PAULO',
                            municipio: 'SÃO PAULO',
                            url: `${SITE_LINK}`
                        },
                        {
                            emissora: 'SBT SÃO JOSÉ DOS CAMPOS',
                            municipio: 'SÃO JOSÉ DOS CAMPOS',
                            url: ''
                        },
                        {
                            emissora: 'SBT CENTRAL',
                            municipio: 'JAÚ',
                            url: ''
                        },
                        {
                            emissora: 'SBT RP',
                            municipio: 'RIBEIRÃO PRETO',
                            url: ''
                        },
                        {
                            emissora: 'SBT INTERIOR',
                            municipio: 'ARAÇATUBA',
                            url: 'http://www.sbtinterior.com.br'
                        },
                        {
                            emissora: 'TV SOROCABA',
                            municipio: 'SOROCABA',
                            url: 'http://www.tvsorocaba.com.br'
                        },
                        {
                            emissora: 'REDE VTV',
                            municipio: 'SANTOS',
                            url: 'http://www.vtv.com.br'
                        }
                    ]
                },
                {
                    estado: 'TOCANTINS',
                    dados: [
                        {
                            emissora: 'TV GURUPI',
                            municipio: 'GURUPI',
                            url: ''
                        },
                        {
                            emissora: 'TV PORTO',
                            municipio: 'PORTO NACIONAL',
                            url: ''
                        },
                        {
                            emissora: 'TV SERRA DO CARMO',
                            municipio: 'PALMAS',
                            url: ''
                        },
                        {
                            emissora: 'TV ARAGUAÍNA',
                            municipio: 'ARAGUAÍNA',
                            url: ''
                        }
                    ]
                }
            ]
        };
    }
}
InstitutionalService.ɵprov = i0.ɵɵdefineInjectable({ factory: function InstitutionalService_Factory() { return new InstitutionalService(); }, token: InstitutionalService, providedIn: "root" });
