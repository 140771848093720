import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotFoundService {
    constructor(_http) {
        this._http = _http;
    }
    getHighlightedList() {
        return this._http
            .get(`${URL}/api/highlighted?type=entretenimento&limit=4`)
            .toPromise()
            .then((response) => {
            const notices = response.results;
            return [
                {
                    medias: notices
                }
            ];
        });
    }
}
NotFoundService.ɵprov = i0.ɵɵdefineInjectable({ factory: function NotFoundService_Factory() { return new NotFoundService(i0.ɵɵinject(i1.HttpClient)); }, token: NotFoundService, providedIn: "root" });
