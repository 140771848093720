/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./validate.component";
import * as i3 from "../../services/title.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/metatags.service";
var styles_ValidatedComponent = [i0.styles];
var RenderType_ValidatedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidatedComponent, data: {} });
export { RenderType_ValidatedComponent as RenderType_ValidatedComponent };
export function View_ValidatedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "validate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Usu\u00E1rio validado com sucesso!"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Voc\u1EBD ser\u00E1 redirecionado para a home em 5 segundos... "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
export function View_ValidatedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validate", [], null, null, null, View_ValidatedComponent_0, RenderType_ValidatedComponent)), i1.ɵdid(1, 114688, null, 0, i2.ValidatedComponent, [i3.TitleService, i4.Meta, i5.MetatagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidatedComponentNgFactory = i1.ɵccf("app-validate", i2.ValidatedComponent, View_ValidatedComponent_Host_0, { updateTitile: "update-title" }, {}, []);
export { ValidatedComponentNgFactory as ValidatedComponentNgFactory };
