<section *ngIf="content" class="cards">
  <a
    class="card"
    [ngClass]="[size]"
    [queryParams]="parameters"
    [fragment]="fragment"
    [routerLink]="'/' + link"
  >
    <div class="card-image"
    (click)="goToLink($event, content.opcional5, getRouterLink(content), parameters, fragment)"
    (mouseenter)="handleExternalLink($event)"
    >
      <img
        onerror="this.src='../../../assets/images/default-image.png'"
        [src]="content.thumbnail || content.image"
        alt=""
        loading="lazy"
      />
      <span
        *ngIf="hasTag(content) && showBadge"
        class="card-image-badge"
        [ngClass]="[color || '']"
        [ngStyle]="{ background: '#' + color || '' }"
        [innerText]="content.taghighlight || content.program || content.opcional"
      ></span>
    </div>
    <div class="card-body">
      <div class="card-title" [innerText]="content.title"></div>
      <div
        *ngIf="content.description"
        class="card-subtitle"
        [innerText]="content.description"
      ></div>
      <div
        class="card-publish-date"
        [innerText]="
          (content.enddate | date: 'dd/MM/yyyy \'às\' HH:mm') ||
          (content.createdAt | date: 'dd/MM/yyyy \'às\' HH:mm')
        "
      ></div>
    </div>
  </a>
</section>
