<div class="content">
  <h2 class="content-title" [innerText]="title"></h2>

  <div class="content-list">

    <div class="content-previous" (click)="prev()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="content-wrapper" #tabItens>
      <div *ngFor="let content of contents; let i = index;" class="content-item">
        <p (click)="setContent($event, i, content)" [ngClass]="{'active': contentIndex === i}" [innerText]="content.title"></p>
      </div>
    </div>

    <div class="content-next" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </div>

  <ngx-masonry class="current-content">
    <div ngxMasonryItem class="content-card" *ngFor="let card of currentContent.cards; let i = index;" [ngClass]="{'active': contentIndex === i}">
      <div class="card-header">
        <span class="card-header-icon"></span>
        <h6 class="card-header-title" [innerText]="card.cardTitle"></h6>
      </div>
      <div class="card-description">
        <p class="card-description-text" [innerText]="card.cardDescription"></p>
        <div *ngIf="currentContent.cards[i].values" class="description-values-wrapper">
          <div class="values" [ngClass]="{'fullWidth': ! card.observation}">
            <div *ngFor="let value of currentContent.cards[i].values">
              <p [innerText]="value.valueTitle" class="value-title"></p>
              <p [innerText]="value.valueDescription" class="value-description"></p>
            </div>
          </div>

          <div *ngIf="card.observation" class="card-observations">
            <p class="observation" [innerText]="card.observation"></p>
          </div>
        </div>
        <div *ngIf="card.banks" class="description-banks">
          <div *ngFor="let bank of card.banks" class="banks-box">
            <p [innerText]="bank.title" class="bank-title"></p>
            <p [innerText]="bank.description" class="bank-description"></p>
          </div>
          <p class="bank-option">ou</p>
        </div>
      </div>
    </div>
  </ngx-masonry>
