/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./item.component";
var styles_ItemComponent = [i0.styles];
var RenderType_ItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemComponent, data: {} });
export { RenderType_ItemComponent as RenderType_ItemComponent };
function View_ItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-image"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "card-image-big": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["loading", "lazy"], ["onerror", "this.src='/assets/images/default-image.png'"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "card-image"; var currVal_1 = _ck(_v, 2, 0, _co.imageBig); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.content == null) ? null : _co.content.thumbnail) || ((_co.content == null) ? null : _co.content.image)); var currVal_3 = _co.content.title; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_ItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "card"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "card-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "card-publish-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(6, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.content == null) ? null : _co.content.thumbnail) || ((_co.content == null) ? null : _co.content.image)); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.author; var currVal_1 = ((_co.content.opcional5 === "B") ? "_blank" : "_self"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = ((_co.content == null) ? null : _co.content.title); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), (((_co.content == null) ? null : _co.content.publishdatestring) || ((_co.content == null) ? null : _co.content.enddate)), "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 5, 0, currVal_4); }); }
function View_ItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink(_co.getRouterLink(_co.content), _co.parameters[0], _co.fragment[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "card-image-big": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["loading", "lazy"], ["onerror", "this.src='/assets/images/default-image.png'"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "card-image"; var currVal_1 = _ck(_v, 2, 0, _co.imageBig); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.content == null) ? null : _co.content.thumbnail) || ((_co.content == null) ? null : _co.content.image)); var currVal_3 = _co.content.title; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_ItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "card-body"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink(_co.getRouterLink(_co.content), _co.parameters[0], _co.fragment[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "card-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "card-publish-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(7, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.parameters[0]; var currVal_3 = _co.fragment[0]; var currVal_4 = _co.getRouterLink(_co.content); _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = (((_co.content == null) ? null : _co.content.thumbnail) || ((_co.content == null) ? null : _co.content.image)); _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = ((_co.content == null) ? null : _co.content.title); _ck(_v, 5, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), (((_co.content == null) ? null : _co.content.publishdatestring) || ((_co.content == null) ? null : _co.content.enddate)), "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 6, 0, currVal_7); }); }
export function View_ItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.content.author.indexOf("http") === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.content.author.indexOf("http") === (0 - 1)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-item", [], null, null, null, View_ItemComponent_0, RenderType_ItemComponent)), i1.ɵdid(1, 114688, null, 0, i4.ItemComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemComponentNgFactory = i1.ɵccf("app-lego-item", i4.ItemComponent, View_ItemComponent_Host_0, { content: "content", imageBig: "imageBig" }, {}, []);
export { ItemComponentNgFactory as ItemComponentNgFactory };
