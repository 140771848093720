/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sbt-videos-program.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/playlist/playlist.component.ngfactory";
import * as i4 from "../../shared/playlist/playlist.component";
import * as i5 from "@angular/router";
import * as i6 from "../program/program.service";
import * as i7 from "../../services/analytics.service";
import * as i8 from "../../services/station.service";
import * as i9 from "../../services/comscore.service";
import * as i10 from "./sbt-videos-program.service";
import * as i11 from "@angular/common/http";
import * as i12 from "./sbt-videos-program.component";
import * as i13 from "../../services/title.service";
import * as i14 from "@angular/platform-browser";
var styles_SbtVideosProgramComponent = [i0.styles];
var RenderType_SbtVideosProgramComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SbtVideosProgramComponent, data: {} });
export { RenderType_SbtVideosProgramComponent as RenderType_SbtVideosProgramComponent };
function View_SbtVideosProgramComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "program-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assistindo v\u00EDdeos de:"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h1", [], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.program == null) ? null : _co.program.title); _ck(_v, 4, 0, currVal_0); }); }
function View_SbtVideosProgramComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["sectionAssistindo", 1]], null, 6, "section", [["class", "sbt-videos-program"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "section", [["class", "program-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SbtVideosProgramComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "section", [["class", "videos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-playlist", [], null, [[null, "load-more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load-more" === en)) {
        var pd_0 = (_co.loadMore($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PlaylistComponent_0, i3.RenderType_PlaylistComponent)), i1.ɵdid(6, 114688, null, 0, i4.PlaylistComponent, [i5.Router, i2.Location, i6.ProgramService, i7.AnalyticsService, i8.StationService, i9.ComscoreService], { videos: [0, "videos"], pagination: [1, "pagination"], currentVideo: [2, "currentVideo"], route: [3, "route"], programTitle: [4, "programTitle"], programId: [5, "programId"], adunit: [6, "adunit"] }, { loadMore: "load-more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.program == null) ? null : _co.program.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.videos; var currVal_2 = _co.pagination; var currVal_3 = _co.currentVideo; var currVal_4 = _co.buildRoute(); var currVal_5 = ((_co.program == null) ? null : _co.program.title); var currVal_6 = ((_co.program == null) ? null : _co.program.id); var currVal_7 = ((_co.program == null) ? null : _co.program.adunitname); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_SbtVideosProgramComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { sectionAssistindo: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SbtVideosProgramComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.videos && (_co.videos.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SbtVideosProgramComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-sbt-videos-program", [], null, null, null, View_SbtVideosProgramComponent_0, RenderType_SbtVideosProgramComponent)), i1.ɵprd(512, null, i10.SbtVideosProgramService, i10.SbtVideosProgramService, [i11.HttpClient]), i1.ɵdid(2, 245760, null, 0, i12.SbtVideosProgramComponent, [i10.SbtVideosProgramService, i5.ActivatedRoute, i13.TitleService, i8.StationService, i5.Router, i14.Meta], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SbtVideosProgramComponentNgFactory = i1.ɵccf("app-sbt-videos-program", i12.SbtVideosProgramComponent, View_SbtVideosProgramComponent_Host_0, {}, {}, []);
export { SbtVideosProgramComponentNgFactory as SbtVideosProgramComponentNgFactory };
