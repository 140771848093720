/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pwa-integra.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ad/ad.component.ngfactory";
import * as i3 from "../../shared/ad/ad.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/station.service";
import * as i6 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i7 from "../../shared/breadcrumb/breadcrumb.component";
import * as i8 from "../../services/canonical.service";
import * as i9 from "@angular/common";
import * as i10 from "./pwa-integra.component";
import * as i11 from "../../services/title.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../services/metatags.service";
import * as i14 from "../../services/analytics.service";
var styles_PwaIntegraComponent = [i0.styles];
var RenderType_PwaIntegraComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PwaIntegraComponent, data: {} });
export { RenderType_PwaIntegraComponent as RenderType_PwaIntegraComponent };
export function View_PwaIntegraComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "pwa"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "content-ad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-ad", [["path", "Pwa/top"], ["sizesDesktop", "[970,250],[728,90]"], ["sizesMobile", "[320,100],[320, 50]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(3, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(4, { bloco: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pwa-breadcrumb-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i6.View_BreadcrumbComponent_0, i6.RenderType_BreadcrumbComponent)), i1.ɵdid(7, 114688, null, 0, i7.BreadcrumbComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "pwa-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "pwa-content-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" J\u00E1 conhece o nosso APP? "])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "pwa-content-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "pwa-content-description-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Instale agora, fique por dentro de todas as novidades e assista aos v\u00EDdeos de seus programas favoritos. "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "pwa-content-description-icon"], ["title", "Instala\u00E7\u00E3o do App"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-download fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "pwa-content-description-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.instalePwa() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Instale o APP"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "app-ad", [["path", "Pwa/bottom"], ["sizesDesktop", "[970,90]"], ["sizesMobile", " [320,50], [320,100]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(20, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(21, { bloco: 0 })], function (_ck, _v) { var currVal_0 = "Pwa/top"; var currVal_1 = "[970,250],[728,90]"; var currVal_2 = "[320,100],[320, 50]"; var currVal_3 = _ck(_v, 4, 0, "top"); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 7, 0); var currVal_4 = "Pwa/bottom"; var currVal_5 = "[970,90]"; var currVal_6 = " [320,50], [320,100]"; var currVal_7 = _ck(_v, 21, 0, "bottom"); _ck(_v, 20, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_PwaIntegraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-pwa-integra", [], null, [["window", "beforeinstallprompt"], ["window", "appinstalled"]], function (_v, en, $event) { var ad = true; if (("window:beforeinstallprompt" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).beforeInstallPwa($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:appinstalled" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).installPwa($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PwaIntegraComponent_0, RenderType_PwaIntegraComponent)), i1.ɵprd(512, null, i8.CanonicalService, i8.CanonicalService, [i9.DOCUMENT]), i1.ɵdid(2, 245760, null, 0, i10.PwaIntegraComponent, [i11.TitleService, i12.Meta, i13.MetatagsService, i8.CanonicalService, i14.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PwaIntegraComponentNgFactory = i1.ɵccf("app-pwa-integra", i10.PwaIntegraComponent, View_PwaIntegraComponent_Host_0, { updateTitle: "update-title" }, {}, []);
export { PwaIntegraComponentNgFactory as PwaIntegraComponentNgFactory };
