<footer class="footer">
  <div class="footer-logo">
    <a routerLink="/">
      <img
        src="/assets/images/logo-sbt.webp"
        alt="SBT"
        title="Ir para a Página Inicial"
        loading="lazy" 
        width="40" 
        height="40"
      />
    </a>
  </div>

  <div class="footer-phrase">
    <span *ngIf="branding" id="tagComscore"><strong>{{ branding }}</strong></span>
    <span><strong>SBT</strong> Copyright © {{ year }}</span
    ><span><strong [innerText]="phone"></strong></span>
  </div>
  <div class="footer-top" (click)="backToTop()" title="Subir para o topo da página">    
    <i class="fa fa-arrow-up"></i>
  </div>
</footer>
