<div class="content" [ngSwitch]="currentContent.opcional1">
  <h2 class="content-title" [innerText]="title"></h2>

  <div *ngIf="contents.length > 1" class="content-list">
    <div class="content-previous" (click)="prev()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="content-wrapper" #tabItens>
      <div *ngFor="let content of contents; let i = index">
        <p
          (click)="setContent($event, i, content)"
          [ngClass]="{ active: contentIndex === i }"
          [innerText]="content.title"
        ></p>
      </div>
    </div>

    <div class="content-next" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </div>

  <div class="current-content" *ngSwitchCase="'foto'">
    <div class="current-content-image">
      <img  onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="currentContent.thumbnail" alt="" />
    </div>

    <div class="current-content-body">
      <app-share></app-share>

      <p
        class="current-content-body-text"
        [innerHtml]="currentContent.description | safeHtml"
      ></p>
    </div>
  </div>
  <div class="current-content" *ngSwitchCase="'video'">
    <div class="current-content-body-video">
      <app-share></app-share>
      <p
        class="current-content-body-video text"
        [innerHTML]="currentContent.textVideo | safeHtml"
      >
      </p>

    <p
      class="current-content-body-video iframe"
      [innerHTML]="currentContent.iframeVideo | safeHtml">

    </p>
      <!-- <p
        class="current-content-body-text"
        [innerHtml]="currentContent.description | safeHtml"
      ></p> -->
    </div>
  </div>
  <div class="current-content" *ngSwitchDefault>
    <div class="current-content-image">
      <img  onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="currentContent.thumbnail" alt="" />
    </div>

    <div class="current-content-body">
      <app-share></app-share>

      <p
        class="current-content-body-text"
        [innerHtml]="currentContent.description | safeHtml"
      ></p>
    </div>
  </div>
</div>
