/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error.component";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "section", [["class", "error"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorComponent, [], null, null)], null, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-error", i2.ErrorComponent, View_ErrorComponent_Host_0, { error: "error" }, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
