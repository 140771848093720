var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { URL } from '../../app.settings';
import { StationService } from '../../services/station.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/station.service";
export class ProgrammingGridService {
    constructor(_http, _stationService) {
        this._http = _http;
        this._stationService = _stationService;
    }
    getSchedule(date) {
        return __awaiter(this, void 0, void 0, function* () {
            const hasStationCookie = this._stationService.checkStationCookie();
            let station;
            if (hasStationCookie) {
                const stationIdentifier = yield this._stationService.getStationCookie();
                station = yield this._stationService.getStation(stationIdentifier);
            }
            if (station && station[0]) {
                return this._http
                    .get(`${URL}/api/programscheduleregionais?datagrade=${date}${station && station[0] ? `&idregional=${station[0].id}` : ''}&limit=49`)
                    .toPromise()
                    .then((response) => {
                    const schedules = response.results;
                    return schedules;
                });
            }
            return this._http
                .get(`${URL}/api/programgrade?datagrade=${date}&limit=49`)
                .toPromise()
                .then((response) => {
                const schedules = response.results;
                return schedules;
            });
        });
    }
}
ProgrammingGridService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProgrammingGridService_Factory() { return new ProgrammingGridService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StationService)); }, token: ProgrammingGridService, providedIn: "root" });
