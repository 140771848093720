import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SitemapService {
    constructor(_http) {
        this._http = _http;
    }
    getSites() {
        return this._http
            .get(`${URL}/api/medias?idsitearea=1111&idsite=207`)
            .toPromise()
            .then((response) => {
            return {
                sites: response.results
            };
        });
    }
    getOtherLinks() {
        const menuHeader = this._http
            .get(`${URL}/api/menu?idsite=123&orderby=ordem&sort=asc&limit=15`)
            .toPromise();
        const menuFooter = this._http
            .get(`${URL}/api/menu?idsite=123456&orderby=ordem&sort=asc&limit=15`)
            .toPromise();
        const menuFooterItems = this._http
            .get(`${URL}/api/menu?idsite=1234567&orderby=ordem&sort=asc&limit=21`)
            .toPromise();
        return Promise.all([
            menuHeader,
            menuFooter,
            menuFooterItems
        ]).then((response) => {
            const headers = response[0].results;
            const areas = response[1].results;
            const footerItems = response[2].results;
            areas.forEach(footer => {
                footer.subItems = footerItems.filter(footerItem => footerItem.descriptionsite === `/${footer.title.toLowerCase()}/`);
            });
            areas.unshift({ title: 'Páginas', subItems: headers });
            return areas;
        });
    }
}
SitemapService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SitemapService_Factory() { return new SitemapService(i0.ɵɵinject(i1.HttpClient)); }, token: SitemapService, providedIn: "root" });
