/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quick-access-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../services/dom.service";
import * as i4 from "../../services/scroll.service";
import * as i5 from "@nicky-lenaers/ngx-scroll-to";
import * as i6 from "./quick-access-menu.component";
import * as i7 from "../../services/station.service";
var styles_QuickAccessMenuComponent = [i0.styles];
var RenderType_QuickAccessMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickAccessMenuComponent, data: {} });
export { RenderType_QuickAccessMenuComponent as RenderType_QuickAccessMenuComponent };
function View_QuickAccessMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "ball"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "text"]], [[8, "innerText", 0], [1, "data-analytics", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.parent.context.$implicit.id === _co.activeSection)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formatId(_v.parent.context.$implicit.title); var currVal_2 = ("menu-acesso-rapido-" + _v.parent.context.$implicit.title.toString().toLowerCase()); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_QuickAccessMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollTo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickAccessMenuComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.showElement; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_QuickAccessMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { menu: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["menu", 1]], null, 14, "div", [["class", "quick-access-menu"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { open: 0, visible: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "quick-access-menu-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openQuickAccessMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "quick-access-menu-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acesso R\u00E1pido"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-v"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "quick-access-menu-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "quick-access-menu-body-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acesso r\u00E1pido"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "quick-access-menu-body-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickAccessMenuComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "quick-access-menu"; var currVal_1 = _ck(_v, 3, 0, _co.open, _co.visible); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.elements; _ck(_v, 15, 0, currVal_2); }, null); }
export function View_QuickAccessMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-quick-access-menu", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QuickAccessMenuComponent_0, RenderType_QuickAccessMenuComponent)), i1.ɵprd(4608, null, i3.DomService, i3.DomService, []), i1.ɵprd(512, null, i4.ScrollService, i4.ScrollService, [i5.ScrollToService]), i1.ɵdid(3, 770048, null, 0, i6.QuickAccessMenuComponent, [i4.ScrollService, i1.Renderer2, i7.StationService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var QuickAccessMenuComponentNgFactory = i1.ɵccf("app-quick-access-menu", i6.QuickAccessMenuComponent, View_QuickAccessMenuComponent_Host_0, { components: "components", fragment: "fragment", changeUrlState: "change-url-state", order: "order", offsetScroll: "offset-scroll", isAtHome: "is-at-home" }, { updateUrlState: "updateUrlState" }, []);
export { QuickAccessMenuComponentNgFactory as QuickAccessMenuComponentNgFactory };
