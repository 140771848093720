import { URL_GEOLOCATION } from '../app.settings';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { StationService } from './station.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./station.service";
export class GeolocationService {
    constructor(_http, handler, router, stationService) {
        this._http = _http;
        this.handler = handler;
        this.router = router;
        this.stationService = stationService;
        this.httpClientWithoutInterceptor = new HttpClient(handler);
    }
    localizationInitial() {
        this.getPosition(position => {
            this.chooseStation(position.coords).then(response => {
                if (response.regional) {
                    const identifier = this.stationService.formatStationIdentifier(response.regional);
                    const currentStation = this.stationService.getStation(identifier)[0];
                    if (!this.stationService.checkStationCookie() &&
                        identifier !== 'nacional') {
                        if (currentStation && currentStation.affiliate) {
                            this.router.navigate([`/afiliada/${identifier}`]);
                            this.stationService.setStationCookie(identifier);
                            return;
                        }
                        this.router.navigate([`/${identifier}/home`]);
                        this.stationService.setStationCookie(identifier);
                    }
                }
            });
        }, error => {
            const errorDescription = this.catchError(error);
        });
    }
    catchError(error) {
        let descriptionError = 'Ops, ';
        switch (error.code) {
            case error.PERMISSION_DENIED:
                descriptionError += 'usuário não autorizou a Geolocation.';
                break;
            case error.POSITION_UNAVAILABLE:
                descriptionError += 'localização indisponível.';
                break;
            case error.TIMEOUT:
                descriptionError += 'tempo expirado.';
                break;
            case error.UNKNOWN_ERROR:
                descriptionError += 'não sei o que foi, mas deu erro!';
                break;
        }
        return descriptionError;
    }
    getPosition(successCallback, errorCallback) {
        if (navigator.geolocation) {
            return navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        }
        return null;
    }
    chooseStation(coords) {
        return this.httpClientWithoutInterceptor
            .get(`${URL_GEOLOCATION}?lat=${coords.latitude}&lng=${coords.longitude}`)
            .toPromise()
            .then((response) => {
            response.regional = (response.regional && response.regional == 'SBT SC') ? 'Nacional' : response.regional;
            if (localStorage) {
                localStorage.setItem('emissora', response.regional);
            }
            return response;
        })
            .catch(error => {
            console.log(new Error(error));
        });
    }
}
GeolocationService.ɵprov = i0.ɵɵdefineInjectable({ factory: function GeolocationService_Factory() { return new GeolocationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.StationService)); }, token: GeolocationService, providedIn: "root" });
