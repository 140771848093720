import { URL, URL_TOKEN_MEDIASTREAM } from '../../app.settings';
import { Video } from '../../models/video.class';
import { StationService } from '../../services/station.service';
import { HttpClient } from '@angular/common/http';
import { formatDateToDefaultFormat } from '../../utils/util.dates';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/station.service";
export class LiveService {
    constructor(_http, stationService) {
        this._http = _http;
        this.stationService = stationService;
    }
    getLiveVideoUrl() {
        const stationIdentifier = this.stationService.getStationCookie() || 'default';
        const station = this.stationService.getStation(stationIdentifier);
        if (station && station[0]) {
            const { live } = station[0];
            let queryString;
            if (live) {
                queryString = `idsite=${live.idsite}&idsitearea=${live.idsitearea}${live.idplaylist ? `&idplaylist=${live.idplaylist}` : ``}`;
            }
            else {
                const station = this.stationService.getStation('default');
                const { live } = station[0];
                queryString = `idsite=${live.idsite}&idsitearea=${live.idsitearea}${live.idplaylist ? `&idplaylist=${live.idplaylist}` : ``}`;
            }
            return this._http
                .get(`${URL}/api/medias?limit=1&${queryString}`)
                .toPromise()
                .then((response) => {
                const medias = response.results;
                return medias;
            });
        }
    }
    getVideos(program) {
        if (!program.exibe || program.exibe !== 'N') {
            return this._http
                .get(`${URL}/api/videosyoutube?program=${program.id}&limit=12`)
                .toPromise()
                .then((response) => {
                const videos = response;
                if (videos.results.length === 0) {
                    return this.getVideosIfHaveNotProgram();
                }
                return {
                    videos: videos.results.map(video => new Video(video)),
                    title: `Últimos Vídeos de: ${program.title}`
                };
            });
        }
        else {
            return this.getVideosIfHaveNotProgram();
        }
    }
    getProgrammingGrade() {
        const date = new Date();
        const currentDate = formatDateToDefaultFormat(new Date());
        let currentProgram;
        let nextProgram;
        if (this.stationService.checkStationCookie()) {
            const identifier = this.stationService.getStationCookie();
            const station = this.stationService.getStation(identifier);
            if (station && station[0]) {
                currentProgram = this._http
                    .get(`${URL}/api/programscheduleregionais?livegrade=${currentDate}&idregional=${station[0].id}&operation=le&orderby=startdate&limit=1`)
                    .toPromise();
                nextProgram = this._http
                    .get(`${URL}/api/programscheduleregionais?livegrade=${currentDate}&idregional=${station[0].id}&operation=ge&limit=1`)
                    .toPromise();
            }
        }
        else {
            currentProgram = this._http
                .get(`${URL}/api/programschedule?limit=1&livegrade=${currentDate}&operation=le&orderby=startdate&limit=1`)
                .toPromise();
            nextProgram = this._http
                .get(`${URL}/api/programschedule?livegrade=${currentDate}&operation=ge&limit=1`)
                .toPromise();
        }
        return Promise.all([currentProgram, nextProgram]).then((response) => {
            const currentProgram = response[0].results;
            const nextProgram = response[1].results;
            let result = [];
            if (currentProgram[0]) {
                result[0] = currentProgram[0];
            }
            if (nextProgram[0]) {
                result[1] = nextProgram[0];
            }
            return result;
        });
    }
    getVideosIfHaveNotProgram() {
        if (this.stationService.checkStationCookie()) {
            const identifier = this.stationService.getStationCookie();
            const station = this.stationService.getStation(identifier);
            if (station && station[0]) {
                return this._http
                    .get(`${URL}/api/videosyoutube?limit=3&highlighted=s&regional=${station[0].id}`)
                    .toPromise()
                    .then((response) => {
                    const videosRegionais = response && response.results ? response.results.map(video => new Video(video)) : [];
                    return this._http
                        .get(`${URL}/api/videosyoutube?limit=8&highlighted=s&videoshomenacional=true`)
                        .toPromise()
                        .then((response) => {
                        const videosNacionais = response && response.results ? response.results.map(video => new Video(video)) : [];
                        return {
                            videos: [...videosRegionais, ...videosNacionais],
                            title: 'Últimos Vídeos'
                        };
                    });
                });
            }
            else {
                return this._http
                    .get(`${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`)
                    .toPromise()
                    .then((response) => {
                    const videos = response;
                    if (videos.results.length === 0)
                        return this;
                    return {
                        videos: videos.results.map(video => new Video(video)),
                        title: 'Últimos Vídeos'
                    };
                });
            }
        }
        else {
            return this._http
                .get(`${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`)
                .toPromise()
                .then((response) => {
                const videos = response;
                if (videos.results.length === 0)
                    return this;
                return {
                    videos: videos.results.map(video => new Video(video)),
                    title: 'Últimos Vídeos'
                };
            });
        }
    }
    getMediaAoVivo(idMedia) {
        return this._http
            .get(`${URL_TOKEN_MEDIASTREAM}${idMedia}`)
            .toPromise()
            .then((response) => {
            return response;
        });
    }
}
LiveService.ɵprov = i0.ɵɵdefineInjectable({ factory: function LiveService_Factory() { return new LiveService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StationService)); }, token: LiveService, providedIn: "root" });
