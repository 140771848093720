import { URL, URL_INSCRIPTIONS } from '../../app.settings';
import { Inscription } from '../../models/inscription.class';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class InscriptionsService {
    constructor(_http) {
        this._http = _http;
    }
    createUserInscription(data) {
        return this._http
            .post(`${URL_INSCRIPTIONS}/api/inscriptions`, data)
            .toPromise()
            .then((response) => {
            const createdResponse = response;
            if (!createdResponse.created) {
                throw new Error(createdResponse.error);
            }
            return response;
        })
            .catch(error => {
            throw new Error(error);
        });
    }
    getInscriptions() {
        return this._http
            .get(`${URL}/api/medias?idplaylist=6170`)
            .toPromise()
            .then((response) => {
            const inscriptions = response;
            return inscriptions.results.map(inscription => new Inscription(inscription, null));
        })
            .catch(e => console.log(e));
    }
    getHighlightedInscriptions() {
        return this._http
            .get(`${URL}/api/medias?idplaylist=6170&limit=12&searchopcional3=S&orderby=ordem&sort=desc`)
            .toPromise()
            .then((response) => {
            const inscriptions = response;
            return inscriptions.results.map(inscription => new Inscription(inscription, inscriptions.next));
        });
    }
    getInscriptionsById(inscriptionId) {
        return this._http
            .get(`${URL}/api/medias?idplaylist=6170&id=${inscriptionId}`)
            .toPromise()
            .then((response) => {
            const inscriptions = response;
            return inscriptions.results.map(inscription => new Inscription(inscription, null));
        })
            .catch(e => console.log(e));
    }
    cancelInscription(inscriptionId, userEmail) {
        return this._http
            .delete(`${URL_INSCRIPTIONS}/api/inscriptions/${parseInt(inscriptionId, 10)}/${userEmail}`)
            .toPromise()
            .then((response) => response)
            .catch(err => console.log(err));
    }
}
InscriptionsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function InscriptionsService_Factory() { return new InscriptionsService(i0.ɵɵinject(i1.HttpClient)); }, token: InscriptionsService, providedIn: "root" });
