<div class="pwa">
    <div class="content-ad">
        <app-ad
        path="Pwa/top"
        sizesDesktop="[970,250],[728,90]"
        sizesMobile="[320,100],[320, 50]"
        [metadata]="{ bloco: 'top' }"
    ></app-ad>
    </div>
    <div class="pwa-breadcrumb-container">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="pwa-content">
        <div class="pwa-content-title">
            Já conhece o nosso APP?
        </div>
        <div class="pwa-content-description">
            <span class="pwa-content-description-text">
                Instale agora, fique por dentro de todas as novidades e assista aos vídeos de seus programas favoritos.
            </span>
            <div class="pwa-content-description-icon" title="Instalação do App">
                <i class="icon-download fa fa-download"></i>
            </div>
            <div class="pwa-content-description-button" (click)="instalePwa()">
                <button>Instale o APP</button>
            </div>
        </div>
        <app-ad
        path="Pwa/bottom"
        sizesDesktop="[970,90]"
        sizesMobile=" [320,50], [320,100]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </div>
</div>
