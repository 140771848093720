import { API_GATEWAY_TOKEN } from '../app.settings';
import * as i0 from "@angular/core";
/** Pass untouched request through to the next request handler. */
export class HttpInterceptorService {
    intercept(req, next) {
        const authReq = req.clone({
            setHeaders: {
                Authorization: `Bearer ${API_GATEWAY_TOKEN}`,
                'Access-Control-Allow-Origin': '*'
            }
        });
        return next.handle(authReq);
    }
}
HttpInterceptorService.ɵprov = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(); }, token: HttpInterceptorService, providedIn: "root" });
