<div class="chapters">
  <h2 *ngIf="title" class="chapters-title" [innerText]="title" #season></h2>

  <app-tab
    *ngIf="program.chaptersEditions"
    [content-list]="program.chaptersEditions"
    (receive-data)="changeChapters($event)"
    [current-index]="0"
    [sort]="false"
  ></app-tab>

  <div class="chapters-list" #episodesList>
    <div *ngFor="let episode of episodes; let i = index" class="chapter">
      <div
        class="chapter-header"
        (click)="openContent(i)"
        [ngClass]="{ active: currentChapter === i }"
      >
        <h4
          class="chapter-header-title"
          [innerText]="episode.chapter.title"
        ></h4>
        <span class="chapter-header-infos">
          <span
            class="chapter-header-infos-date"
            *ngIf="episode.chapter.publishedAt"
            [innerText]="
              episode.chapter.publishedAt | date: 'dd/MM/yyyy \'às\' HH:mm'
            "
          ></span>
          <span class="chapter-header-arrow" title="Expandir">            
            <i class="fa fa-chevron-down"></i>           
          </span>
        </span>
      </div>

      <div class="chapter-content" [ngClass]="{ active: currentChapter === i }">
        <div class="chapter-wrapper">
          <div class="chapter-videos">
            <h3
              *ngIf="
                program.chapters[currentChapter] && program.chapters[currentChapter].videos
              "
              class="chapter-videos-title"
            >
              Vídeos do Capítulo
            </h3>

            <div class="chapters-wrapper">
              <div
                class="chapters-control left"
                (click)="previousEpisodes()"
                *ngIf="
                  program.chapters[currentChapter] &&
                  program.chapters[currentChapter].length > 4
                "
              >                
                <i class="chevron-left" title="Rolar para a Esuqerda"></i>
              </div>

              <div
                *ngIf="
                  program.chapters[currentChapter] && program.chapters[currentChapter].videos
                "
                class="chapter-videos-list"
                #episodesSlider
              >
                <div *ngIf="episode.videos">
                  <div
                    class="chapter-videos-list-item"
                    *ngFor="let video of episode.videos; let i = index"
                    (click)="changeCurrentVideo(video)"
                  >
                    <img
                      onerror="this.src='../../../assets/images/default-image.png'"
                      [src]="video.thumb"
                      loading="lazy"
                      alt=""
                    />
                    <p><a href="" [innerText]="video.title"></a></p>
                    <span [innerText]="video.publishedAt"></span>
                  </div>
                </div>
              </div>

              <div
                class="chapters-control right"
                (click)="nextEpisodes()"
                *ngIf="
                  program.chapters[currentChapter] &&
                  program.chapters[currentChapter].length >= 0
                "
              >        
                <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
              </div>
            </div>

            <div class="chapter-video" *ngIf="currentVideo !== ''">
              <button class="chapter-video-close" (click)="closeVideo()">
                Fechar o vídeo
              </button>
              <app-video [fixable]="true" [url]="currentVideo"></app-video>
            </div>
          </div>

          <div
            class="chapter-resume"
            [innerHtml]="episode.chapter.description | safeHtml"
          ></div>

          <button class="chapter-read-resume" (click)="readResume($event)">
            Ler o Resumo
          </button>
        </div>
      </div>
    </div>
  </div>

  <button
    *ngIf="episodesToPaginate && episodesToPaginate.length > 0"
    class="chapters-load-more"
    (click)="loadOldChapters()"
  >
    Carregar Anteriores
  </button>
</div>
