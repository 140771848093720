<section class="services">
  <app-ad
    path="Servicos/top"
    sizesDesktop="[970,250],[728,90]"
    sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"
  ></app-ad>

  <app-header url="../../../assets/images/servicos-header.png" [has-blurred-frame]="false"></app-header>

  <div class="services-wrapper">

    <div class="services-list">

      <app-breadcrumb></app-breadcrumb>

      <app-about *ngIf="aboutContent" title="Sobre nossos Serviços" [content]="aboutContent" type="full"></app-about>

      <app-ad
        path="Servicos/between"
        sizesDesktop="[728,90]"
        sizesMobile="[320,50], [320,100], [300,250]"
        [metadata]="{ bloco: 'bloco-1' }"
      ></app-ad>

      <app-card *ngIf="services?.length > 0" [services]="services" (open-modal)="receiveData($event)">

      </app-card>


      <div *ngIf="showOpsMessage()" class="without-results">
        <h2>OPS!</h2>
        <span>Infelizmente ainda não temos um serviço cadastrado. =/</span>
      </div>
    </div>

    <div class="services-ads ads-section">
      <app-ad
        path="Servicos/between"
        sizesDesktop="[300,250], [360,640], [300,600], [336,280]"
        sizesMobile="[300,250], [320,50]"
        [metadata]="{ bloco: 'bloco-2' }"
      ></app-ad>
    </div>

  </div>

  <div class="promotion-final-ad">
    <app-ad
      path="Servicos/bottom"
      sizesDesktop="[970,90]"
      sizesMobile="[320,100]"
      [metadata]="{ bloco: 'bottom' }"
    ></app-ad>
  </div>
</section>

<ngx-smart-modal
  #modalService
  identifier="modalService"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Enviar Dúvidas')"
  (onAnyCloseEventFinished)="removeFromTitle('Enviar Dúvidas')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalService.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>

  <h1 class="modal-title">Mande suas dúvidas</h1>
  <div class="modal-wrapper">
    <section>
      <aw-wizard [navBarLayout]="'large-filled-symbols'">
        <aw-wizard-step [stepId]="1" stepTitle="Dados Pessoais" [navigationSymbol]="{ symbol: '1' }" awSelectedStep>
          <h2 class="modal-content-title">Dados Pessoais</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="personalData" #form>
            <app-form-input [type]="'input'" [parent-form]="personalData" name="nome" label="Nome" placeholder="Digite o seu nome"
              [inputPattern]="pureTextPattern" [required]="true"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="personalData" name="sobrenome" label="Sobrenome" placeholder="Digite o seu sobrenome"
              [inputPattern]="pureTextPattern" [required]="true"></app-form-input>

            <div class="modal-form-buttons">
              <button [disabled]="personalData.invalid" type="button" class="button-next-step" awNextStep>
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Dados de Contato" [navigationSymbol]="{ symbol: '2' }">
          <h2 class="modal-content-title">Dados de Contato</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="contactData" #form>
            <app-form-input [type]="'email'" [parent-form]="contactData" name="email" label="Email" [required]="true"
              [inputPattern]="emailPattern" placeholder="Digite o seu e-mail"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="telefone" label="Telefone Celular"
              formControlName="telefone" [required]="true" inputMask="(00) 00000-0000" placeholder="(00) 00000-0000">
            </app-form-input>

            <app-form-input [type]="'select'" [parent-form]="contactData" name="operadora" label="Operadora" [options]="operatorsList"
              [required]="true"></app-form-input>

            <div class="modal-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button [disabled]="contactData.invalid" class="button-next-step" type="button" (click)="renderCaptchaStep()" awNextStep>
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Mensagem" [navigationSymbol]="{ symbol: '3' }">
          <h2 class="modal-content-title">Enviar Mensagem</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="writeMessage" #form>
            <app-form-input [type]="'select'" [parent-form]="writeMessage" name="assunto" label="Motivo da mensagem"
              [options]="reasonsList" [required]="true"></app-form-input>

            <app-form-input [type]="'textarea'" [parent-form]="writeMessage" name="mensagem" label="Mensagem" [required]="true"
              [maxLength]="500" [hint]="true" placeholder="Fale sobre o objetivo do seu contato">
            </app-form-input>

            <div class="captcha">
              <re-captcha name="recaptcha" formControlName="recaptcha" [siteKey]="siteKey"></re-captcha>
            </div>

            <div class="modal-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button [disabled]="writeMessage.invalid" class="button-next-step" type="button" [awGoToStep]="{ stepIndex: 0 }"
                (finalize)="sendFinish()">
                Enviar Mensagem
              </button>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </section>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalFinish
  identifier="modalFinish"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Mensagem Enviada')"
  (onAnyCloseEventFinished)="removeFromTitle('Mensagem Enviada')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalFinish.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Mensagem enviada com sucesso!</h1>
  </div>
  <div class="modal-content">
    <p>
      Obrigado por enviar sua mensagem. Entraremos em contato o mais breve
      possível para sanar suas dúvidas sobre o serviço
      <strong [innerText]="programToQuestion"></strong>, fique atento!
    </p>
    <button class="modal-button" type="button" (click)="modalFinish.close()">
      Ok, obrigado.
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalRegulation
  identifier="modalRegulation"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Regulamento')"
  (onAnyCloseEventFinished)="removeFromTitle('Regulamento')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalRegulation.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-content">
    <p [innerHtml]="programRegulation">
    </p>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalWinners
  identifier="modalWinners"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Ganhadores')"
  (onAnyCloseEventFinished)="removeFromTitle('Ganhadores')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalWinners.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-content">
    <p [innerHtml]="programWinners">
    </p>
  </div>
</ngx-smart-modal>
