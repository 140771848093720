import { URL, BUCKET } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class JournalismService {
    constructor(_http) {
        this._http = _http;
    }
    getColumnistList() {
        return [
            {
                description: '',
                author: 'correspondentes/',
                descriptionplaylist: 'Chamada Lateral Vlogs',
                opcional: '',
                image: `${BUCKET}/media/playlist/20100804112619/20150707102211/20150918163539.jpg`,
                descriptionsite: '/jornalismo/',
                id: 98924,
                title: 'Correspondentes',
                idsitearea: 1441,
                idgendersite: 15,
                idsite: 43
            }
        ];
    }
    getVideos() {
        return this._http
            .get(`${URL}/api/videosyoutube?limit=20&genderid=15&regional=0`)
            .toPromise()
            .then((response) => {
            const videos = response;
            return videos.results.filter(video => video.idprogram !== 529 && video.idprogram !== 675);
        });
    }
    getHighlightedList() {
        return this._http
            .get(`${URL}/api/highlighted?type=jornalismo&limit=7`)
            .toPromise()
            .then((response) => {
            let medias = response.results;
            return [
                {
                    medias
                }
            ];
        });
    }
    getMediaBox() {
        return this._http
            .get(`${URL}/api/medias?idsite=43&idsitearea=1441&idplaylist=10752&limit=10&orderby=ordem&sort=asc`)
            .toPromise()
            .then((response) => response.results);
    }
    getColumnistAbout() {
        return this._http
            .get(`${URL}/api/medias?idgendersite=15&gendersite=Jornalismo&titlesitearea=Sobre&limit=10`)
            .toPromise()
            .then((response) => {
            const columnists = response;
            return columnists.results;
        });
    }
    getColumnistLastNotices(term, pagination = null) {
        return this._http
            .get(`${URL}/api/notices?limit=5&idregional=0&idgender=15&searchkeyword=${term}${pagination && pagination.hasMore ? `&next=${pagination.key}` : ''}&orderby=publishdate&sort=desc`)
            .toPromise()
            .then((response) => {
            const notices = response;
            return {
                notices: notices.results,
                pagination: notices.next
            };
        });
    }
    getColumnistLastNoticesByGender(gender, pagination = null) {
        return this._http
            .get(`${URL}/api/notices?limit=10&idregional=0&idgender=${gender}${pagination && pagination.hasMore ? `&next=${pagination.key}` : ''}&orderby=publishdate&sort=desc`)
            .toPromise()
            .then((response) => {
            const notices = response;
            return {
                notices: notices.results.filter(notice => notice.idprogram !== 529 && notice.idprogram !== 675 && notice.idprogram !== 682),
                pagination: notices.next
            };
        });
    }
    getNoticesByProgram(program, pagination = null) {
        return this._http
            .get(`${URL}/api/notices?limit=5&idregional=0&program=${program}${pagination && pagination.hasMore ? `&next=${pagination.key}` : ''}&orderby=publishdate&sort=desc`)
            .toPromise()
            .then((response) => {
            const notices = response;
            return {
                notices: notices.results,
                pagination: notices.next
            };
        });
    }
    getNoticesByTag(tag = '', pagination = null) {
        return this._http
            .get(`${URL}/api/notices?limit=5&idregional=0&idgender=15&searchkeyword=${tag.toLocaleLowerCase()}${pagination && pagination.hasMore ? `&next=${pagination.key}` : ''}&orderby=publishdate&sort=desc`)
            .toPromise()
            .then((response) => {
            const notices = response;
            return {
                notices: notices.results,
                pagination: notices.next
            };
        });
    }
}
JournalismService.ɵprov = i0.ɵɵdefineInjectable({ factory: function JournalismService_Factory() { return new JournalismService(i0.ɵɵinject(i1.HttpClient)); }, token: JournalismService, providedIn: "root" });
