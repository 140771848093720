<div class="search">
  <div class="search-input">
    <label *ngIf="!placeholder" for="search-field">Faça sua busca aqui</label>
    <div class="search-field">
      <input autofocus id="search-field" type="text" [formControl]="searchField" [ngClass]="{
          found:
            (results?.notices?.length > 0 || results?.programs?.length > 0) &&
            canShowAutoComplete
        }"
        (keyup.enter)="navigateToResults()" [placeholder]="placeholder || ''" />
    </div>

    <div *ngIf="canShowAutoComplete" class="search-input-results">
      <div *ngIf="results?.notices?.length > 0">Notícias</div>
      <div *ngFor="let result of results?.notices">
        <a [routerLink]="'/' + getRouterLink(result)">{{ result.title }}</a>
      </div>
      <div *ngIf="results?.programs?.length > 0">Programas</div>
      <div *ngFor="let result of results?.programs">
        <a [routerLink]="['/' + result?.gender?.toLowerCase() + '/' + result?.slug?.toLowerCase()]">{{ result.title }}</a>
      </div>
    </div>
  </div>
  <div class="search-button">
    <button (click)="navigateToResults()" [disabled]="searchField?.value?.length === 0">
      Buscar
    </button>
  </div>
</div>
