<section class="card-inscriptions">
  <div class="inscriptions-list">
    <div *ngFor="let content of contentList" class="inscription">
      <div class="inscription-image">
        <img  onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="content.thumbnail" alt="" />
      </div>
      <div class="inscription-content">
        <div class="inscription-title" [innerText]="content.title"></div>
        <div
          class="inscription-description"
          [innerHTML]="content.description.slice(0, 250) + '...'"
        ></div>
        <div class="inscription-buttons">
          <button
            class="button button-cancel"
            (click)="cancelInscriptionModal(content)"
          >
            Cancelar Inscrição
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<ngx-smart-modal
  #modalCancel
  identifier="modalCancel"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Cancelar Inscrição')"
  (onAnyCloseEventFinished)="removeFromTitle('Cancelar Inscrição')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalCancel.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header cancel">
    <i class="fa fa-warning"></i>
    <h1>Tem certeza que deseja cancelar sua inscrição?</h1>
  </div>
  <div class="modal-content">
    <p>
      Nós sentimos muito por você cancelar sua inscrição. Mas se você deseja
      realizar esta ação, clique em <a><strong>Cancelar Inscrição.</strong></a>
    </p>
    <!-- <p>
      <strong>Cancelando a sua inscrição você não poderá se inscrever novamente nela.</strong>
    </p> -->
    <button class="modal-button" type="button" (click)="modalCancel.close()">
      Manter Inscrição
    </button>
    <button
      class="modal-button delete"
      type="button"
      (click)="confirmDeletion(inscription)"
    >
      Cancelar Inscrição
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalFinish
  identifier="modalFinish"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Inscrição Cancelada')"
  (onAnyCloseEventFinished)="removeFromTitle('Inscrição Cancelada')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalFinish.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header-confirm">
    <i class="fa fa-check"></i>
    <h1>Inscrição cancelada com sucesso!</h1>
  </div>
  <div class="modal-content confirm">
    <button class="modal-button" type="button" (click)="modalFinish.close()">
      Ok, obrigado.
    </button>
  </div>
</ngx-smart-modal>
