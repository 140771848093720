<section class="videos">
  <section class="videos-title-wrapper">
    <h2 *ngIf="title" class="video-title" [innerText]="title"></h2>

    <!-- <a
      *ngIf="!sbtVideosPage"
      [routerLink]="'/sbt-videos/' + slug"
      class="video-title-link"
      [innerText]="'Ver em Vídeos'"
    ></a> -->
  </section>

  <div class="videos-wrapper" [ngClass]="{ white: isWhite }">
    <app-video-section
      *ngFor="let videoSection of videos"
      [section]="videoSection"
      [program]="getProgram(videoSection)"
      [redirect-to-sbt-videos]="redirectToSbtVideos"
      [has-title]="hasTitle"
      [slug]="slug"
      [is-white]="isWhite"
    ></app-video-section>
  </div>
</section>
