<div class="cards">
  <h1 *ngIf="title" class="cards-title" [innerText]="title"></h1>

  <section *ngIf="termToFilter === '' && showGenders">
    <app-tab
      [content-list]="genders"
      (receive-data)="receiveData($event)"
    ></app-tab>
  </section>

  <div *ngIf="contentList?.length > 0 && termToFilter !== ''"
    class="search-results"
  >
    <p *ngIf="isInscriptions" class="search-results-title">
      A inscrição que você busca se encontra em:
    </p>
  </div>

  <div *ngIf="contentList?.length > 0 && !isInscriptions && isPodcasts" class="cards-list">
    <a
      *ngFor="let content of contentList"
      class="card"
      [ngClass]="[
        'items-per-row-' + itemsPerRow + '',
        'desktop-' + desktopView
      ]"
      [href]="[getRouterLink(content)]"
    >
      <div class="card-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="
            content.header ||
            content.image ||
            content.thumbnail ||
            content.inscriptions[0]?.thumbnail
          "
          alt=""
          loading="lazy"
        />
      </div>
      <div *ngIf="desktopView === 'column'" class="card-title">
        <span [innerText]="content.title"></span>
        <a [routerLink]="getRouterLink(content)" [innerText]="buttonText"></a>
      </div>

      <div *ngIf="desktopView === 'row'" class="card-title">
        <span [innerText]="content.title"></span>
        <div class="card-title-wrapper">
          <span
            *ngIf="content.playlists"
            [innerText]="
              content.playlists > 1
                ? content.playlists + ' playlists'
                : content.playlists + ' playlist'
            "
          ></span>

          <a [routerLink]="getRouterLink(content)" [innerText]="buttonText"></a>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="contentList?.length > 0 && !isInscriptions && !isPodcasts" class="cards-list">
    <a
      *ngFor="let content of contentList"
      class="card"
      [ngClass]="[
        'items-per-row-' + itemsPerRow + '',
        'desktop-' + desktopView
      ]"
      [routerLink]="[getRouterLink(content)]"
    >
      <div class="card-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="
            content.header ||
            content.image ||
            content.thumbnail ||
            content.inscriptions[0]?.thumbnail
          "
          alt=""
          loading="lazy"
        />
      </div>
      <div *ngIf="desktopView === 'column'" class="card-title">
        <span [innerText]="content.title"></span>
        <a [routerLink]="getRouterLink(content)" [innerText]="buttonText"></a>
      </div>

      <div *ngIf="desktopView === 'row'" class="card-title">
        <span [innerText]="content.title"></span>
        <div class="card-title-wrapper">
          <span
            *ngIf="content.playlists"
            [innerText]="
              content.playlists > 1
                ? content.playlists + ' playlists'
                : content.playlists + ' playlist'
            "
          ></span>

          <a [routerLink]="getRouterLink(content)" [innerText]="buttonText"></a>
        </div>
      </div>
    </a>
  </div>

  <div *ngIf="contentList?.length > 0 && isInscriptions && !isPodcasts" class="cards-list">
    <div
      *ngFor="let content of contentList"
      class="card items-per-row-1 desktop-row"
      (click)="openModal(content)"
    >
      <div class="card-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="
            content.header ||
            content.image ||
            content.inscriptions[0]?.thumbnail
          "
          alt=""
          loading="lazy"
        />
      </div>

      <div class="card-title">
        <span [innerText]="content.title"></span>
        <div class="card-title-wrapper">
          <span
            [innerText]="
              content.inscriptions.length > 1
                ? content.inscriptions.length + ' inscrições possíveis'
                : content.inscriptions.length + ' inscrição possível'
            "
          ></span>

          <a (click)="openModal(content)" [innerText]="buttonText"></a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="contentList?.length === 0 && termToFilter !== ''"
    class="search-results"
  >
    <p class="search-results-title">
      Não foram encontrados resultados para
      <strong>"{{ termToFilter }}"</strong>
    </p>

    <ul class="search-results-list">
      <li>Confira se digitou o termo corretamente</li>
      <li>Tente usar termos mais simples</li>
    </ul>
  </div>

  <div *ngIf="contentList?.length === 0 && currentIndex !== -1 && !isInscriptions && !isPodcasts && !searchManual"
    class="search-results"
  >
    <p class="search-results-title">
      Não foram encontrados resultados para a categoria
      <strong>"{{ genders[currentIndex] }}"</strong>
    </p>

    <ul class="search-results-list">
      <li>Confira se digitou o termo corretamente</li>
      <li>Tente usar termos mais simples</li>
    </ul>
  </div>

  <button *ngIf="contentList?.length > 0 && pagination && pagination.hasMore"
    class="cards-load-more"
    (click)="sendInfo()"
    [innerText]="'Ver Mais'"
    data-analytics="ver-mais-programas"
    title="Ver Mais"
  ></button>
</div>

<ngx-smart-modal
  #modalInscription
  identifier="modalInscription"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Lista de Inscrições')"
  (onAnyCloseEventFinished)="removeFromTitle('Lista de Inscrições');removeFragment()"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalInscription.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <h1 class="modal-inscriptions-title">Lista de Inscrições</h1>
  <div class="modal-container">
    <div class="header">
      <div class="header-image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="currentProgram?.header"
          alt=""
          loading="lazy"
        />
      </div>
      <a *ngIf="currentProgram" [routerLink]="'/' + getProgramLink()">
        <button
          class="header-button"
          [innerText]="'Visitar página do programa'"
          (click)="modalInscription.close()"
        ></button>
      </a>
    </div>
    <div class="list">
      <h1 class="list-inscriptions-title">Lista de Inscrições</h1>
      <ul>
        <li
          *ngFor="
            let inscription of currentProgram?.inscriptions;
            let i = index
          "
        >
          <div
            class="list-item"
            [ngClass]="{ opened: i === currentInscriptionInfo }"
          >
            <div class="item-title" (click)="openInfo($event, i)">
              <span [innerText]="inscription.title"></span>              
              <i class="fa fa-chevron-down" title="Expandir"></i>
            </div>
            <div class="item-info">
              <div class="item-image">
                <img
                  onerror="this.src='../../../assets/images/default-image.png'"
                  loading="lazy"
                  [src]="inscription.thumbnail"
                  alt=""
                />
              </div>
              <a [routerLink]="'/' + navigateToForm(currentProgram, inscription)">
                <button
                  class="item-button"
                  [innerText]="'Quero me Inscrever!'"
                  (click)="modalInscription.close()"
                ></button>
              </a>
              <p
                class="item-description"
                [innerHtml]="inscription.description"
              ></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ngx-smart-modal>
