/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ecad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/header/header.component.ngfactory";
import * as i4 from "../../shared/header/header.component";
import * as i5 from "@angular/router";
import * as i6 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i7 from "../../shared/breadcrumb/breadcrumb.component";
import * as i8 from "../../services/canonical.service";
import * as i9 from "./ecad.component";
import * as i10 from "../../services/title.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../services/metatags.service";
import * as i13 from "./ecad.service";
var styles_EcadComponent = [i0.styles];
var RenderType_EcadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EcadComponent, data: {} });
export { RenderType_EcadComponent as RenderType_EcadComponent };
function View_EcadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "monthsPanel-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ["])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Baixar"])), (_l()(), i1.ɵted(-1, null, ["] "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.month; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.file, ""); _ck(_v, 2, 0, currVal_1); }); }
function View_EcadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "yearPanel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "accordion"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentContent(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { activeIndicator: 0 }), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "monthsPanel"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { active: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcadComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "accordion"; var currVal_1 = _ck(_v, 3, 0, (_co.currentContent === _v.context.index)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "monthsPanel"; var currVal_4 = _ck(_v, 7, 0, (_co.currentContent === _v.context.index)); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.data; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.year; _ck(_v, 4, 0, currVal_2); }); }
export function View_EcadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "ecad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [["url", "/assets/images/ecad-header.png"]], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i4.HeaderComponent, [i5.Router], { url: [0, "url"], hasBlurredFrame: [1, "hasBlurredFrame"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ecad-breadcrumb-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i6.View_BreadcrumbComponent_0, i6.RenderType_BreadcrumbComponent)), i1.ɵdid(5, 114688, null, 0, i7.BreadcrumbComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "ecad-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Execu\u00E7\u00E3o P\u00FAblica "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "ecad-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Planilhas de Programa\u00E7\u00E3o Musical reportadas ao Ecad (Escrit\u00F3rio Central de Arrecada\u00E7\u00E3o e Distribui\u00E7\u00E3o). "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "ecad-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcadComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/assets/images/ecad-header.png"; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 5, 0); var currVal_2 = _co.ecadResults; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_EcadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ecad", [], null, null, null, View_EcadComponent_0, RenderType_EcadComponent)), i1.ɵprd(512, null, i8.CanonicalService, i8.CanonicalService, [i2.DOCUMENT]), i1.ɵdid(2, 245760, null, 0, i9.EcadComponent, [i10.TitleService, i11.Meta, i12.MetatagsService, i13.EcadService, i8.CanonicalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EcadComponentNgFactory = i1.ɵccf("app-ecad", i9.EcadComponent, View_EcadComponent_Host_0, { updateTitle: "update-title", opened: "opened" }, {}, []);
export { EcadComponentNgFactory as EcadComponentNgFactory };
