/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-youtube.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-youtube.component";
var styles_ButtonYoutubeComponent = [i0.styles];
var RenderType_ButtonYoutubeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonYoutubeComponent, data: {} });
export { RenderType_ButtonYoutubeComponent as RenderType_ButtonYoutubeComponent };
function View_ButtonYoutubeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "g-ytsubscribe"]], [[1, "data-channelid", 0], [1, "data-layout", 0], [1, "data-count", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.channel; var currVal_1 = _v.context.$implicit.layout; var currVal_2 = _v.context.$implicit.showSubscribers; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ButtonYoutubeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "content-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonYoutubeComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.youtubeButtons; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ButtonYoutubeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonYoutubeComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.youtubeButtons && (_co.youtubeButtons.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ButtonYoutubeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-youtube", [], null, null, null, View_ButtonYoutubeComponent_0, RenderType_ButtonYoutubeComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonYoutubeComponent, [i1.Renderer2, i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonYoutubeComponentNgFactory = i1.ɵccf("app-button-youtube", i3.ButtonYoutubeComponent, View_ButtonYoutubeComponent_Host_0, { youtubeButtons: "youtube-buttons" }, {}, []);
export { ButtonYoutubeComponentNgFactory as ButtonYoutubeComponentNgFactory };
