/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bookmarks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./bookmarks.component";
import * as i5 from "../../services/station.service";
import * as i6 from "../../services/user.service";
var styles_BookmarksComponent = [i0.styles];
var RenderType_BookmarksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookmarksComponent, data: {} });
export { RenderType_BookmarksComponent as RenderType_BookmarksComponent };
function View_BookmarksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeIndex(_v.context.index, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (_co.currentIndex === _v.context.index)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_BookmarksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bookmarks-genders"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "bookmarks-genders-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookmarksComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.genders; _ck(_v, 3, 0, currVal_0); }, null); }
function View_BookmarksComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-star"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bookmarkProgram(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BookmarksComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeBookmark() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BookmarksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "bookmark"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { bookmarked: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "bookmark-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "bookmark-sparks"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/sparks.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "bookmark-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "bookmark-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Veja o site"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookmarksComponent_4)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookmarksComponent_5)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bookmark"; var currVal_1 = _ck(_v, 2, 0, _co.isBookmarked(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = ("/" + _co.getRouterLink(_v.context.$implicit)); _ck(_v, 11, 0, currVal_6); var currVal_7 = !_co.isBookmarked(_v.context.$implicit); _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.isBookmarked(_v.context.$implicit); _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.header; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 11).target; var currVal_5 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_BookmarksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "bookmarks"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookmarksComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "bookmarks-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookmarksComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showGenders; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.contentList; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BookmarksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bookmarks", [], null, null, null, View_BookmarksComponent_0, RenderType_BookmarksComponent)), i1.ɵdid(1, 114688, null, 0, i4.BookmarksComponent, [i5.StationService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookmarksComponentNgFactory = i1.ɵccf("app-bookmarks", i4.BookmarksComponent, View_BookmarksComponent_Host_0, { contents: "content", title: "title", showGenders: "show-genders", returnGenders: "return-genders" }, {}, []);
export { BookmarksComponentNgFactory as BookmarksComponentNgFactory };
