import { URL_USERS_API, URL_INSCRIPTIONS } from '../app.settings';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UpdateBotService } from '../thirdparty/inbot.service';
import { NaveggClass } from '../thirdparty/navegg.service';
import { encrypt, decrypt, encryptFull } from '../utils/util.encrypt';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(_http) {
        this._http = _http;
        this.userLogin = new BehaviorSubject(null);
        this.userLogin$ = this.userLogin.asObservable();
    }
    getHeaderUserAuth() {
        const token = JSON.parse(localStorage.getItem('token'));
        return {
            headers: new HttpHeaders().set('UserAuthorization', `Bearer ${token}`)
        };
    }
    getUserLogin() {
        return this.userLogin$;
    }
    getUser() {
        if (localStorage.getItem('user')) {
            const user = decrypt(localStorage.getItem('user') || '');
            this.setUserObservable(user);
            return user;
        }
        this.resetUserObservable();
        return null;
    }
    isLoggedIn() {
        const isLoggedIn = decrypt(localStorage.getItem('user') || '');
        return isLoggedIn;
    }
    errorHandler({ error }) {
        const errorText = error.error;
        throw new Error(errorText);
    }
    delete() {
        const user = decrypt(localStorage.getItem('user') || '');
        return this._http
            .delete(`${URL_USERS_API}/api/user/${user.email}`, this.getHeaderUserAuth())
            .toPromise()
            .then(response => {
            this.logout();
            return response;
        })
            .catch(this.errorHandler);
    }
    forgotPassword(user) {
        return this._http
            .get(`${URL_USERS_API}/api/user/password/reset/${user.email}`)
            .toPromise()
            .then(response => {
            return response;
        })
            .catch(this.errorHandler);
    }
    resetPassword(token, user) {
        if (!token) {
            throw new Error('O token informado é invalido');
        }
        return this._http
            .post(`${URL_USERS_API}/api/user/password/reset/${token}`, user)
            .toPromise()
            .then(response => {
            return response;
        })
            .catch(this.errorHandler);
    }
    create(user) {
        return this._http
            .post(`${URL_USERS_API}/api/user`, user)
            .toPromise()
            .then(response => {
            return response;
        })
            .catch(this.errorHandler);
    }
    update(userData) {
        if (localStorage.getItem('user')) {
            var decryptedData = decrypt(localStorage.getItem('user') || '');
        }
        userData.email = decryptedData.email;
        return this._http
            .put(`${URL_USERS_API}/api/user/${decryptedData.email}`, userData, this.getHeaderUserAuth())
            .toPromise()
            .then((response) => {
            if (response) {
                localStorage.removeItem('user');
                const updatedUser = response;
                let updateData = encrypt(JSON.stringify(updatedUser));
                localStorage.setItem('user', `${updateData}`);
            }
            return response;
        })
            .catch(this.errorHandler);
    }
    updatePassword(userData) {
        if (localStorage.getItem('user')) {
            var decryptedData = decrypt(localStorage.getItem('user') || '');
        }
        return this._http
            .post(`${URL_USERS_API}/api/user/password/${decryptedData.email}`, userData, this.getHeaderUserAuth())
            .toPromise()
            .then((response) => {
            if (response) {
                localStorage.removeItem('user');
                let updatePassword = encrypt(JSON.stringify(response));
                localStorage.setItem('user', `${updatePassword}`);
            }
            return response;
        })
            .catch(this.errorHandler);
    }
    login(user) {
        console.log('LOGIN');
        return this._http
            .post(`${URL_USERS_API}/api/login`, user)
            .toPromise()
            .then((response) => {
            if (response.token && response.user) {
                let userHash = encrypt(JSON.stringify(response.user));
                localStorage.setItem('user', `${userHash}`);
                localStorage.setItem('token', JSON.stringify(response.token));
                localStorage.setItem('programFav', response.user.favoriteProgram);
                const nameHash = encryptFull(response.user.name, '541278abcd4564654abdAc', '23123908abcde67532acc6');
                const emailHash = encryptFull(response.user.email, '541278abcd4564654abdAc', '23123908abcde67532acc6');
                UpdateBotService({ name: response.user.name, email: response.user.email }, 0);
                const nvg = new NaveggClass();
                nvg.sendAnalyticsData({ name: nameHash, email: emailHash }, ['USER_SIGN_IN'], 0);
            }
            return response.user;
        })
            .catch(err => {
            UpdateBotService({ name: '', email: '' }, 0);
            this.resetUserObservable();
            return this.errorHandler(err);
        });
    }
    setUserObservable(user) {
        this.userLogin.next({
            user: user,
            programFav: user.favoriteProgram,
            userIsLogged: true
        });
    }
    resetUserObservable() {
        this.userLogin.next({
            user: null,
            programFav: null,
            userIsLogged: false
        });
    }
    logout() {
        console.log('LOGOUT');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('programFav');
        UpdateBotService({ name: '', email: '' }, 0);
        this.resetUserObservable();
    }
    getInscriptionsByUser(email) {
        return this._http
            .get(`${URL_INSCRIPTIONS}/api/inscriptions/user/${email}`)
            .toPromise()
            .then((response) => {
            const inscriptions = response.inscriptions[0];
            let user = inscriptions[0] || {};
            user.inscriptions = [];
            inscriptions.forEach(element => {
                user.inscriptions.push({
                    id: element.inscription,
                    enable: element.enable === undefined ? true : element.enable
                });
                return user;
            });
            return inscriptions;
        });
    }
    getUserFromDatabase(email) {
        return this._http
            .get(`${URL_USERS_API}/api/user/${email}`, this.getHeaderUserAuth())
            .toPromise()
            .then((response) => {
            return response;
        })
            .catch(err => {
            return this.errorHandler(err);
        });
    }
}
UserService.ɵprov = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
