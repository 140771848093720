/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./thanks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../video/video.component.ngfactory";
import * as i3 from "../video/video.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./thanks.component";
var styles_ThanksComponent = [i0.styles];
var RenderType_ThanksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThanksComponent, data: {} });
export { RenderType_ThanksComponent as RenderType_ThanksComponent };
function View_ThanksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "thanks-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [["class", "thanks-content-description"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "thanks-content-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-video", [], null, null, null, i2.View_VideoComponent_0, i2.RenderType_VideoComponent)), i1.ɵdid(4, 638976, null, 0, i3.VideoComponent, [i1.ElementRef, i4.DomSanitizer], { url: [0, "url"], fixable: [1, "fixable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.thanks.video; var currVal_2 = false; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thanks.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ThanksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "item-img"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.author; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.thumbnail; _ck(_v, 1, 0, currVal_1); }); }
function View_ThanksComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "item-img"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.thumbnail; _ck(_v, 0, 0, currVal_0); }); }
function View_ThanksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThanksComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThanksComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.author; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.author; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ThanksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "thanks"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "thanks-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Agradecimentos"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThanksComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "thanks-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThanksComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thanks.description; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.thanksList; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ThanksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-thanks", [], null, null, null, View_ThanksComponent_0, RenderType_ThanksComponent)), i1.ɵdid(1, 114688, null, 0, i6.ThanksComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThanksComponentNgFactory = i1.ɵccf("app-thanks", i6.ThanksComponent, View_ThanksComponent_Host_0, { thanks: "thanks" }, {}, []);
export { ThanksComponentNgFactory as ThanksComponentNgFactory };
