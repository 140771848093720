import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime } from 'rxjs/operators';
import { Inscription } from 'src/app/models/inscription.class';
import Program from 'src/app/models/program.class';
import { TitleService } from 'src/app/services/title.service';
import { checkOfAge } from 'src/app/validators/age-of.validator';
import { checkCpf } from 'src/app/validators/cpf.validator';
import { checkInvalidDate } from 'src/app/validators/invalid-date.validator';
import { siteMap } from '../../app.metatags';
import { MetatagsService } from '../../services/metatags.service';
import { StatesService } from '../../services/states.service';
import { UserService } from '../../services/user.service';
import { InscriptionsService } from '../inscriptions/inscriptions.service';
import { ProgramsService } from '../programs/programs.service';
import { MySbtService } from './my-sbt.service';
import { decrypt } from '../../utils/util.encrypt'
@Component({
  selector: 'app-my-sbt',
  templateUrl: './my-sbt.component.html',
  styleUrls: ['./my-sbt.component.scss'],
  providers: [
    ProgramsService,
    InscriptionsService,
    NgxSmartModalService,
    MySbtService,
    StatesService
  ]
})
export class MySbtComponent implements OnInit, OnDestroy {
  public user: any
  public userEmail: string
  public downloadToken: string
  public userInscriptions: any
  public userDataDownloadUrl: any
  public confirmPasswordError: string
  public uploadError: string

  public sectionUrl: string

  public sectionsList: any = [
    {
      title: 'Minhas Inscrições',
      link: 'minhas-inscricoes'
    },
    {
      title: 'Meus Favoritos',
      link: 'meus-favoritos'
    },
    {
      title: 'Meus Dados',
      link: 'meus-dados'
    }
  ]

  public currentItemIndex: number

  public genderOptions: any = [
    {
      value: 'Masculino',
      text: 'Masculino'
    },
    {
      value: 'Feminino',
      text: 'Feminino'
    }
  ]

  public maritalOptions: any = [
    {
      value: 'Solteiro',
      text: 'Solteiro'
    },
    {
      value: 'Casado',
      text: 'Casado'
    },
    {
      value: 'Divorciado',
      text: 'Divorciado'
    },
    {
      value: 'Viuvo',
      text: 'Viúvo'
    }
  ]

  public states: any = this.statesService.getStates()

  public programs: Program[]
  public inscriptions: Inscription[] = []

  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  public rgValidator: string = '^[a-zA-Z0-9]{5,15}$'

  public personalData = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(this.pureTextPattern)]],
    gender: [null, [Validators.required]],
    birthday: [
      '',
      [
        Validators.required,
        checkOfAge(18),
        checkInvalidDate()
      ]
    ],
    rg: ['', [Validators.pattern(this.rgValidator)]],
    cpf: ['', [Validators.required, checkCpf()]],
    picture: ['']
    // maritalStatus: [null, [Validators.required]]
  })

  public contactData = this.fb.group({
    cep: ['', [Validators.required]],
    state: [null, Validators.required],
    city: ['', Validators.required],
    neighborhood: ['', Validators.required],
    address: ['', Validators.required],
    number: ['', Validators.required],
    additional: [''],
    cellphone: [''],
    phone: ['']
  })

  public accountData = this.fb.group({
    // email: ['', [Validators.required]],
    currentPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
  })

  public personalDataError: string
  public contactDataError: string
  public accountDataError: string
  public downloadDataError: string
  public deleteAccountError: string

  public subscriptions = []

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private title: TitleService,
    private programsService: ProgramsService,
    private inscriptionService: InscriptionsService,
    private service: MySbtService,
    private route: ActivatedRoute,
    private meta: Meta,
    private metasService: MetatagsService,
    private userService: UserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private statesService: StatesService
  ) {
    this.metasService.updateTags(siteMap, this.meta)
  }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe()
      }
    })
  }

  async ngOnInit () {
    if (localStorage.getItem('user')) {
      const user = decrypt(localStorage.getItem('user') || '')    
      this.user = user
      await this.userService.getUserFromDatabase(this.user.email)
        .then((response: any) => {
          if (!response || !response.email) {
            this.userService.logout()
            this.router.navigate(['/'])
          }
        })
        .catch((error: Error) => {
          this.userService.logout()
          this.router.navigate(['/'])
        })

      this.userEmail = this.user.email
      this.downloadToken = this.user.downloadtoken

      this.fillUpdateForm()

      this.generateDownloadJsonUri(this.user)
    }

    const activeRouteSubscription = await this.route.params.subscribe(params => {
      if (params['section']) {
        this.sectionUrl = params['section']
        this.setTitle(this.sectionUrl)
        this.currentItemIndex = this.sectionsList
          .map(section => section.link)
          .indexOf(params['section'])
      } else {
        this.currentItemIndex = 0
      }
    })

    this.subscriptions.push(activeRouteSubscription)

    const getCepSubscription = this.contactData
      .get('cep')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(cep => {
        if (cep.length === 8) {
          this.service.getAdressByCEP(cep).then(response => {
            const { uf, city, district, street } = response

            if (uf) {
              this.contactData.get('state').setValue(uf)
            }

            if (city) {
              this.contactData.get('city').setValue(city)
            }

            if (district) {
              this.contactData.get('neighborhood').setValue(district)
            }

            if (street) {
              this.contactData.get('address').setValue(street)
            }
          })
        }
      })

    this.subscriptions.push(getCepSubscription)

    await this.userService
      .getInscriptionsByUser(this.userEmail)
      .then((response: any) => {
        this.userInscriptions = response

        if (this.userInscriptions.length > 0) {
          this.userInscriptions[0].inscriptions.forEach(inscription => {
            if (inscription.enable !== false) {
              this.inscriptionService
                .getInscriptionsById(inscription.id)
                .then(inscriptions => {
                  this.inscriptions.push(...inscriptions)
                })
            }
          })
        }
      })

      const newPasswordFieldSubscription = this.accountData
      .get('newPassword')
      .valueChanges.pipe()
      .subscribe(newPassword => {
        if (newPassword.length < 6) {
          this.accountDataError = 'SENHA PRECISA TER NO MÍNIMO 6 E NO MÁXIMO 20 CARACTERES'

          return
        }

        this.accountDataError = undefined
      })

    this.programsService.getPrograms().then(programs => {
      return (this.programs = programs)
    })

    this.subscriptions = [newPasswordFieldSubscription]
  }

  isBirthDayValid() {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.underAge &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  isDateValid () {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.invalidDate &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  isCpfValid () {
    return (
      this.personalData.get('cpf').value.length === 11 &&
      this.personalData.get('cpf').errors &&
      this.personalData.get('cpf').errors.invalidCpf &&
      (this.personalData.get('cpf').touched ||
        this.personalData.get('cpf').dirty)
    )
  }

  generateDownloadJsonUri (json) {
    let theJSON = JSON.stringify(json)
    let uri = this.sanitizer.bypassSecurityTrustUrl(
      'data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON)
    )
    this.userDataDownloadUrl = uri
  }

  changeSelectedValue (event: any) {
    this.currentItemIndex = event.index
    this.sectionUrl = event.sectionURL
    this.setTitle(this.sectionUrl)
  }

  setTitle (url) {
    switch (url) {
      case 'minhas-inscricoes':
        this.title.setTitle('Meu SBT - Inscrições - SBT')
        break
      case 'meus-favoritos':
        this.title.setTitle('Meu SBT - Favoritos - SBT')
        break
      case 'meus-dados':
        this.title.setTitle('Meu SBT - Dados - SBT')
        break
    }
  }

  deleteAccountModal (): void {
    this.ngxSmartModalService.getModal('modalDelete').open()
  }

  userHasPicture() {
    if ( this.user.picture === undefined ) return false
    if ( this.user.picture === 'none' ) return false
    if ( this.user.picture === '' ) return false
    return true
  }

  confirmDeletion (): void {
    /* */
  }

  cancelInscription (inscription: Inscription): void {
    this.inscriptionService
      .cancelInscription(inscription.id.toString(), this.userEmail)
      .then(response => {
        this.inscriptions = this.inscriptions.filter(inscription => {
          return inscription.id.toString() !== response.inscription
        })
      })
  }

  getFieldsForUpdate (formGroup) {
    const user = {}

    Object.keys(formGroup.controls).forEach((name) => {
      let currentControl = formGroup.controls[name];

      if (currentControl.dirty) {
        if (name !== 'birthday') {
          user[name] = currentControl.value

          return
        }

        const birthday: string = this.personalData
          .get('birthday')
          .value.split('/')
          .join('')

        user['birthdayDay'] = birthday.substring(0, 2),
        user['birthdayMonth'] = birthday.substring(2, 4),
        user['birthdayYear'] = birthday.substring(4, 8)
      }
    });

    return user
  }

  updatePersonalData () {
    const user = {...this.getFieldsForUpdate(this.personalData), picture: this.user.picture}

    this.userService
      .update(user)
      .then(response => {
        this.ngxSmartModalService.getModal('modalUpdateData').open()
        if ( localStorage.getItem('user') ) {
          var user = decrypt(localStorage.getItem('user') || '')
        }
        const loggedUser = user
        this.generateDownloadJsonUri(loggedUser)
      })
      .catch((error: Error) => (this.personalDataError = error.message))
  }

  updateContactData () {
    const user = {
      postalcode: this.contactData.get('cep').value,
      streetLabel: this.contactData.get('address').value,
      streetNumber: this.contactData.get('number').value,
      streetNumber2: this.contactData.get('additional').value,
      district: this.contactData.get('neighborhood').value,
      city: this.contactData.get('city').value,
      state: this.contactData.get('state').value,
      homephone: this.contactData.get('phone').value,
      cellphone: this.contactData.get('cellphone').value
    }

    this.userService
      .update(user)
      .then(response => {
        this.ngxSmartModalService.getModal('modalUpdateData').open()
        if ( localStorage.getItem('user') ) {       
          var user = decrypt(localStorage.getItem('user') || '')
        }
        const loggedUser = user
        this.generateDownloadJsonUri(loggedUser)
      })
      .catch((error: Error) => (this.contactDataError = error.message))
  }

  updateAccountData () {
    const user = {
      newPassword: this.accountData.get('newPassword').value,
      oldPassword: this.accountData.get('currentPassword').value
    }

    this.userService
      .updatePassword(user)
      .then(response => {
        this.accountDataError = null
        this.ngxSmartModalService.getModal('modalUpdateData').open()
        if ( localStorage.getItem('user') ) {
          var user = decrypt(localStorage.getItem('user') || '')
        }
        const loggedUser = user
        this.generateDownloadJsonUri(loggedUser)
      })
      .catch((error: Error) => (this.accountDataError = error.message))
  }

  removeUserPic () {
    this.user.picture = 'none'
  }

  deleteAccount () {
    this.userService
      .delete()
      .then(response => {
        if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalDelete')) {
          this.ngxSmartModalService.getModal('modalDelete').close()

          if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalFinishAccount')) {
            this.ngxSmartModalService.getModal('modalFinishAccount').open()
          }
        }

        setTimeout(() => {
          this.router.navigate(['/'])
        }, 5000)
      })
      .catch((error: Error) => {
        if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalDelete')) {
          this.ngxSmartModalService.getModal('modalDelete').close()
        }
        console.log('Delete Account: ', error.message)
        this.deleteAccountError = 'Ocorreu um erro ao tentar excluir a conta. Atualize a página e tente novamente.'
      })
  }

  fillUpdateForm () {
    const day = this.user.birthdayDay
    const month = this.user.birthdayMonth
    const year = this.user.birthdayYear

    const birthDate = `${day}/${month}/${year}`

    this.personalData.get('name').setValue(this.user.name)
    this.personalData.get('gender').setValue(this.user.gender)
    this.personalData.get('birthday').setValue(birthDate)
    this.personalData.get('cpf').setValue(this.user.cpf)
    this.personalData.get('rg').setValue(this.user.rg)

    this.contactData.get('cep').setValue(this.user.postalcode)
    this.contactData.get('state').setValue(this.user.state)
    this.contactData.get('city').setValue(this.user.city)
    this.contactData.get('neighborhood').setValue(this.user.district)
    this.contactData.get('address').setValue(this.user.streetLabel)
    this.contactData.get('number').setValue(this.user.streetNumber)
    this.contactData.get('additional').setValue(this.user.streetNumber2)
    this.contactData.get('cellphone').setValue(this.user.cellphone)
    this.contactData.get('phone').setValue(this.user.homephone)
  }

  downloadUserData () {
    this.service
      .getJsonUserData(this.downloadToken)
      .then((response: any) => {
        window.open(response)
        return
      })
      .catch((error: Error) => {
        this.downloadDataError = error.message
      })
  }

  receiveData (event: any): void {

    if (event.uploadError) {
      this.uploadError = event.uploadError
      return
    }

    this.uploadError = undefined
    this.user.picture = event.fieldValue
  }

}
