<div class="content-ad">
  <app-ad
    path="Site_Sbt_Pagina_De_Resultados_De_Busca/top"
    sizesDesktop="[970,250,[728,90]"
    sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"
  ></app-ad>
</div>
<section class="results-header">
  <app-search></app-search>
</section>

<section class="results-wrapper">
  <section class="results-content">
    <h1 class="results-title">Resultados da busca</h1>

    <app-tab-search-results
      *ngIf="results"
      [queryString]="queryString"
      [results]="results"
      [pagination]="pagination"
      [searching]="searching"
      (load-more-notices)="loadMoreNotices()"
      (load-more-videos)="loadMoreVideos()"
    ></app-tab-search-results>
  </section>

  <section class="results-aside">
    <section class="results-tags">
      <h1 class="results-title">Tags</h1>
      <app-pill
        *ngIf="results?.tags?.length > 0"
        [values]="results?.tags"
      ></app-pill>

      <p
        *ngIf="results?.tags?.length == 0 && !searching"
        class="results-not-found"
      >
        Não encontramos nenhuma <strong>tag</strong> para o(s) termo(s)
        <strong>"{{ queryString }}"</strong>
      </p>
    </section>

    <section class="results-programs">
      <h1 class="results-title">Programas</h1>

      <app-card-list
        *ngIf="results.programs.length > 0"
        items-per-row="1"
        [show-genders]="false"
        [content]="results.programs"
      ></app-card-list>

      <p
        *ngIf="results?.programs?.length == 0 && !searching"
        class="results-not-found"
      >
        Não encontramos nenhum <strong>programa</strong> para o(s) termo(s)
        <strong>"{{ queryString }}"</strong>
      </p>
    </section>

    <section class="results-highligth">
      <h2 class="highligth-title">Em Alta</h2>

      <app-lego-list
        *ngIf="results.highlightList"
        [has-title]="false"
        [list]="results.highlightList"
        [show-more]="false"
        [full-items]="true"
        size="small"
      ></app-lego-list>
    </section>

    <section class="results-ads" *ngIf="isMobile">
      <app-ad
        path="Site_Sbt_Pagina_De_Resultados_De_Busca/between"
        sizesMobile="[300,250], [320,50]"
        [metadata]="{ bloco: 'bloco-1' }"
      ></app-ad>
    </section>
    <section class="results-ads" *ngIf="!isMobile" (scroll)="onscroll()" [ngClass]="bannerfixed?'fixed':'nofixed'">
      <app-ad
        path="Site_Sbt_Pagina_De_Resultados_De_Busca/between"
        sizesMobile="[300,250], [360,640], [300,600],[336,280],[320,50]"
        [metadata]="{ bloco: 'bloco-1' }"
      ></app-ad>
    </section>
  </section>
</section>
