<div class="characters">
  <h2 class="characters-title" *ngIf="title" [innerText]="title"></h2>

  <div class="characters-wrapper">
    <span class="characters-control left" (click)="previousCharacters()" *ngIf="characters.length > 4">      
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>

    </span>
    <div class="characters-list" #charactersList>
      <div *ngFor="let character of characters" (click)="setCurrentCharacter(character)" class="character">
        <img class="character-image"  onerror="this.src='../../../assets/images/default-image.png'" [src]="character.thumbnail" alt="" loading="lazy" />
        <span class="character-name" [innerHtml]="character.title"></span>
      </div>
    </div>
    <span class="characters-control right" (click)="nextCharacters()" *ngIf="characters.length > 4">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </span>
  </div>
</div>

<ngx-smart-modal
  #modal
  identifier="modal"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle(currentCharacter.title)"
  (onAnyCloseEventFinished)="removeFromTitle(currentCharacter.title)">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modal.close()" title="Fechar">    
    <i class="fa fa-close"></i>
  </button>

  <div *ngIf="currentCharacter" class="modal-wrapper bg-white">
    <section class="characters-section">
      <h5 class="modal-title" [innerText]="currentCharacter.title"></h5>
      <h6 class="modal-subtitle" [innerText]="currentCharacter.realName"></h6>

      <div class="modal-description">
        <img  onerror="this.src='../../../assets/images/default-image.png'" [src]="currentCharacter?.thumbnail" loading="lazy" class="modal-image" alt="" />

        <p class="modal-text" [innerHtml]="currentCharacter?.description"></p>
      </div>
    </section>
  </div>
</ngx-smart-modal>
