<section *ngIf="videos && videos.length > 0 " class="sbt-videos-program" #sectionAssistindo>
  <section class="program-container">
    <section *ngIf="program?.title" class="program-info" >
      <p>Assistindo vídeos de:</p>
      <div>
        <h1 [innerText]="program?.title"></h1>
      </div>
    </section>

    <section class="videos">
      <app-playlist
        [program-title]="program?.title"
        [program-id]="program?.id"
        [program-unit]="program?.adunitname"
        [videos]="videos"
        [current-video]="currentVideo"
        [route]="buildRoute()"
        [pagination]="pagination"
        (load-more)="loadMore($event)"
      ></app-playlist>
    </section>

    <!-- <section *ngIf="playlists?.length > 0 && program" class="playlists">
      <app-playlist-videos
        [title]="[ playlists.length > 1 ? 'Playlists' : 'Playlist' ]"
        [route]="'sbt-videos/' + slugProgram"
        [content]="playlists"
        [programs]="[program]"
        [extra-classes]="['playlist']"
        [current-playlist]="currentPlaylist"
        (emitPlaylist)="getPlaylist($event)"
    ></app-playlist-videos>
    </section> -->
  </section>
</section>
