/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./video-section/video-section.component.ngfactory";
import * as i3 from "./video-section/video-section.component";
import * as i4 from "@angular/router";
import * as i5 from "../../pages/program/program.service";
import * as i6 from "@angular/common";
import * as i7 from "./videos.component";
var styles_VideosComponent = [i0.styles];
var RenderType_VideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideosComponent, data: {} });
export { RenderType_VideosComponent as RenderType_VideosComponent };
function View_VideosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h2", [["class", "video-title"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_VideosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-section", [], null, null, null, i2.View_VideoSectionComponent_0, i2.RenderType_VideoSectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.VideoSectionComponent, [i4.Router, i5.ProgramService], { section: [0, "section"], program: [1, "program"], hasTitle: [2, "hasTitle"], slug: [3, "slug"], isWhite: [4, "isWhite"], redirectToSbtVideos: [5, "redirectToSbtVideos"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.getProgram(_v.context.$implicit); var currVal_2 = _co.hasTitle; var currVal_3 = _co.slug; var currVal_4 = _co.isWhite; var currVal_5 = _co.redirectToSbtVideos; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_VideosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [["class", "videos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["class", "videos-title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "videos-wrapper"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { white: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosComponent_2)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = "videos-wrapper"; var currVal_2 = _ck(_v, 6, 0, _co.isWhite); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.videos; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_VideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-videos", [], null, null, null, View_VideosComponent_0, RenderType_VideosComponent)), i1.ɵdid(1, 114688, null, 0, i7.VideosComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideosComponentNgFactory = i1.ɵccf("app-videos", i7.VideosComponent, View_VideosComponent_Host_0, { videos: "videos", programs: "programs", slug: "slug", redirectToSbtVideos: "redirect-to-sbt-videos", hasTitle: "has-title", isWhite: "is-white", title: "title" }, {}, []);
export { VideosComponentNgFactory as VideosComponentNgFactory };
