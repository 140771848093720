import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SecretCodeService {
    constructor(_http) {
        this._http = _http;
    }
    sendSecretCode(code, params) {
        return this._http
            .get(`${URL}/api/medias?idsite=${params.idSite}&idsitearea=${params.idSiteArea}&idplaylist=${params.idPlaylist}&author=${code.toLowerCase()}`)
            .toPromise()
            .then((response) => response.results)
            .catch(err => console.log(err));
    }
}
SecretCodeService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SecretCodeService_Factory() { return new SecretCodeService(i0.ɵɵinject(i1.HttpClient)); }, token: SecretCodeService, providedIn: "root" });
