/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mediastream.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mediastream.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./mediastream.service";
var styles_MediastreamComponent = [i0.styles];
var RenderType_MediastreamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediastreamComponent, data: {} });
export { RenderType_MediastreamComponent as RenderType_MediastreamComponent };
function View_MediastreamComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "section", [["class", "mediastream-player"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["allow", "autoplay; fullscreen; encrypted-media"], ["always", ""], ["frameborder", "0 allowfullscreen allowscriptaccess="], ["height", "334"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeSrc; _ck(_v, 1, 0, currVal_0); }); }
export function View_MediastreamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediastreamComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!!_co.safeSrc && (_co.safeSrc !== "")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MediastreamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live-mediastream", [], null, null, null, View_MediastreamComponent_0, RenderType_MediastreamComponent)), i1.ɵdid(1, 114688, null, 0, i3.MediastreamComponent, [i4.DomSanitizer, i5.MediastreamService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediastreamComponentNgFactory = i1.ɵccf("app-live-mediastream", i3.MediastreamComponent, View_MediastreamComponent_Host_0, { url: "url" }, {}, []);
export { MediastreamComponentNgFactory as MediastreamComponentNgFactory };
