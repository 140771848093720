/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./characters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-smart-modal/ngx-smart-modal.ngfactory";
import * as i4 from "ngx-smart-modal";
import * as i5 from "./characters.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/title.service";
var styles_CharactersComponent = [i0.styles];
var RenderType_CharactersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CharactersComponent, data: {} });
export { RenderType_CharactersComponent as RenderType_CharactersComponent };
function View_CharactersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h2", [["class", "characters-title"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_CharactersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "characters-control left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousCharacters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"], ["title", "Rolar para a esquerda"]], null, null, null, null, null))], null, null); }
function View_CharactersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "character"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentCharacter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "character-image"], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "character-name"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.thumbnail; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_1); }); }
function View_CharactersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "characters-control right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextCharacters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"], ["title", "Rolar para a direita"]], null, null, null, null, null))], null, null); }
function View_CharactersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-wrapper bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "section", [["class", "characters-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "h5", [["class", "modal-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "h6", [["class", "modal-subtitle"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "modal-image"], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [["class", "modal-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentCharacter.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currentCharacter.realName; _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.currentCharacter == null) ? null : _co.currentCharacter.thumbnail); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.currentCharacter == null) ? null : _co.currentCharacter.description); _ck(_v, 6, 0, currVal_3); }); }
export function View_CharactersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { charactersList: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "characters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CharactersComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "characters-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CharactersComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["charactersList", 1]], null, 2, "div", [["class", "characters-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CharactersComponent_3)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CharactersComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 5, "ngx-smart-modal", [["class", "modal"], ["identifier", "modal"]], null, [[null, "onOpen"], [null, "onAnyCloseEventFinished"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).targetPlacement() !== false);
        ad = (pd_0 && ad);
    } if (("onOpen" === en)) {
        var pd_1 = (_co.changeTitle(_co.currentCharacter.title) !== false);
        ad = (pd_1 && ad);
    } if (("onAnyCloseEventFinished" === en)) {
        var pd_2 = (_co.removeFromTitle(_co.currentCharacter.title) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_NgxSmartModalComponent_0, i3.RenderType_NgxSmartModalComponent)), i1.ɵdid(13, 4440064, [["modal", 4]], 0, i4.NgxSmartModalComponent, [i1.Renderer2, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i2.DOCUMENT, i1.PLATFORM_ID], { closable: [0, "closable"], identifier: [1, "identifier"] }, { onAnyCloseEventFinished: "onAnyCloseEventFinished", onOpen: "onOpen" }), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "button", [["aria-label", "Close"], ["class", "modal-button-close nsm-dialog-btn-close"], ["title", "Fechar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CharactersComponent_5)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.characters.length > 4); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.characters; _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.characters.length > 4); _ck(_v, 11, 0, currVal_3); var currVal_4 = false; var currVal_5 = "modal"; _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_6 = _co.currentCharacter; _ck(_v, 17, 0, currVal_6); }, null); }
export function View_CharactersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-characters", [], null, null, null, View_CharactersComponent_0, RenderType_CharactersComponent)), i1.ɵdid(1, 4374528, null, 0, i5.CharactersComponent, [i4.NgxSmartModalService, i6.ActivatedRoute, i7.TitleService], null, null)], null, null); }
var CharactersComponentNgFactory = i1.ɵccf("app-characters", i5.CharactersComponent, View_CharactersComponent_Host_0, { characters: "characters", title: "title" }, {}, []);
export { CharactersComponentNgFactory as CharactersComponentNgFactory };
