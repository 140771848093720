/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-editions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-editions.component";
var styles_MenuEditionsComponent = [i0.styles];
var RenderType_MenuEditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuEditionsComponent, data: {} });
export { RenderType_MenuEditionsComponent as RenderType_MenuEditionsComponent };
function View_MenuEditionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "menu-editions-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "\u001Ctitle"]], [[8, "innerText", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setEdition($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "\u001Ctitle"; var currVal_2 = _ck(_v, 3, 0, (_v.context.index === _co.editionIndex)); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_MenuEditionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tabItens: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "menu-editions"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, [["editionsList", 1]], null, 7, "div", [["class", "menu-editions-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "content-previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"], ["title", "Rolar para a esquerda"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["tabItens", 1]], null, 2, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuEditionsComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "content-next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"], ["title", "Rolar para a direita"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu-editions"; var currVal_1 = _co.extraClasses; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.editions; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_MenuEditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-editions", [], null, null, null, View_MenuEditionsComponent_0, RenderType_MenuEditionsComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuEditionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuEditionsComponentNgFactory = i1.ɵccf("app-menu-editions", i3.MenuEditionsComponent, View_MenuEditionsComponent_Host_0, { editions: "editions", type: "type", extraClasses: "extra-classes" }, {}, []);
export { MenuEditionsComponentNgFactory as MenuEditionsComponentNgFactory };
