<div class="validate">
  <br />
  <h1>Usuário validado com sucesso!</h1>
  <br />
  <p>
    Vocẽ será redirecionado para a home em 5 segundos...
  </p>
  <br />
  <br />
  <br />
  <br />
  <br />
</div>