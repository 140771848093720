var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { generateSlug } from 'src/app/utils/utils.generators';
import { StationService } from 'src/app/services/station.service';
import { decrypt } from '../../utils/util.encrypt';
export class BookmarksComponent {
    constructor(stationService, userService) {
        this.stationService = stationService;
        this.userService = userService;
        this.showGenders = true;
        this.returnGenders = false;
        this.genders = ['Programas Gerais', 'Programas Jornalísticos'];
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.programFav = yield localStorage.getItem('programFav');
            yield this.contents.sort(program => {
                if (this.programFav === program.id.toString()) {
                    return -1;
                }
                if (this.programFav !== program.id.toString()) {
                    return 1;
                }
                return 0;
            });
            this.contentList = this.contents.filter(content => content.gender !== 'Jornalismo');
            this.currentIndex = this.genders.indexOf('Programas Gerais');
        });
    }
    getRouterLink(content) {
        if (content.gender) {
            const gender = generateSlug(content.gender);
            if (content.regional) {
                const station = this.stationService.getStationById(content.regional);
                if (station.length > 0) {
                    if (station[0].affiliate) {
                        return `afiliada/${station[0].identifier}/${gender}/${content.slug}`;
                    }
                    return `${station[0].identifier}/${gender}/${content.slug}`;
                }
            }
            return `${gender}/${content.slug}`;
        }
    }
    filterPrograms(gender) {
        if (gender === 'Programas Gerais') {
            this.contentList = this.contents.filter(content => content.gender !== 'Jornalismo');
            return;
        }
        this.contentList = this.contents.filter(content => content.gender === 'Jornalismo');
    }
    changeIndex(index, gender) {
        if (this.currentIndex !== index) {
            this.currentIndex = index;
            this.filterPrograms(gender);
        }
    }
    bookmarkProgram(program) {
        this.programFav = program.id.toString();
        if (localStorage.getItem('user')) {
            const decryptedData = decrypt(localStorage.getItem('user') || '');
            const loogedUser = decryptedData;
            if (loogedUser.favoriteProgram === this.programFav) {
                return;
            }
            const user = {
                email: loogedUser.email,
                favoriteProgram: this.programFav
            };
            this.userService.update(user).then(response => {
                localStorage.setItem('programFav', program.id.toString());
            });
        }
    }
    removeBookmark() {
        this.programFav = null;
        if (localStorage.getItem('user')) {
            const loogedUser = decrypt(localStorage.getItem('user') || '');
            const user = {
                email: loogedUser.email,
                favoriteProgram: "null"
            };
            this.userService.update(user).then(() => {
                localStorage.setItem('programFav', null);
            });
        }
    }
    isBookmarked(program) {
        return this.programFav === program.id.toString();
    }
}
