<section class="reset-password">
  <h1 class="title" [innerText]="'Recuperar Senha'"></h1>

  <form class="modal-form" [formGroup]="accountData" #form autocomplete="off">
    <app-form-input [type]="'email'" [parent-form]="accountData" name="email" label="E-mail" placeholder="Digite o seu email"
      [required]="true" class="full-width"></app-form-input>

    <app-form-input [type]="'password'" [parent-form]="accountData" name="password" label="Senha" [required]="true" [maxLength]="20" [minLength]="6" class="full-width"></app-form-input>

    <app-form-input [type]="'password'" [parent-form]="accountData" name="confirmPassword" label="Confirmar Senha" [maxLength]="20" [minLength]="6" [required]="true" class="full-width"></app-form-input>

      <app-error
        *ngIf="confirmPasswordError"
        [error]="confirmPasswordError"
      ></app-error>

      <app-error
        *ngIf="error"
        [error]="error"
      ></app-error>

    <div class="modal-form-buttons">
      <button [disabled]="accountData.invalid || accountData.pending" data-analytics="recuperar-senha" class="button-next-step" type="button"
        (click)="resetPassword()">
        Recuperar Conta
      </button>
    </div>
  </form>
</section>

<ngx-smart-modal 
  #modalResetPassword 
  identifier="modalResetPassword" 
  class="modal" 
  [closable]="false"
  (onOpen)="changeTitle('Senha Alterada')"
  (onAnyCloseEventFinished)="removeFromTitle('Senha Alterada')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalResetPassword.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Senha Alterada!</h1>
  </div>
  <div class="modal-wrapper white">
    <section>
      <ul class="modal-list">
        <li>
          Sua senha foi alterada!
        </li>

        <li style="list-style-type: none;">
          Aproveite que recuperou sua senha e <a (click)="openLoginModal()">Faça Login</a>
        </li>
      </ul>
    </section>
  </div>
</ngx-smart-modal>
