import { URL_TOKEN_MEDIASTREAM } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MediastreamService {
    constructor(_http) {
        this._http = _http;
    }
    get(idMedia) {
        return this._http
            .get(`${URL_TOKEN_MEDIASTREAM}${idMedia}`)
            .toPromise()
            .then((response) => {
            return response;
        });
    }
}
MediastreamService.ɵprov = i0.ɵɵdefineInjectable({ factory: function MediastreamService_Factory() { return new MediastreamService(i0.ɵɵinject(i1.HttpClient)); }, token: MediastreamService, providedIn: "root" });
