/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab.component";
var styles_TabComponent = [i0.styles];
var RenderType_TabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"], ["title", "Rolar para a esquerda"]], null, null, null, null, null))], null, null); }
function View_TabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeIndex(_v.context.index, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (_co.currentIndex === _v.context.index)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.title) || _v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_TabComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"], ["title", "Rolar para a direita"]], null, null, null, null, null))], null, null); }
export function View_TabComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tabContent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "section", [["class", "tab"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "is-overflown": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["tabContent", 1]], null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab"; var currVal_1 = _ck(_v, 3, 0, _co.isOverflown); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isOverflown; _ck(_v, 5, 0, currVal_2); var currVal_3 = "content-wrapper"; var currVal_4 = _co.extraClasses; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.contentList; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.isOverflown; _ck(_v, 11, 0, currVal_6); }, null); }
export function View_TabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i1.ɵdid(1, 8503296, null, 0, i3.TabComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabComponentNgFactory = i1.ɵccf("app-tab", i3.TabComponent, View_TabComponent_Host_0, { contentList: "content-list", extraClasses: "extra-classes", currentIndex: "current-index", sort: "sort" }, { eventEmit: "receive-data" }, []);
export { TabComponentNgFactory as TabComponentNgFactory };
