var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Pagination from '../../models/pagination.class';
import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LegoListService {
    constructor(_http) {
        this._http = _http;
    }
    getMoreResults(endpoint) {
        return __awaiter(this, void 0, void 0, function* () {
            return this._http
                .get(endpoint)
                .toPromise()
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                const result = response;
                const playlists = result.results;
                const pagination = new Pagination(result.next);
                playlists.forEach((playlist) => __awaiter(this, void 0, void 0, function* () {
                    if (pagination.hasMore) {
                        playlist.pagination = pagination;
                        playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${playlist.title}&limit=1&orderby=startdate`;
                    }
                    yield this.getCustomMedia(`idplaylist=${playlist.id}`).then(medias => (playlist.medias = medias));
                }));
                return playlists;
            }));
        });
    }
    getCustomMedia(queryString) {
        return this._http
            .get(`${URL}/api/medias?${queryString}&orderby=ordem&sort=desc`)
            .toPromise()
            .then((response) => {
            const medias = response.results;
            return medias;
        });
    }
}
LegoListService.ɵprov = i0.ɵɵdefineInjectable({ factory: function LegoListService_Factory() { return new LegoListService(i0.ɵɵinject(i1.HttpClient)); }, token: LegoListService, providedIn: "root" });
