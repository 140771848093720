<section class="my-sbt">
  <app-header url="../../../assets/images/meu-sbt-header.png" [has-blurred-frame]="false"></app-header>

  <app-nav *ngIf="sectionsList" [nav-content]="sectionsList" [route]="'meu-sbt'" [current-index]="currentItemIndex"
    (change-selected-value)="changeSelectedValue($event)"></app-nav>

  <div class="container" [ngSwitch]="currentItemIndex">
    <section *ngSwitchCase="0" class="my-inscriptions">
      <h1>Minhas Inscrições</h1>

      <div *ngIf="inscriptions?.length === 0" class="wrapper results-empty">
        <section class="without-information">
          <img src="/assets/images/no-inscriptions.png" alt="" />

          <h2>Você ainda não tem inscrições</h2>

          <p class="without-information-text">
            Identificamos que você ainda não realizou nenhuma inscrição. Acesse
            a página de Inscrições para fazer já a sua inscrição!
          </p>

          <a class="without-information-link" routerLink="/inscricoes">Acessar inscrições</a>

        </section>

        <section class="site-section">
          <h2>Quer descobrir mais sobre o SBT?</h2>

          <p class="results-empty-msg">
            Você também pode acessar algum dos seguintes links:
          </p>

          <ul class="results-empty-list">
            <li>
              <a class="results-empty-link" routerLink="/programas">Programas</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/programacao">Programação</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/jornalismo">Jornalismo</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/ao-vivo">SBT Ao Vivo</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/sbt-videos">SBT Vídeos</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/inscricoes">Inscrições</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/promocoes">Promoções</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/institucional">Institucional</a>
            </li>
            <li>
              <a class="results-empty-link" routerLink="/mapa-do-site">Mapa do Site</a>
            </li>
          </ul>

          <a routerLink="/" class="button-go-to-home">Ir para a home</a>
        </section>
      </div>

      <div *ngIf="inscriptions?.length > 0">
        <section class="wrapper">
          <app-card-inscriptions route="inscricoes" [content]="inscriptions" (cancel-inscription)="cancelInscription($event)"></app-card-inscriptions>
        </section>
      </div>
    </section>

    <section *ngSwitchCase="1" class="my-bookmarks">
      <h1>Escolher Favorito</h1>
      <app-blockquote content=" A escolha de um programa favorito permitirá você acompanhar as últimas
          notícias do mesmo, direto na <a routerlink='/'>Página Inicial</a> do
          site do SBT, de forma personalizada"
        [is-big]="true"></app-blockquote>
      <section class="wrapper">
        <section class="bookmarks-programs">
          <app-bookmarks *ngIf="programs" [show-genders]="true" [content]="programs"></app-bookmarks>
        </section>
      </section>
    </section>

    <section *ngSwitchCase="2" class="my-data">
      <section>
        <div class="data-header">
          <h1 class="data-subtitle">1. Dados Pessoais</h1>
          <p class="data-hint">Campo obrigatório</p>
        </div>
        <form class="data-form" [formGroup]="personalData" #form>
          <app-form-input [type]="'input'" [parent-form]="personalData" name="name" label="Nome" placeholder="Digite o seu nome"
            [inputPattern]="pureTextPattern" [required]="true" class="full-width"></app-form-input>

          <app-form-input [type]="'select'" [parent-form]="personalData" name="gender" label="Sexo" [options]="genderOptions"
            [required]="true" class="full-width"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="personalData" name="birthday" label="Data de Nascimento"
            placeholder="00/00/0000" inputMask="00/00/0000" [required]="true" class="reduced"></app-form-input>

          <app-error *ngIf="isBirthDayValid()" error="É proibido o cadastro de menores de 18 anos."></app-error>
          <app-error *ngIf="isDateValid()" error="Insira uma data válida."></app-error>

          <app-form-input [type]="'input'" [parent-form]="personalData" name="rg" label="RG" placeholder="Digite o seu RG"
            [required]="false" class="reduced" [inputPattern]="rgValidator"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="personalData" name="cpf" label="CPF" placeholder="000.000.000-00"
            inputMask="000.000.000-00" [required]="true" class="reduced"></app-form-input>

          <app-error
            *ngIf="isCpfValid()"
            error="O CPF inserido é inválido."
          ></app-error>

          <p *ngIf="userHasPicture()">
            <label class="form-label">Foto:</label>
            <img [src]="user.picture" height="100" class="user-pic"/>
            <span class="data-form-buttons">
              <button type="button" (click)="removeUserPic()" class="delete" data-analytics="deletar-foto-usuario">
                Deletar foto
              </button>
            </span>
          </p>

          <app-form-input
            [type]="'image'"
            [parent-form]="personalData"
            name="picture"
            (upload-field)="receiveData($event)"
            label="Atualizar foto:">
          </app-form-input>

          <app-error *ngIf="personalDataError || uploadError" [error]="personalDataError || uploadError"></app-error>

          <!-- <app-form-input
            [type]="'select'"
            [parent-form]="personalData"
            name="maritalStatus"
            label="Estado Civil"
            [options]="maritalOptions"
            [required]="true"
            class="full-width"
          ></app-form-input> -->

          <div class="data-form-buttons">
            <button [disabled]="personalData.invalid" type="button" (click)="updatePersonalData()" data-analytics="atualizar-dados-pessoais">
              Salvar mudanças
            </button>
          </div>
        </form>
      </section>

      <section>
        <div class="data-header">
          <h1 class="data-subtitle">2. Dados de Contato</h1>
          <p class="data-hint">Campo obrigatório</p>
        </div>
        <form class="data-form" [formGroup]="contactData" #form>
          <app-form-input [type]="'input'" [parent-form]="contactData" name="cep" label="CEP" placeholder="00000-000"
            inputMask="00000-000" [required]="true" class="reduced"></app-form-input>

          <app-form-input [type]="'select'" [parent-form]="contactData" name="state" label="Estado" [required]="true"
            class="full-width" [options]="states"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="city" label="Cidade" [required]="true"
            class="full-width"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="neighborhood" label="Bairro" placeholder="Ex: Bela Vista"
            [required]="true" class="full-width"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="address" label="Endereço" placeholder="Ex: Rua Washington Luiz"
            [required]="true" class="full-width"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="number" label="Número" placeholder="Ex: 820"
            [required]="true" class="reduced"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="additional" label="Complemento"
            placeholder="Ex: Bloco 14 Apartamento 302" class="full-width"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="cellphone" label="Telefone Celular"
            placeholder="(00) 00000-0000" inputMask="(00) 00000-0000" class="reduced"></app-form-input>

          <app-form-input [type]="'input'" [parent-form]="contactData" name="phone" label="Telefone Residencial"
            placeholder="(00) 0000-0000" inputMask="(00) 0000-0000" class="reduced"></app-form-input>

          <app-error *ngIf="contactDataError" [error]="contactDataError"></app-error>

          <div class="data-form-buttons">
            <button [disabled]="contactData.invalid" type="button" (click)="updateContactData()" data-analytics="atualizar-dados-de-contato">
              Salvar mudanças
            </button>
          </div>
        </form>
      </section>

      <section>
        <div class="data-header">
          <h1 class="data-subtitle">3. Dados da Conta</h1>
          <p class="data-hint">Campo obrigatório</p>
        </div>
        <form class="data-form" [formGroup]="accountData" #form>

          <app-form-input [type]="'password'" [parent-form]="accountData" name="currentPassword" label="Senha Atual" [required]="true"
           class="full-width" [maxLength]="50"></app-form-input>

          <app-form-input [type]="'password'" [parent-form]="accountData" name="newPassword" label="Nova Senha" [minLength]="6" [maxLength]="20" 
          [required]="true" class="full-width"></app-form-input>

          <app-error *ngIf="accountDataError" [error]="accountDataError"></app-error>

          <div class="data-form-buttons">
            <button [disabled]="accountData.invalid" type="button" (click)="updateAccountData()" data-analytics="atualizar-dados-de-conta">
              Salvar mudanças
            </button>
          </div>
        </form>
      </section>

      <div class="data-download">
        <a [href]="userDataDownloadUrl" download="meus-dados.json">          
          <i class="fa fa-download"></i><span>Fazer download dos meus dados</span>
        </a>
      </div>

      <div class="data-delete" (click)="deleteAccountModal()">
        <div>          
          <i class="fa fa-exclamation-triangle"></i> <span>Deletar minha conta</span>
        </div>
        <app-blockquote content="Ao deletar sua conta, todos os seus dados também serão deletados da
        nossa base."></app-blockquote>

        <app-error *ngIf="deleteAccountError" [error]="deleteAccountError"></app-error>
      </div>
    </section>
  </div>
</section>

<ngx-smart-modal
  #modalDelete
  identifier="modalDelete"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Deletar Conta')"
  (onAnyCloseEventFinished)="removeFromTitle('Deletar Conta')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalDelete.close()" title="Fechar">    
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header">
    <p><span>Ahhh!</span> <span>Não se vá...</span></p>
  </div>
  <div class="modal-content">
    <p>
      Nós sentimos muito por você estar indo. Ao excluir sua conta você não
      conseguirá efetuar as inscrições e personalizar o site do SBT conforme sua
      preferência.
    </p>
    <p>
      Mas se mesmo assim você deseja deletar permanentemente sua conta, clique
      em <strong>Excluir minha conta.</strong>
    </p>
    <button class="modal-button" type="button" (click)="modalDelete.close()">
      Manter minha conta
    </button>
    <button class="modal-button delete" type="button" (click)="deleteAccount()" data-analytics="excluir-conta">
      Excluir minha conta
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalFinishAccount
  identifier="modalFinishAccount"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Finalizar')"
  (onAnyCloseEventFinished)="removeFromTitle('Finalizar')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalFinishAccount.close()" title="Fechar">    
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header-confirm">
    <i class="fa fa-check"></i>
    <h1>Conta excluída com sucesso!</h1>
  </div>
  <div class="modal-content confirm">
    <p>
      Caso você deseje ter novamente uma conta conosco, você pode criá-la na
      nossa <a><strong>Página de Cadastro.</strong></a> Você será redirecionado
      para a <strong>home</strong> do site em 5 segundos
    </p>
    <button class="modal-button" type="button" (click)="modalFinishAccount.close()">
      Ok, entendi.
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalUpdateData
  identifier="modalUpdateData"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Dados Atualizados')"
  (onAnyCloseEventFinished)="removeFromTitle('Dados Atualizados')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button" (click)="modalUpdateData.close()" title="Fechar">    
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header-confirm">    
    <i class="fa fa-check"></i>
    <h1>Dados atualizados!</h1>
  </div>
  <div class="modal-content confirm">
    <p>
      Você acaba de manter seus dados atualizados na base de dados do SBT! Você
      pode voltar e editar seus dados a qualquer momento!
    </p>
    <button class="modal-button" type="button" (click)="modalUpdateData.close()">
      Ok, entendi.
    </button>
  </div>
</ngx-smart-modal>
