import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  OnDestroy
} from '@angular/core'
import { MenuService } from './menu.service'
import { Router, NavigationStart, NavigationEnd } from '@angular/router'
import { StationService } from '../../services/station.service'
import { UserService } from '../../services/user.service'
import { AnalyticsService } from '../../services/analytics.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [MenuService, StationService]
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('menu', { static: true })
  public menu: ElementRef

  @ViewChild('menuContainer', { static: true })
  public menuContainer: ElementRef

  @ViewChild('menuItems', { static: true })
  public menuItems: ElementRef

  @Input('show-station-tab')
  public showStationTab: boolean

  public userCookie: string
  public station: string

  public open: boolean = false
  public search: boolean = false
  public headers: any = []
  public emissoras: any = {}
  public footers: any = {}
  public currentHeader: number
  public currentSubmenu: number
  public iconOpenWithoutContent: boolean = false
  public hasStationBefore: boolean = false
  public defaultApps: any = [{
    'network': 'google',
    'url': 'https://play.google.com/store/apps/details?id=br.com.sbt.app&pli=1',
    'icon': 'fa-android'
  }, {
    'network': 'apple',
    'url': 'https://apps.apple.com/br/app/tv-sbt/id965947410?l=pt&ls=1',
    'icon': 'fa-apple store'
  }]
  public defaultSocialNetworks: any = [{
    'network': 'youtube',
    'url': 'https://www.youtube.com/user/SBTonline',
    'icon': 'fa-youtube'
  }, {
    'network': 'instagram',
    'url': 'https://www.instagram.com/sbtonline',
    'icon': 'fa-instagram'
  }, {
    'network': 'facebook',
    'url': 'https://pt-br.facebook.com/SBTonline/',
    'icon': 'fa-facebook'
  }, {
    'network': 'twitter',
    'url': 'https://twitter.com/sbtonline',
    'icon': 'fa-twitter'
  }]

  public subscriptions = []

  public isShowButton: boolean = true

  public deferredPrompt: any
  public pwaName: any
  public epochTime: any
  public pwaStatusInstalled: any
  public pwaStatusCancelled: any
  public pwaStatusNotInstalled: any
  public cache: any
  public expires: any

  @HostListener('window:beforeinstallprompt', ['$event']) beforeInstallPwa(e) {
    e.preventDefault()
    this.deferredPrompt = e
    this.showInstallPromotion()
  }

  @HostListener('window:appinstalled', ['$event']) installPwa(e) {
    e.preventDefault()
    this.deferredPrompt = null
    this.setCacheLocal(this.pwaName, this.pwaStatusInstalled, -1)
    this.isShowButton = false
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.open === true ? this.open = false : this.open
  }

  constructor(
    private _service: MenuService,
    private router: Router,
    private renderer: Renderer2,
    private stationService: StationService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    const time = Math.floor(Date.now() / 1000)
    this.deferredPrompt = null
    this.pwaName = 'installPwa'
    this.epochTime = time
    this.pwaStatusInstalled = 'installed'
    this.pwaStatusCancelled = 'cancelled'
    this.pwaStatusNotInstalled = 'not installed'
    this.expires = parseInt(this.epochTime + 2592000, 10)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  async ngOnInit() {
    const routeSubscription = this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationStart) {
        this.open = false

        this.setStation()
      }

      if (event instanceof NavigationEnd) {
        const user = this.userService.getUser()
        this.userCookie = user && user.name ? user.name : ''

        if (event.urlAfterRedirects.includes('/resultados')) {
          this.iconOpenWithoutContent = true

          return
        }

        this.iconOpenWithoutContent = false

        this.setStation();

        if (this.stationService.checkStationCookie()) {
          const stationIdentifier: string = this.stationService.getStationCookie()
        }
      }
    })

    this.subscriptions.push(routeSubscription)

    const resMenu = await this._service.getMenu()

    if (resMenu && resMenu['headers']) {
      this.headers = resMenu["headers"]
      this.emissoras = resMenu["emissoras"]
      this.footers = resMenu["footers"]
      console.log('[MENU] Call GetMenu', this.headers, this.emissoras, this.footers)

      this.setStation()
    } else {
      console.log('[MENU] Call GetMenu - Empty')
    }

    let userAgent = navigator.userAgent || navigator.vendor

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/Macintosh/i) || userAgent.includes("Mac")) {
      this.isShowButton = false
      return
    }

    this.cache = this.getCacheLocal(this.pwaName)

    if (this.cache.status === this.pwaStatusInstalled || this.cache.status === this.pwaStatusCancelled) {
      this.isShowButton = false
    }

    if (!this.cache.expires) {
      this.setCacheLocal(this.pwaName, this.pwaStatusNotInstalled, this.expires)
    }
  }

  setStation(): void {
    const hasStation: boolean = this.stationService.checkStationCookie()

    if (!hasStation && this.hasStationBefore) {
      this.hasStationBefore = false

      this.station = ''
    }

    if (hasStation) {
      const stationIdentifier: string = this.stationService.getStationCookie()

      const station = this.stationService.getStation(stationIdentifier)[0]

      if (station) {
        station.affiliate
          ? (this.station = `afiliada/${station.identifier}`)
          : (this.station = `${station.identifier}`)

        this.hasStationBefore = true

        this.defaultSocialNetworks = [{
          'network': 'youtube',
          'url': station.socialNetworks.youtube,
          'icon': 'fa-youtube'
        }, {
          'network': 'instagram',
          'url': station.socialNetworks.instagram,
          'icon': 'fa-instagram'
        }, {
          'network': 'facebook',
          'url': station.socialNetworks.facebook,
          'icon': 'fa-facebook'
        }, {
          'network': 'twitter',
          'url': station.socialNetworks.twitter,
          'icon': 'fa-twitter'
        }]
      }
    }
  }

  openMenu(): void {
    this.menuItems.nativeElement.scrollBy({
      top: -999,
      behavior: 'smooth'
    })

    this.open = !this.open
    this.search = false
    this.currentHeader = -1
    this.currentSubmenu = -1
  }

  hideSearch(event: any): void {
    const { hide } = event

    if (hide) {
      this.search = false
    }
  }

  openSubMenuChildren(element: any, index: number): void {
    if (this.currentSubmenu === index) {
      this.currentSubmenu = -1

      return
    }

    this.currentSubmenu = index
  }

  openSubMenu(element: any, index: number): void {
    if (this.currentHeader === index) {
      this.currentHeader = -1

      return
    }

    this.currentHeader = index
  }

  openSearch(): void {
    if (location.pathname.includes('resultados')) {
      return
    }

    this.search = !this.search
  }

  clickedInside($event: any): any {
    if ($event.target) {
      if ($event.target.nodeName) {
        const tagName = $event.target.nodeName.toLowerCase()

        if (tagName !== 'a' && tagName !== 'i') {
          $event.preventDefault()
          $event.stopPropagation()

          return
        }

        if (tagName === 'i') {
          const { parentNode } = $event.target

          if (parentNode) {
            const { classList } = parentNode

            if (classList && !classList.contains('external-link')) {
              $event.preventDefault()
              $event.stopPropagation()

              return
            }
          }
        }

        if (tagName === 'a') {
          const { classList } = $event.target

          if (classList && !classList.contains('external-link')) {
            $event.preventDefault()
            $event.stopPropagation()

            return
          }
        }
      }
    }
  }

  clickedOutside($event: any) {
    if (!this.menuContainer.nativeElement.contains($event.target)) {
      this.open = false
    }
  }

  logout() {
    this.userService.logout()

    this.router.navigate([`/`])
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      this.renderer.addClass(this.menu.nativeElement, 'sticky')
    } else {
      this.renderer.removeClass(this.menu.nativeElement, 'sticky')
    }
  }

  changeStation(stationIdentifier: string): any {
    const currentStation = this.stationService.getStation(stationIdentifier)

    if (stationIdentifier === 'default') {
      this.stationService.deleteStationCookie()

      window.location.href = '/'
      return
      // return this.router.navigate([`/`])
    }

    if (currentStation) {
      this.stationService.setStationCookie(stationIdentifier)

      if (currentStation[0].affiliate) {
        window.location.href = `/afiliada/${stationIdentifier}`
        return
        // this.router.navigate([`/afiliada/${stationIdentifier}`])
      }

      window.location.href = `/${stationIdentifier}/home`
      return

      // this.router.navigate([`/${stationIdentifier}/home`])
    }
  }

  setCacheLocal(key, status, expires) {
    localStorage.setItem(key, JSON.stringify({ status, expires }))
  }

  getCacheLocal(key) {
    const LC = localStorage.getItem(key)
    if (LC) {
      try {
        return JSON.parse(LC)
      } catch (e) {
        return {}
      }
    } else {
      return {}
    }
  }

  async insertPwa() {
    if (this.deferredPrompt !== null)
      this.isShowButton = false
    this.deferredPrompt.prompt()
    const { outcome } = await this.deferredPrompt.userChoice
    if (outcome === 'accepted') {
      this.deferredPrompt = null
      this.analyticsService.sendEvent('installed', 'app')
    } else if (outcome === 'dismissed') {
      this.setCacheLocal(this.pwaName, this.pwaStatusCancelled, this.expires)
      this.isShowButton = false
    }
  }

  showInstallPromotion() {
    const cacheLS = this.getCacheLocal(this.pwaName)
    const expiresInstallPWA = cacheLS.expires
    const statusInstallPWA = cacheLS.status

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.setCacheLocal(this.pwaName, this.pwaStatusInstalled, 0)
    } else {
      switch (statusInstallPWA) {
        case this.pwaStatusCancelled:
          if (expiresInstallPWA < this.epochTime) {
            this.isShowButton = true
          }
          break
        default:
          this.isShowButton = true
          break
      }
    }
  }

  isInternalLink(url: string): boolean {
    const domain = (new URL(url))
    if (url.indexOf(domain.host) > -1) {
      return true
    } else {
      return false
    }
  }

  getInternalUrl(url: string): string {
    const domain = (new URL(url))
    return domain.pathname
  }
}
