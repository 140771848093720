var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime } from 'rxjs/operators';
import { TitleService } from 'src/app/services/title.service';
import { checkOfAge } from 'src/app/validators/age-of.validator';
import { checkCpf } from 'src/app/validators/cpf.validator';
import { checkInvalidDate } from 'src/app/validators/invalid-date.validator';
import { siteMap } from '../../app.metatags';
import { MetatagsService } from '../../services/metatags.service';
import { StatesService } from '../../services/states.service';
import { UserService } from '../../services/user.service';
import { InscriptionsService } from '../inscriptions/inscriptions.service';
import { ProgramsService } from '../programs/programs.service';
import { MySbtService } from './my-sbt.service';
import { decrypt } from '../../utils/util.encrypt';
export class MySbtComponent {
    constructor(ngxSmartModalService, fb, title, programsService, inscriptionService, service, route, meta, metasService, userService, router, sanitizer, statesService) {
        this.ngxSmartModalService = ngxSmartModalService;
        this.fb = fb;
        this.title = title;
        this.programsService = programsService;
        this.inscriptionService = inscriptionService;
        this.service = service;
        this.route = route;
        this.meta = meta;
        this.metasService = metasService;
        this.userService = userService;
        this.router = router;
        this.sanitizer = sanitizer;
        this.statesService = statesService;
        this.sectionsList = [
            {
                title: 'Minhas Inscrições',
                link: 'minhas-inscricoes'
            },
            {
                title: 'Meus Favoritos',
                link: 'meus-favoritos'
            },
            {
                title: 'Meus Dados',
                link: 'meus-dados'
            }
        ];
        this.genderOptions = [
            {
                value: 'Masculino',
                text: 'Masculino'
            },
            {
                value: 'Feminino',
                text: 'Feminino'
            }
        ];
        this.maritalOptions = [
            {
                value: 'Solteiro',
                text: 'Solteiro'
            },
            {
                value: 'Casado',
                text: 'Casado'
            },
            {
                value: 'Divorciado',
                text: 'Divorciado'
            },
            {
                value: 'Viuvo',
                text: 'Viúvo'
            }
        ];
        this.states = this.statesService.getStates();
        this.inscriptions = [];
        this.pureTextPattern = '^[A-Za-zÀ-ú ]+$';
        this.emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
        this.rgValidator = '^[a-zA-Z0-9]{5,15}$';
        this.personalData = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(this.pureTextPattern)]],
            gender: [null, [Validators.required]],
            birthday: [
                '',
                [
                    Validators.required,
                    checkOfAge(18),
                    checkInvalidDate()
                ]
            ],
            rg: ['', [Validators.pattern(this.rgValidator)]],
            cpf: ['', [Validators.required, checkCpf()]],
            picture: ['']
            // maritalStatus: [null, [Validators.required]]
        });
        this.contactData = this.fb.group({
            cep: ['', [Validators.required]],
            state: [null, Validators.required],
            city: ['', Validators.required],
            neighborhood: ['', Validators.required],
            address: ['', Validators.required],
            number: ['', Validators.required],
            additional: [''],
            cellphone: [''],
            phone: ['']
        });
        this.accountData = this.fb.group({
            // email: ['', [Validators.required]],
            currentPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
        });
        this.subscriptions = [];
        this.metasService.updateTags(siteMap, this.meta);
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (localStorage.getItem('user')) {
                const user = decrypt(localStorage.getItem('user') || '');
                this.user = user;
                yield this.userService.getUserFromDatabase(this.user.email)
                    .then((response) => {
                    if (!response || !response.email) {
                        this.userService.logout();
                        this.router.navigate(['/']);
                    }
                })
                    .catch((error) => {
                    this.userService.logout();
                    this.router.navigate(['/']);
                });
                this.userEmail = this.user.email;
                this.downloadToken = this.user.downloadtoken;
                this.fillUpdateForm();
                this.generateDownloadJsonUri(this.user);
            }
            const activeRouteSubscription = yield this.route.params.subscribe(params => {
                if (params['section']) {
                    this.sectionUrl = params['section'];
                    this.setTitle(this.sectionUrl);
                    this.currentItemIndex = this.sectionsList
                        .map(section => section.link)
                        .indexOf(params['section']);
                }
                else {
                    this.currentItemIndex = 0;
                }
            });
            this.subscriptions.push(activeRouteSubscription);
            const getCepSubscription = this.contactData
                .get('cep')
                .valueChanges.pipe(debounceTime(1000))
                .subscribe(cep => {
                if (cep.length === 8) {
                    this.service.getAdressByCEP(cep).then(response => {
                        const { uf, city, district, street } = response;
                        if (uf) {
                            this.contactData.get('state').setValue(uf);
                        }
                        if (city) {
                            this.contactData.get('city').setValue(city);
                        }
                        if (district) {
                            this.contactData.get('neighborhood').setValue(district);
                        }
                        if (street) {
                            this.contactData.get('address').setValue(street);
                        }
                    });
                }
            });
            this.subscriptions.push(getCepSubscription);
            yield this.userService
                .getInscriptionsByUser(this.userEmail)
                .then((response) => {
                this.userInscriptions = response;
                if (this.userInscriptions.length > 0) {
                    this.userInscriptions[0].inscriptions.forEach(inscription => {
                        if (inscription.enable !== false) {
                            this.inscriptionService
                                .getInscriptionsById(inscription.id)
                                .then(inscriptions => {
                                this.inscriptions.push(...inscriptions);
                            });
                        }
                    });
                }
            });
            const newPasswordFieldSubscription = this.accountData
                .get('newPassword')
                .valueChanges.pipe()
                .subscribe(newPassword => {
                if (newPassword.length < 6) {
                    this.accountDataError = 'SENHA PRECISA TER NO MÍNIMO 6 E NO MÁXIMO 20 CARACTERES';
                    return;
                }
                this.accountDataError = undefined;
            });
            this.programsService.getPrograms().then(programs => {
                return (this.programs = programs);
            });
            this.subscriptions = [newPasswordFieldSubscription];
        });
    }
    isBirthDayValid() {
        return (this.personalData.get('birthday').errors &&
            this.personalData.get('birthday').errors.underAge &&
            (this.personalData.get('birthday').touched ||
                this.personalData.get('birthday').dirty));
    }
    isDateValid() {
        return (this.personalData.get('birthday').errors &&
            this.personalData.get('birthday').errors.invalidDate &&
            (this.personalData.get('birthday').touched ||
                this.personalData.get('birthday').dirty));
    }
    removeFromTitle(title) {
        const currentTitle = this.title.getTitle().replace(`${title} - `, '');
        this.title.setTitle(`${currentTitle}`);
    }
    changeTitle(title) {
        const currentTitle = this.title.getTitle();
        this.title.setTitle(`${title} - ${currentTitle}`);
    }
    isCpfValid() {
        return (this.personalData.get('cpf').value.length === 11 &&
            this.personalData.get('cpf').errors &&
            this.personalData.get('cpf').errors.invalidCpf &&
            (this.personalData.get('cpf').touched ||
                this.personalData.get('cpf').dirty));
    }
    generateDownloadJsonUri(json) {
        let theJSON = JSON.stringify(json);
        let uri = this.sanitizer.bypassSecurityTrustUrl('data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON));
        this.userDataDownloadUrl = uri;
    }
    changeSelectedValue(event) {
        this.currentItemIndex = event.index;
        this.sectionUrl = event.sectionURL;
        this.setTitle(this.sectionUrl);
    }
    setTitle(url) {
        switch (url) {
            case 'minhas-inscricoes':
                this.title.setTitle('Meu SBT - Inscrições - SBT');
                break;
            case 'meus-favoritos':
                this.title.setTitle('Meu SBT - Favoritos - SBT');
                break;
            case 'meus-dados':
                this.title.setTitle('Meu SBT - Dados - SBT');
                break;
        }
    }
    deleteAccountModal() {
        this.ngxSmartModalService.getModal('modalDelete').open();
    }
    userHasPicture() {
        if (this.user.picture === undefined)
            return false;
        if (this.user.picture === 'none')
            return false;
        if (this.user.picture === '')
            return false;
        return true;
    }
    confirmDeletion() {
        /* */
    }
    cancelInscription(inscription) {
        this.inscriptionService
            .cancelInscription(inscription.id.toString(), this.userEmail)
            .then(response => {
            this.inscriptions = this.inscriptions.filter(inscription => {
                return inscription.id.toString() !== response.inscription;
            });
        });
    }
    getFieldsForUpdate(formGroup) {
        const user = {};
        Object.keys(formGroup.controls).forEach((name) => {
            let currentControl = formGroup.controls[name];
            if (currentControl.dirty) {
                if (name !== 'birthday') {
                    user[name] = currentControl.value;
                    return;
                }
                const birthday = this.personalData
                    .get('birthday')
                    .value.split('/')
                    .join('');
                user['birthdayDay'] = birthday.substring(0, 2),
                    user['birthdayMonth'] = birthday.substring(2, 4),
                    user['birthdayYear'] = birthday.substring(4, 8);
            }
        });
        return user;
    }
    updatePersonalData() {
        const user = Object.assign({}, this.getFieldsForUpdate(this.personalData), { picture: this.user.picture });
        this.userService
            .update(user)
            .then(response => {
            this.ngxSmartModalService.getModal('modalUpdateData').open();
            if (localStorage.getItem('user')) {
                var user = decrypt(localStorage.getItem('user') || '');
            }
            const loggedUser = user;
            this.generateDownloadJsonUri(loggedUser);
        })
            .catch((error) => (this.personalDataError = error.message));
    }
    updateContactData() {
        const user = {
            postalcode: this.contactData.get('cep').value,
            streetLabel: this.contactData.get('address').value,
            streetNumber: this.contactData.get('number').value,
            streetNumber2: this.contactData.get('additional').value,
            district: this.contactData.get('neighborhood').value,
            city: this.contactData.get('city').value,
            state: this.contactData.get('state').value,
            homephone: this.contactData.get('phone').value,
            cellphone: this.contactData.get('cellphone').value
        };
        this.userService
            .update(user)
            .then(response => {
            this.ngxSmartModalService.getModal('modalUpdateData').open();
            if (localStorage.getItem('user')) {
                var user = decrypt(localStorage.getItem('user') || '');
            }
            const loggedUser = user;
            this.generateDownloadJsonUri(loggedUser);
        })
            .catch((error) => (this.contactDataError = error.message));
    }
    updateAccountData() {
        const user = {
            newPassword: this.accountData.get('newPassword').value,
            oldPassword: this.accountData.get('currentPassword').value
        };
        this.userService
            .updatePassword(user)
            .then(response => {
            this.accountDataError = null;
            this.ngxSmartModalService.getModal('modalUpdateData').open();
            if (localStorage.getItem('user')) {
                var user = decrypt(localStorage.getItem('user') || '');
            }
            const loggedUser = user;
            this.generateDownloadJsonUri(loggedUser);
        })
            .catch((error) => (this.accountDataError = error.message));
    }
    removeUserPic() {
        this.user.picture = 'none';
    }
    deleteAccount() {
        this.userService
            .delete()
            .then(response => {
            if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalDelete')) {
                this.ngxSmartModalService.getModal('modalDelete').close();
                if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalFinishAccount')) {
                    this.ngxSmartModalService.getModal('modalFinishAccount').open();
                }
            }
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 5000);
        })
            .catch((error) => {
            if (this.ngxSmartModalService && this.ngxSmartModalService.getModal('modalDelete')) {
                this.ngxSmartModalService.getModal('modalDelete').close();
            }
            console.log('Delete Account: ', error.message);
            this.deleteAccountError = 'Ocorreu um erro ao tentar excluir a conta. Atualize a página e tente novamente.';
        });
    }
    fillUpdateForm() {
        const day = this.user.birthdayDay;
        const month = this.user.birthdayMonth;
        const year = this.user.birthdayYear;
        const birthDate = `${day}/${month}/${year}`;
        this.personalData.get('name').setValue(this.user.name);
        this.personalData.get('gender').setValue(this.user.gender);
        this.personalData.get('birthday').setValue(birthDate);
        this.personalData.get('cpf').setValue(this.user.cpf);
        this.personalData.get('rg').setValue(this.user.rg);
        this.contactData.get('cep').setValue(this.user.postalcode);
        this.contactData.get('state').setValue(this.user.state);
        this.contactData.get('city').setValue(this.user.city);
        this.contactData.get('neighborhood').setValue(this.user.district);
        this.contactData.get('address').setValue(this.user.streetLabel);
        this.contactData.get('number').setValue(this.user.streetNumber);
        this.contactData.get('additional').setValue(this.user.streetNumber2);
        this.contactData.get('cellphone').setValue(this.user.cellphone);
        this.contactData.get('phone').setValue(this.user.homephone);
    }
    downloadUserData() {
        this.service
            .getJsonUserData(this.downloadToken)
            .then((response) => {
            window.open(response);
            return;
        })
            .catch((error) => {
            this.downloadDataError = error.message;
        });
    }
    receiveData(event) {
        if (event.uploadError) {
            this.uploadError = event.uploadError;
            return;
        }
        this.uploadError = undefined;
        this.user.picture = event.fieldValue;
    }
}
