<section class="list">
  <h2 class="list-title" [innerText]="title"></h2>

  <section class="list-container">
    <div class="list-items">
      <div class="topContent">
        <div class="topContent-big">
          <a *ngIf="firstContent" class="list-item list-item-full" [routerLink]="
              buildLink(
                firstContent.programRoute,
                firstContent.type,
                firstContent.slug
              )
            ">
            <div class="list-item-image">
              <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="firstContent.thumbnail" alt="" />

              <span class="list-item-image-position">#1</span>
              <span class="list-item-image-badge" [innerText]="firstContent.program"></span>
            </div>
            <div class="list-item-content">
              <span class="list-item-title" [innerText]="firstContent.title"></span>
              <span class="list-item-date" [innerText]="
                  firstContent.publishdatestring
                    | date: 'dd/MM/yyyy \'às\' HH:mm'
                "></span>
            </div>
          </a>
        </div>
        <div class="topContent-small">
          <a *ngFor="let item of topContent; let i = index" class="list-item" [routerLink]="buildLink(item.programRoute, item.type, item.slug)">
            <div class="list-item-image">
              <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="item.thumbnail" alt="" />

              <span class="list-item-image-position">#{{ i + 2 }}</span>
              <span class="list-item-image-badge" [innerText]="item.program"></span>
            </div>
            <div class="list-item-content">
              <span class="list-item-title" [innerText]="item.title"></span>
              <span class="list-item-date" [innerText]="
                  item.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'
                "></span>
            </div>
          </a>
        </div>
      </div>
      <div class="bottomContent">
        <a *ngFor="let item of bottomContent; let i = index" class="list-item" [routerLink]="buildLink(item.programRoute, item.type, item.slug)">
          <div class="list-item-image">
            <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="item.thumbnail" alt="" />

            <span class="list-item-image-position">#{{ i + 4 }}</span>
            <span class="list-item-image-badge" [innerText]="item.program"></span>
          </div>
          <div class="list-item-content">
            <span class="list-item-title" [innerText]="item.title"></span>
            <span class="list-item-date" [innerText]="
                item.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'
              "></span>
          </div>
        </a>
      </div>
    </div>
  </section>
</section>
