
<a
  *ngFor="let item of content; let i=index"
  class="card"
  [queryParams]="parameters[i]"
  [fragment]="fragment[i]"
  [routerLink]="getRouterLink(item, i)"
>
  <!-- sem imagem -->
  <div class="card-container" *ngIf="item.thumbnail === ''"
  (click)="goToLink($event, content[i].opcional5, getRouterLink(content[i]),parameters[i], fragment[i])"
  (mouseenter)="handleExternalLink($event)"
  >
    <div class="card-bold-title" [innerText]="item.title" [ngClass]="color"
    [ngStyle]="{ color: '#' + color || '' }"></div>
    <div
      class="card-title"
      *ngIf="item.description"
      [innerText]="item.description"
    ></div>
    <div class="card-info">
      <div
        class="card-date"
        [innerText]="item.startdate | date: 'dd/MM/yyyy \'às\' HH:mm'"
      ></div>
      <a
        [queryParams]="parameters[i]"
        [fragment]="fragment[i]"
        [routerLink]="getRouterLink(item, i)"
      >
        <button class="card-button" [ngStyle]="{ background: '#' + color || '' }"
          (click)="goToLink($event, content[i].opcional5, getRouterLink(content[i]),parameters[i], fragment[i])"
          (mouseenter)="handleExternalLink($event)"
        >
          Confira <i class="fa fa-arrow-right"></i>
        </button>
      </a>
    </div>
  </div>
   <!-- com imagem -->
  <div class="cards" *ngIf="item.thumbnail">
    <div class="card-image"
      (click)="goToLink($event, content[i].opcional5, getRouterLink(content[i]), parameters[i], fragment[i])"
      (mouseenter)="handleExternalLink($event)"
    >
      <img
        onerror="this.src='../../../assets/images/default-image.png'"
        [src]="item.thumbnail || item.image"
        loading="lazy"
        alt=""
      />
      <span
        *ngIf="hasTag(item) && showBadge"
        class="card-image-badge"
        [ngClass]="[color || '']"
        [ngStyle]="{ background: '#' + color || '' }"
        [innerText]="item.taghighlight || item.program || item.opcional"
      ></span>
    </div>
    <div class="card-body">
      <div class="card-title" [innerText]="item.title"></div>
      <div
        *ngIf="item.description"
        class="card-subtitle"
        [innerText]="item.description"
      ></div>
      <div
        class="card-publish-date"
        [innerText]="
          (item.enddate | date: 'dd/MM/yyyy \'às\' HH:mm') ||
          (item.createdAt | date: 'dd/MM/yyyy \'às\' HH:mm')
        "
      ></div>
    </div>
  </div>
</a>
