<div class="thanks">
  <h2 class="thanks-title">Agradecimentos</h2>

  <div class="thanks-content" *ngIf="thanks.description">
    <p class="thanks-content-description" [innerText]="thanks.description"></p>

    <div class="thanks-content-video">
      <app-video [url]="thanks.video" [fixable]="false"></app-video>
    </div>
  </div>

  <div class="thanks-list">
    <div class="list-item" *ngFor="let thank of thanksList">
      <a *ngIf="thank.author" [href]="thank.author" target="_blank">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="thank.thumbnail"
          class="item-img"
          alt=""
        />
      </a>
      <img
        *ngIf="!thank.author"
        onerror="this.src='../../../assets/images/default-image.png'"
        [src]="thank.thumbnail"
        class="item-img"
        alt=""
      />
    </div>
  </div>
</div>
