/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./twitter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../directives/run-scripts.directive";
import * as i5 from "./twitter.component";
var styles_TwitterComponent = [i0.styles];
var RenderType_TwitterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TwitterComponent, data: {} });
export { RenderType_TwitterComponent as RenderType_TwitterComponent };
export function View_TwitterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["runScripts", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i4.RunScriptsDirective, [i1.ElementRef], null, null), i1.ɵppd(3, 1)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.render())); _ck(_v, 1, 0, currVal_0); }); }
export function View_TwitterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-twitter", [], null, null, null, View_TwitterComponent_0, RenderType_TwitterComponent)), i1.ɵdid(1, 114688, null, 0, i5.TwitterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TwitterComponentNgFactory = i1.ɵccf("app-twitter", i5.TwitterComponent, View_TwitterComponent_Host_0, {}, {}, []);
export { TwitterComponentNgFactory as TwitterComponentNgFactory };
