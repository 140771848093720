/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lego-hibrido.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./lego-hibrido.component";
var styles_LegoHibridoComponent = [i0.styles];
var RenderType_LegoHibridoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegoHibridoComponent, data: {} });
export { RenderType_LegoHibridoComponent as RenderType_LegoHibridoComponent };
function View_LegoHibridoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "card-title"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.description; _ck(_v, 0, 0, currVal_0); }); }
function View_LegoHibridoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "card-container"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.content[_v.parent.context.index].opcional5, _co.getRouterLink(_co.content[_v.parent.context.index]), _co.parameters[_v.parent.context.index], _co.fragment[_v.parent.context.index]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.handleExternalLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-bold-title"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { color: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "card-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "card-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "button", [["class", "card-button"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.content[_v.parent.context.index].opcional5, _co.getRouterLink(_co.content[_v.parent.context.index]), _co.parameters[_v.parent.context.index], _co.fragment[_v.parent.context.index]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.handleExternalLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 278528, null, 0, i2.NgStyle, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(14, { background: 0 }), (_l()(), i1.ɵted(-1, null, [" Confira "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-arrow-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "card-bold-title"; var currVal_2 = _co.color; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 4, 0, (("#" + _co.color) || "")); _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.description; _ck(_v, 6, 0, currVal_4); var currVal_8 = _co.parameters[_v.parent.context.index]; var currVal_9 = _co.fragment[_v.parent.context.index]; var currVal_10 = _co.getRouterLink(_v.parent.context.$implicit, _v.parent.context.index); _ck(_v, 11, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _ck(_v, 14, 0, (("#" + _co.color) || "")); _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); var currVal_5 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.startdate, "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 8, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 11).target; var currVal_7 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_6, currVal_7); }); }
function View_LegoHibridoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "card-image-badge"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { background: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "card-image-badge"; var currVal_2 = _ck(_v, 2, 0, (_co.color || "")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 4, 0, (("#" + _co.color) || "")); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit.taghighlight || _v.parent.parent.context.$implicit.program) || _v.parent.parent.context.$implicit.opcional); _ck(_v, 0, 0, currVal_0); }); }
function View_LegoHibridoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "card-subtitle"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.description; _ck(_v, 0, 0, currVal_0); }); }
function View_LegoHibridoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "cards"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-image"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.content[_v.parent.context.index].opcional5, _co.getRouterLink(_co.content[_v.parent.context.index]), _co.parameters[_v.parent.context.index], _co.fragment[_v.parent.context.index]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.handleExternalLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "card-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "card-publish-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(10, 2), i1.ɵppd(11, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.hasTag(_v.parent.context.$implicit) && _co.showBadge); _ck(_v, 4, 0, currVal_1); var currVal_3 = _v.parent.context.$implicit.description; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.thumbnail || _v.parent.context.$implicit.image); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_2); var currVal_4 = (i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.enddate, "dd/MM/yyyy '\u00E0s' HH:mm")) || i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.createdAt, "dd/MM/yyyy '\u00E0s' HH:mm"))); _ck(_v, 9, 0, currVal_4); }); }
function View_LegoHibridoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.parameters[_v.context.index]; var currVal_3 = _co.fragment[_v.context.index]; var currVal_4 = _co.getRouterLink(_v.context.$implicit, _v.context.index); _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = (_v.context.$implicit.thumbnail === ""); _ck(_v, 3, 0, currVal_5); var currVal_6 = _v.context.$implicit.thumbnail; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LegoHibridoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoHibridoComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LegoHibridoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-hibrido", [], null, null, null, View_LegoHibridoComponent_0, RenderType_LegoHibridoComponent)), i1.ɵdid(1, 114688, null, 0, i4.LegoHibridoComponent, [i3.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegoHibridoComponentNgFactory = i1.ɵccf("app-lego-hibrido", i4.LegoHibridoComponent, View_LegoHibridoComponent_Host_0, { content: "content", size: "size", color: "color", showBadge: "show-badge" }, {}, []);
export { LegoHibridoComponentNgFactory as LegoHibridoComponentNgFactory };
