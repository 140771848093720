import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class CookieService {
    constructor(document) {
        this.document = document;
    }
    check(cookie) {
        const cookies = this.document.cookie;
        return cookies.includes(`${cookie}=`);
    }
    get(cookie) {
        const cookies = `; ${this.document.cookie}`;
        const cookieParts = cookies.split(`; ${cookie}=`);
        if (cookieParts.length !== 2) {
            return undefined;
        }
        return cookieParts
            .pop()
            .split(';')
            .shift();
    }
    set(cookie, value) {
        this.document.cookie = `${cookie}=${value || ''}; path=/`;
        return this.document.cookie;
    }
    delete(cookie) {
        this.document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
}
CookieService.ɵprov = i0.ɵɵdefineInjectable({ factory: function CookieService_Factory() { return new CookieService(i0.ɵɵinject(i1.DOCUMENT)); }, token: CookieService, providedIn: "root" });
