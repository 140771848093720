/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sitemap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i5 from "../../shared/breadcrumb/breadcrumb.component";
import * as i6 from "./sitemap.service";
import * as i7 from "@angular/common/http";
import * as i8 from "./sitemap.component";
import * as i9 from "../../services/title.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../services/metatags.service";
var styles_SitemapComponent = [i0.styles];
var RenderType_SitemapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SitemapComponent, data: {} });
export { RenderType_SitemapComponent as RenderType_SitemapComponent };
function View_SitemapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], [[8, "innerText", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeCategory(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (_co.currentCategoryIndex === _v.context.index)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_SitemapComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[8, "innerText", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.buildLink(_v.parent.context.$implicit.description); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SitemapComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0); }); }
function View_SitemapComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.available; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.available; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SitemapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ul", [["class", "sitemap-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [["class", "letter"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { unavailable: 0 }), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "letter"; var currVal_2 = _ck(_v, 3, 0, !_v.context.$implicit.available); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.getSitesOf(_v.context.$implicit.value); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.value)); _ck(_v, 1, 0, currVal_0); }); }
function View_SitemapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "sitemap-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alphabetLetters; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SitemapComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4], [8, "innerText", 0]], null, null, null, null)), i1.ɵpad(1, 1)], null, function (_ck, _v) { var currVal_0 = _ck(_v, 1, 0, _v.parent.context.$implicit.author); var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SitemapComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[8, "innerText", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1)], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, _v.parent.context.$implicit.author); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SitemapComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_11)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.opcional3 === "S"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.opcional3 === "N") || !_v.context.$implicit.opcional3); _ck(_v, 4, 0, currVal_1); }, null); }
function View_SitemapComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "sitemap-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "letter"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(2, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_9)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.subItems; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_SitemapComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "sitemap-list double-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.otherLinks.links; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SitemapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "sitemap-breadcrumb-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i4.View_BreadcrumbComponent_0, i4.RenderType_BreadcrumbComponent)), i1.ɵdid(3, 114688, null, 0, i5.BreadcrumbComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "section", [["class", "sitemap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "h1", [["class", "sitemap-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "section", [["class", "category-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [["categoriesList", 1]], null, 2, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SitemapComponent_7)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = _co.categories; _ck(_v, 9, 0, currVal_1); var currVal_2 = (_co.currentCategoryIndex === 0); _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.currentCategoryIndex === 1); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = "Mapa do Site do SBT"; _ck(_v, 5, 0, currVal_0); }); }
export function View_SitemapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-sitemap", [], null, null, null, View_SitemapComponent_0, RenderType_SitemapComponent)), i1.ɵprd(512, null, i6.SitemapService, i6.SitemapService, [i7.HttpClient]), i1.ɵdid(2, 114688, null, 0, i8.SitemapComponent, [i6.SitemapService, i9.TitleService, i10.Meta, i11.MetatagsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SitemapComponentNgFactory = i1.ɵccf("app-sitemap", i8.SitemapComponent, View_SitemapComponent_Host_0, {}, {}, []);
export { SitemapComponentNgFactory as SitemapComponentNgFactory };
