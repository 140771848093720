import { URL_USERS_API } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MySbtService {
    constructor(_http) {
        this._http = _http;
    }
    getAdressByCEP(cep) {
        return this._http
            .get(`${URL_USERS_API}/api/address/${cep}`)
            .toPromise()
            .then((response) => {
            return response;
        });
    }
    getJsonUserData(token) {
        return this._http
            .get(`${URL_USERS_API}/api/user/${token}/download`)
            .toPromise()
            .then(() => {
            return `${URL_USERS_API}/api/user/${token}/download`;
        });
    }
}
MySbtService.ɵprov = i0.ɵɵdefineInjectable({ factory: function MySbtService_Factory() { return new MySbtService(i0.ɵɵinject(i1.HttpClient)); }, token: MySbtService, providedIn: "root" });
