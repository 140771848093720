<div class="menu-footer">
  <div class="menu-footer-wrapper">
    <div>
      <div class="menu-footer-icons">
        <p>NOSSAS REDES</p>
        <ul class="icon-itens">
          <li *ngFor="let itemSocial of socialNetworks">
            <a [href]="itemSocial.url" target="_blank" [title]="itemSocial?.network" rel="nofollow noopener preconnect">
              <i class="external-link fa {{itemSocial?.icon}}"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div class="menu-footer-icons">
        <p>APP TV SBT</p>
        <ul class="icon-itens">
          <li *ngFor="let itemApp of apps">
            <a [href]="itemApp['url']" target="_blank" [title]="itemApp['network']" rel="nofollow noopener preconnect">
              <i class="external-link fa {{itemApp['icon']}}"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div *ngIf="footers['about'] && footers['about']['length'] > 0" class="menu-footer-itens">
        <p>SOBRE O SBT</p>
        <ul class="itens-list">
          <li *ngFor="let item of footers['about']">
            <!-- <a *ngIf="item['externalLink'] === 'S'" [href]="item['link']" target="_blank" [title]="item['title']" class="external-link" rel="nofollow noopener preconnect">
              <span class="item-badge"><i class="fa fa-external-link" title="Conteúdo externo"></i></span>{{ item['title'] }}
            </a>

            <a *ngIf="item['externalLink'] === 'N' && isInternalLink(item['link'])" [routerLink]="getInternalUrl(item['link'])">{{ item['title'] }}</a>

            <a *ngIf="item['externalLink'] === 'N' && !isInternalLink(item['link'])" [href]="item['link']">{{ item['title'] }}</a> -->


            <a *ngIf="item['externalLink'] === 'S'" [href]="item['link']" target="_blank" [title]="item['title']" class="external-link" rel="nofollow noopener preconnect">
              <span class="item-badge"><i class="fa fa-external-link" title="Conteúdo externo"></i></span>{{ item['title'] }}
            </a>

            <a *ngIf="item['externalLink'] === 'N' && isInternalLink(item['link'])" [routerLink]="getInternalUrl(item['link'])">{{ item['title'] }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div *ngIf="footers['gss'] && footers['gss']['length'] > 0" class="menu-footer-itens">
        <p>GRUPO SILVIO SANTOS</p>
        <ul class="itens-list">
          <li *ngFor="let item of footers['gss']">
            <!-- <a *ngIf="item['externalLink'] === 'S'" [href]="item['link']" target="_blank" [title]="item['title']" class="external-link" rel="nofollow noopener preconnect">
              <span class="item-badge"><i class="fa fa-external-link" title="Conteúdo externo"></i></span> {{ item['title'] }}
            </a>

            <a *ngIf="item['externalLink'] === 'N' && isInternalLink(item['link'])" [routerLink]="getInternalUrl(item['link'])">{{ item['title'] }}</a>

            <a *ngIf="item['externalLink'] === 'N' && !isInternalLink(item['link'])" [href]="item['link']">{{ item['title'] }}</a> -->


            <a *ngIf="item['externalLink'] === 'S'" [href]="item['link']" target="_blank" [title]="item['title']" class="external-link" rel="nofollow noopener preconnect">
              <span class="item-badge"><i class="fa fa-external-link" title="Conteúdo externo"></i></span> {{ item['title'] }}
            </a>

            <a *ngIf="item['externalLink'] === 'N' && getInternalUrl(item['link'])" [routerLink]="getInternalUrl(item['link'])" rel="nofollow noopener preconnect">{{ item['title'] }}</a>
                       
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="menu-footer-header">
    <img src="/assets/images/logo-sbt.webp" alt="Logo SBT" loading="lazy" width="40" height="40" />
    <p><strong>SBT</strong> - Sistema Brasileiro de Televisão</p>
  </div>
</div>
