import * as i0 from "@angular/core";
export class DomService {
    getIds() {
        const elements = Array.from(document.getElementsByTagName('*'));
        const allIds = elements
            .filter(element => {
            if (element.id) {
                return element;
            }
        })
            .map((element) => {
            return {
                id: element.id,
                top: element.children[0].offsetTop,
                title: element.title
            };
        });
        return allIds;
    }
    filterIds(ids) {
        const elements = Array.from(document.getElementsByTagName('*'));
        const allIds = elements
            .filter(element => {
            if (element.id && element.tagName !== 'iframe') {
                return element;
            }
        })
            .map((currentElement) => {
            const element = { id: '', title: '', top: '' };
            if (currentElement.id) {
                element.id = currentElement.id;
            }
            if (currentElement.title) {
                element.title = currentElement.title;
            }
            if (currentElement.title.toLowerCase().includes('disqus') ||
                currentElement.title.toLowerCase().includes('dsq')) {
                return;
            }
            if (currentElement.id.toLowerCase().includes('disqus') ||
                currentElement.id.toLowerCase().includes('dsq')) {
                return;
            }
            if (currentElement.children &&
                currentElement.children[0] &&
                currentElement.children[0].offsetTop) {
                element.top = currentElement.children[0].offsetTop;
            }
            return element;
        });
        return allIds;
    }
}
DomService.ɵprov = i0.ɵɵdefineInjectable({ factory: function DomService_Factory() { return new DomService(); }, token: DomService, providedIn: "root" });
