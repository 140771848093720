<section class="inscriptions">
  <div class="content-ad">
    <app-ad path="Inscricoes/top" sizesDesktop="[970,250],[728,90]" sizesMobile="[320,100],[320, 50]"
      [metadata]="{ bloco: 'top' }"></app-ad>
  </div>
  <app-header url="../../../assets/images/inscricoes-header.png" [has-blurred-frame]="false" route="/inscricoes">
  </app-header>
  <app-ad *ngIf="!isMobile" path="Inscricoes/especial" [metadata]="{ bloco: 'especial' }" [wallpaper]="true"></app-ad>
  <div class="inscriptions-breadcrumb-container">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="inscriptions-container">
    <div class="container-destaque">
      <app-list-cards *ngIf="highlightInscriptions && programs" id="inscriptions" title="Destaques"
        [content]="highlightInscriptions" [programs]="programs" route="/inscricoes" [is-inscription]="true">
      </app-list-cards>
    </div>

    <section class="inscriptions-ads" *ngIf="isMobile">
      <app-ad path="Inscricoes/between" sizesMobile="[300,250], [320,50]" [metadata]="{ bloco: 'bloco-1' }"></app-ad>
    </section>

    <div class="inscriptions-wrapper" *ngIf="programs">
      <div class="inscriptions-content">
        <div class="inscriptions-title">
          <h2>Todas as Inscrições</h2>
          <i class="fa fa-search" title="Buscar" (click)="toggleSearch()"></i>
        </div>

        <app-list-search *ngIf="!hideSearch" (hide-search)="toggleSearch()" (search)="search($event)"></app-list-search>

        <app-card-list [content]="programs" route="/inscricoes" button-text="Ver Lista" [term-to-filter]="filterTerm"
          [show-genders]="true" [is-inscriptions]="true" [fragment]="fragment"></app-card-list>
      </div>

      <div class="inscriptions-high">
        <section class="inscriptions-ads" *ngIf="!isMobile">
          <app-ad path="Inscricoes/between" sizesDesktop="[300,250], [360,640], [300,600], [336,280]"
            [metadata]="{ bloco: 'bloco-1' }"></app-ad>
        </section>

        <app-stay-inside *ngIf="stayInside && stayInside.length > 0" id="fique-por-dentro" title="Fique por Dentro"
          [stay-inside]="stayInside"></app-stay-inside>

        <section class="inscriptions-ads" *ngIf="!isMobile" (scroll)="onscroll()"
          [ngClass]="bannerfixed?'fixed':'nofixed'">
          <app-ad path="Inscricoes/between" sizesDesktop="[300,250], [360,640], [300,600], [336,280]"
            [metadata]="{ bloco: 'bloco-2' }"></app-ad>
        </section>
      </div>
    </div>

    <section class="inscriptions-ads">
      <app-ad path="Inscricoes/bottom" sizesDesktop="[970,90]" sizesMobile="[320,100]" [metadata]="{ bloco: 'bottom' }">
      </app-ad>
    </section>
  </div>
</section>