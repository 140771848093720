<div class="content-ad">
  <app-ad
  path="Not_Found/top"
  sizesDesktop="[970,250],[728,90]"
  sizesMobile="[320,100],[320, 50]"
  [metadata]="{ bloco: 'top' }"
  ></app-ad>
</div>

<section class="not-found">
  <div class="not-found-container">
  <section class="header">
    <p class="header-title">Ops!</p>
    <p class="header-description">
      Infelizmente não encontramos o conteúdo que você procura...
    </p>

    <a routerLink="/">
      <button class="header-button">
        Ir para a página inicial
      </button>
    </a>

    <p class="header-strike">ou</p>
  </section>
  <section class="content">
    <p class="content-description">
      Confira se a URL está certa e tente de novo. Você também pode acessar
      algum dos seguintes links:
    </p>

    <ul class="content-list">
      <li><a class="content-link" routerLink="/programas">Programas</a></li>
      <li><a class="content-link" routerLink="/programacao">Programação</a></li>
      <li><a class="content-link" routerLink="/jornalismo">Jornalismo</a></li>
      <li><a class="content-link" routerLink="/ao-vivo">SBT Ao Vivo</a></li>
      <li><a class="content-link" routerLink="/sbt-videos">SBT Vídeos</a></li>
      <li><a class="content-link" routerLink="/inscricoes">Inscrições</a></li>
      <li>
        <a class="content-link" routerLink="/promocoes">Promoções</a>
      </li>
      <li>
        <a class="content-link" routerLink="/institucional">Institucional</a>
      </li>
      <li>
        <a class="content-link" routerLink="/mapa-do-site">Mapa do Site</a>
      </li>
    </ul>
  </section>
</div>
  <div class="notFoundHigh">
    <h2>Em Alta</h2>
    <app-lego-list
      *ngIf="notFoundHigh"
      [has-title]="false"
      [list]="notFoundHigh"
      [show-more]="false"
      [full-items]="true"
      size="small"
    ></app-lego-list>
    <app-ad
      [path]="'Not_Found/between'"
      sizesDesktop="[300, 250]"
      sizesMobile="[300, 250], [300, 50]"
      [metadata]="{ bloco: 'bloco-1' }"
    ></app-ad>
  </div>
</section>

<section class="help">
  <div class="help-content">
    <p class="help-description">Precisa de ajuda?</p>
    <app-search placeholder="Digite aqui o que você busca"></app-search>
  </div>
</section>

<app-ad
  path="Not_Found/bottom"
  sizesDesktop="[970,90]"
  sizesMobile=" [320,50], [320,100]"
  [metadata]="{ bloco: 'bottom' }"
></app-ad>

