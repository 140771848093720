/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iframe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./iframe.component";
var styles_IframeComponent = [i0.styles];
var RenderType_IframeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IframeComponent, data: {} });
export { RenderType_IframeComponent as RenderType_IframeComponent };
function View_IframeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner spin"]], null, null, null, null, null))], null, null); }
function View_IframeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["class", "iframe-content"], ["frameborder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
export function View_IframeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h2", [["class", "iframe-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IframeComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IframeComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showSpiner; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.showSpiner; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_IframeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iframe", [], null, null, null, View_IframeComponent_0, RenderType_IframeComponent)), i1.ɵdid(1, 114688, null, 0, i3.IframeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IframeComponentNgFactory = i1.ɵccf("app-iframe", i3.IframeComponent, View_IframeComponent_Host_0, { title: "title", content: "content" }, {}, []);
export { IframeComponentNgFactory as IframeComponentNgFactory };
