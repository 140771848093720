<div #menu class="quick-access-menu" [ngClass]="{ open: open, visible: visible }">
  <div class="quick-access-menu-button" (click)="openQuickAccessMenu()">
    <span class="quick-access-menu-text">Acesso Rápido</span>   
    <i class="fa fa-ellipsis-v"></i>
  </div>
  <div class="quick-access-menu-body">
    <div class="quick-access-menu-body-header">
      <span>Acesso rápido</span>
    </div>

    <div class="quick-access-menu-body-content">
      <ul>
        <li *ngFor="let element of elements" (click)="scrollTo(element)">
          <div [ngClass]="{ active: element.id === activeSection }" *ngIf="element.showElement">
           <div class="ball"></div>
            <span class="text" [innerText]="formatId(element.title)" [attr.data-analytics]="'menu-acesso-rapido-' + element.title.toString().toLowerCase()"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>