<ol vocab="https://schema.org/" typeof="BreadcrumbList">
  <li property="itemListElement" typeof="ListItem">
    <a href="/" property="item" title="Home" typeof="WebPage">
      <span property="name">Home</span>
    </a>
  </li>
  <li property="itemListElement" typeof="ListItem" *ngFor="let item of items">
    <a href="{{item.route}}" property="item" title="{{item.title}}" typeof="WebPage" *ngIf="(item.route !== '')">
      <span property="name">{{item.title}}</span>
    </a>
    <span property="name" *ngIf="(item.route === '')">{{item.title}}</span>
  </li>
</ol>