import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SignalSearchService {
    constructor(_http) {
        this._http = _http;
    }
    sendInfo(body) {
        return this._http
            .post(`${URL}/api/signal-search`, body)
            .toPromise()
            .then(response => {
            console.log(response);
            return response;
        })
            .catch(error => {
            console.log(error);
            return error;
        });
    }
}
SignalSearchService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SignalSearchService_Factory() { return new SignalSearchService(i0.ɵɵinject(i1.HttpClient)); }, token: SignalSearchService, providedIn: "root" });
