/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./list-header/list-header.component.ngfactory";
import * as i3 from "./list-header/list-header.component";
import * as i4 from "./list-search/list-search.component.ngfactory";
import * as i5 from "./list-search/list-search.component";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "../../pipes/safe-html.pipe";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../pages/program/program.service";
import * as i11 from "@angular/common/http";
import * as i12 from "./list.component";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-header", [], null, [[null, "toggle-search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle-search" === en)) {
        var pd_0 = (_co.toggleSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ListHeaderComponent_0, i2.RenderType_ListHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.ListHeaderComponent, [], { title: [0, "title"], hasSearch: [1, "hasSearch"] }, { emitToggleSearch: "toggle-search" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.hasSearch; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-search", [], null, [[null, "hide-search"], [null, "search"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hide-search" === en)) {
        var pd_0 = (_co.toggleSearch() !== false);
        ad = (pd_0 && ad);
    } if (("search" === en)) {
        var pd_1 = (_co.search($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ListSearchComponent_0, i4.RenderType_ListSearchComponent)), i1.ɵdid(1, 245760, null, 0, i5.ListSearchComponent, [], null, { emitSearch: "search", emitHideSearch: "hide-search" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "frame"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "frame-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentContent(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h2", [["class", "frame-header-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "frame-header-arrow"], ["title", "Expandir"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-chevron-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "frame-content"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "is-card": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "frame-content-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "frame-content-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(14, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "frame"; var currVal_1 = _ck(_v, 2, 0, (_co.currentContent === _v.context.index)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = "frame-content"; var currVal_4 = _ck(_v, 9, 0, _co.accordionWithCard); _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); var currVal_5 = _v.context.$implicit.thumbnail; _ck(_v, 11, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.description)); _ck(_v, 13, 0, currVal_6); }); }
function View_ListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "frames-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_4)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "frame-subheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.description.slice(0, 250) + "..."); _ck(_v, 1, 0, currVal_0); }); }
function View_ListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "frame"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "frame-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h2", [["class", "frame-header-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Inscreva-se"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_7)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "frame"; var currVal_1 = _ck(_v, 2, 0, (_co.currentContent === _v.context.index)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = _co.sendToInscriptions(_v.context.$implicit); _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.hasSubtitle; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); }); }
function View_ListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "frames-list"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "has-subtitle": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_6)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "frames-list"; var currVal_1 = _ck(_v, 2, 0, _co.hasSubtitle); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.contentList; _ck(_v, 4, 0, currVal_2); }, null); }
function View_ListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "search-results-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" N\u00E3o foram encontrados resultados para "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\"", "\""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["class", "search-results-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confira se digitou o termo corretamente"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tente usar termos mais simples"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterTerm; _ck(_v, 4, 0, currVal_0); }); }
function View_ListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "frames-load-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadMoreContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" Carregar Mais ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.SafeHtmlPipe, [i9.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "frames"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_3)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_5)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_8)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_9)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.hideSearch; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isAccordion; _ck(_v, 7, 0, currVal_2); var currVal_3 = (!_co.isAccordion && _co.hasAction); _ck(_v, 9, 0, currVal_3); var currVal_4 = ((((_co.contentList == null) ? null : _co.contentList.length) === 0) && (_co.filterTerm !== "")); _ck(_v, 11, 0, currVal_4); var currVal_5 = ((_co.content && (_co.content.length > 0)) && _co.hasMore); _ck(_v, 13, 0, currVal_5); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵprd(512, null, i10.ProgramService, i10.ProgramService, [i11.HttpClient, i9.DomSanitizer]), i1.ɵdid(2, 770048, null, 0, i12.ListComponent, [i10.ProgramService, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i12.ListComponent, View_ListComponent_Host_0, { hasSearch: "has-search", isAccordion: "is-accordion", hasSubtitle: "has-subtitle", hasAction: "has-action", accordionWithCard: "accordion-with-card", title: "title", content: "content", endpoint: "endpoint", idProgram: "program-id", programSlug: "program-slug" }, {}, []);
export { ListComponentNgFactory as ListComponentNgFactory };
