/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./programs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ad/ad.component.ngfactory";
import * as i3 from "../../shared/ad/ad.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/station.service";
import * as i6 from "../../shared/card-list/card-list.component.ngfactory";
import * as i7 from "../../shared/card-list/card-list.component";
import * as i8 from "ngx-smart-modal";
import * as i9 from "../../services/title.service";
import * as i10 from "@angular/common";
import * as i11 from "../../shared/header/header.component.ngfactory";
import * as i12 from "../../shared/header/header.component";
import * as i13 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i14 from "../../shared/breadcrumb/breadcrumb.component";
import * as i15 from "./programs.service";
import * as i16 from "@angular/common/http";
import * as i17 from "../../services/canonical.service";
import * as i18 from "./programs.component";
import * as i19 from "@angular/platform-browser";
import * as i20 from "../../services/metatags.service";
var styles_ProgramsComponent = [i0.styles];
var RenderType_ProgramsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramsComponent, data: {} });
export { RenderType_ProgramsComponent as RenderType_ProgramsComponent };
function View_ProgramsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ad", [["path", "Programas/especial"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], wallpaper: [1, "wallpaper"], metadata: [2, "metadata"] }, null), i1.ɵpod(2, { bloco: 0 })], function (_ck, _v) { var currVal_0 = "Programas/especial"; var currVal_1 = true; var currVal_2 = _ck(_v, 2, 0, "especial"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProgramsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-list", [], null, null, null, i6.View_CardListComponent_0, i6.RenderType_CardListComponent)), i1.ɵdid(1, 4833280, null, 0, i7.CardListComponent, [i8.NgxSmartModalService, i5.StationService, i9.TitleService, i10.Location, i4.Router], { contents: [0, "contents"], showGenders: [1, "showGenders"], returnGenders: [2, "returnGenders"], itemsPerRow: [3, "itemsPerRow"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.programs; var currVal_1 = true; var currVal_2 = true; var currVal_3 = _co.itemsPerRow; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ProgramsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { adsElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "content-ad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-ad", [["path", "Programas/top"], ["sizesDesktop", "[970,250],[728,90]"], ["sizesMobile", "[320,100],[320, 50]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(3, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(4, { bloco: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-header", [["url", "/assets/images/programas-header.png"]], null, null, null, i11.View_HeaderComponent_0, i11.RenderType_HeaderComponent)), i1.ɵdid(6, 114688, null, 0, i12.HeaderComponent, [i4.Router], { url: [0, "url"], hasBlurredFrame: [1, "hasBlurredFrame"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramsComponent_1)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "programs-breadcrumb-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i13.View_BreadcrumbComponent_0, i13.RenderType_BreadcrumbComponent)), i1.ɵdid(11, 114688, null, 0, i14.BreadcrumbComponent, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 7, "section", [["class", "programs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "programs-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [[1, 0], ["adsElement", 1]], null, 2, "div", [["class", "programs-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramsComponent_2)), i1.ɵdid(16, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "app-ad", [["path", "Programas/bottom"], ["sizesDesktop", "[970,90]"], ["sizesMobile", " [320,50], [320,100]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdComponent_0, i2.RenderType_AdComponent)), i1.ɵdid(18, 4308992, null, 0, i3.AdComponent, [i4.Router, i5.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(19, { bloco: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Programas/top"; var currVal_1 = "[970,250],[728,90]"; var currVal_2 = "[320,100],[320, 50]"; var currVal_3 = _ck(_v, 4, 0, "top"); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "/assets/images/programas-header.png"; var currVal_5 = false; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = !_co.isMobile; _ck(_v, 8, 0, currVal_6); _ck(_v, 11, 0); var currVal_7 = _co.programs; _ck(_v, 16, 0, currVal_7); var currVal_8 = "Programas/bottom"; var currVal_9 = "[970,90]"; var currVal_10 = " [320,50], [320,100]"; var currVal_11 = _ck(_v, 19, 0, "bottom"); _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_ProgramsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-programs", [], null, null, null, View_ProgramsComponent_0, RenderType_ProgramsComponent)), i1.ɵprd(512, null, i15.ProgramsService, i15.ProgramsService, [i16.HttpClient, i5.StationService]), i1.ɵprd(512, null, i17.CanonicalService, i17.CanonicalService, [i10.DOCUMENT]), i1.ɵdid(3, 245760, null, 0, i18.ProgramsComponent, [i4.Router, i15.ProgramsService, i9.TitleService, i19.Meta, i20.MetatagsService, i17.CanonicalService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ProgramsComponentNgFactory = i1.ɵccf("app-programs", i18.ProgramsComponent, View_ProgramsComponent_Host_0, {}, {}, []);
export { ProgramsComponentNgFactory as ProgramsComponentNgFactory };
