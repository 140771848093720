/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lego-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./lego-text.component";
var styles_LegoTextComponent = [i0.styles];
var RenderType_LegoTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegoTextComponent, data: {} });
export { RenderType_LegoTextComponent as RenderType_LegoTextComponent };
function View_LegoTextComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "card-title"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 0, 0, currVal_0); }); }
function View_LegoTextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "a", [["class", "card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "card-container"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.content[_v.context.index].opcional5, _co.getRouterLink(_co.content[_v.context.index]), _co.parameters[_v.context.index], _co.fragment[_v.context.index]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.handleExternalLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "card-bold-title"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(5, 278528, null, 0, i3.NgStyle, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { color: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoTextComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "card-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "card-date"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "button", [["class", "card-button"]], null, [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.content[_v.context.index].opcional5, _co.getRouterLink(_co.content[_v.context.index]), _co.parameters[_v.context.index], _co.fragment[_v.context.index]) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.handleExternalLink($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 278528, null, 0, i3.NgStyle, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(16, { background: 0 }), (_l()(), i1.ɵted(-1, null, [" Confira "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fa fa-arrow-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.parameters[_v.context.index]; var currVal_3 = _co.fragment[_v.context.index]; var currVal_4 = _co.getRouterLink(_v.context.$implicit, _v.context.index); _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = "card-bold-title"; var currVal_7 = _co.color; _ck(_v, 4, 0, currVal_6, currVal_7); var currVal_8 = _ck(_v, 6, 0, (("#" + _co.color) || "")); _ck(_v, 5, 0, currVal_8); var currVal_9 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_9); var currVal_13 = _co.parameters[_v.context.index]; var currVal_14 = _co.fragment[_v.context.index]; var currVal_15 = _co.getRouterLink(_v.context.$implicit, _v.context.index); _ck(_v, 13, 0, currVal_13, currVal_14, currVal_15); var currVal_16 = _ck(_v, 16, 0, (("#" + _co.color) || "")); _ck(_v, 15, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_5); var currVal_10 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.startdate, "dd/MM/yyyy '\u00E0s' HH:mm")); _ck(_v, 10, 0, currVal_10); var currVal_11 = i1.ɵnov(_v, 13).target; var currVal_12 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_11, currVal_12); }); }
export function View_LegoTextComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegoTextComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LegoTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lego-text", [], null, null, null, View_LegoTextComponent_0, RenderType_LegoTextComponent)), i1.ɵdid(1, 114688, null, 0, i4.LegoTextComponent, [i2.Router, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegoTextComponentNgFactory = i1.ɵccf("app-lego-text", i4.LegoTextComponent, View_LegoTextComponent_Host_0, { content: "content", color: "color" }, {}, []);
export { LegoTextComponentNgFactory as LegoTextComponentNgFactory };
