/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sbt-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sbt-videos.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../services/station.service";
import * as i5 from "./sbt-videos.component";
import * as i6 from "../../services/title.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../services/metatags.service";
import * as i9 from "../../services/branding.service";
var styles_SbtVideosComponent = [i0.styles];
var RenderType_SbtVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SbtVideosComponent, data: {} });
export { RenderType_SbtVideosComponent as RenderType_SbtVideosComponent };
export function View_SbtVideosComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_SbtVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-sbt-videos", [], null, null, null, View_SbtVideosComponent_0, RenderType_SbtVideosComponent)), i1.ɵprd(512, null, i2.SbtVideosService, i2.SbtVideosService, [i3.HttpClient, i4.StationService]), i1.ɵdid(2, 245760, null, 0, i5.SbtVideosComponent, [i6.TitleService, i2.SbtVideosService, i7.Meta, i8.MetatagsService, i9.BrandingService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SbtVideosComponentNgFactory = i1.ɵccf("app-sbt-videos", i5.SbtVideosComponent, View_SbtVideosComponent_Host_0, {}, {}, []);
export { SbtVideosComponentNgFactory as SbtVideosComponentNgFactory };
