<div class="menu-images">
  <h1 *ngIf="showH1Header;else semH1" class="menu-logo">
    <a [routerLink]="homeLink">
      <img
        [routerLink]="homeLink"
        src="/assets/images/logo-sbt.webp"
        alt="SBT"
        title="Ir para a Página Inicial"
        loading="lazy"
        width="40" 
        height="40"
      
      />
    </a>
  </h1>

  <ng-template #semH1>
    <a [routerLink]="homeLink" class="menu-logo">
      <img
        [routerLink]="homeLink"
        src="/assets/images/logo-sbt.webp"
        alt="SBT"
        title="Ir para a Página Inicial"
        loading="lazy"
        width="40" 
        height="40"
       
      />
    </a>
  </ng-template>
  <div class="menu-live"><a [routerLink]="liveLink">ao vivo</a></div>
</div>
