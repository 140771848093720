<div class="mediabox" [ngClass]="extraClasses">
  <div class="mediabox-box">
    <div class="mediabox-media">
      <div  *ngIf="medias.length > 1" class="mediabox-media-previous left" (click)="prev()">
        <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
      </div>
      <div *ngIf="medias" class="mediabox-media-images">
        <ng-container *ngFor="let media of medias; let i = index">
          <a
            *ngIf="(media.opcional5 === 'B')"
            class="media-image"
            [ngClass]="{ active: i === currentIndex, 'has-link': media.author }"
            (touchstart)="swipe($event, 'start')"
            (touchend)="swipe($event, 'end')"
            [href]="[media.author]"
            [target]="'_blank'"
            [attr.data-analytics]="'masthead_' + type"
            [attr.data-analytics-label]="(i + 1) + ' - ' + media.author"
            [title]="[media.opcional8]"
            rel= "noopener"
          >
            <img
              onerror="this.src='../../../assets/images/default-mediabox.png'"
              [src]="media.thumbnail"
              loading="lazy"
              [alt]="media.opcional8"
            />
          </a>

          <a
            *ngIf="(media.opcional5 !== 'B')"
            class="media-image"
            [ngClass]="{ active: i === currentIndex, 'has-link': media.author }"
            (touchstart)="swipe($event, 'start')"
            (touchend)="swipe($event, 'end')"
            [routerLink]="media.author.split('#')[0] || media.author "
            [fragment]="media.author.split('#')[1] || null"
            [attr.data-analytics]="'masthead_' + type"
            [attr.data-analytics-label]="(i + 1) + ' - ' + media.author"
            [title]="[media.opcional8]"
          >
            <img
              onerror="this.src='../../../assets/images/default-mediabox.png'"
              [src]="media.thumbnail"
              [alt]="media.opcional8"
              loading="lazy"
            />
          </a>
        </ng-container>

      </div>
      <div  *ngIf="medias.length > 1"class="mediabox-media-next right" (click)="next()">
        <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
      
      </div>
    </div>
    <div
      *ngIf="medias && medias[currentIndex] && medias[currentIndex].title !== ''"
      (click)="navigateTo()"
      class="mediabox-content"
    >
      <h6 class="mediabox-title" [innerHtml]="medias[currentIndex].title"></h6>
      <span
        class="mediabox-description"
        [innerHtml]="medias[currentIndex].description"
      ></span>
    </div>
  </div>
</div>
<div *ngIf="medias.length > 1" class="mediabox-dots">
  <div
    class="mediabox-dot"
    *ngFor="let media of medias; let i = index"
    (click)="showImage(i)"
    [ngClass]="{ active: i === currentIndex }"
  ></div>
</div>
