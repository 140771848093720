import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SbtVideosProgramService {
    constructor(_http) {
        this._http = _http;
    }
    getProgramBySlug(slug) {
        return this._http
            .get(`${URL}/api/programs?slug=${slug}`)
            .toPromise()
            .then((response) => response.results[0]);
    }
    getVideosByProgram(idprogram = null, playlist = null, pagination) {
        if (playlist) {
            return this._http
                .get(`${URL}/api/videosyoutube?program=${idprogram}&category=${playlist}&limit=20&${pagination && pagination.hasMore ? `&next=${pagination.key}` : ``}`)
                .toPromise()
                .then((response) => {
                const videos = response;
                const { results, next } = videos;
                results.forEach(video => {
                    video.link = video.id;
                });
                return {
                    results: [...results],
                    next
                };
            });
        }
        else {
            return this._http
                .get(`${URL}/api/videosyoutube?program=${idprogram}&limit=20&${pagination && pagination.hasMore ? `&next=${pagination.key}` : ``}`)
                .toPromise()
                .then((response) => {
                const videos = response;
                const { results, next } = videos;
                results.forEach(video => {
                    video.link = video.id;
                });
                return {
                    results: [...results],
                    next
                };
            });
        }
    }
    getPlaylists(idprogram) {
        return this._http
            .get(`${URL}/api/medias?idsite=211&idsitearea=1068&descriptionplaylist=${idprogram}&orderby=ordem&sort=asc`)
            .toPromise()
            .then((response) => {
            let playlists = response.results;
            playlists.forEach(playlist => {
                playlist.link = playlist.opcional1 === '0' ? '' : playlist.opcional1;
            });
            return playlists;
        });
    }
}
SbtVideosProgramService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SbtVideosProgramService_Factory() { return new SbtVideosProgramService(i0.ɵɵinject(i1.HttpClient)); }, token: SbtVideosProgramService, providedIn: "root" });
