/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blockquote.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./blockquote.component";
var styles_BlockquoteComponent = [i0.styles];
var RenderType_BlockquoteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlockquoteComponent, data: {} });
export { RenderType_BlockquoteComponent as RenderType_BlockquoteComponent };
export function View_BlockquoteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isBig ? "is-big" : ""); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
export function View_BlockquoteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blockquote", [], null, null, null, View_BlockquoteComponent_0, RenderType_BlockquoteComponent)), i1.ɵdid(1, 49152, null, 0, i3.BlockquoteComponent, [], null, null)], null, null); }
var BlockquoteComponentNgFactory = i1.ɵccf("app-blockquote", i3.BlockquoteComponent, View_BlockquoteComponent_Host_0, { content: "content", isBig: "is-big" }, {}, []);
export { BlockquoteComponentNgFactory as BlockquoteComponentNgFactory };
