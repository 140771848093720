var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { URL } from '../../app.settings';
import { StationService } from '../../services/station.service';
import Program from '../../models/program.class';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/station.service";
export class SbtVideosService {
    constructor(_http, _stationService) {
        this._http = _http;
        this._stationService = _stationService;
    }
    getSbtVideosData(pagination) {
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            let mediabox;
            let videos;
            let programs;
            let highlightPrograms = [];
            yield this.getMediabox().then(mediaboxResponse => (mediabox = mediaboxResponse));
            yield this.getPlaylists().then(videosResponse => (videos = videosResponse));
            yield this.getPrograms(pagination).then(programsResponse => {
                programs = programsResponse;
            });
            videos.forEach(video => {
                this.getProgramById(video[0][0].idprogram).then(program => highlightPrograms.push(program));
            });
            /* await programs.programs.forEach(program => {
              this.getPlaylistsTotal(program.id).then(
                response => (program.playlists = response)
              )
            }) */
            return resolve({
                mediabox,
                videos,
                programs,
                highlightPrograms
            });
        }));
    }
    getProgramById(id) {
        return this._http
            .get(`${URL}/api/programs?id=${id}`)
            .toPromise()
            .then((response) => response.results[0]);
    }
    getPlaylists() {
        return this._http
            .get(`${URL}/api/playlists?idsite=211&idsitearea=1065&limit=100&orderby=startdate&sort=desc`)
            .toPromise()
            .then((response) => {
            return Promise.all(response.results.map(result => this.getMediaProgramsVideos(result.id)));
        });
    }
    getMediaProgramsVideos(playlist) {
        return this._http
            .get(`${URL}/api/medias?idplaylist=${playlist}`)
            .toPromise()
            .then((response) => __awaiter(this, void 0, void 0, function* () {
            return Promise.all(response.results.map(result => this.getVideosByProgram(result.opcional3)));
        }));
    }
    getMediabox() {
        const hasStationCookie = this._stationService.checkStationCookie();
        if (hasStationCookie) {
            const stationIdentifier = this._stationService.getStationCookie();
            const station = this._stationService.getStation(stationIdentifier);
            if (station && station[0]) {
                return this._http
                    .get(`${URL}/api/medias?idsite=211&idsitearea=${station[0].idsiteareamediabox}&limit=4`)
                    .toPromise()
                    .then((response) => {
                    if (response) {
                        return response.results;
                    }
                    return undefined;
                });
            }
        }
        return this._http
            .get(`${URL}/api/medias?idsite=211&idsitearea=1066&limit=10`)
            .toPromise()
            .then((response) => {
            return response.results;
        });
    }
    getPrograms(pagination, titleSearch) {
        return this._http
            .get(`${URL}/api/programs?idregional=0&noar=S&showvideos=S${pagination && pagination.hasMore ? `&next=${pagination.key}` : ``}${titleSearch ? `&titlesearch=${titleSearch}` : '&limit=5'}`)
            .toPromise()
            .then((response) => {
            const programs = response;
            const hasStationCookie = this._stationService.checkStationCookie();
            if (hasStationCookie) {
                const stationIdentifier = this._stationService.getStationCookie();
                const station = this._stationService.getStation(stationIdentifier);
                if (station && station[0] && !pagination) {
                    return this._http
                        .get(`${URL}/api/programs?idregional=${station[0].id}${titleSearch ? `&titlesearch=${titleSearch}` : ''}&limit=20&noar=S&showvideos=S`)
                        .toPromise()
                        .then((regionalResponse) => {
                        const regionalPrograms = regionalResponse.results.map(program => new Program(program));
                        const nationalPrograms = programs.results.map(program => new Program(program));
                        return {
                            programs: [...regionalPrograms, ...nationalPrograms],
                            pagination: programs.next
                        };
                    });
                }
            }
            return {
                programs: programs.results.map(program => new Program(program)),
                pagination: programs.next
            };
        })
            .catch(e => console.log(e));
    }
    getVideosByProgram(idprogram) {
        return this._http
            .get(`${URL}/api/videosyoutube?program=${idprogram}&limit=20`)
            .toPromise()
            .then((response) => {
            const videos = response.results;
            videos.forEach(video => {
                video.link = video.id;
            });
            return videos;
        });
    }
    getPlaylistsTotal(idprogram) {
        return this._http
            .get(`${URL}/api/medias?idsite=211&idsitearea=1068&descriptionplaylist=${idprogram}&orderby=ordem&sort=asc`)
            .toPromise()
            .then((response) => response.results.length);
    }
}
SbtVideosService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SbtVideosService_Factory() { return new SbtVideosService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StationService)); }, token: SbtVideosService, providedIn: "root" });
