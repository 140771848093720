<nav class="menu" #menu [ngClass]="{ logged: userCookie }">
  <app-account-actions *ngIf="!userCookie"></app-account-actions>

  <div class="menu-header">
    <app-menu-header></app-menu-header>

    <div class="menu-icons">
      <span *ngIf="isShowButton" class="app-installer" title="Instalação do App" (click)="insertPwa()">
        <p class="title-pwa">Instale o App</p>
        <i class="icon-download fa fa-download"></i>
      </span>

      <i class="fa fa-sign-out" (click)="logout()" *ngIf="userCookie" data-analytics="logout" title="Sair"></i>
      <i class="fa fa-user-circle-o" routerLink="/meu-sbt/minhas-inscricoes" *ngIf="userCookie" data-analytics="meu-sbt"
        title="Meu SBT"></i>

      <span class="menu-icon-search" [ngClass]="{ 'search-open': search }" title="Busca">
        <i class="fa" [ngClass]="search ? 'fa-close' : 'fa-search'" (click)="openSearch()" data-analytics="busca"></i>
        <span *ngIf="search || iconOpenWithoutContent" class="triangle"></span>
      </span>
      <span class="menu-icon-bars" title="Menu">
        <i class="fa" [ngClass]="open ? 'fa-close' : 'fa-bars'" (click)="openMenu()" data-analytics="menu"></i>
      </span>
    </div>
  </div>

  <div *ngIf="search" class="menu-search">
    <app-search (hide-search)="hideSearch($event)"></app-search>
  </div>

  <app-station *ngIf="showStationTab"></app-station>

  <div class="menu-content" [ngClass]="{ opened: open, closed: !open }" (click)="clickedOutside($event)">
    <div class="menu-items" #menuItems>
      <div class="menu-items-wrapper" #menuContainer>
        <div>
          <ul class="menu-items-list">
            <li *ngFor="let header of headers; let i = index" class="list-item"
              [ngClass]="{ active: currentHeader === i }">
              <div class="list-item-information">
                <a *ngIf="getInternalUrl(header['link']) && station && header['externalLink'] === 'N'" [routerLink]="header['title'] === 'Jornalismo' || 
                  header['title'] === 'SBT Sports' || 
                  header['title'] === 'Podcasts' ||
                  header['title'] === 'SBT na Web' ||
                  header['title'] === 'SBT do Bem'
                  ? getInternalUrl(header['link'])
                  : station + getInternalUrl(header['link'])" [innerText]="header['title']">
                </a>

                <a *ngIf="getInternalUrl(header['link']) && (!station) && header['externalLink'] === 'N'"
                  [routerLink]="[getInternalUrl(header['link'])]" [innerText]="header['title']">
                </a>

                <span class="item-badge" *ngIf="header['externalLink'] === 'S'">
                  <i class="fa fa-external-link" title="Conteúdo externo"></i>
                </span>

                <a *ngIf="header['externalLink'] === 'S'" [href]="header['link']" target="_blank" class="external-link"
                  [innerText]="header['title']" rel="nofollow noopener preconnect">
                </a>
              </div>
            </li>

            <li class="list-item" [ngClass]="{ active: currentHeader === 11}">
              <div class="list-item-information" (click)="openSubMenu($event, 11)">
                <a>EMISSORAS</a>
                <i class="fa fa-chevron-down" title="Expandir"></i>
              </div>
              <ul class="list-item-sublist">
                <li>
                  <div (click)="changeStation('default')">
                    <p>TV SBT SP (Nacional)</p>
                  </div>
                </li>
                <li [ngClass]="{ active: currentSubmenu === 0 }">
                  <div (click)="openSubMenuChildren($event, 0)">
                    <p>Norte</p>
                    <i class="fa fa-chevron-down" title="Expandir"></i>
                  </div>
                  <ul class="menu-items-subitem"
                    *ngIf="emissoras && emissoras['emissorasNorte'] && emissoras['emissorasNorte']['length']">
                    <li *ngFor="let emissora of emissoras['emissorasNorte']; let i = index">
                      <span class="item-badge" *ngIf="emissora['externalLink'] === 'S'">
                        <i class="fa fa-external-link" title="Conteúdo externo"></i>
                      </span>
                      <a *ngIf="emissora['externalLink'] === 'N'" [innerText]="emissora['title']"
                        [routerLink]="getInternalUrl(emissora['link'])" class="negrito"></a>
                      <a *ngIf="emissora['externalLink'] === 'S'" [innerText]="emissora['title']"
                        [href]="emissora['link']" target="_blank"></a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{ active: currentSubmenu === 1 }">
                  <div (click)="openSubMenuChildren($event, 1)">
                    <p>Nordeste</p>
                    <i class="fa fa-chevron-down" title="Expandir"></i>
                  </div>
                  <ul class="menu-items-subitem"
                    *ngIf="emissoras && emissoras['emissorasNordeste'] && emissoras['emissorasNordeste']['length'] > 0">
                    <li *ngFor="let emissora of emissoras['emissorasNordeste']; let i = index">
                      <span class="item-badge" *ngIf="emissora['externalLink'] === 'S'">
                        <i class="fa fa-external-link" title="Conteúdo externo"></i>
                      </span>

                      <a *ngIf="emissora['externalLink'] === 'N'" [innerText]="emissora['title']"
                        [routerLink]="getInternalUrl(emissora['link'])" class="negrito"></a>
                      <a *ngIf="emissora['externalLink'] === 'S'" [innerText]="emissora['title']"
                        [href]="emissora['link']" target="_blank"></a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{ active: currentSubmenu === 2 }">
                  <div (click)="openSubMenuChildren($event, 2)">
                    <p>Centro-Oeste</p>
                    <i class="fa fa-chevron-down" title="Expandir"></i>
                  </div>
                  <ul class="menu-items-subitem"
                    *ngIf="emissoras && emissoras['emissorasCentroOeste'] && emissoras['emissorasCentroOeste']['length'] > 0">
                    <li *ngFor="let emissora of emissoras['emissorasCentroOeste']; let i = index">
                      <span class="item-badge" *ngIf="emissora['externalLink'] === 'S'">
                        <i class="fa fa-external-link" title="Conteúdo externo"></i>
                      </span>

                      <a *ngIf="emissora['externalLink'] === 'N'" [innerText]="emissora['title']"
                        [routerLink]="getInternalUrl(emissora['link'])" class="negrito"></a>
                      <a *ngIf="emissora['externalLink'] === 'S'" [innerText]="emissora['title']"
                        [href]="emissora['link']" target="_blank"></a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{ active: currentSubmenu === 3 }">
                  <div (click)="openSubMenuChildren($event, 3)">
                    <p>Sudeste</p>
                    <i class="fa fa-chevron-down" title="Expandir"></i>
                  </div>
                  <ul class="menu-items-subitem"
                    *ngIf="emissoras && emissoras['emissorasSudeste'] && emissoras['emissorasSudeste']['length'] > 0">
                    <li *ngFor="let emissora of emissoras['emissorasSudeste']; let i = index">
                      <span class="item-badge" *ngIf="emissora['externalLink'] === 'S'">
                        <i class="fa fa-external-link" title="Conteúdo externo"></i>
                      </span>

                      <a *ngIf="emissora['externalLink'] === 'N'" [innerText]="emissora['title']"
                        [routerLink]="getInternalUrl(emissora['link'])" class="negrito"></a>
                      <a *ngIf="emissora['externalLink'] === 'S'" [innerText]="emissora['title']"
                        [href]="emissora['link']" target="_blank"></a>
                    </li>
                  </ul>
                </li>
                <li [ngClass]="{ active: currentSubmenu === 4 }">
                  <div (click)="openSubMenuChildren($event, 4)">
                    <p>Sul</p>
                    <i class="fa fa-chevron-down" title="Expandir"></i>
                  </div>
                  <ul class="menu-items-subitem"
                    *ngIf="emissoras && emissoras['emissorasSul'] && emissoras['emissorasSul']['length'] > 0">
                    <li *ngFor="let emissora of emissoras['emissorasSul']; let i = index">
                      <span class="item-badge" *ngIf="emissora['externalLink'] === 'S'">
                        <i class="fa fa-external-link" title="Conteúdo externo"></i>
                      </span>

                      <a *ngIf="emissora['externalLink'] === 'N'" [innerText]="emissora['title']"
                        [routerLink]="getInternalUrl(emissora['link'])" class="negrito"></a>
                      <a *ngIf="emissora['externalLink'] === 'S'" [innerText]="emissora['title']"
                        [href]="emissora['link']" target="_blank"></a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <app-menu-footer [footers]="footers" [socialNetworks]="defaultSocialNetworks"
            [apps]="defaultApps"></app-menu-footer>
        </div>
      </div>
    </div>
  </div>
</nav>