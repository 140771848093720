import { COMSCORE_PUBLISHER_ID } from '../app.settings';
import { Log } from '../utils/util.console';
import * as i0 from "@angular/core";
export class ComscoreService {
    constructor() {
        this.componentName = 'COMSCORE';
        this.previousURL = '';
    }
    init(url) {
        if (!document.getElementById("comscore-sdk")) {
            var s = document.createElement("script"), el = document.getElementsByTagName("script")[0];
            s.async = true;
            s.id = "comscore-sdk";
            s.src =
                (document.location.protocol == "https:" ? "https://sb" : "http://b") +
                    ".scorecardresearch.com/beacon.js";
            el.parentNode.insertBefore(s, el);
            Log(this.componentName, `Setup`, true);
        }
        else {
            this.sendPageView(url);
        }
    }
    sendPageView(url) {
        if (this.previousURL != url) {
            setTimeout(() => {
                const COMSCORE = window.COMSCORE;
                if (COMSCORE) {
                    fetch(`/candidate.txt?${new Date().getTime()}`).then(() => {
                        COMSCORE.beacon({ c1: "2", c2: COMSCORE_PUBLISHER_ID });
                        Log(this.componentName, `Send Pageview: ${url}`, true);
                    });
                }
            }, 2000);
        }
        this.previousURL = url;
    }
}
ComscoreService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ComscoreService_Factory() { return new ComscoreService(); }, token: ComscoreService, providedIn: "root" });
