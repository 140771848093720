/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-youtube-player/ngx-youtube-player.ngfactory";
import * as i4 from "ngx-youtube-player";
import * as i5 from "./video.component";
import * as i6 from "@angular/platform-browser";
var styles_VideoComponent = [i0.styles];
var RenderType_VideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoComponent, data: {} });
export { RenderType_VideoComponent as RenderType_VideoComponent };
function View_VideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "video-mask"], ["title", "Fixar v\u00EDdeo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFixVideo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "video-mask-fixed": 0, "video": 1, "video-fixed": 2 }), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "video-mask"; var currVal_1 = _ck(_v, 2, 0, _co.isFixed, _co.isFixed, _co.isFixed); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isFixed ? "Desfixar Video do topo" : "Fixar Video No Topo"); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "fa fa-external-link ", (_co.isFixed ? "fa-times-circle" : "fa-thumb-tack"), ""); _ck(_v, 4, 0, currVal_3); }); }
function View_VideoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "video-mask"], ["title", "Fixar v\u00EDdeo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFixVideo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "video-mask-fixed": 0, "video": 1, "video-fixed": 2 }), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "video-mask"; var currVal_1 = _ck(_v, 2, 0, _co.isFixed, _co.isFixed, _co.isFixed); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isFixed ? "Desfixar Video do topo" : "Fixar Video No Topo"); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "fa fa-external-link ", (_co.isFixed ? "fa-times-circle" : "fa-thumb-tack"), ""); _ck(_v, 4, 0, currVal_3); }); }
export function View_VideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "video-full": 0, "video": 1, "video-fixed": 2, "full-width": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "youtube-player", [], null, [[null, "ready"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ready" === en)) {
        var pd_0 = (_co.savePlayer($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onStateChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ɵa_0, i3.RenderType_ɵa)), i1.ɵdid(4, 1097728, null, 0, i4.ɵa, [i4.ɵb, i1.ElementRef, i1.Renderer2], { videoId: [0, "videoId"], playerVars: [1, "playerVars"] }, { ready: "ready", change: "change" }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "btn-mobile"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "DB": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "btn-desktop"]], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "DN": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isFull, _co.isFixed, (_co.isFixed && _co.fixable), _co.fullWidth); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.videoId; var currVal_2 = _co.params; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = "btn-mobile"; var currVal_4 = _ck(_v, 7, 0, _co.isFixed); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.fixable; _ck(_v, 9, 0, currVal_5); var currVal_6 = "btn-desktop"; var currVal_7 = _ck(_v, 12, 0, _co.isFixed); _ck(_v, 11, 0, currVal_6, currVal_7); var currVal_8 = _co.fixable; _ck(_v, 14, 0, currVal_8); }, null); }
export function View_VideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video", [], null, null, null, View_VideoComponent_0, RenderType_VideoComponent)), i1.ɵdid(1, 638976, null, 0, i5.VideoComponent, [i1.ElementRef, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoComponentNgFactory = i1.ɵccf("app-video", i5.VideoComponent, View_VideoComponent_Host_0, { url: "url", adunit: "adunit", fixable: "fixable", isFixed: "is-fixed", isFull: "is-full", pauseVideo: "pause-video", fullWidth: "full-width", programTitle: "program-title", programId: "program-id", isLive: "is-live" }, { fixVideo: "fixVideo" }, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
