<div class="gallery" [ngClass]="{ reduced: reduced}" *ngIf="images?.length > 0; else semFoto">
  <div class="gallery-current-image" *ngIf="currentImage">
    <img onerror="this.src='../../../assets/images/default-image.png'" [src]="currentImage.image" alt="">

    <div class="gallery-details">
      <span *ngIf="currentImage.title" class="gallery-title" [innerText]="currentImage.title"></span>
      <span *ngIf="currentImage.author" class="gallery-author" [innerText]="currentImage.author"></span>
    </div>
  </div>

  <div class="gallery-panel" *ngIf="images">
    <div *ngFor="let list of images">
      <ul class="gallery-list" *ngFor="let imageList of list" [ngClass]="{'active': list.isActive}">
        <li class="gallery-item" *ngFor="let image of imageList.images; let i = index;" [ngClass]="{'active': i == 0}"
          (click)="changeCurrentImage($event, image)">
          <img  onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="image.thumbnail" alt="" class="gallery-list-img">
        </li>
      </ul>
    </div>

    <div class="gallery-controls" *ngIf="images.length > 1">
      <a class="gallery-prev" (click)="prevGridSlider()">&#10094;</a>
      <div class="gallery-dots">
        <div class="dot active" *ngFor="let list of images; let i = index" [ngClass]="{'active': list.isActive}"
          (click)="callGridSlider(i)"></div>
      </div>
      <a class="gallery-next" (click)="nextGridSlider()">&#10095;</a>
    </div>
  </div>
</div>
<ng-template #semFoto>
  <div class="without-results">
    <h2>OPS!</h2>
  <span>Infelizmente ainda não temos fotos cadastradas. =/</span>
  </div>
</ng-template>
