<div class="share">
    <button class="share-facebook" shareButton="facebook">        
        <i class="fa fa-facebook-square" title="Facebook"></i>Compartilhar
    </button>
    <button class="share-whatsapp" shareButton="whatsapp">        
        <i class="fa fa-whatsapp" title="Whatsapp"></i>Compartilhar
    </button>
    <button class="share-twitter" shareButton="twitter">        
        <i class="fa fa-twitter" title="Twitter"></i>Compartilhar
    </button>
    <button class="share-link" *ngIf="!showMore" (click)="showButtons()">        
        <i class="fa fa-ellipsis-h"></i>Mais
    </button>
    <span *ngIf="showMore" class="share-show-more">
        <button class="share-pinterest" shareButton="pinterest">
            <i class="fa fa-pinterest" title="Pinterest"></i>Compartilhar
        </button>

        <button class="share-google" shareButton="google">
            <i class="fa fa-google-plus" title="Google+"></i>Compartilhar
        </button>

        <button class="share-link" *ngIf="showMore" (click)="hideButtons()">            
            <i class="fa fa-ellipsis-h"></i>Menos
        </button>
    </span>
</div>
