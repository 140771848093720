<section class="signal-search">
  <app-header url="../../../assets/images/pesquisa-de-sinal.png" [has-blurred-frame]="false"></app-header>
  <div class="signal-search-breadcrumb-container">
    <app-breadcrumb></app-breadcrumb>
  </div>

  <section class="signal-search-container">
    <section class="header">
      <div class="logo">
        <img src="../../../assets/images/logo-sbt.png" alt="" />
      </div>
      <div class="content">
        <h1>Como está o sinal do SBT aí na sua casa?</h1>
        <p>Preencha o formulário e conte pra gente</p>
      </div>
    </section>

    <section class="forms">
      <aw-wizard [navBarLayout]="'large-filled-symbols'">
        <aw-wizard-step [stepId]="1" stepTitle="Dados Pessoais" [navigationSymbol]="{ symbol: '1' }" awSelectedStep>
          <app-error *ngIf="registerError" [error]="registerError"></app-error>
          <h2 class="data-content-title">1. Dados Pessoais</h2>
          <p class="data-hint">Campo obrigatório</p>
          <form class="data-form" [formGroup]="personalData" #form>
            <app-form-input [type]="'input'" [parent-form]="personalData" name="name" label="Nome"
              placeholder="Digite o seu nome" [inputPattern]="pureTextPattern" [required]="true" class="full-width">
            </app-form-input>

            <app-form-input [type]="'select'" [parent-form]="personalData" name="sex" label="Sexo"
              [options]="sexOptions" [required]="true" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="personalData" name="birthday" label="Data de Nascimento"
              placeholder="00/00/0000" inputMask="00/00/0000" [required]="true" class="reduced"></app-form-input>

            <app-error *ngIf="isBirthDayValid()" error="É proibido o cadastro de menores de 18 anos."></app-error>

            <app-error *ngIf="isDateValid()" error="Insira uma data válida."></app-error>

            <app-form-input [type]="'input'" [parent-form]="personalData" name="cpf" label="CPF"
              placeholder="000.000.000-00" inputMask="000.000.000-00" [required]="true" class="reduced"
              [inputPattern]="cpfValidator"></app-form-input>

            <app-error *ngIf="isCpfValid()" error="O CPF inserido é inválido."></app-error>

            <div class="data-form-buttons">
              <button [disabled]="personalData.invalid" type="button" class="button-next-step" awNextStep>
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Dados de Contato" [navigationSymbol]="{ symbol: '2' }">
          <h2 class="data-content-title">2. Dados de Contato</h2>
          <p class="data-hint">Campo obrigatório</p>
          <form class="data-form" [formGroup]="contactData" #form>
            <app-form-input [type]="'email'" [parent-form]="contactData" name="email" label="E-mail"
              placeholder="Digite o seu email" [required]="true" class="full-width" minLength="5" maxLength="250"></app-form-input>

            <app-form-input [type]="'email'" [parent-form]="contactData" name="confirmEmail" label="Confirmar email"
              placeholder="Confirme o email digitado no campo anterior" [required]="true" class="full-width" minLength="5" maxLength="250">
            </app-form-input>

            <app-error *ngIf="confirmEmailError" [error]="confirmEmailError"></app-error>


            <app-form-input [type]="'input'" [parent-form]="contactData" name="cep" label="CEP" placeholder="00000-000"
              inputMask="00000-000" [required]="true" class="reduced"></app-form-input>

            <!-- <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="state"
              label="Estado"
              [required]="true"
              class="full-width"
            ></app-form-input> -->

            <app-form-input [type]="'select'" [parent-form]="contactData" name="state" label="Estado"
              [options]="statesOptions" [required]="true" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="city" label="Cidade"
              placeholder="Ex: São Paulo" [required]="true" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="neighborhood" label="Bairro"
              placeholder="Ex: Bela Vista" [required]="true" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="address" label="Endereço"
              placeholder="Ex: Rua Washington Luiz" [required]="true" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="number" label="Número"
              placeholder="Ex: 820" [required]="true" class="reduced"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="additional" label="Complemento"
              placeholder="Ex: Bloco 14 Apartamento 302" class="full-width"></app-form-input>

            <app-form-input [type]="'select'" [parent-form]="contactData" name="place" label="Você mora em"
              [required]="true" [options]="placeOptions" class="full-width"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="cellphone" label="Telefone Celular"
              placeholder="(00) 00000-0000" inputMask="(00) 00000-0000" class="reduced"></app-form-input>

            <app-form-input [type]="'input'" [parent-form]="contactData" name="phone" label="Telefone Residencial"
              placeholder="(00) 0000-0000" inputMask="(00) 0000-0000" class="reduced"></app-form-input>

            <div class="data-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button [disabled]="contactData.invalid" class="button-next-step" type="button" awNextStep>
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Dados do Sinal" [navigationSymbol]="{ symbol: '3' }">
          <h2 class="data-content-title">3. Dados do Sinal</h2>
          <p class="data-hint">Campo obrigatório</p>
          <form class="data-form" [formGroup]="signalData" #form>
            <app-form-input [type]="'select'" [parent-form]="signalData" name="haveSignal"
              label="Você tem TV DIGITAL de acesso gratuito através de uma antena instalada na sua casa/apartamento?"
              [required]="true" [options]="booleanOptions" class="full-width"></app-form-input>

            <app-form-input [type]="'select'" [parent-form]="signalData" name="tunesIn"
              label="Vocês recebe / sintoniza o(s) Canal(is) Digital(is) por" [required]="false"
              [options]="sintonizeChanel" class="full-width"></app-form-input>

            <app-form-input [type]="'select'" [parent-form]="signalData" name="haveProblem"
              label="Você possui algum problema para assistir o SBT?" [required]="true" [options]="booleanOptions"
              class="full-width"></app-form-input>

            <app-form-input [type]="'select'" [parent-form]="signalData" name="problem"
              label="Se sim, qual tipo de problema?" [required]="false" [options]="problemSignal" class="full-width">
            </app-form-input>

            <app-form-input [type]="'select'" [parent-form]="signalData" name="problemTime"
              label="A quanto tempo está com este problema?" [required]="false" [options]="problemTime"
              class="full-width"></app-form-input>

            <app-form-input [type]="'textarea'" [parent-form]="signalData" name="problemDescription"
              label="Descreva o problema abaixo:" [required]="false" class="full-width" [maxLength]="250">
            </app-form-input>

            <app-form-input [type]="'checkbox'" [parent-form]="signalData" name="acceptTerms" [required]="true">
              <span class="modal-footer dark">
                Ao criar minha conta, eu declaro que li e aceito os
                <a (click)="openTermsModal()">Termos de Uso</a> e as
                <a (click)="openPolicyModal()">Políticas de Privacidade</a>
                do Meu SBT.
              </span>
            </app-form-input>
            <app-form-input [type]="'checkbox'" [parent-form]="signalData" name="optin" [required]="false">
              <span class="modal-footer dark">
                Aceito receber informações e promoções do SBT, das demais empresas do Grupo Silvio Santos e de seus
                parceiros comerciais
              </span>
            </app-form-input>

            <div class="captcha">
              <re-captcha name="recaptcha" formControlName="recaptcha" [siteKey]="siteKey"></re-captcha>
            </div>

            <div class="data-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button [disabled]="signalData.invalid" class="button-send" type="button" [awGoToStep]="{ stepIndex: 0 }"
                (finalize)="openFinishdata()">
                Enviar
              </button>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </section>
  </section>
</section>

<ngx-smart-modal #modalSignalSearch identifier="modalSignalSearch" class="modal" [closable]="false"
  (onOpen)="changeTitle('Mensagem Enviada')" (onAnyCloseEventFinished)="removeFromTitle('Mensagem Enviada')">
  <button aria-label="Close" class="modal-button-close nsm-dialog-btn-close" type="button"
    (click)="modalSignalSearch.close()" title="Fechar">
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Mensagem enviada com sucesso!</h1>
  </div>
  <div class="modal-content">
    <p>
      Obrigada por preencher o formulário e nos ajudar na pesquisa para melhorar
      a qualidade do nosso sinal.
    </p>
    <p>Enquanto computamos sua resposta, continue a navegar no nosso site!</p>
    <button class="modal-button" type="button" (click)="modalSignalSearch.close()">
      Ok, entendi.
    </button>
  </div>
</ngx-smart-modal>