<div class="stay-inside">
  <h2 class="stay-inside-title" [innerText]="title"></h2>

  <div class="stay-inside-articles">
    <div
      *ngIf="stayInside.length > 4"
      class="stay-inside-control up"
      (click)="previousArticles()"
    >
      <i class="fa fa-chevron-up" title="Rolar para cima"></i>
    </div>

    <div class="stay-inside-articles-list" #articles>
      <ng-container *ngIf="isNoticeNews; else internalBlock">
        <a
        *ngFor="let article of stayInside; let i = index;"
        class="stay-inside-article"
        [href]="getRouterLink(article)"
        target="_blank"
      >
        <div class="stay-inside-article-image">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="article.thumbnail"
            [alt]="article.altimage"
            loading="lazy"
          />
        </div>
        <div class="stay-inside-article-content desktop">
          <span *ngIf="i === (stayInside.length - 1)">
            <p
              inViewport
              (inViewportAction)="checkIfCanLoad($event)"
              [inViewportOptions]="{ threshold: [0] }">
              <a
                class="stay-inside-article-content-title"
                [innerHtml]="lengthIsMaxSplit(article.title)"
              ></a>
            </p>
          </span>

          <span *ngIf="i !== (stayInside.length - 1)">
            <p>
              <a
                class="stay-inside-article-content-title"
                [innerHtml]="lengthIsMaxSplit(article.title)"
              ></a>
            </p>

            <span
            class="stay-inside-article-content-date"
            [innerHtml]="article.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'"
          ></span>
          </span>
        </div>

        <div class="stay-inside-article-content mobile">
          <p>
            <a
              class="stay-inside-article-content-title"
              [innerHtml]="lengthIsMaxSplit(article.title)"
            ></a>
          </p>
          <span
            class="stay-inside-article-content-date"
            [innerHtml]="
              article.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'
            "
          ></span>
        </div>
      </a>

      </ng-container>
      <ng-template #internalBlock>
        <a
        *ngFor="let article of stayInside; let i = index;"
        class="stay-inside-article"
        [routerLink]="getRouterLink(article)"
      >
        <div class="stay-inside-article-image">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="article.thumbnail"
            [alt]="article.altimage"
            loading="lazy"
          />
        </div>
        <div class="stay-inside-article-content desktop">
          <span *ngIf="i === (stayInside.length - 1)">
            <p
              inViewport
              (inViewportAction)="checkIfCanLoad($event)"
              [inViewportOptions]="{ threshold: [0] }">
              <a
                class="stay-inside-article-content-title"
                [innerHtml]="lengthIsMaxSplit(article.title)"
              ></a>
            </p>
          </span>

          <span *ngIf="i !== (stayInside.length - 1)">
            <p>
              <a
                class="stay-inside-article-content-title"
                [innerHtml]="lengthIsMaxSplit(article.title)"
              ></a>
            </p>

            <span
            class="stay-inside-article-content-date"
            [innerHtml]="article.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'"
          ></span>
          </span>
        </div>

        <div class="stay-inside-article-content mobile">
          <p>
            <a
              class="stay-inside-article-content-title"
              [innerHtml]="lengthIsMaxSplit(article.title)"
            ></a>
          </p>
          <span
            class="stay-inside-article-content-date"
            [innerHtml]="
              article.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'
            "
          ></span>
        </div>
      </a>
      </ng-template>
    </div>

    <span
      *ngIf="stayInside.length > 4"
      class="stay-inside-control down"
      (click)="nextArticles()"
    >
      <i class="fa fa-chevron-down" title="Rolar para baixo"></i>
    </span>
  </div>

  <button
    *ngIf="stayInside[lastIndex]?.pagination?.hasMore"
    class="stay-inside-load-more"
    (click)="loadMore()"
  >
    Veja Mais!
  </button>
</div>
