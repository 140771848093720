<div class="frames-search">
  <div class="frames-search-input">
    <input
      type="text"
      [formControl]="searchField"
      placeholder="Digite o termo desejado"
    />
  </div>
  <div class="frames-search-button">
    <button (click)="search()">Buscar</button>
  </div>
  <div class="frames-search-hide" (click)="hideSearchBox()" title="Fechar">
    <i class="fa fa-close"></i>
  </div>
</div>
