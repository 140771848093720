import { URL, IDSITE_TERMS_POLICY, IDSITEAREA_TERMS_POLICY, PLAYLIST_ID_TERMS_PARTNER } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TermsService {
    constructor(_http) {
        this._http = _http;
    }
    getTerms() {
        let queryString = `idsite=${IDSITE_TERMS_POLICY}&idsitearea=${IDSITEAREA_TERMS_POLICY}&idplaylist=${PLAYLIST_ID_TERMS_PARTNER}`;
        return this._http
            .get(`${URL}/api/medias?limit=1&${queryString}`);
    }
}
TermsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function TermsService_Factory() { return new TermsService(i0.ɵɵinject(i1.HttpClient)); }, token: TermsService, providedIn: "root" });
