/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-article.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./list-article.component";
import * as i5 from "../../pages/program/program.service";
var styles_ListArticleComponent = [i0.styles];
var RenderType_ListArticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListArticleComponent, data: {} });
export { RenderType_ListArticleComponent as RenderType_ListArticleComponent };
function View_ListArticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "list-title-link"]], [[8, "innerText", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.link; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkTitle; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ListArticleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "list-item"], ["fragment", "videos"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), i1.ɵpod(2, { video: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "list-item-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["loading", "lazy"], ["onerror", "this.src='../../../assets/images/default-image.png'"]], [[8, "src", 4], [1, "data-analytics", 0], [1, "data-title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "list-item-title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "list-item-title"]], [[8, "innerText", 0]], null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit.id); var currVal_3 = "videos"; var currVal_4 = _v.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.thumbnail; var currVal_6 = ((_v.context.$implicit.routerLink + "/video/") + _v.context.$implicit.id); var currVal_7 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_8); }); }
export function View_ListArticleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { contentSliderList: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "section", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "section", [["class", "list-title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "h2", [["class", "list-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListArticleComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "section", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "list-control left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"], ["title", "Rolar para a esquerda"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["contentSlider", 1]], null, 2, "div", [["class", "list-content-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListArticleComponent_2)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "list-control right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"], ["title", "Rolar para a direita"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hasLink; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.contents; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_ListArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-article", [], null, null, null, View_ListArticleComponent_0, RenderType_ListArticleComponent)), i1.ɵdid(1, 114688, null, 0, i4.ListArticleComponent, [i5.ProgramService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListArticleComponentNgFactory = i1.ɵccf("app-list-article", i4.ListArticleComponent, View_ListArticleComponent_Host_0, { title: "title", hasCategories: "has-categories", contents: "content", url: "url", hasLink: "has-link", link: "link", linkTitle: "link-title" }, {}, []);
export { ListArticleComponentNgFactory as ListArticleComponentNgFactory };
