<section *ngIf="hasContent && loaded">
  <div *ngIf="program" class="program">
    <app-quick-access-menu
      *ngIf="loaded"
      [components]="quickAccessMenuComponents"
      (updateUrlState)="onUpdateUrlState($event)"
      [offset-scroll]="240"
      [is-at-home]="true"
      [order]="true"
      [fragment]="initialComponent"
    >
    </app-quick-access-menu>

    <div class="content-ad" *ngIf="program && program.adunitname">
      <app-ad
        *ngIf="program && program.adunitname"
        [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/top'"
        sizesDesktop="[970,250],[728,90]"
        sizesMobile="[320,100],[320, 50]"
        [metadata]="{ bloco: 'top' }"
      ></app-ad>
    </div>

    <app-header
      *ngIf="program.header"
      [url]="program.header"
      [alt]="program.title"
      extra-classes="space"
      [reload-to-same-page]="true"
      [has-blurred-frame]="false">
    </app-header>

    <app-ad
      *ngIf="program && program.adunitname && !isMobile"
      [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/especial'"
      [metadata]="{ bloco: 'especial' }"
      [wallpaper]="true"
    ></app-ad>

    <div class="program-breadcrumb-container">
      <app-breadcrumb [items]="itemsBreadcrumb"></app-breadcrumb>
    </div>
    
    <section class="program-container">
      <section *ngFor="let item of renderOrder;">
        <app-program-wrapper
          [type]="item.type"
          [title]="item.title"
          [program]="program"
          [aboutAdIndex]="getAdBlockIndex('about')"
          [station-ad-unit]="stationAdUnit"
          (updateUrlState)="onUpdateUrlState($event)">
        </app-program-wrapper>
        <app-ad
          *ngIf="program && program.adunitname && adHorizontalIsVisible(item.type)"
          [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/between'"
          sizesDesktop="[728,90]"
          sizesMobile="[320, 50]"
          [metadata]="{bloco: 'bloco-'+ getAdBlockIndex(item.type), componente: item.type}"
        ></app-ad>
      </section>
      <!-- <app-sbt-videos-program></app-sbt-videos-program> -->
      <app-ad
        *ngIf="program && program.adunitname"
        [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/bottom'"
        sizesDesktop="[970,90]"
        sizesMobile=" [320,50], [320,100]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </section>
  </div>
</section>

<section *ngIf="!hasContent && loaded">
  <app-not-found></app-not-found>
</section>
