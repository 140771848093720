<section class="programming-grid wrapper">
  <h2 *ngIf="title" class="programming-grid-title"
        [innerText]="title"
        [title]="'Abrir em ' + title"
        [ngClass]="[color || '']"
        [ngStyle]="{ color: '#' + hexaColor }"
        [routerLink]="'/programacao'"
        [attr.data-analytics]="stationName + '-ver-programacao'"
        [attr.data-analytics-label]="'/programacao'"
      >
  </h2>

  <section>
    <div class="programming-grid-days">
      <div *ngIf="!isHome" class="content-previous" (click)="prev('days')">
        <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
      </div>

      <div class="content-wrapper" #daysList [ngClass]="{ home: isHome }">
        <div *ngFor="let day of daysGrid; let i = index" class="programming-grid-day">
          <p [ngClass]="{ active: i === currentDayIndex }" (click)="setDay(i)">
            <span class="programming-grid-day-title" [innerText]="day.title"></span>
            <span class="programming-grid-day-date" [innerText]="day.date"></span>
          </p>
        </div>
      </div>

      <div *ngIf="!isHome" class="content-next" (click)="next('days')">
        <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
      </div>
    </div>
  </section>

  <section class="programming-grid-schedule" *ngIf="isHome">
    <div class="programming-grid-control left" (click)="prev('schedules')">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div *ngIf="programmingGrid.programs && programmingGrid.programs.length > 0" class="programming-grid-programs-list"
      #scheduleList>
      <a *ngFor="let program of programmingGrid.programs; let i = index" [routerLink]="program.exibe && program.exibe === 'N' ? '/programacao' : program.link" class="programming-grid-program" [title]="program.title">
        <div class="program-time">
          <span>{{ program.startdate | date: 'HH:mm'}}</span>
        </div>
        <div class="program-image">
          <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="program.thumbnail" [alt]="program.title" />
        </div>

        <a *ngIf="program.live" class="program-is-live" routerLink="/ao-vivo">No ar</a>
        <span *ngIf="program.next" class="program-next">A seguir</span>
      </a>
    </div>

    <div class="programming-grid-control right" (click)="next('schedules')">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </section>

  <section *ngIf="!isHome">
    <ul class="programming-grid-schedule-list">
      <li *ngFor="let program of programmingGrid.programs; let i = index" [id]="i">
        <div class="program-header">
          <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="program.thumbnail" [alt]="program.title" />
          <a *ngIf="program.live" class="header-live" routerLink="/ao-vivo">No Ar</a>
          <span *ngIf="program.next" class="header-next">A seguir</span>
        </div>
        <div class="program-content" [ngClass]="{ opened: i === currentProgramAbout }">
          <div class="content-info" (click)="openInfo($event, i)">
            <span class="info-time">{{ program.startdate | date: 'HH:mm'}}</span>
            <div class="info-about">
              <span class="info-title" [innerText]="program.title"></span>
              <span *ngIf="program.description" class="info-description" [innerText]="program.description"></span>
            </div>
            <span class="seta">
              <i *ngIf="program.description" class="fa fa-chevron-down"></i>
            </span>            
          </div>
          <div class="content-utils">
            <div class="utils-tags">
              <div *ngIf="program.classification">
                <span class="utils-classification" [innerText]="program.classification" [ngClass]="['classification-' + program.classification]"></span>
              </div>
              <div *ngIf="program.videoformat === '1'">
                <img loading="lazy" src="../../../assets/images/schedules/hd.png" alt="" />
              </div>
              <div *ngIf="program.ad === '1'">
                <img loading="lazy" src="../../../assets/images/schedules/ad.png" alt="" />
              </div>
              <div *ngIf="program.sap === '1'">
                <img loading="lazy" src="../../../assets/images/schedules/sap.png" alt="" />
              </div>
              <div *ngIf="program.cc === '1'">
                <img loading="lazy" src="../../../assets/images/schedules/cc.png" alt="" />
              </div>
            </div>
            <div class="utils-buttons" *ngIf="!program.exibe || program.exibe !== 'N'">
              <a [routerLink]="program.link" class="utils-visit">Veja o Site</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>

  <section *ngIf="showOpsMessage()" class="without-results">
    <h2>OPS!</h2>
    <span>Infelizmente ainda não temos uma programação mapeada para este dia.
      =/</span>
  </section>
</section>
