import { URL, URL_SEARCH_API, ID_SITE_SBTNEWS, ID_SITE_SBT_BRASIL, ID_SITE_PRIMEIRO_IMPACTO } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SearchResultsService {
    constructor(_http) {
        this._http = _http;
    }
    getNotices(query, page = 0) {
        const notices = this._http
            .get(`${URL_SEARCH_API}/api/notices?term=${query}&page=${page}`)
            .toPromise();
        return Promise.all([notices]).then((response) => {
            const notices = response[0];
            return {
                notices: notices.results,
                hasMoreNotices: notices.hasMore
            };
        });
    }
    getVideos(query, page = 0) {
        const videos = this._http
            .get(`${URL_SEARCH_API}/api/videos?term=${query}&page=${page}`)
            .toPromise();
        return Promise.all([videos]).then((response) => {
            const videos = response[0];
            return {
                videos: videos.results,
                hasMoreVideos: videos.hasMore
            };
        });
    }
    getResults(query, page = 0) {
        const notices = this._http
            .get(`${URL_SEARCH_API}/api/notices?term=${query}&page=${page}`)
            .toPromise();
        const videos = this._http
            .get(`${URL_SEARCH_API}/api/videos?term=${query}&page=${page}`)
            .toPromise();
        const programs = this._http
            .get(`${URL}/api/programs?titlesearch=${query}&limit=5`)
            .toPromise();
        return Promise.all([notices, programs, videos]).then((response) => {
            const notices = response[0];
            const programs = response[1].results;
            const videos = response[2];
            return {
                notices: notices.results.filter(notice => notice.idProgram != ID_SITE_SBTNEWS || ID_SITE_SBT_BRASIL || ID_SITE_PRIMEIRO_IMPACTO),
                videos: videos.results,
                programs,
                hasMoreNotices: notices.hasMore,
                hasMoreVideos: videos.hasMore
            };
        });
    }
    getHighlightedList() {
        return this._http
            .get(`${URL}/api/highlighted?type=entretenimento&limit=5`)
            .toPromise()
            .then((response) => {
            const notices = response.results;
            return [
                {
                    medias: notices
                }
            ];
        });
    }
}
SearchResultsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SearchResultsService_Factory() { return new SearchResultsService(i0.ɵɵinject(i1.HttpClient)); }, token: SearchResultsService, providedIn: "root" });
