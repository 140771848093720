<section class="download-app">
  <div class="container">
    <section class="header">
      <h2>Já que não dá para levar uma TV no bolso, leve um canal inteiro.</h2>
      <h3>App do SBT: O controle em suas mãos.</h3>
    </section>

    <section class="content">
      <div class="image">
        <img
          onerror="this.src='../../../assets/images/default-image.png'"
          [src]="randomImage"
          alt=""
        />
      </div>

      <div class="info">
        <p>
          Baixe o App do SBT, assita a toda a programação quando e onde você
          quiser e aproveite conteúdos exclusivos que não passam na TV.
        </p>
      </div>
    </section>

    <section class="buttons">
      <a
        href="http://goo.gl/ZWTSdG" target="_blank"
        class="button-appstore"
      ></a>
      <a
        href="http://goo.gl/fqIAcU" target="_blank"
        class="button-playstore"
      ></a>
    </section>
  </div>
</section>
