/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/quick-access-menu/quick-access-menu.component.ngfactory";
import * as i3 from "../../services/dom.service";
import * as i4 from "../../services/scroll.service";
import * as i5 from "@nicky-lenaers/ngx-scroll-to";
import * as i6 from "../../shared/quick-access-menu/quick-access-menu.component";
import * as i7 from "../../services/station.service";
import * as i8 from "../../shared/mediabox/mediabox.component.ngfactory";
import * as i9 from "../../shared/mediabox/mediabox.component";
import * as i10 from "../../wrappers/home-wrapper/home-wrapper.component.ngfactory";
import * as i11 from "../../wrappers/home-wrapper/home-wrapper.component";
import * as i12 from "@angular/common";
import * as i13 from "../../shared/ad/ad.component.ngfactory";
import * as i14 from "../../shared/ad/ad.component";
import * as i15 from "@angular/router";
import * as i16 from "../../services/geolocation.service";
import * as i17 from "@angular/common/http";
import * as i18 from "../../services/cookie.service";
import * as i19 from "./home.service";
import * as i20 from "../../services/canonical.service";
import * as i21 from "./home.component";
import * as i22 from "../../services/title.service";
import * as i23 from "@angular/platform-browser";
import * as i24 from "../../services/metatags.service";
import * as i25 from "../../services/user.service";
import * as i26 from "../../services/branding.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-quick-access-menu", [], null, [[null, "updateUrlState"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateUrlState" === en)) {
        var pd_1 = (_co.onUpdateUrlState($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_QuickAccessMenuComponent_0, i2.RenderType_QuickAccessMenuComponent)), i1.ɵprd(4608, null, i3.DomService, i3.DomService, []), i1.ɵprd(512, null, i4.ScrollService, i4.ScrollService, [i5.ScrollToService]), i1.ɵdid(3, 770048, null, 0, i6.QuickAccessMenuComponent, [i4.ScrollService, i1.Renderer2, i7.StationService], { components: [0, "components"], order: [1, "order"], offsetScroll: [2, "offsetScroll"], isAtHome: [3, "isAtHome"] }, { updateUrlState: "updateUrlState" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quickAccessMenuComponents; var currVal_1 = true; var currVal_2 = (0 - 150); var currVal_3 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mediabox", [], null, null, null, i8.View_MediaboxComponent_0, i8.RenderType_MediaboxComponent)), i1.ɵdid(1, 114688, null, 0, i9.MediaboxComponent, [], { medias: [0, "medias"], type: [1, "type"], extraClasses: [2, "extraClasses"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.home.mediabox; var currVal_1 = ("home" + _co.stationName); var currVal_2 = "black-bars"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_HomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-wrapper", [], null, null, null, i10.View_HomeWrapperComponent_0, i10.RenderType_HomeWrapperComponent)), i1.ɵdid(1, 180224, null, 0, i11.HomeWrapperComponent, [], { type: [0, "type"], home: [1, "home"], stationAdUnit: [2, "stationAdUnit"], stationName: [3, "stationName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.home; var currVal_2 = _co.stationAdUnit; var currVal_3 = ("home" + _co.stationName); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_5)), i1.ɵdid(2, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "section", [["class", "home-ads"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-ad", [["sizesDesktop", "[970,90]"], ["sizesMobile", "[320,50]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AdComponent_0, i13.RenderType_AdComponent)), i1.ɵdid(5, 4308992, null, 0, i14.AdComponent, [i15.Router, i7.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(6, { bloco: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.renderOrder; _ck(_v, 2, 0, currVal_0); var currVal_1 = (("Home" + _co.stationAdUnit) + "/bottom"); var currVal_2 = "[970,90]"; var currVal_3 = "[320,50]"; var currVal_4 = _ck(_v, 6, 0, "bottom"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "ads-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-ad", [["sizesDesktop", "[970,250],[728,90]"], ["sizesMobile", "[320,100],[320, 50]"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AdComponent_0, i13.RenderType_AdComponent)), i1.ɵdid(6, 4308992, null, 0, i14.AdComponent, [i15.Router, i7.StationService, i1.Renderer2], { path: [0, "path"], sizesDesktop: [1, "sizesDesktop"], sizesMobile: [2, "sizesMobile"], metadata: [3, "metadata"] }, null), i1.ɵpod(7, { bloco: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_3)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_4)), i1.ɵdid(11, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.quickAccessMenuComponents == null) ? null : _co.quickAccessMenuComponents.length) > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (("Home" + _co.stationAdUnit) + "/top"); var currVal_2 = "[970,250],[728,90]"; var currVal_3 = "[320,100],[320, 50]"; var currVal_4 = _ck(_v, 7, 0, "top"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.home.mediabox; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.renderOrder; _ck(_v, 11, 0, currVal_6); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.home; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵprd(4608, null, i16.GeolocationService, i16.GeolocationService, [i17.HttpClient, i17.HttpBackend, i15.Router, i7.StationService]), i1.ɵprd(512, null, i7.StationService, i7.StationService, [i18.CookieService]), i1.ɵprd(512, null, i19.HomeService, i19.HomeService, [i17.HttpClient, i7.StationService]), i1.ɵprd(512, null, i20.CanonicalService, i20.CanonicalService, [i12.DOCUMENT]), i1.ɵdid(5, 507904, null, 0, i21.HomeComponent, [i19.HomeService, i22.TitleService, i15.ActivatedRoute, i7.StationService, i23.Meta, i15.Router, i24.MetatagsService, i12.Location, i25.UserService, i20.CanonicalService, i26.BrandingService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i21.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
