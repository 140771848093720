<a
    *ngIf="(content.author.indexOf('http') === 0)"
    class="card"
    [href]="content.author"
    [target]="(content.opcional5 === 'B') ? '_blank' : '_self'"
>
    <div class="card-image" [ngClass]="{'card-image-big': imageBig}" *ngIf="content?.thumbnail || content?.image">
        <img onerror="this.src='/assets/images/default-image.png'" [src]="content?.thumbnail || content?.image" [alt]="content.title" loading="lazy" />
    </div>
    <div class="card-body">        
        <div class="card-title" [innerText]="content?.title"></div>
        <div class="card-publish-date" [innerText]="content?.publishdatestring || content?.enddate | date: 'dd/MM/yyyy \'às\' HH:mm'"></div>
    </div>
</a>

<a
    *ngIf="(content.author.indexOf('http') === -1)"
    class="card"
    [routerLink]="getRouterLink(content)"
    [queryParams]="parameters[0]"
    [fragment]="fragment[0]"
>
    <div class="card-image" [ngClass]="{'card-image-big': imageBig}" *ngIf="content?.thumbnail || content?.image" 
    (click)="goToLink(getRouterLink(content), parameters[0], fragment[0])">
        <img onerror="this.src='/assets/images/default-image.png'" [src]="content?.thumbnail || content?.image" [alt]="content.title" loading="lazy" />
    </div>
    <div class="card-body"
    (click)="goToLink(getRouterLink(content), parameters[0], fragment[0])">        
        <div class="card-title" [innerText]="content?.title"></div>
        <div class="card-publish-date" [innerText]="content?.publishdatestring || content?.enddate | date: 'dd/MM/yyyy \'às\' HH:mm'"></div>
    </div>
</a>