<div class="account-actions" #accountLabel>
  <div class="account-actions-login">
    <span (click)="openLoginModal()">Fazer Login</span>
  </div>
  <div class="account-actions-register">
    <span (click)="openCreateAccountModal()" >Criar Conta</span>
  </div>
</div>

<ngx-smart-modal
  #modalForgotPassword
  identifier="modalForgotPassword"
  class="modal"
  [closable]="false"
  (onOpen)="putHashOnUrl('redefinir-senha'); changeTitle('Esqueceu Sua Senha')"
  (onAnyCloseEventFinished)="removeFragment('redefinir-senha'); removeFromTitle('Esqueceu Sua Senha')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalForgotPassword.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper">
    <section>
      <h2 class="modal-content-title">Esqueci Minha Senha</h2>

      <app-error
        *ngIf="forgotPasswordError"
        [error]="forgotPasswordError"
      ></app-error>

      <form class="modal-form" [formGroup]="forgotPasswordData" #form>
        <app-form-input
          [type]="'email'"
          [parent-form]="forgotPasswordData"
          name="email"
          label="E-mail"
          placeholder="Digite o seu email"
          [required]="true"
          class="full-width"
          (input)="forgotPasswordError = null"
          [inputPattern]="emailPattern"
          [maxLength]="100"
          [minLength]="5"
        ></app-form-input>

        <div class="modal-form-buttons">
          <button
            type="button"
            class="button-next-step"
            data-analytics="esqueci-a-senha"
            (click)="forgotPassword()"
            [disabled]="forgotPasswordData.invalid"
          >
            Recuperar minha senha
          </button>
        </div>

        <p class="modal-footer center">
          Lembra de sua senha?
          <a (click)="openLoginModal()">Fazer Login.</a>
        </p>
      </form>
    </section>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalForgotPasswordSuccess
  identifier="modalForgotPasswordSuccess"
  class="modal"
  [closable]="false"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalForgotPasswordSuccess.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Recuperação de senha!</h1>
  </div>
  <div class="modal-wrapper white">
    <section>
      <ul class="modal-list">
        <li>
          Foi enviado um e-mail para
          <strong [innerText]="forgotPasswordData.get('email').value"></strong>,
          acesse seu e-mail e recupere sua senha!
        </li>
      </ul>
    </section>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalCreateAccount
  identifier="modalCreateAccount"
  class="modal"
  [closable]="false"
  (onOpen)="putHashOnUrl('criar-conta'); changeTitle('Criar Conta')"
  (onAnyCloseEventFinished)="removeFragment('criar-conta'); removeFromTitle('Criar Conta')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalCreateAccount.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper white">
    <section>
      <h2 class="modal-title">
        Crie sua conta no Meu SBT e personalize sua experiência no site!
      </h2>

      <ul class="modal-list">
        <li>
          Defina seus conteúdos preferidos e customize a página incial do site
          de acordo com seu interesse
        </li>
        <li>
          Ative notificações em tempo real para seus conteúdos preferidos, assim
          você sempre ficará sabendo das últimas novidades
        </li>
        <li>
          Faça inscrições nos programas do SBT com apenas um clique, sem
          precisar preencher os formulários a cada nova inscrição
        </li>
      </ul>

      <div class="modal-form-buttons">
        <button
          type="button"
          class="button-next-step"
          (click)="openFormsModal()"
        >
          Criar conta
        </button>
      </div>

      <p class="modal-footer center">
        Ja possui conta no Meu SBT? Faça <a (click)="openLoginModal()">Login</a>
      </p>
    </section>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalTerms
  identifier="modalTerms"
  class="modal high-index"
  [closable]="false"
  (onOpen)="changeTitle('Termos de Uso')"
  (onAnyCloseEventFinished)="removeFromTitle('Termos de Uso')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalTerms.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header fixed-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper white with-scroll">
    <app-terms [update-title]="false"></app-terms>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalPolicy
  identifier="modalPolicy"
  class="modal high-index"
  [closable]="false"
  (onOpen)="changeTitle('Políticas de Privacidade')"
  (onAnyCloseEventFinished)="removeFromTitle('Políticas de Privacidade')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalPolicy.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header fixed-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper white with-scroll">
    <app-privacy-policy [update-title]="false"></app-privacy-policy>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalForms
  identifier="modalForms"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Preencha os Dados')"
  (onAnyCloseEventFinished)="removeFromTitle('Preencha os Dados')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalForms.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="modal-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper">
    <section>
      <h2 class="modal-content-title">Crie sua conta</h2>
      <aw-wizard [navBarLayout]="'large-filled-symbols'">
        <aw-wizard-step
          [stepId]="1"
          stepTitle="Dados Pessoais"
          [navigationSymbol]="{ symbol: '1' }"
          awSelectedStep
        >
          <app-error *ngIf="registerError" [error]="registerError"></app-error>

          <h2 class="modal-content-title">Dados Pessoais</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="personalData" #form>
            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="name"
              label="Nome Completo"
              placeholder="Digite o seu nome"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'select'"
              [parent-form]="personalData"
              name="sex"
              label="Sexo"
              [options]="sexOptions"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="birthday"
              label="Data de Nascimento"
              placeholder="00/00/0000"
              inputMask="00/00/0000"
              [required]="true"
              class="reduced"
            ></app-form-input>

            <app-error
              *ngIf="isBirthDayValid()"
              error="É proibido o cadastro de menores de 18 anos."
            ></app-error>

            <app-error
              *ngIf="isDateValid()"
              error="Insira uma data válida."
            ></app-error>

            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="rg"
              label="RG"
              placeholder="Digite o seu RG"
              [required]="false"
              class="reduced"
              [inputPattern]="rgValidator"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="personalData"
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              inputMask="000.000.000-00"
              [required]="true"
              class="reduced"
              [inputPattern]="cpfValidator"
            ></app-form-input>

            <app-error
              *ngIf="isCpfValid()"
              error="O CPF inserido é inválido."
            ></app-error>

            <!-- <app-form-input
              [type]="'select'"
              [parent-form]="personalData"
              name="maritalStatus"
              label="Estado Civil"
              [options]="maritalOptions"
              [required]="true"
              class="full-width"
            ></app-form-input> -->

            <div class="modal-form-buttons">
              <button
                [disabled]="personalData.invalid"
                type="button"
                class="button-next-step"
                awNextStep
              >
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          stepTitle="Dados de Contato"
          [navigationSymbol]="{ symbol: '2' }"
        >
          <h2 class="modal-content-title">Dados de Contato</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="contactData" #form>
            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="cep"
              label="CEP"
              placeholder="00000-000"
              inputMask="00000-000"
              [required]="true"
              class="reduced"
              [inputPattern]="cepValidator"
            ></app-form-input>

            <!-- <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="state"
              label="Estado"
              placeholder="Ex: RS"
              [required]="true"
              class="full-width"
            ></app-form-input> -->

            <app-form-input
              [type]="'select'"
              [parent-form]="contactData"
              name="state"
              label="Estado"
              [options]="states"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="city"
              label="Cidade"
              placeholder="Ex: São Paulo"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="neighborhood"
              label="Bairro"
              placeholder="Ex: Bela Vista"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="address"
              label="Endereço"
              placeholder="Ex: Rua Washington Luiz"
              [required]="true"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="number"
              label="Número"
              placeholder="Ex: 820"
              [required]="true"
              class="reduced"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="additional"
              label="Complemento"
              placeholder="Ex: Bloco 14 Apartamento 302"
              class="full-width"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="cellphone"
              label="Telefone Celular"
              placeholder="(00) 00000-0000"
              inputMask="(00) 00000-0000"
              class="reduced"
              [inputPattern]="cellphoneValidator"
            ></app-form-input>

            <app-form-input
              [type]="'input'"
              [parent-form]="contactData"
              name="phone"
              label="Telefone Residencial"
              placeholder="(00) 0000-0000"
              inputMask="(00) 0000-0000"
              class="reduced"
              [inputPattern]="residentialPhoneValidator"
            ></app-form-input>

            <div class="modal-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button
                [disabled]="contactData.invalid"
                class="button-next-step"
                type="button"
                (click)="renderCaptchaStep()"
                awNextStep
              >
                Continuar
              </button>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          stepTitle="Dados da Conta"
          [navigationSymbol]="{ symbol: '3' }"
        >
          <h2 class="modal-content-title">Dados da Conta</h2>
          <p class="modal-hint">Campo obrigatório</p>
          <form class="modal-form" [formGroup]="accountData" #form>
            <app-form-input
              [type]="'email'"
              [parent-form]="accountData"
              name="email"
              label="E-mail"
              placeholder="Digite o seu email"
              [required]="true"
              class="full-width"
              [maxLength]="100"
              [minLength]="5"
            ></app-form-input>

            <app-form-input
              [type]="'email'"
              [parent-form]="accountData"
              name="confirmEmail"
              label="Confirmar email"
              placeholder="Confirme o email digitado no campo anterior"
              [required]="true"
              class="full-width"
              [maxLength]="100"
              [minLength]="5"
            ></app-form-input>

            <app-error
              *ngIf="confirmEmailError"
              [error]="confirmEmailError"
            ></app-error>

            <app-form-input
              [type]="'password'"
              [parent-form]="accountData"
              name="password"
              label="Senha"
              [required]="true"
              class="full-width"
              [maxLength]="20"
              [minLength]="6"
            ></app-form-input>

            <app-form-input
              [type]="'password'"
              [parent-form]="accountData"
              name="confirmPassword"
              label="Confirmar Senha"
              [required]="true"
              class="full-width"
              [maxLength]="20"
              [minLength]="6"
            ></app-form-input>

            <app-error
              *ngIf="confirmPasswordError"
              [error]="confirmPasswordError"
            ></app-error>

            <app-form-input
              [type]="'checkbox'"
              [parent-form]="accountData"
              name="emailoptin"
              [required]="false"
            >
              <span class="modal-footer dark">
                Aceito receber informações e promoções do SBT, das demais empresas do Grupo Silvio Santos e de seus parceiros comerciais
              </span>
            </app-form-input>

            <app-form-input
              [type]="'checkbox'"
              [parent-form]="accountData"
              name="acceptTerms"
              [required]="true"
            >
              <span class="modal-footer dark">
                Ao criar minha conta, eu declaro que li e aceito os
                <a (click)="openTermsModal()">Termos de Uso</a> e as
                <a (click)="openPolicyModal()">Políticas de Privacidade</a>
                do Meu SBT.
              </span>
            </app-form-input>

            <div class="captcha" *ngIf="renderCaptcha">
              <re-captcha
                name="recaptcha"
                formControlName="recaptcha"
                [siteKey]="siteKey"
              ></re-captcha>
            </div>

            <div class="modal-form-buttons">
              <button class="button-prev-step" type="button" awPreviousStep>
                Voltar
              </button>
              <button
                [disabled]="accountData.invalid"
                data-analytics="criar-conta"
                class="button-next-step"
                type="button"
                [awGoToStep]="{ stepIndex: 0 }"
                (finalize)="createAccount()"
              >
                Criar conta
              </button>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </section>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalFinish
  identifier="modalFinish"
  class="modal"
  [closable]="false"
  (onOpen)="changeTitle('Conta Criada')"
  (onAnyCloseEventFinished)="removeFragment(); removeFromTitle('Conta Criada')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalFinish.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header confirm">
    <i class="fa fa-check"></i>
    <h1>Conta criada com sucesso!</h1>
    <div class="modal-header-promotion-jequiti">
      <div class="modal-header-promotion-jequiti-info">
        <img src="./../../../assets/images/logo-jequiti.png" alt="Logo Jequiti" width="100px" height="38px">
        <p>Você ganhou 20% de desconto nos produtos Jequiti! <a href="https://www.jequiti.com.br/?utm_source=parceria&utm_medium=crm&utm_campaign=opt-in-sbt-jequiti">Clique aqui</a>, insira o código <strong>SBTJQT20</strong> e aproveite nosso site!</p>
      </div>
    </div>
  </div>
  <div class="modal-content">
    <button class="modal-button" type="button" (click)="openLoginModal()">
      Fazer Login
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #modalLogin
  identifier="modalLogin"
  class="modal"
  [closable]="false"
  (onOpen)="putHashOnUrl('login'); changeTitle('Login')"
  (onAnyCloseEventFinished)="removeFragment('login'); removeFromTitle('Login')"
>
  <button
    aria-label="Close"
    class="modal-button-close nsm-dialog-btn-close"
    type="button"
    (click)="modalLogin.close()"
    title="Fechar"
  >
    <i class="fa fa-close"></i>
  </button>
  <div class="modal-header">
    <img src="../../../assets/images/logo-meu-sbt.png" alt="" />
  </div>
  <div class="modal-wrapper">
    <section>
      <h2 class="modal-content-title">Faça seu Login</h2>

      <app-error *ngIf="loginError" [error]="loginError"></app-error>

      <form class="modal-form" [formGroup]="loginData" #form>
        <app-form-input
          [type]="'email'"
          [parent-form]="loginData"
          name="email"
          label="E-mail"
          placeholder="Digite o seu email"
          [required]="true"
          class="full-width"
          (input)="loginError = null"
          [maxLength]="100"
          [minLength]="5"
        ></app-form-input>

        <app-form-input
          [type]="'password'"
          [parent-form]="loginData"
          name="password"
          label="Senha"
          [required]="true"
          class="full-width"
          [maxLength]="20"
          [minLength]="6"
        ></app-form-input>

        <div class="modal-form-buttons">
          <button
            type="button"
            class="button-next-step"
            data-analytics="fazer-login"
            (click)="login()"
            [disabled]="loginData.invalid"
          >
            Fazer Login
          </button>
        </div>

        <p class="modal-footer center">
          Ainda não possui conta no Meu SBT?
          <a (click)="openCreateAccountModal()">Crie a sua.</a>
        </p>

        <p class="modal-footer center">
          Esqueceu sua senha?
          <a (click)="openForgotPasswordModal()">Recupere sua senha.</a>
        </p>
      </form>
    </section>
  </div>
</ngx-smart-modal>
