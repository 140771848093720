<div class="tab-search-results">
  <div class="content-list">
    <div *ngIf="contents.length > 4" class="content-previous" (click)="prev()">      
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="content-wrapper" #tabItens>
      <div *ngFor="let content of contents; let i = index" class="content-item">
        <p
          (click)="setContent($event, i, content.title)"
          [ngClass]="{ active: contentIndex === i }"
          [innerText]="content.title"
        ></p>
      </div>
    </div>

    <div *ngIf="contents.length > 4" class="content-next" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>      
    </div>
  </div>

  <section
    *ngIf="results.notices"
    class="search-results-list"
    [ngClass]="{ active: contentTitle === 'Notícias' }"
  >
    <ul class="results-list">
      <li
        *ngFor="let result of results?.notices"

      >
      <a class="item" [routerLink]="sendToContent(result, 'notice')">
        <div class="item-image">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="result.thumbnail || defaultImage"
            [alt]="result.altimage || 'default'"
            loading="lazy"
          />
        </div>

        <div class="item-info">
          <a class="item-description" [innerText]="result.title"></a>

          <p
            class="item-publish-date"
            [innerText]="
              result.publishdatestring | date: 'dd/MM/yyyy \'às\' HH:mm'
            "
          ></p>
        </div>
      </a>
      </li>
    </ul>

    <button
      *ngIf="results?.notices.length > 0 && results?.hasMoreNotices"
      (click)="loadMoreNotices()"
      class="results-load-more"
    >
      Carregar mais
    </button>

    <section
      *ngIf="results?.notices.length === 0 && !searching"
      class="results-empty"
    >
      <p class="results-empty-msg">
        Infelizmente não encontramos nenhuma notícia para o(s) termo(s)
        <span class="results-empty-msg-query">"{{ queryString }}".</span>
      </p>

      <p class="results-empty-msg">
        Confira se você digitou os termos corretamente no campo de busca ou
        tente usar termos mais simples. Você também pode acessar algum dos
        seguintes links:
      </p>

      <ul class="results-empty-list">
        <li>
          <a class="results-empty-link" routerLink="/programas">Programas</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/programacao"
            >Programação</a
          >
        </li>
        <li>
          <a class="results-empty-link" routerLink="/jornalismo">Jornalismo</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/ao-vivo">SBT Ao Vivo</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/sbt-videos">SBT Vídeos</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/inscricoes">Inscrições</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/promocoes">Promoções</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/institucional"
            >Institucional</a
          >
        </li>
        <li>
          <a class="results-empty-link" routerLink="/mapa-do-site"
            >Mapa do Site</a
          >
        </li>
      </ul>
    </section>
  </section>

  <section
    class="search-results-cards"
    [ngClass]="{ active: contentTitle === 'Vídeos' }"
  >
    <div class="results-cards">
      <ul class="videos-sections-list">
        <li
          *ngFor="let result of results.videos"
          class="videos-sections-item"

        >
          <a
            [routerLink]="sendToContent(result, 'video')"
            [queryParams]="{video:result.idvideo}"
            fragment="videos"
          >
            <div class="section-video">
              <img
                [src]="'https://i.ytimg.com/vi/'+ result.idvideo + '/mqdefault.jpg'"
                onerror="this.src='../../../assets/images/default-image.png'"
                alt="Imagem de um vídeo do SBT"
                [attr.data-analytics]="sendToContent(result, 'video') + '/video/' + result.idvideo"
                [attr.data-title]="result?.title"
                loading="lazy"
              />
              <h6 class="video-title" [innerText]="result.title"></h6>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <button
      *ngIf="results?.videos.length > 0 && results?.hasMoreVideos"
      (click)="loadMoreVideos()"
      class="results-load-more"
    >
      Carregar mais
    </button>

    <section
      *ngIf="results?.videos.length === 0 && !searching"
      class="results-empty"
    >
      <p class="results-empty-msg">
        Infelizmente não encontramos nenhum video para o(s) termo(s)
        <span class="results-empty-msg-query">"{{ queryString }}".</span>
      </p>

      <p class="results-empty-msg">
        Confira se você digitou os termos corretamente no campo de busca ou
        tente usar termos mais simples. Você também pode acessar algum dos
        seguintes links:
      </p>

      <ul class="results-empty-list">
        <li>
          <a class="results-empty-link" routerLink="/programas">Programas</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/programacao"
            >Programação</a
          >
        </li>
        <li>
          <a class="results-empty-link" routerLink="/jornalismo">Jornalismo</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/ao-vivo">SBT Ao Vivo</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/sbt-videos">SBT Vídeos</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/inscricoes">Inscrições</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/promocoes">Promoções</a>
        </li>
        <li>
          <a class="results-empty-link" routerLink="/institucional"
            >Institucional</a
          >
        </li>
        <li>
          <a class="results-empty-link" routerLink="/mapa-do-site"
            >Mapa do Site</a
          >
        </li>
      </ul>
    </section>
  </section>
</div>
