import { URL, ID_PODCASTS, IDSITEAREA_PODCASTS } from '../../app.settings';
import Podcast from '../../models/podcast.class';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PodcastsService {
    constructor(_http) {
        this._http = _http;
    }
    getMedias() {
        return this._http
            .get(`${URL}/api/medias?idsite=${ID_PODCASTS}&idsitearea=${IDSITEAREA_PODCASTS}&limit=100&orderby=ordem&sort=asc`)
            .toPromise()
            .then((response) => {
            const podcasts = response;
            return podcasts.results.map(podcast => new Podcast(podcast));
        })
            .catch(e => console.log(e));
    }
}
PodcastsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function PodcastsService_Factory() { return new PodcastsService(i0.ɵɵinject(i1.HttpClient)); }, token: PodcastsService, providedIn: "root" });
