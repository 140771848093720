<div class="content-ad">
  <app-ad path="Podcasts/top" sizesDesktop="[970,250],[728,90]" sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"></app-ad>
</div>

<app-header url="/assets/images/podcasts-header.png" [has-blurred-frame]="false"></app-header>

<app-ad *ngIf="!isMobile" path="Podcasts/especial" [metadata]="{ bloco: 'especial' }" [wallpaper]="true"></app-ad>

<div class="podcasts-breadcrumb-container">
  <app-breadcrumb></app-breadcrumb>
</div>
<section class="podcasts">
  <div class="podcasts-wrapper">
    <div class="podcasts-list" #adsElement>
      <app-card-list *ngIf="podcasts" [is-podcasts]="true" [return-genders]="false" [show-genders]="false"
        [content]="podcasts" [items-per-row]="itemsPerRow"></app-card-list>
    </div>
  </div>

  <app-ad path="Podcasts/bottom" sizesDesktop="[970,90]" sizesMobile=" [320,50], [320,100]"
    [metadata]="{ bloco: 'bottom' }"></app-ad>
</section>