<div class="frames">
  <app-list-header *ngIf="title" [title]="title" [has-search]="hasSearch" (toggle-search)="toggleSearch()">
  </app-list-header>

  <app-list-search *ngIf="!hideSearch" (hide-search)="toggleSearch()" (search)="search($event)"></app-list-search>

  <div *ngIf="isAccordion" class="frames-list">
    <div *ngFor="let content of contentList; let i = index" class="frame" [ngClass]="{ active: currentContent === i }">
      <div class="frame-header" (click)="setCurrentContent(i)">
        <h2 class="frame-header-title" [innerHtml]="content.title"></h2>
        <span class="frame-header-arrow" title="Expandir">          
          <i class="fa fa-chevron-down"></i>
        </span>
      </div>
      <div class="frame-content" [ngClass]="{ 'is-card': accordionWithCard }">
        <div class="frame-content-image">
          <img onerror="this.src='../../../assets/images/default-image.png'" loading="lazy" [src]="content.thumbnail" alt="" />
        </div>
        <div class="frame-content-text">
          <div [innerHtml]="content.description | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAccordion && hasAction" class="frames-list" [ngClass]="{ 'has-subtitle': hasSubtitle }">
    <div *ngFor="let content of contentList; let i = index" class="frame" [ngClass]="{ active: currentContent === i }">
      <div class="frame-header">
        <h2 class="frame-header-title" [innerHtml]="content.title"></h2>
        <a [routerLink]="sendToInscriptions(content)">Inscreva-se</a>
      </div>
      <div *ngIf="hasSubtitle" class="frame-subheader">
        <p [innerHtml]="content.description.slice(0, 250) + '...'"></p>
      </div>
    </div>
  </div>

  <div
    *ngIf="contentList?.length === 0 && filterTerm !== ''"
    class="search-results"
  >
    <p class="search-results-title">
      Não foram encontrados resultados para
      <strong>"{{ filterTerm }}"</strong>
    </p>

    <ul class="search-results-list">
      <li>Confira se digitou o termo corretamente</li>
      <li>Tente usar termos mais simples</li>
    </ul>
  </div>

  <button *ngIf="content && content.length > 0 && hasMore" class="frames-load-more" (click)="loadMoreContent()">
    Carregar Mais {{ title }}
  </button>
</div>
