<div *ngIf="program" class="program">
  <app-quick-access-menu *ngIf="program" [components]="quickAccessMenuComponents"></app-quick-access-menu>

  <app-ad
    *ngIf="program && program.adunitname"
    [path]="'Programas/' + program.adunitname+'/top'"
    sizesDesktop="[970,250],[728,90]"
    sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"
  ></app-ad>

  <app-header [url]="program.header"></app-header>

  <app-ad
    *ngIf="program && program.adunitname && !isMobile"
    [path]="'Programas/' + program.adunitname+'/especial'"
    [metadata]="{ bloco: 'especial' }"
    [wallpaper]="true"
  ></app-ad>
  <div class="wrapper">
    <section *ngIf="program.about" class="wrapper flex">
      <section class="about">
        <app-about
          id="about"
          title="Sobre o Programa"
          [content]="program.about.description"
          [title]="program.title"
          [header]="program.about.thumbnail"
          [full-width]="false"
        ></app-about>
      </section>

      <section class="stay-inside">
        <app-stay-inside
          *ngIf="program.notices && program.notices.length > 0"
          id="notices"
          title="Fique por Dentro"
          [stay-inside]="program.notices"
          [program]="program"
        ></app-stay-inside>
        <app-ad
          [path]="'Programas/' + program.adunitname+'/between'"
          sizesDesktop="[300, 250]"
          sizesMobile="[300, 250]"
          [metadata]="{bloco: 'bloco-1'}"
        ></app-ad>
      </section>
    </section>

    <section *ngIf="program.judgesEditionYears && program.judges" class="judges" id="judges">
      <h2 class="title" [innerText]="'Jurados'"></h2>

      <app-tab
        [content-list]="program.judgesEditionYears"
        (receive-data)="changeJudgeYear($event)"
        [current-index]="0"
        [sort]="false"
      ></app-tab>

      <app-list
        id="judges"
        [has-search]="false"
        [content]="program.judges"
        [is-accordion]="true"
        [has-subtitle]="false"
        [accordion-with-card]="true"
        endpoint="medias"
      ></app-list>
    </section>
    <app-ad
      [path]="'Programas/' + program.adunitname+'/between'"
      sizesDesktop="[728,90]"
      sizesMobile="[320, 50]"
      [metadata]="{bloco: 'bloco-2'}"
    ></app-ad>
    <section *ngIf="program.photos" class="photos" id="photos">
        <h2 class="title" [innerText]="'Fotos'"></h2>

        <app-tab
          [content-list]="program.photosEditions"
          (receive-data)="changePhotosEdition($event)"
          [current-index]="0"
          [sort]="false"
        ></app-tab>

        <app-gallery [galleryImages]="program.photos"></app-gallery>
    </section>
    <app-ad
      [path]="'Programas/' + program.adunitname+'/between'"
      sizesDesktop="[728,90]"
      sizesMobile="[320, 50]"
      [metadata]="{bloco: 'bloco-3'}"
    ></app-ad>

    <section *ngIf="program.videos && program.videos.length > 0 && program.videos[0].length > 0">
      <app-videos
        id="videos"
        title="Vídeos"
        [videos]="program.videos"
        [redirect-to-sbt-videos]="program.showVideosOnSbtVideos"
        [slug]="program.slug"
      ></app-videos>
    </section>
    <app-ad
      [path]="'Programas/' + program.adunitname+'/between'"
      sizesDesktop="[728,90]"
      sizesMobile="[320, 50]"
      [metadata]="{bloco: 'bloco-4'}"
    ></app-ad>
    <section *ngIf="program.winners" id="winners">
      <h2 class="title" [innerText]="'Ganhadores'"></h2>

      <app-tab
        [content-list]="program.winnersEditionYears"
        (receive-data)="changeWinnersYear($event)"
        [current-index]="0"
        [sort]="false"
      ></app-tab>

      <app-list
        id="winners"
        [has-search]="false"
        [content]="program.winners"
        [is-accordion]="true"
        [has-subtitle]="false"
        [accordion-with-card]="true"
        endpoint="medias"
      ></app-list>
      <app-ad
        *ngIf="program && program.adunitname"
        [path]="'Programas/' + program.adunitname+'/bottom'"
        sizesDesktop="[970,90]"
        sizesMobile=" [320,50], [320,100]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </section>
  </div>
</div>
