var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { SbtVideosProgramService } from './sbt-videos-program.service';
import { TitleService } from 'src/app/services/title.service';
import Pagination from 'src/app/models/pagination.class';
import { isMobile } from 'src/app/utils/util.browser';
import { StationService } from '../../services/station.service';
import { generateTitleSlug } from 'src/app/utils/utils.generators';
// import { MetatagsService } from 'src/app/services/metatags.service'
export class SbtVideosProgramComponent {
    constructor(service, route, title, stationService, router, 
    // private metaService: MetatagsService,
    meta) {
        this.service = service;
        this.route = route;
        this.title = title;
        this.stationService = stationService;
        this.router = router;
        this.meta = meta;
        this.isMobile = false;
        this.renderAd = true;
        this.urlVideo = '';
        this.subscriptions = [];
        this.currentVideo = '';
        this.stationAdUnit = '';
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('video');
            if (myParam != null) {
                this.currentVideo = myParam;
            }
            else {
                this.route.fragment.subscribe(fragment => {
                    if (!!fragment && fragment.includes('videos')) {
                        this.currentVideo = fragment.replace('videos', '');
                    }
                });
            }
            this.urlVideo = this.router.url;
            const pagination = this.pagination ? this.pagination : null;
            this.isMobile = isMobile();
            const slugProgramSubscription = yield this.route.params.subscribe(params => (this.slugProgram = params['slug']));
            yield this.service.getProgramBySlug(this.slugProgram).then(program => {
                this.program = program;
                this.setStationAdUnit();
            });
            const videosByProgramSubscription = yield this.route.params.subscribe(params => {
                this.currentPlaylist = params['playlist']
                    ? params['playlist']
                    : 'todos';
                this.service
                    .getVideosByProgram(this.program.id, this.currentPlaylist !== 'todos' ? this.currentPlaylist : null, pagination)
                    .then(({ results, next }) => {
                    this.videos = results;
                    const currentVideoObject = this.videos[0] ? this.videos[0] : null;
                    // this.updateMetaTags(currentVideoObject)
                    if (params['video'] && this.currentVideo === '') {
                        this.currentVideo = params['video'];
                    }
                    else if (this.videos.length > 0 && this.currentVideo === '') {
                        this.currentVideo = this.videos[0].id;
                        this.urlVideo = this.router.serializeUrl(this.router.createUrlTree([
                            `sbt-videos/${params['slug']}/${this.currentPlaylist}/${this.currentVideo}/${generateTitleSlug(this.videos[0].title)}`
                        ]));
                    }
                    // this.setTitleByVideo(this.currentVideo)
                    if (next) {
                        this.pagination = new Pagination(next);
                    }
                });
            });
            // await this.service
            //   .getPlaylists(this.program.id)
            //   .then(playlistResponse => (this.playlists = playlistResponse))
            // const renderAdSubscription = this.router.events.subscribe((event: any) => {
            //   if (event instanceof NavigationEnd) {
            //     this.renderAd = false
            //     setTimeout(() => {
            //       this.renderAd = true
            //     }, 100)
            //   }
            // })
            this.subscriptions = [
                slugProgramSubscription,
                videosByProgramSubscription
            ];
        });
    }
    // updateMetaTags (currentVideoObject: any): void {
    //   // this.metaService.removeTags(this.meta)
    //   const metatags = [
    //     { name: 'description', content: currentVideoObject.title },
    //     { name: 'keywords', content: currentVideoObject.keywordsearch.join(',') },
    //     { property: 'og:type', content: 'website' },
    //     { property: 'og:locale', content: 'pt_BR' },
    //     { property: 'og:site_name', content: 'SBT - Sistema Brasileiro de Televis&atilde;o' },
    //     { property: 'og:title', content: currentVideoObject.title },
    //     { property: 'og:description', content: currentVideoObject.title },
    //     { property: 'og:image', content: `https://i.ytimg.com/vi/${currentVideoObject.id}/maxresdefault.jpg` },
    //     { property: 'og:url', content: window.location.href },
    //     { name: 'twitter:card', content: 'summary' }
    //   ]
    //   // this.metaService.updateTags(metatags, this.meta)
    // }
    setStationAdUnit() {
        if (this.stationService.checkStationCookie()) {
            const stationIdentifier = this.stationService.getStationCookie();
            if (stationIdentifier) {
                const station = this.stationService.getStation(stationIdentifier);
                if (station && station[0] && this.program.regional !== 0) {
                    this.stationAdUnit = `_${station[0].adUnitName}`;
                }
            }
        }
    }
    setTitleByVideo(currentVideo) {
        const video = this.videos.find(video => video.id === currentVideo);
        this.title.setTitle(`${video.title} - SBT`);
    }
    alphabeticSort(first, second) {
        if (first && first.title && second && second.title) {
            if (first.title.toLowerCase() < second.title.toLowerCase()) {
                return -1;
            }
            if (first.title.toLowerCase() > second.title.toLowerCase()) {
                return 1;
            }
            return 0;
        }
    }
    buildRoute() {
        if (this.currentPlaylist) {
            return `sbt-videos/${this.slugProgram}/${this.currentPlaylist}`;
        }
        return `sbt-videos/${this.slugProgram}`;
    }
    loadMore(pagination) {
        if (pagination.hasMore) {
            this.service
                .getVideosByProgram(this.program.id, this.currentPlaylist !== 'todos' ? this.currentPlaylist : null, pagination)
                .then(({ results, next }) => {
                this.videos.push(...results);
                this.currentVideo = this.videos[0].id;
                if (next) {
                    this.pagination = new Pagination(next);
                }
            });
        }
    }
    getPlaylist(event) {
        this.currentPlaylist = event['link'];
        const pagination = this.pagination ? this.pagination : null;
        this.service
            .getVideosByProgram(this.program.id, this.currentPlaylist !== 'todos' ? this.currentPlaylist : null, pagination)
            .then(({ results, next }) => {
            this.videos = results;
            // console.log(results)
            const currentVideoObject = this.videos[0] ? this.videos[0] : null;
            // this.updateMetaTags(currentVideoObject)
            if (next) {
                this.pagination = new Pagination(next);
            }
        });
    }
}
