<section class="station" #station>
  <div class="container">
    <div class="station-content">
      <div class="station-select">
        <label class="station-label" [innerHtml]="topLabel"></label>
        <div class="select-mask-wrapper">
          <select
            #select
            [formControl]="stationField"
            data-analytics="mudar-regional/afiliada"
            (change)="changeStation($event)"
          >
            <option
              *ngFor="let station of stationsList"
              [value]="station.identifier"
              [innerHtml]="station.title"
              [disabled]="station.identifier === null"
            ></option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="stationLogo" class="station-logo">
      <img [src]="stationLogo" alt="" />
    </div>
    <div class="station-icon" (click)="openStation()" data-analytics="emissora">
      <img src="../../../assets/images/icone-antena.png" alt="" />
    </div>
  </div>
</section>
