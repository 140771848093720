<div class="menu-editions" [ngClass]="extraClasses">

  <div class="menu-editions-container" #editionsList>

    <div class="content-previous" (click)="prev()">
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>
    </div>

    <div class="content-wrapper" #tabItens>
      <div *ngFor="let edition of editions; let i = index;" class="menu-editions-item">
        <p [ngClass]="{'active': i === editionIndex}" [innerText]="edition.title" class="title" (click)="setEdition($event, i)"></p>
      </div>
    </div>

    <div class="content-next" (click)="next()">
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>
    </div>
  </div>
</div>
