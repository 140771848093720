<section class="schedules">
  <div class="content-ad">
    <app-ad
    path="Programacao/top"
    sizesDesktop="[970,250],[728,90]"
    sizesMobile="[320,100],[320, 50]"
    [metadata]="{ bloco: 'top' }"
  ></app-ad>
  </div>
  <app-header
    url="../../../assets/images/programacao-header.png"
    [has-blurred-frame]="false"
  ></app-header>

  <app-ad
    *ngIf="!isMobile"
    path="Programacao/especial"
    [metadata]="{ bloco: 'especial' }"
    [wallpaper]="true"
  ></app-ad>
  
  
  <div class="schedules-breadcrumb-container">
    <app-breadcrumb></app-breadcrumb>
  </div>

  <section class="schedules-content">
    <div class="wrapper">
      <section>
        <app-programming-grid></app-programming-grid>
      </section>

      <aside class="schedules-highligth">
        <section class="schedules-ads">
          <app-ad
            path="Programacao/between"
            sizesDesktop="[300,250], [360,640], [300,600], [336,280]"
            sizesMobile="[300,250], [320,50] "
            [metadata]="{ bloco: 'bloco-1' }"
          ></app-ad>
        </section>

        <h2 class="highligth-title">Em Alta</h2>

        <app-lego-list
          *ngIf="highlightList"
          [has-title]="false"
          [list]="highlightList"
          [show-more]="false"
          [full-items]="true"
          size="small"
        ></app-lego-list>
      </aside>
    </div>

    <section class="schedules-ads">
      <app-ad
        path="Programacao/bottom"
        sizesDesktop="[970,90]"
        sizesMobile=" [320,50], [320,100]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </section>
  </section>
</section>
