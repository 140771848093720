import { URL } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MarketOptinService {
    constructor(_http) {
        this._http = _http;
    }
    sendInfo(body) {
        return this._http
            .post(`${URL}/api/market-optin`, body)
            .toPromise()
            .then(response => {
            console.log(response);
            return response;
        })
            .catch(error => {
            console.log(error);
            return error;
        });
    }
}
MarketOptinService.ɵprov = i0.ɵɵdefineInjectable({ factory: function MarketOptinService_Factory() { return new MarketOptinService(i0.ɵɵinject(i1.HttpClient)); }, token: MarketOptinService, providedIn: "root" });
