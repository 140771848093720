<section *ngIf="hasContent && loaded">
  <div class="notices" *ngIf="notices && notices.length > 0">
    <div class="notices-ads">
      <app-ad *ngIf="program && program.adunitname"
        [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/top'" sizesDesktop="[970,250],[728,90]"
        sizesMobile="[320,100],[320, 50]" [metadata]="{ bloco: 'top' }"></app-ad>
    </div>
    <div *ngFor="let notice of notices; let i = index">

      <app-header *ngIf="i === 0 && notice.programHeader" [url]="notice.programHeader" [alt]="program.title"
        [route]="notice.programRoute" [has-blurred-frame]="false"></app-header>

      <app-ad *ngIf="program && program.adunitname && !isMobile"
        [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/especial'" [metadata]="{ bloco: 'especial' }"
        [wallpaper]="true"></app-ad>

      <div class="wrapper">
        <section class="notice">
          <section class="notice-container">
            <app-video *ngIf="notice.video" [fixable]="true" [url]="notice.video" [adunit]="program.adunitname"
              [pause-video]="pauseVideo"></app-video>

            <app-breadcrumb [items]="itemsBreadcrumb"></app-breadcrumb>

            <h1 class="notice-title" [innerText]="notice.title" inViewport
              (inViewportAction)="changeUrlState($event, notice)" [inViewportOptions]="{ threshold: [2] }"></h1>
            <!-- Start Audima Widget Injection -->
            <div id="audimaWidget" #audimaWidget></div>
            <!-- End Audima Widget Injection -->
            <h2 class="notice-subtitle" [innerText]="notice.content"></h2>
            <span class="notice-infos">por:
              <strong class="notice-author" [innerText]="notice.author"></strong>
              |
              <time [innerText]="notice.createdAt | date: 'dd/MM/yyyy \'às\' HH:mm'"></time>
            </span>

            <app-share></app-share>
            <app-button-youtube *ngIf="showButtonYoutube" [youtube-buttons]="program.youtubeSubscribeButton">
            </app-button-youtube>

            <div class="notice-content" *ngFor="let description of notice.descriptions; let i = index">
              <div
                *ngIf="description.type === typeDescriptionEnum.TEXT && !isMediastreamIframe(description.content) && !!description.content && notice"
                [innerHtml]="description.content | safeHtml" inViewport
                (inViewportAction)="changeUrlState($event, notice)" [inViewportOptions]="{ threshold: [2] }"></div>

              <app-playbuzz *ngIf="hasPlaybuzz(description.content)"></app-playbuzz>
              <app-twitter *ngIf="hasTwitter(description.content)"></app-twitter>
              <app-instagram *ngIf="hasInstagram(description.content)"></app-instagram>

              <app-video *ngIf="description.type === typeDescriptionEnum.IFRAME && !!description.content && notice"
                [fixable]="true" [is-fixed]="description.isFixable" [url]="description.content"
                [adunit]="program.adunitname" [pause-video]="pauseVideo" inViewport
                (inViewportAction)="changeUrlState($event, notice)" [inViewportOptions]="{ threshold: [2] }"
                (fixVideo)="onFixVideo($event)">
              </app-video>

              <app-live-mediastream *ngIf="isMediastreamIframe(description.content) && description.content && notice"
                [url]="getIframeURL(description.content).replace('?autoplay=false', '')"></app-live-mediastream>
            </div>

            <app-gallery *ngIf="notice.images" [galleryImages]="notice.images" [reduced]="true"></app-gallery>

            <div *ngIf="isMobile && showAudima">
              <app-audima-banner [idnotice]="notice.id"></app-audima-banner>
            </div>

            <div *ngIf="isMobile && !showAudima">
              <section class="ads">
                <app-ad [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/between'"
                  sizesDesktop="[300, 250]" sizesMobile="[300, 250], [300, 50]" [metadata]="{ bloco: 'bloco-2' }">
                </app-ad>
              </section>
            </div>

            <app-pill [values]="notice.keywords"></app-pill>

            <button *ngIf="hasProgramComments" (click)="openComments()" class="notice-button-comments">
              Abrir os comentários
            </button>
          </section>

          <section class="notice-right-container">
            <section class="ads content-second-ads">
              <app-ad [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/between'"
                sizesDesktop="[300, 250]" sizesMobile="[300, 250], [300, 50]" [metadata]="{ bloco: 'bloco-1' }">
              </app-ad>
            </section>

            <app-lego-list *ngIf="notice.relatedNotices" [title]="'Veja Também'" [list]="notice.relatedNotices"
              [show-more]="false" [show-badge]="false" [full-items]="true" size="small" class="see-more"
              scrollNotice="controlNotices"></app-lego-list>


            <div *ngIf="!isMobile && showAudima" (scroll)="onscroll()" [ngClass]="bannerfixed?'fixed':'nofixed'">
              <app-audima-banner [idnotice]="notice.id"></app-audima-banner>
            </div>

            <div *ngIf="!isMobile && !showAudima">
              <section class="ads">
                <app-ad [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/between'"
                  sizesDesktop="[300, 250]" sizesMobile="[300, 250], [300, 50]" [metadata]="{ bloco: 'bloco-2' }">
                </app-ad>
              </section>
            </div>

          </section>
        </section>
      </div>
    </div>
    <div class="wrapper">

      <div (scroll)="onscroll()" [ngClass]="bannerFixedFooter?'fixedFooter':'noFixedFooter'" (click)="closedBanner()"
        *ngIf="buttonClosedBanner && program && program.adunitname">
        <button class="closedBanner">X</button>
        <app-ad *ngIf="program && program.adunitname"
          [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/anchor'" sizesDesktop="[728,90], [970,90]"
          sizesMobile="[320,50]" margin="0" padding="20px 0" [metadata]="{ bloco: 'anchor' }"></app-ad>
      </div>

      <app-ad *ngIf="!isMobile && program && program.adunitname"
        [path]="'Programas' + stationAdUnit + '/' + program.adunitname+'/bottom'" sizesDesktop="[970,90]"
        sizesMobile="[320,50], [320,100]" [metadata]="{ bloco: 'bottom' }"></app-ad>
    </div>

    <!-- <app-comments [active]="showComments" (close)="hideComments($event)" [page-id]="pageId"></app-comments> -->

    <div class="wrapper">
      <div inViewport (inViewportAction)="loadNextNotice($event)" [inViewportOptions]="{ threshold: [0] }"
        class="notice-load-more">
        <i class="fa fa-spinner spin"></i>
      </div>
    </div>
  </div>
</section>

<section *ngIf="!hasContent && loaded">
  <app-not-found></app-not-found>
</section>