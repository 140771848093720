import { HttpClient } from '@angular/common/http';
import { URL_INSCRIPTIONS, URL_USERS_API } from '../app.settings';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
import * as i3 from "@angular/router";
export class UploadService {
    constructor(_http, userService, router) {
        this._http = _http;
        this.userService = userService;
        this.router = router;
    }
    errorHandler({ error }) {
        const errorText = error.error;
        throw new Error(errorText);
    }
    uploadFile(file) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        let url;
        if (this.userService.isLoggedIn()) {
            const user = this.userService.getUser();
            if (this.router.url.includes('/meu-sbt/meus-dados')) {
                url = `${URL_USERS_API}/api/upload/${user.email}`;
            }
            if (this.router.url.includes('inscricoes')) {
                const inscriptionId = this.router.routerState.snapshot.root.children[0].params.inscription;
                url = `${URL_INSCRIPTIONS}/api/upload/${inscriptionId}/${user.email}`;
            }
        }
        else {
            url = `${URL_INSCRIPTIONS}/api/upload`;
        }
        return this._http
            .post(url, formData)
            .toPromise()
            .then(response => {
            return response;
        })
            .catch(this.errorHandler);
    }
}
UploadService.ɵprov = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Router)); }, token: UploadService, providedIn: "root" });
