import { getWinVar, callWinFN } from '../utils/util.window';
import { Log } from '../utils/util.console';
import * as i0 from "@angular/core";
export class NaveggClass {
    constructor() {
        this.setOnboard = (user, timeout = 0) => {
            if (window) {
                try {
                    setTimeout(() => {
                        const NaveggInstalled = getWinVar('Navegg');
                        if (NaveggInstalled) {
                            callWinFN('nvg72620.setOnboard', {
                                prtid: user.naveggAccount,
                                prtusride: user.emailHash
                            });
                        }
                    }, timeout);
                }
                catch (e) {
                    Log('NAVEGG', `Error on call setOnboard: ${e.message}`, false);
                }
            }
        };
        this.sendAnalyticsData = (attrs, events, timeout = 0) => {
            if (window) {
                // console.log(`[NAVEGG]: name = ${user.nameHash}, email = ${user.emailHash}`)
                try {
                    setTimeout(() => {
                        const NaveggInstalled = getWinVar('Navegg');
                        if (NaveggInstalled) {
                            const nvgObj = window['nvg72620'];
                            if (nvgObj) {
                                // console.log(`[NAVEGG]: Function sendAnalyticsData ${nvgObj.sendAnalyticsData ? 'EXISTS' : 'NOT EXISTS'} in nvg72620`)
                                nvgObj.sendAnalyticsData({
                                    attrs,
                                    events
                                });
                                Log('NAVEGG', `Sending data to Navegg: ${JSON.stringify({ attrs, events })}`, false);
                            }
                            else {
                                Log('NAVEGG', 'nvg72620 not exists', false);
                            }
                        }
                    }, timeout);
                }
                catch (e) {
                    Log('NAVEGG', `Error on call sendAnalyticsData: ${e.message}`, false);
                }
            }
        };
    }
}
NaveggClass.ɵprov = i0.ɵɵdefineInjectable({ factory: function NaveggClass_Factory() { return new NaveggClass(); }, token: NaveggClass, providedIn: "root" });
