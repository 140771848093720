import { Component, OnInit, OnDestroy } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { ANALYTICS_ID, DEBUG } from '../../app.settings'

import { ProgramService } from './program.service'
import Program from '../../models/program.class'
import { ScrollService } from '../../services/scroll.service'
import { ActivatedRoute, Params } from '@angular/router'
import { MetatagsService } from '../../services/metatags.service'
import { program } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { AnalyticsService } from 'src/app/services/analytics.service'
import { StationService } from 'src/app/services/station.service'
import { isMobile } from '../../utils/util.browser'
import { BrandingService } from 'src/app/services/branding.service'
import { generateTitleSlug } from 'src/app/utils/utils.generators'
import { ComscoreService } from 'src/app/services/comscore.service'
import { CanonicalService } from 'src/app/services/canonical.service'

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
  providers: [ProgramService, ScrollService, CanonicalService]
})
export class ProgramComponent implements OnInit, OnDestroy {
  public program: Program
  public loaded: boolean = false
  public renderOrder: any[]
  public hasContent: boolean
  public quickAccessMenuComponents: any[]
  public componentsWithAds: any[]
  public isMobile: boolean = false
  public stationAdUnit: string = ''
  public initialComponent: any
  public itemsBreadcrumb: Array<object> = []
  public subscriptions = []

  constructor(
    private _service: ProgramService,
    private title: TitleService,
    private _scrollService: ScrollService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private stationService: StationService,
    private meta: Meta,
    private metasService: MetatagsService,
    private brandingService: BrandingService,
    private canonicalService: CanonicalService,
    private comScoreService: ComscoreService
  ) { }

  ngOnDestroy(): void {
    this.brandingService.setBranding(null)

    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe()
      }
    })
    this.canonicalService.removeLink()
  }

  ngOnInit(): void {
    this.canonicalService.appendLink()
    this.isMobile = isMobile()
    const routeParams = this.route.params.subscribe((params: Params) => {
      if (params.slug) {
        this.getProgram(params.slug)
      }
    })

    this.subscriptions.push(routeParams)
  }

  formatSlug(slug: string): string {
    return slug.split('-').join('')
  }

  setStationAdUnit() {
    if (this.stationService.checkStationCookie()) {
      const stationIdentifier = this.stationService.getStationCookie()

      if (stationIdentifier) {
        const station = this.stationService.getStation(stationIdentifier)

        if (station && station[0] && this.program.regional !== 0) {
          this.stationAdUnit = `_${station[0].adUnitName}`
        }
      }
    }
  }

  renderQuickAccessMenu(program: Program): void { }

  onUpdateUrlState(title) {
    const sectionTitle = generateTitleSlug(title)

    const currentUrlWithHash: string = `${window.location.pathname}${window.location.hash
      }`
    const currentUrl: string = window.location.pathname

    const newUrl = `${currentUrl}#${sectionTitle}`

    if (newUrl !== currentUrlWithHash) {
      window.history.pushState({}, '', `${newUrl}`)

      const hasStation = !!this.stationService.checkStationCookie()
      const stationIdentifier = this.stationService.getStationCookie()
      this.title.setTitle(`${this.program.title.toString()} - ${title} - SBT`)

      const station = this.stationService.getStation(stationIdentifier)[0] || ''
      // this.analyticsService.sendPageView(newUrl, this.program.title.toString(), (hasStation) ? station.identifier : 'nacional')
      // this.comScoreService.sendPageView()
    }
  }

  getProgram(slug: string): void {
    this._service.getProgramBySlug(slug).then(response => {
      const header = response.header
      this.loaded = false

      if (response.loaded !== undefined && !response.loaded) {
        this.hasContent = false
        this.loaded = true

        return
      }

      this.quickAccessMenuComponents = response.quickAccessMenu
      this.quickAccessMenuComponents = this.quickAccessMenuComponents.map(
        menu => {
          return {
            ...menu,
            showElement: true
          }
        }
      )
      this.hasContent = true
      this.program = response.program
      this.setStationAdUnit()

      this.title.setTitle(`${this.program.title.toString()} - SBT`)
      this.renderOrder = response.renderOrder
        .sort((current, next) => current.order - next.order)
        .filter(response => response.type !== 'header')

      this.loaded = true

      this.componentsWithAds = this.renderOrder
        .filter(component => this.hasAd(component.type))
        .map(component => component.type)

      if (location.hash) {
        this.initialComponent = this.quickAccessMenuComponents
          .find(component => `#${generateTitleSlug(component.title)}` === location.hash)
      }

      const branding = this.program.tagComscore || this.program.gender

      this.updateMetaTags(header)

      this.brandingService.setBranding(branding)

      console.log('this.program___', this.program)
      console.log('this.program___', this.program.title)

      this.program && this.program.gender && this.program.gender === 'Jornalismo' ?
        this.itemsBreadcrumb = [{ route: '/jornalismo', title: 'Jornalismo' },
        { route: '', title: this.program.title }] :
        this.itemsBreadcrumb = [{ route: '', title: this.program.title }]
    })
  }

  updateMetaTags(header: string): void {
    this.metasService.removeTags(this.meta)

    const keywords = this.program.description
      .split(' ')
      .map(text => text.toLowerCase().trim())

    const metatags = [
      { name: 'description', content: this.program.description },
      { name: 'keywords', content: keywords },
      { property: 'og:title', content: this.program.title },
      { property: 'og:description', content: this.program.description },
      { property: 'og:image', content: header || this.program.header },
      { property: 'og:url', content: window.location.href }
    ]

    this.metasService.updateTags([...program, ...metatags], this.meta)
  }

  getSlug(): string {
    let slug

    const routeParams = this.route.params.subscribe(params => {
      slug = params['slug']
    })

    this.subscriptions.push(routeParams)

    return slug
  }

  hasAd(componentType: string): boolean {
    const blockList = ['header', 'mediabox', 'notices']
    const pageComponents = this.renderOrder.filter(
      item => !blockList.includes(item.type)
    )
    const isLastItem =
      componentType === pageComponents[pageComponents.length - 1].type
    return !blockList.includes(componentType) && !isLastItem
  }

  adHorizontalIsVisible(componentType: string): boolean {
    return this.hasAd(componentType) && componentType !== 'about'
  }

  getAdBlockIndex(componentType: string): number {
    return this.componentsWithAds.indexOf(componentType) + 1
  }
}
