<section class="live">
  <div class="content-ad">
    <app-ad
      [path]="'Ao_Vivo' + stationAdUnit + '/top'"
      sizesDesktop="[970,250],[728,90]"
      sizesMobile="[320,100],[320, 50]"
      [metadata]="{ bloco: 'top' }"
    ></app-ad>
  </div>

  <app-header
    url="../../../assets/images/aovivo-header.png"
    [has-blurred-frame]="false"
  ></app-header>

  <app-ad
    *ngIf="!isMobile"
    [path]="'Ao_Vivo' + stationAdUnit + '/especial'"
    [metadata]="{ bloco: 'especial' }"
    [wallpaper]="true"
  ></app-ad>

  <div class="live-container">
    <div class="live-video">
      <section *ngIf="typeVideo === 'youtube'; else otherIframe" class="live-iframe">
        <app-video
        [fixable]="false"
        [program-title]="programTitle"
        [url]="videoUrl"
        [full-width]="true"
        [is-live]="true"
        ></app-video>
      </section>

      <ng-template #otherIframe>
        <div [innerHTML]="playerHTML" class="embed-container"></div>
      </ng-template>
    </div>

    <section class="live-grade">
      <div
        *ngFor="let item of schedule; let i = index"
        class="live-grade-item"
        [ngClass]="{ 'current-program': i === 0 }"
      >
        <div
          class="item-status"
          [innerText]="i === 0 ? 'AO VIVO' : 'A SEGUIR'"
        ></div>
        <div class="item-informations">
          <div class="item-schedules">
            <div class="item-name" [innerText]="item.title"></div>
            <div class="item-time">{{ item.startdate | date: "HH:mm" }}</div>
          </div>
          <div class="item-link" *ngIf="!item.exibe || item.exibe !== 'N'">
            <a
              class="item-button"
              [routerLink]="item.link"
              [ngClass]="{ 'yellow-button': i == 0 }"
            >
              Visite o Site
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="button-container">
      <a routerLink="/programacao">
        <button class="button-show-all-schedules">
          Ver Horários de Todos os Programas
        </button>
      </a>
    </section>

    <app-list-article
      *ngIf="videos && currentProgram"
      id="ultimos-videos"
      [title]="videosTitle"
      [content]="videos"
      url="/sbt-videos"
    >
    </app-list-article>

    <section class="live-ads">
      <app-ad
        [path]="'Ao_Vivo' + stationAdUnit + '/bottom'"
        sizesDesktop="[970,90]"
        sizesMobile="[320,50], [320,100]"
        [metadata]="{ bloco: 'bottom' }"
      ></app-ad>
    </section>
  </div>
</section>
