<section class="playlist">
  <app-video
    [fixable]="false"
    [program-title]="programTitle"
    [program-id]="programId"
    [url]="videoUrl"
    [adunit]="adunit"
    [full-width]="true">

  </app-video>
  <p>Vídeos da playlist atual</p>
  <section class="playlist-videos">
    <li
      *ngFor="let video of videos"
      class="video-container"
      [ngClass]="{ actived: video.id === currentVideo }"
      (click)="changeVideo(video)"
    >
      <div class="content">
        <div class="content-info">
          <div class="video-thumbnail">
            <img onerror="this.src='../../../assets/images/default-image.png'" [src]="video.thumbnail" loading="lazy" alt=""/>
          </div>
          <p class="video-title" [innerText]="video.title" [attr.data-title]="video?.title" [attr.data-pageview]="urlAnalytics + video?.id"></p>
        </div>
        <i class="fa fa-play-circle" title="Assistir"></i>        
      </div>
    </li>
    <div
      *ngIf="videos?.length > 0 && pagination && pagination.hasMore"
      class="video-container"
    >
      <div class="content">
        <div class="content-button">
          <button (click)="sendInfo()" [innerText]="'Ver Mais'" data-analytics="ver-mais-playlists"></button>
        </div>
      </div>
    </div>
  </section>
</section>
