<a 
  *ngFor="let item of content; let i=index" 
  class="card"
  [queryParams]="parameters[i]"
  [fragment]="fragment[i]"
  [routerLink]="getRouterLink(item, i)"
>
  <div class="card-container"
  (click)="goToLink($event, content[i].opcional5, getRouterLink(content[i]), parameters[i], fragment[i])"
  (mouseenter)="handleExternalLink($event)"
  >
    <div class="card-bold-title" [innerText]="item.title" [ngClass]="color"
    [ngStyle]="{ color: '#' + color || '' }"></div>
    <div
      class="card-title"
      *ngIf="item.description"
      [innerText]="item.description"
    ></div>
    <div class="card-info">
      <div
        class="card-date"
        [innerText]="item.startdate | date: 'dd/MM/yyyy \'às\' HH:mm'"
      ></div>
      <a 
        [queryParams]="parameters[i]"
        [fragment]="fragment[i]"
        [routerLink]="getRouterLink(item, i)"
      >
        <button class="card-button" [ngStyle]="{ background: '#' + color || '' }"
          (click)="goToLink($event, content[i].opcional5, getRouterLink(content[i]), parameters[i], fragment[i])"
          (mouseenter)="handleExternalLink($event)"
        >
          Confira <i class="fa fa-arrow-right"></i>
        </button>
      </a>
    </div>
  </div>
</a>
