<div>
  <app-accept-cookie></app-accept-cookie>
  <ng-progress [spinner]="false" [thick]="true" color="#e2de1c" aria-label="progress-bar" aria-labelledby="progress-bar"></ng-progress>

  <app-menu [show-station-tab]="showStationTab"></app-menu>

  <div class="dialogLocalization" (scroll)="onscroll()" [ngClass]="localizationFixed?'fixed':'noFixed'"
    *ngIf="localization">
    <span class="localization-text">
      <p class="titleLocalization"> Você autoriza compartilhar a sua localização?</p>
    </span>
    <span class="localization-button">
      <button class="button-none" (click)="localizationNotAccepted()">Não, obrigado</button>
      <button class="button-accepted" (click)="localizationAccepted()">
        <i class="fa fa-map-marker"></i>
        Autorizo
      </button>
    </span>
  </div>

  <div class="webpush" *ngIf="push">
    <div class="webpush-dialog">
      <div class="webpush-info">
        <div class="webpush-close" (click)="webPushClose()">
          <i class="fa fa-close"></i>
        </div>
        <img src="./../assets/images/logo-sbt.png" alt="Logo SBT">
        <p>Gostaria de receber notificações sobre as últimas notícias e atualizações? </p>
      </div>
      <div class="webpush-button">
        <button class="button-none" (click)="webPushNotAccepted()">Não, obrigado</button>
        <button class="button-accepted" (click)="webPushAccepted()">
          <i class="fa fa-bell"></i>
          Aceito
        </button>
      </div>
    </div>
  </div>

  <section #siteContent [ngClass]="{ logged: userCookie }">
    <router-outlet class="app-content"></router-outlet>
    <app-footer></app-footer>
  </section>
</div>