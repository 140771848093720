<section *ngIf="section?.length > 0" class="container">
  <h3
    *ngIf="hasTitle"
    class="section-title"
    [innerText]="
      !section[0].program ? 'Vídeos do Programa' : section[0].program
    "
  ></h3>

  <div class="videos-wrapper" [ngClass]="{ white: isWhite }">
    <div
      *ngIf="section.length > 4"
      class="videos-control left"
      (click)="previousVideos()"
    >
      <i class="fa fa-chevron-left" title="Rolar para a esquerda"></i>

    </div>
    <div *ngIf="!redirectToSbtVideos" class="videos-sections-list" #videos>
      <div
        *ngFor="let video of section; let i = index"
        class="videos-sections-item"
        (click)="setCurrentVideo(video, i)"
        [ngClass]="{ active: i === currentActiveVideo }"
        [title]="video.title"
      >
        <div class="section-video">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="video.thumbnail"
            loading="lazy"
            alt=""
          />
          <h6
            class="video-title"
            [ngClass]="{ white: isWhite }"
            [innerText]="video.title"
          ></h6>
          <span
            *ngIf="video.publishedAt"
            class="video-published-date"
            [innerText]="video.publishedAt | date: 'dd/MM/yyyy \'às\' HH:mm'"
          ></span>
        </div>
      </div>
    </div>
    <div *ngIf="redirectToSbtVideos && program" class="videos-sections-list" #videos>
      <a
        *ngFor="let video of section; let i = index"
        class="videos-sections-item"
        [routerLink]="getRouterLink(video)"
        [ngClass]="{ active: i === currentActiveVideo }"
        [title]="video.title"
      >
        <div class="section-video">
          <img
            onerror="this.src='../../../assets/images/default-image.png'"
            [src]="video.thumbnail"
            alt=""
            loading="lazy"
          />
          <h6
            class="video-title"
            [ngClass]="{ white: isWhite }"
            [innerText]="video.title"
          ></h6>
          <span
            *ngIf="video.publishedAt"
            class="video-published-date"
            [innerText]="video.publishedAt | date: 'dd/MM/yyyy \'às\' HH:mm'"
          ></span>
        </div>
      </a>
    </div>
    <div
      *ngIf="section.length > 4"
      class="videos-control right"
      (click)="nextVideos()"
    >
      <i class="fa fa-chevron-right" title="Rolar para a direita"></i>      
    </div>
  </div>

  <div class="section-video" *ngIf="currentVideo !== ''">
    <button class="section-video-close" (click)="closeVideo()">
      Fechar o vídeo
    </button>
    <app-video [fixable]="true" [url]="currentVideo" [adunit]="programAdUnit" ></app-video>
  </div>
</section>
