var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { URL } from '../../app.settings';
import { Inscription } from '../../models/inscription.class';
import { Video } from '../../models/video.class';
import Home from '../../models/home.class';
import Program from '../../models/program.class';
import Station from '../../models/station.class';
import { StationService } from '../../services/station.service';
import Pagination from '../../models/pagination.class';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/station.service";
export class HomeService {
    constructor(_http, _stationService) {
        this._http = _http;
        this._stationService = _stationService;
    }
    getQuickAccessMenu() {
        return {
            items: [
                { id: 'programming-grid', title: 'Programação', showElement: true, order: 0 },
                { id: 'inscriptions', title: 'Inscrições', showElement: true, order: 1 },
                { id: 'programFav', title: 'Programa Favorito', showElement: false, order: 2 },
                { id: 'station', title: 'Destaques', showElement: false, order: 3 },
                { id: 'playlists', title: 'Playlists', showElement: false, order: 4 },
                { id: 'high', title: 'Em Alta!', showElement: true, order: 20 },
                { id: 'videos', title: 'Últimos Vídeos', showElement: true, order: 21 },
            ],
            initialOrderValue: 5
        };
    }
    getData(station, programFav) {
        let videos = [];
        if (station && station !== null) {
            videos.push(this._http
                .get(`${URL}/api/videosyoutube?limit=5&highlighted=s&regional=${station.id}`)
                .toPromise());
        }
        videos.push(this._http
            .get(`${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`)
            .toPromise());
        const inscriptions = this._http
            .get(`${URL}/api/medias?idplaylist=6170&limit=12&searchopcional2=s&orderby=ordem&sort=desc`)
            .toPromise();
        const requests = [...videos, inscriptions];
        return Promise.all(requests).then((response) => {
            const home = new Home(null);
            // get the dynamic playlists at home
            this._http.get(`${URL}/api/playlists?idsitearea=2376&orderby=startdate&sort=desc&limit=60`)
                .toPromise()
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                home.playlists = [];
                const playlistsList = yield response.results.map(playlist => playlist);
                const removeDuplicates = (playlists) => __awaiter(this, void 0, void 0, function* () {
                    return yield playlists
                        .map(playlist => playlist.title)
                        .map((playlist, index, final) => final.indexOf(playlist) === index && index)
                        .filter(index => playlists[index])
                        .map(index => playlists[index]);
                });
                const getId = title => {
                    let id = title.replace(/[^a-zA-Z ]/g, '');
                    id = id.split(' ').join('-');
                    id = id.toLowerCase();
                    return id;
                };
                const playlists = yield removeDuplicates(playlistsList);
                const quickAccessMenu = this.getQuickAccessMenu();
                home.quickAccessMenu = quickAccessMenu.items;
                yield playlists.forEach((playlist, index) => __awaiter(this, void 0, void 0, function* () {
                    yield this._http.get(`${URL}/api/playlists?idsitearea=2376&playlisttitle=${encodeURIComponent(playlist.title)}&limit=1&orderby=startdate`)
                        .toPromise()
                        .then(response => {
                        const id = getId(playlist.title);
                        const quickAccessMenuItem = {
                            id,
                            title: playlist.title,
                            showElement: true,
                            order: index + quickAccessMenu.initialOrderValue
                        };
                        home.quickAccessMenu.push(quickAccessMenuItem);
                        return response;
                    })
                        .then((response) => new Pagination(response.next))
                        .then((pagination) => __awaiter(this, void 0, void 0, function* () {
                        return yield this.getCustomMedia(`idplaylist=${playlist.id}&orderby=ordem&sort=desc`)
                            .then((medias) => __awaiter(this, void 0, void 0, function* () {
                            if (pagination.hasMore) {
                                playlist.pagination = pagination;
                                playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${encodeURIComponent(playlist.title)}&limit=1&orderby=startdate`;
                            }
                            const id = getId(playlist.title);
                            return {
                                title: playlist.title,
                                link: playlist.description,
                                color: playlist.opcionalplaylist3,
                                adUnitBlock: `bloco-${index + 1}`,
                                order: index,
                                id,
                                medias: [
                                    Object.assign({}, playlist, { medias })
                                ]
                            };
                        }));
                    }))
                        .then(playlist => {
                        home.playlists.push(playlist);
                        return playlist;
                    })
                        .then((playlist) => __awaiter(this, void 0, void 0, function* () {
                        const sortPlaylistsByOrder = (firstPlaylist, nextPlaylist) => {
                            if (firstPlaylist.order < nextPlaylist.order)
                                return -1;
                            if (firstPlaylist.order > nextPlaylist.order)
                                return 1;
                            return 0;
                        };
                        yield home.playlists.sort(sortPlaylistsByOrder);
                        return playlist;
                    }));
                }));
            }));
            if (response.length > 2) {
                home.videos = response[0].results.map(video => new Video(video));
                home.videos.push(...response[1].results.map(video => new Video(video)));
                home.inscriptions = response[2].results.map(inscription => new Inscription(inscription, null));
            }
            else {
                home.videos = response[0].results.map(video => new Video(video));
                home.inscriptions = response[1].results.map(inscription => new Inscription(inscription, null));
            }
            this.getPrograms().then(programs => (home.programs = programs));
            if (station && station !== null) {
                this.getAreaByStation(station.idsite, station.idsitearea).then(stationData => {
                    home.station = new Station({
                        title: station.title,
                        notices: stationData
                    });
                });
            }
            if (programFav && programFav !== null && programFav !== "null") {
                this._http
                    .get(`${URL}/api/notices?program=${programFav}&limit=3&orderby=publishdate&sort=desc`)
                    .toPromise()
                    .then((response) => {
                    const notices = response.results;
                    const program = notices[0].program;
                    const idProgram = notices[0].idprogram;
                    this._http
                        .get(`${URL}/api/programs?id=${idProgram}`)
                        .toPromise()
                        .then((response) => __awaiter(this, void 0, void 0, function* () {
                        const responseJSON = yield response
                            .results[0];
                        home.programFavTitle = yield program;
                        home.programFavRoute = yield `${responseJSON.gender.toLowerCase()}/${responseJSON.slug}`;
                        const programFavData = [
                            {
                                opcionalplaylist2: 'modulo-trio',
                                medias: notices.slice(0, 3)
                            }
                        ];
                        home.programFav = programFavData;
                    }));
                });
            }
            this.getHighlightedList().then(high => (home.inHigh = high));
            this.getMediabox().then(mediabox => (home.mediabox = mediabox));
            home.programmingGrid = { days: [], programs: [] };
            home.programmingGrid.days = this.generateDaysGrid();
            const date = home.programmingGrid.days[0].formattedDate;
            if (date) {
                this.getSchedule(date, station).then(schedule => (home.programmingGrid.programs = schedule));
            }
            return home;
        });
    }
    getMediabox() {
        return __awaiter(this, void 0, void 0, function* () {
            const mediaboxMedias = [];
            const hasStationCookie = yield this._stationService.checkStationCookie();
            if (hasStationCookie) {
                const stationIdentifier = yield this._stationService.getStationCookie();
                const station = yield this._stationService.getStation(stationIdentifier);
                if (station && station[0]) {
                    yield this._http
                        .get(`${URL}/api/medias?idsitearea=${station[0].idsiteareamediabox}&limit=10&orderby=ordem&sort=asc`)
                        .toPromise()
                        .then((response) => __awaiter(this, void 0, void 0, function* () {
                        if (response) {
                            const results = yield response.results;
                            yield mediaboxMedias.push(...results);
                        }
                    }));
                }
            }
            yield this._http
                .get(`${URL}/api/medias?limit=10&idsitearea=2091&orderby=ordem&sort=asc`)
                .toPromise()
                .then((response) => {
                if (response) {
                    const results = response.results;
                    mediaboxMedias.push(...results);
                }
                return null;
            });
            return mediaboxMedias;
        });
    }
    getPrograms() {
        return this._http
            .get(`${URL}/api/programs?showathome=S&noar=S&idregional=0`)
            .toPromise()
            .then((response) => {
            const programs = response;
            const hasStationCookie = this._stationService.checkStationCookie();
            if (hasStationCookie) {
                const stationIdentifier = this._stationService.getStationCookie();
                const station = this._stationService.getStation(stationIdentifier);
                if (station && station[0]) {
                    return this._http
                        .get(`${URL}/api/programs?showathome=S&noar=S&idregional=${station[0].id}`)
                        .toPromise()
                        .then((regionalResponse) => {
                        const regionalPrograms = regionalResponse.results.map(program => new Program(program));
                        const nationalPrograms = programs.results.map(program => new Program(program));
                        return [...regionalPrograms, ...nationalPrograms];
                    });
                }
            }
            return programs.results.map(program => new Program(program));
        })
            .catch(e => console.log(e));
    }
    getSchedule(date, station) {
        if (station) {
            return this._http
                .get(`${URL}/api/programscheduleregionais?datagrade=${date}&idregional=${station.id}&limit=49`)
                .toPromise()
                .then((response) => {
                const schedules = response.results;
                /* if (schedules.length === 0) {
                  return this._http
                    .get<any>(
                      `${URL}/api/programgrade?datagrade=${date}&limit=50`
                    )
                    .toPromise()
                    .then((response: any) => {
                      const schedules = response.results
      
                      return schedules
                    })
                } */
                return schedules;
            });
        }
        return this._http
            .get(`${URL}/api/programgrade?datagrade=${date}&limit=49`)
            .toPromise()
            .then((response) => {
            const schedules = response.results;
            return schedules;
        });
    }
    getArea(title) {
        return this._http
            .get(`${URL}/api/playlists?idsitearea=2376&playlisttitle=${title}&limit=3&orderby=startdate`)
            .toPromise()
            .then((response) => {
            const result = response;
            const playlists = result.results;
            const pagination = new Pagination(result.next);
            playlists.forEach(playlist => {
                if (pagination.hasMore) {
                    playlist.pagination = pagination;
                    playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${title}&limit=1&orderby=startdate`;
                }
                this.getCustomMedia(`idplaylist=${playlist.id}`).then(medias => (playlist.medias = medias));
            });
            return playlists;
        });
    }
    getAreaByStation(idsite, idsitearea) {
        return this._http
            .get(`${URL}/api/playlists?idsitearea=${idsitearea}&idsite=${idsite}&limit=3&orderby=startdate`)
            .toPromise()
            .then((response) => {
            const result = response;
            const playlists = result.results;
            const pagination = new Pagination(result.next);
            playlists.forEach(playlist => {
                if (pagination.hasMore) {
                    playlist.pagination = pagination;
                    playlist.nextQuery = `${URL}/api/playlists?idsitearea=${idsitearea}&idsite=${idsite}&limit=1&orderby=startdate`;
                }
                this.getCustomMedia(`idplaylist=${playlist.id}&orderby=ordem&sort=desc`).then(medias => (playlist.medias = medias));
            });
            return playlists;
        });
    }
    getCustomMedia(queryString) {
        return this._http
            .get(`${URL}/api/medias?${queryString}&limit=10`)
            .toPromise()
            .then((response) => {
            const medias = response.results;
            return medias;
        });
    }
    getInscriptions() {
        return this._http
            .get(`${URL}/api/medias?idplaylist=6170&searchopcional2=s`)
            .toPromise()
            .then((response) => {
            const inscriptions = response;
            return inscriptions.results.map(inscription => new Inscription(inscription, inscriptions.next));
        });
    }
    getVideos() {
        return this._http
            .get(`${URL}/api/videosyoutube?limit=12`)
            .toPromise()
            .then((response) => {
            const videos = response;
            return videos.results.map(video => new Video(video));
        });
    }
    getHighlightedList() {
        return this._http
            .get(`${URL}/api/highlighted?type=entretenimento&limit=6`)
            .toPromise()
            .then((response) => {
            const videos = response;
            return videos.results;
        });
    }
    generateDaysGrid() {
        let dates = [];
        for (let i = 0; i < 7; i++) {
            let date = new Date();
            date.setDate(date.getDate() + i);
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
            const year = date.getFullYear();
            let dateString = `${day}/${month}`;
            let dayString = this.getStringDay(date.getDay());
            let formattedDate = `${year}-${month}-${day}`;
            dates.push({
                title: dayString,
                date: dateString,
                formattedDate
            });
        }
        return dates;
    }
    getStringDay(dayIndex) {
        switch (dayIndex) {
            case 0:
                return `DOM`;
            case 1:
                return `SEG`;
            case 2:
                return `TER`;
            case 3:
                return `QUA`;
            case 4:
                return `QUI`;
            case 5:
                return `SEX`;
            case 6:
                return `SÁB`;
            default:
                return ``;
        }
    }
}
HomeService.ɵprov = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StationService)); }, token: HomeService, providedIn: "root" });
